import React from 'react';
import * as Yup from 'yup';
import BaseFormFields from '../../common-components/BaseFormFields';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import { getConfigSection } from '../../../common/utils';
import { mapValuesArrayToSelectComponentValues } from '../../common-components/common-functions';
import commonPropTypes from '../../../common/common-prop-types';
import { RequiredFieldsTip } from '../../common-components/RequiredFieldsTip';

const uiTexts = require('../../../resources/uiTexts.json');

const errorsTexts = require('../../../resources/errorTexts.json');

export default class FormFields extends React.PureComponent {
  static propTypes = {
    ...commonPropTypes.formFields,
  };

  static validationSchema() {
    return Yup.object().shape({
      merchantName: Yup.string().required(errorsTexts.mandatoryField),
    });
  }

  constructor(props) {
    super(props);
    const { authToken, customProps } = this.props;
    this.updateRefDataState = this.updateRefDataState.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
    this.state = {
      merchantList: customProps.merchantList,
    };
  }

  render() {
    const { textsKey, authToken } = this.props;

    const { merchantList } = this.state;
    const fieldsTextKey = `${textsKey}.form.fields`;
    const { renderGenericSelect } = BaseFormFields;
    const selectAnOptionText = getConfigSection(uiTexts, 'common.editForm.comboSelectAnOption');

    const fieldsRenderConfig = [
      {
        id: 'merchantName',
        useRequiredLabel: true,
        function: renderGenericSelect(
          true,
          mapValuesArrayToSelectComponentValues(
            merchantList.filter(item => item.name),
            'name',
            'id',
            {
              defaultValue: '',
              defaultText: selectAnOptionText,
            },
          ),
        ),
      },
    ];

    return (
      <>
        <RequiredFieldsTip />
        <BaseFormFields
          authToken={authToken}
          fieldsTextKey={fieldsTextKey}
          fieldsRenderConfig={fieldsRenderConfig}
          testIdPrefix="merchant-form"
        />
      </>
    );
  }

  updateRefDataState(refDataArray) {
    this.setState({ merchantList: refDataArray[0] });
  }
}
