import React from 'react';
import PropTypes from 'prop-types';
import BaseFormFields from '../../../common-components/BaseFormFields';
import ConfigurationDataApi from '../../../../api/ConfigurationData';
import DestinationsAccountsTable from './DestinationsAccountsTable';
import { addDefaultSelectOptionToSelectValues } from '../../../common-components/common-functions';
import { getConfigSection } from '../../../../common/utils';
import uiTexts from '../../../../resources/uiTexts.json';
import commonPropTypes from '../../../../common/common-prop-types';
import InfoIconText from '../../../common-components/InfoIconText';
import { getDestinationAccountsValidation } from './getDestinationAccountsValidation';

export default class SplitPaymentsPage extends React.PureComponent {
  static propTypes = {
    textsKey: PropTypes.string.isRequired,
    authToken: commonPropTypes.authToken,
    isFormEditable: PropTypes.bool.isRequired,
    dataStatusLoadCallback: PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    formData: PropTypes.object.isRequired,
    dispatchFormData: PropTypes.func.isRequired,
    setSubmittingDetails: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const { authToken } = this.props;

    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
    this.updateRefDataState = this.updateRefDataState.bind(this);
    this.state = {
      currencyList: [],
    };
  }

  render() {
    const {
      textsKey,
      authToken,
      isFormEditable,
      dataStatusLoadCallback,
      formData,
      dispatchFormData,
      setSubmittingDetails,
      valuesRef,
    } = this.props;

    const { renderGenericInput, renderGenericSelect } = BaseFormFields;
    const fieldsTextKey = `${textsKey}.form.fields`;
    const selectAnOptionText = getConfigSection(uiTexts, 'common.editForm.comboSelectAnOption');

    const {
      account: { programAccountType },
    } = formData;

    const { currencyList } = this.state;
    const fieldsRenderConfig = [
      {
        id: 'destinationAccountsConfiguration',
        isCustomNestedElement: true,
        ConstructorElement: DestinationsAccountsTable,
        constructorProps: {
          textsKey,
          valuesRef,
          dispatchFormData,
          setSubmittingDetails,
          isFormEditable,
          key: 'destination-accounts-table',
          validationSchema: getDestinationAccountsValidation({
            programAccountType,
          }),
          initialData: formData?.paymentSplitConfiguration?.destinationAccounts || [],
          tableRowTemplate: [
            {
              id: 'merchantReference',
              useRequiredLabel: true,
              function: renderGenericInput('text', isFormEditable),
              width: '400px',
            },
            {
              id: 'merchantProgramCode',
              function: renderGenericInput('text', isFormEditable),
            },
            {
              id: 'merchantAccno',
              useRequiredLabel: true,
              function: renderGenericInput('text', isFormEditable),
            },
            {
              id: 'merchantCurrency',
              useRequiredLabel: true,
              function: renderGenericSelect(
                isFormEditable,
                addDefaultSelectOptionToSelectValues(
                  currencyList.map(item => ({
                    value: item.isoCode,
                    label: item.isoCode,
                  })),
                  '',
                  '',
                  selectAnOptionText,
                ),
              ),
            },
          ],
        },
      },
    ];

    return (
      <>
        <div className="u-mb">
          <InfoIconText
            text={getConfigSection(
              uiTexts,
              'merchants.channels.form.fields.splitB2BPaymentsConfiguration.info.destinationAccountsInfo',
            )}
            testId="table-info-text"
          />
        </div>
        <BaseFormFields
          authToken={authToken}
          fieldsTextKey={fieldsTextKey}
          fieldsRenderConfig={fieldsRenderConfig}
          refDataLoadFunctions={[
            this.configurationDataApi.getCountries,
            this.configurationDataApi.getCurrencies,
            this.configurationDataApi.getCustomerGroups,
            this.configurationDataApi.getProductGroups,
          ]}
          refDataLoadCallback={this.updateRefDataState}
          dataStatusLoadCallback={dataStatusLoadCallback}
        />
      </>
    );
  }

  updateRefDataState(refDataArray) {
    this.setState({
      currencyList: refDataArray[1],
    });
  }
}
