import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';

export default function ValidationFieldErrorMessage(props) {
  const { fieldName, testId } = props;

  return (
    <ErrorMessage name={fieldName}>
      {message => (
        <p className="c-error-message" data-testid={testId}>
          {message}
        </p>
      )}
    </ErrorMessage>
  );
}

ValidationFieldErrorMessage.propTypes = {
  fieldName: PropTypes.string.isRequired,
};
