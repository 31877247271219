import React from 'react';
import { get } from '@turbopay/ts-helpers/object-utils';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Spinner } from 'cj-common-components';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import BaseEditForm from '../../common-components/BaseEditForm';
import FormFields from './FormFields';
import commonPropTypes from '../../../common/common-prop-types';
import {
  EXEMPTION_FLAG,
  PCI_TYPE,
  ROUTE_KEYS,
  ROUTES,
  INTEGRATION_TYPE,
  RESOURCES_KEYS,
} from '../../../common/constants';
import { SplitPaymentsConfigurationButton } from './SplitPaymentsConfiguration/SplitPaymentsConfigurationButton';
import { TargetMerchantAccountsButton } from './targetMerchantAccounts/TargetMerchantAccountsButton';
import TargetMerchantAccountEditForm from './targetMerchantAccounts/editForm/TargetMerchantAccountEditForm';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import { setChannelName, setIsLoading } from '../../../redux/breadCrumbsSlice';
import SplitPaymentsEditForm from './SplitPaymentsConfiguration/SplitPaymentsEditForm';
import TargetMerchantAccountsPage from './targetMerchantAccounts/TargetMerchantAccountsPage';
import { revertChannelDataTransformation } from './channelDataUtils/revertChannelDataTransformation';
import { prepareChannelDataTransformation } from './channelDataUtils/prepareChannelDataTransformation';
import { getMaxChannelPriorityForMerchant } from './channelDataUtils/getMaxChannelPriorityForMerchant';
import uiTexts from '../../../resources/uiTexts.json';
import { getConfigSection } from '../../../common/utils';
import { RequiredFieldsTip } from '../../common-components/RequiredFieldsTip';
import DisplayRuleExpressions from './display-rule-expressions/DisplayRuleExpressions';
import { DisplayRuleExpressionsButton } from './display-rule-expressions/DisplayRuleExpressionsButton';
import { diff } from 'deep-diff';
import { BlockNavigation } from '../../common-components/BlockNavigation';
import { reconcile } from '../../../common/utils';

class ChannelEditForm extends React.PureComponent {
  static propTypes = {
    routeProps: PropTypes.shape({
      location: commonPropTypes.router.location,
      history: commonPropTypes.router.history,
      match: commonPropTypes.router.match,
    }),
    authToken: commonPropTypes.authToken,
    merchantData: commonPropTypes.editForm.data,
    textsKey: PropTypes.string,
    isFormEditable: PropTypes.bool.isRequired,
    onBack: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.overrideInitialValues = this.overrideInitialValues.bind(this);
    this.addChannel = this.addChannel.bind(this);
    this.modifyChannel = this.modifyChannel.bind(this);
    this.goBack = this.goBack.bind(this);
    this.renderAdditionalButtons = this.renderAdditionalButtons.bind(this);
    this.getPaymentOptionsCustomTags = this.getPaymentOptionsCustomTags.bind(this);
    this.isDisplayRuleEpressionsButtonDisabled = this.isDisplayRuleEpressionsButtonDisabled.bind(this);
    const { authToken, channelsList } = this.props;
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
    this.hideSuccessMessage = this.hideSuccessMessage.bind(this);
    this.state = {
      isLoading: true,
      merchantId: null,
      channelId: null,
      isCreationFlow: false,
      initialPriority: 1,
      channelsNames: this.getChannelsNames(channelsList),
      paymentOptions: [],
      data: {},
      successMessage: false,
      displayRuleExpressions: [],
    };

    this.valuesRef = React.createRef();
  }

  componentDidMount() {
    this.initializeData();
  }

  componentDidUpdate(prevProps) {
    const {
      routeProps: {
        match: {
          params: { channelId },
        },
      },
    } = prevProps;

    const {
      routeProps: {
        match: {
          params: { channelId: channelIdNow },
        },
      },
    } = this.props;

    if (channelId !== channelIdNow) {
      this.initializeData();
    }
  }

  initializeData() {
    const { dispatch } = this.props;
    const {
      routeProps: {
        match: {
          params: { channelId, merchantId },
        },
      },
    } = this.props;

    this.setState({
      merchantId,
      channelId,
    });

    if (channelId === ROUTE_KEYS.creation) {
      dispatch(setIsLoading({ isLoading: true }));
      this.setState({ isLoading: true, isCreationFlow: true });
      this.loadChannelsData(merchantId);
    } else {
      dispatch(setIsLoading({ isLoading: true }));
      this.setState({ isCreationFlow: false });
      this.getChannelById(merchantId, channelId);
    }
  }

  getDisplayRuleExpressions = data => {
    this.setState({ displayRuleExpressions: data });
  };

  // eslint-disable-next-line class-methods-use-this
  trimKCFields(values) {
    const { account } = values;
    const trimmedAccountValues = Object.keys(account).reduce((acc, currentKey) => {
      if (typeof account[currentKey] === 'string') {
        acc[currentKey] = account[currentKey].trim();
      } else {
        acc[currentKey] = account[currentKey];
      }
      return acc;
    }, {});
    return { ...values, account: trimmedAccountValues };
  }

  filterVals = change => {
    if (change.kind === 'E') {
      if (reconcile(change.lhs) === reconcile(change.rhs)) {
        return false;
      }

      if (change.lhs === 'true' && change.rhs === true) {
        return false;
      }
      if (change.lhs === 'false' && change.rhs === false) {
        return false;
      }
    }
    if (change.kind === 'N' && change.path?.includes('is3RI') && change.rhs === false) {
      return false;
    }
    if (change.kind === 'N' && change.rhs === '') {
      return false;
    }

    if (change.kind === 'N' && Array.isArray(change.rhs) && change.rhs.length === 0) {
      return false;
    }
    return true;
  };

  compareValues = () => {
    const form = this.valuesRef.current;
    const fVals = form.values;

    const isAddNew = this.props.routeProps.match.params.channelId === 'creation';
    if (isAddNew) {
      const diffs = (diff(form.initialValues, form.values) || []).filter(this.filterVals);
      return diffs.length === 0 ? undefined : diffs;
    }

    let origVals = this.state.data;
    if (this.state.data.configuration) {
      origVals = revertChannelDataTransformation(this.state.data);
      if (!origVals.configuration.cardBrandsBinBlacklist) {
        origVals.configuration.cardBrandsBinBlacklist = [];
      }
    }

    const currentVals = revertChannelDataTransformation(fVals);

    const diffs = (diff(origVals, currentVals) || []).filter(this.filterVals);
    return diffs.length === 0 ? undefined : diffs;
  };

  render() {
    const { authToken, textsKey, merchantData, isVisible, isFormEditable, isSaveButtonEnable, onBack } = this.props;
    const { data, merchantId, /*channelId,*/ isLoading, isCreationFlow, initialPriority, channelsNames } = this.state;
    const overridenInitialValues = this.overrideInitialValues();

    return isLoading ? (
      <Spinner center small={false} fullPage />
    ) : (
      <Switch>
        <Route
          exact
          path={ROUTES.channelPage}
          render={() => (
            <>
              {this.state.successMessage && this.renderSuccessMessage()}
              <RequiredFieldsTip />

              <BlockNavigation compareValues={this.compareValues} />

              <BaseEditForm
                data={data}
                authToken={authToken}
                textsKey={textsKey}
                onBack={this.goBack}
                onSaveModifiedItem={channel =>
                  this.modifyChannel(channel, `/${ROUTE_KEYS.merchants}/${merchantId}/${ROUTE_KEYS.channels}`)
                }
                onSaveNewItem={this.addChannel}
                onCancel={this.goBack}
                isVisible={isVisible}
                fieldsComponent={{
                  type: FormFields,
                }}
                overridenInitialValues={overridenInitialValues}
                validationSchema={() => FormFields.validationSchema(channelsNames, isCreationFlow)}
                isFormEditable={isFormEditable}
                customProps={{
                  isMerchantStoredPaymentsEnabled: merchantData.storedPayments.isEnabled,
                  initialPriority,
                  channelData: this.state.data,
                  onChannelLoaded: data => {
                    this.setState({
                      ...this.state,
                      paymentOptions: data,
                    });
                  },
                  onPaymentOptionsPrepared: data => {
                    const newState = { ...this.state };

                    if (!newState.data.configuration) {
                      newState.data.configuration = { paymentOption: data };
                    } else if (newState.data.configuration) {
                      newState.data.configuration.paymentOptions = data;
                    }
                    this.setState(newState);
                  },
                }}
                isSaveButtonEnable={isSaveButtonEnable}
                formAppendComponent={this.renderAdditionalButtons}
                preSubmitHook={this.trimKCFields}
                valuesRef={this.valuesRef}
              />
            </>
          )}
        />
        <Route
          exact
          path={ROUTES.paymentsSplitConfiguration}
          render={routeProps => (
            <>
              {this.state.successMessage && this.renderSuccessMessage()}
              <RequiredFieldsTip />

              <SplitPaymentsEditForm
                data={data}
                routeProps={routeProps}
                authToken={authToken}
                textsKey="merchants.channels"
                isFormEditable={isFormEditable}
                merchantId={merchantId}
                saveChannelData={channel => this.modifyChannel(channel)}
                hideSuccessMessage={this.hideSuccessMessage}
              />
            </>
          )}
        />
        <Route
          exact
          path={ROUTES.targetMerchantAccounts}
          render={routeProps => (
            <>
              {this.state.successMessage && this.renderSuccessMessage()}

              <TargetMerchantAccountsPage
                routeProps={routeProps}
                authToken={authToken}
                textsKey={RESOURCES_KEYS.targetMerchantAccounts}
                isFormEditable={isFormEditable}
                merchantId={merchantId}
                merchantData={merchantData}
                onBack={this.goBack}
              />
            </>
          )}
        />
        <Route
          path={ROUTES.targetMerchantAccount}
          render={routeProps => {
            return (
              <TargetMerchantAccountEditForm
                routeProps={routeProps}
                authToken={authToken}
                textsKey={RESOURCES_KEYS.targetMerchantAccounts}
                isFormEditable={isFormEditable}
              />
            );
          }}
        />
        <Route
          exact
          path={ROUTES.targetMerchantAccountCreation}
          render={routeProps => (
            <TargetMerchantAccountEditForm
              routeProps={routeProps}
              authToken={authToken}
              textsKey={RESOURCES_KEYS.targetMerchantAccounts}
              isFormEditable={isFormEditable}
            />
          )}
        />
        <Route
          exact
          path={ROUTES.displayRuleExpressions}
          render={routeProps => (
            <DisplayRuleExpressions
              routeProps={routeProps}
              authToken={authToken}
              textsKey={RESOURCES_KEYS.displayRuleExpressions}
              isFormEditable={isFormEditable}
              paymentOptionsWithTags={this.getPaymentOptionsCustomTags()}
              channelId={routeProps.match.channelId}
              merchantId={routeProps.match.merchantId}
              getDisplayRuleExpressions={this.getDisplayRuleExpressions}
              onBack={onBack}
            />
          )}
        />
      </Switch>
    );
  }

  overrideInitialValues() {
    const { initialPriority } = this.state;
    /* This object overrides some default values set up for the fields of
    the form. This is needed because some of the input fields can not
    be correctly initialized via the generic method initializeValues
    (it doesn´t know the type of each field).
    Some values, e.g. the select ones need to be explicitly initialized
    with true/false, otherwise they remain as undefined and are sent to
    the backend empty.
    Added 'undefined' values to fire 'required' case of Yup validations. */
    return {
      priority: initialPriority,
      account: {
        programAccountNumber: undefined,
        programAccountType: undefined,
        merchantAccountNumber: undefined,
        isVWPayments: false,
      },
      configuration: {
        autoCancel: false,
        autoCapture: {
          isEnabled: true,
          delayDays: undefined,
        },
        integrationType: INTEGRATION_TYPE.LEGACY,
        paymentOptions: {
          activeUpcfPaymentOptions: [],
          inactiveUpcfPaymentOptions: [],
          isEnabled: [],
          isStorable: [],
        },
        connectorType: PCI_TYPE.PAYON,
        exemptionFlag: EXEMPTION_FLAG.NO,
      },
      rule: {
        properties: {
          currencies: [],
          customerBillingAddressCountries: [],
          customerGroups: [],
          productGroups: [],
          amount: {
            operator: undefined,
            value: undefined,
          },
        },
        expresion: {
          value: undefined,
        },
      },
    };
  }

  getChannelById(merchantId, channelId) {
    const { dispatch } = this.props;

    Promise.all([
      this.configurationDataApi.getChannelById(merchantId, channelId),
      this.configurationDataApi.getUpcfPaymentOptions(),
    ]).then(([channelResult, paymentOptionsResult]) => {
      const preparedData = prepareChannelDataTransformation(channelResult, get({}, 'storedPayments.isEnabled', false));

      dispatch(setChannelName({ channelName: preparedData.name }));
      dispatch(setIsLoading({ isLoading: false }));

      this.setState({
        data: {
          ...preparedData,
        },
        paymentOptions: paymentOptionsResult,
        isLoading: false,
        displayRuleExpressions: preparedData.configuration.displayRuleExpressions,
      });
    });
  }

  loadChannelsData(merchantId) {
    const { dispatch } = this.props;

    this.configurationDataApi.getChannels(merchantId).then(merchantChannels => {
      const initialPriority = getMaxChannelPriorityForMerchant(merchantChannels) + 1;

      this.setState({
        initialPriority,
        channelsNames: this.getChannelsNames(merchantChannels),
        isLoading: false,
      });

      dispatch(setIsLoading({ isLoading: false }));
    });
  }

  getChannelsNames(channels) {
    return channels.map(channel => channel.name);
  }

  async addChannel(newChannel) {
    this.setState({ isLoading: true });

    const { merchantId } = this.state;

    await this.configurationDataApi.addChannel(merchantId, revertChannelDataTransformation(newChannel)).then(() => {
      this.setState({ isLoading: false });
      this.goBack();
    });
  }

  goToChannelPage(channelId, merchantId) {
    const route = ROUTES.channelPage.replace(':merchantId', merchantId).replace(':channelId', channelId);
    this.props.history.replace(route);
  }

  async modifyChannel(channel) {
    this.setState({ isLoading: true });

    const { merchantId, displayRuleExpressions } = this.state;

    channel.configuration.displayRuleExpressions = displayRuleExpressions;

    await this.configurationDataApi
      .modifyChannel(merchantId, revertChannelDataTransformation(channel))
      .then(updatedChannel => {
        const preparedData = prepareChannelDataTransformation(
          updatedChannel,
          get({}, 'storedPayments.isEnabled', false),
        );

        this.setState({
          isLoading: false,
          data: { ...preparedData },
          successMessage: true,
        });
      })
      .catch(() => {
        this.setState({
          ...this.state,
          successMessage: false,
        });
      });
  }

  goBack() {
    const { merchantId } = this.state;
    const { onBack } = this.props;
    onBack(`/${ROUTE_KEYS.merchants}/${merchantId}/${ROUTE_KEYS.channels}`);
  }

  renderAdditionalButtons() {
    const { isCreationFlow } = this.state;
    const { location } = this.props;
    return (
      <>
        <SplitPaymentsConfigurationButton
          isCreationFlow={isCreationFlow}
          handler={() => {
            this.hideSuccessMessage();
            this.props.history.push(`${location.pathname}/${ROUTE_KEYS.splitPaymentsConfiguration}`);
          }}
        />
        <TargetMerchantAccountsButton
          isCreationFlow={isCreationFlow}
          textsKey={RESOURCES_KEYS.targetMerchantAccounts}
          handler={() => {
            this.hideSuccessMessage();
            this.props.history.push(`${location.pathname}/${ROUTE_KEYS.targetMerchantAccounts}`);
          }}
        />
        <DisplayRuleExpressionsButton
          isCreationFlow={isCreationFlow || this.isDisplayRuleEpressionsButtonDisabled()}
          textsKey={RESOURCES_KEYS.displayRuleExpressions}
          handler={() => {
            this.hideSuccessMessage();
            this.props.history.push(`${location.pathname}/${ROUTE_KEYS.displayRuleExpressions}`);
          }}
        />
      </>
    );
  }

  isDisplayRuleEpressionsButtonDisabled() {
    const integrationType = this.state.data.configuration?.integrationType;
    const paymentOptions = this.state.data.configuration?.paymentOptions.upcfPaymentOptions;

    return (
      integrationType === INTEGRATION_TYPE.LEGACY ||
      (paymentOptions && paymentOptions.every(po => !po.isStoredCitPaymentsAllowed && !po.isStoredMitPaymentsAllowed))
    );
  }

  getPaymentOptionsCustomTags() {
    const customTags = [];
    const paymentOptionsRefData = this.state.paymentOptions;
    const paymentOptions = this.state.data.configuration.paymentOptions.upcfPaymentOptions;
    const filteredPaymentOptions = paymentOptionsRefData.filter(po => {
      const option = paymentOptions.find(op => op.code === po.smartPayCode);

      return (
        option &&
        po.smartPayCode === option.code &&
        (option.isStoredCitPaymentsAllowed || option.isStoredMitPaymentsAllowed)
      );
    });

    filteredPaymentOptions.forEach(p => {
      if (p.customTags && p.customTags.length > 0) {
        customTags.push({
          paymentOptionCode: p.smartPayCode,
          displayName: p.displayName,
          customTags: p.customTags,
        });
      }
    });

    return customTags;
  }

  hideSuccessMessage() {
    this.setState({
      ...this.state,
      successMessage: false,
    });
  }

  renderSuccessMessage() {
    const text = getConfigSection(uiTexts, 'common.successMessage');

    return (
      <p className="merchant-changes-success-message" data-testid="success-message">
        {text}
      </p>
    );
  }
}

const mapStateToProps = state => {
  const { channelsList } = state.appData;

  return { channelsList };
};

export default connect(mapStateToProps)(withRouter(ChannelEditForm));
