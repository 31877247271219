import React from 'react';
import { Spinner } from 'cj-common-components';
import PropTypes from 'prop-types';
import BaseEditForm from '../../../common-components/BaseEditForm';
import FormFields from './FormFields';
import commonPropTypes from '../../../../common/common-prop-types';
import { reconcile } from '../../../../common/utils';
import ConfigurationDataApi from '../../../../api/ConfigurationData';
import { BlockNavigation } from '../../../common-components/BlockNavigation';

export default class GroupsEditForm extends React.Component {
  static propTypes = {
    routeProps: PropTypes.shape({
      location: commonPropTypes.router.location,
      history: commonPropTypes.router.history,
      match: commonPropTypes.router.match,
    }),
    // eslint-disable-next-line react/forbid-prop-types
    merchantData: PropTypes.object,
    authToken: commonPropTypes.authToken,
    textsKey: PropTypes.string,
    isFormEditable: PropTypes.bool,
    onBack: PropTypes.func,
  };

  constructor(props) {
    super(props);
    const { authToken } = this.props;
    this.state = {
      isLoading: true,
      userData: {},
      groupsWithMerchant: [],
      skskipNavBlockip: false,
    };
    this.addMerchantToGroup = this.addMerchantToGroup.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);

    this.valuesRef = React.createRef();
  }

  componentDidMount() {
    this.loadGroupsData().then(groupsWithMerchant => {
      this.setState({ groupsWithMerchant, isLoading: false });
    });
  }

  setLoading(isLoading) {
    this.setState({ isLoading });
  }

  async addMerchantToGroup(group) {
    this.setState({ ...this.state, skipNavBlock: true });
    const { merchantData, onBack } = this.props;
    this.setLoading(true);

    await this.configurationDataApi.addMerchantGroupMerchant(group.id, merchantData.id).then(() => {
      this.setLoading(false);
      onBack();
    });
  }

  async loadGroupsData() {
    const { merchantData } = this.props;

    const groupsData = await this.configurationDataApi.getMerchantGroupsWithMerchant(merchantData.id);

    return groupsData.groups.map(group => group.groupId);
  }

  compareValues = () => {
    if (this.state.skipNavBlock) {
      return;
    }

    const fVals = this.valuesRef.current.values;

    const hasDiffs = reconcile(fVals.id) !== undefined;

    if (hasDiffs) {
      return [];
    }
  };

  render() {
    const { authToken, textsKey, onBack, onCancel, isFormEditable } = this.props;
    const { userData, isLoading, groupsWithMerchant } = this.state;

    return isLoading ? (
      <Spinner center small={false} fullPage />
    ) : (
      <>
        <BlockNavigation compareValues={this.compareValues} />
        <BaseEditForm
          valuesRef={this.valuesRef}
          data={{ ...userData }}
          authToken={authToken}
          textsKey={textsKey}
          onBack={onBack}
          onSaveModifiedItem={() => {}}
          onSaveNewItem={(...args) => {
            return this.addMerchantToGroup(...args);
          }}
          onCancel={onCancel}
          fieldsComponent={{
            type: FormFields,
          }}
          validationSchema={FormFields.validationSchema}
          isFormEditable={isFormEditable}
          customProps={{ groupsWithMerchant }}
          isSaveButtonEnable={isFormEditable}
        />
      </>
    );
  }
}
