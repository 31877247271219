import { RequiredFieldSymbol } from './RequiredFieldSymbol';
import './RequiredFieldTip.css';
const uiTexts = require('../../resources/uiTexts.json');

export const RequiredFieldsTip = () => {
  return (
    <div className="required-fields-tip" data-testid="required-fields-tip">
      <RequiredFieldSymbol /> {uiTexts.common.requiredFieldHint}
    </div>
  );
};
