import { isEmpty, get } from '@turbopay/ts-helpers/object-utils';
import { getConfigSection } from '../../common/utils';

const config = require('../../resources/config.json');
const uiTexts = require('../../resources/uiTexts.json');

// This function selects the buttons to be presented to the
// customer in the UI based in the role he is assigned to.
// Furthermore, it allows to add, in demmand, several extra buttons
// that must be shown in certain situations (e.g., in the top menu
// we want also the 'home' and 'logout' icons displayed since they
// are common for all roles).
// It returns an object containing all the needed configuration
// (icons, texts and links) to render the buttons allowed according
// to the user´s role + any extra button added via the extraButtons
// option.
export const getAllowedOptionsButtonConfig = (userRole, buttonTextsKey, buttonsConfigKey, extraButtons) => {
  const allowedOptions = getConfigSection(config, `permissions.${userRole}`);
  const buttonTexts = getConfigSection(uiTexts, buttonTextsKey);
  const buttonsConfig = getConfigSection(config, buttonsConfigKey);
  const optionsToRender = get(extraButtons, 'before', [])
    .concat(allowedOptions || [])
    .concat(get(extraButtons, 'after', []));

  return optionsToRender.map(option => ({
    text: buttonTexts[option],
    key: option,
    ...buttonsConfig[option],
  }));
};

export const addDefaultSelectOptionToSelectValues = (values, defaultValue, defaultText) => {
  return [
    {
      value: defaultValue,
      label: defaultText,
    },
  ].concat(values);
};

export const mapValuesArrayToSelectComponentValues = (
  values,
  keyForLabel,
  keyForValue, // single string or string array for concatenation accepted
  addDefaultOptionConfig = {},
) => {
  let mappedValues = values.map(item => {
    const label = Array.isArray(keyForLabel)
      ? keyForLabel.reduce((accum, curr) => {
          const resolvedCurr = get(item, curr);
          return `${accum}${isEmpty(accum) ? '' : ' - '}${resolvedCurr}`;
        }, '')
      : get(item, keyForLabel);
    return {
      value: get(item, keyForValue),
      label,
    };
  });

  if (!isEmpty(addDefaultOptionConfig)) {
    const { defaultValue, defaultText } = addDefaultOptionConfig;
    mappedValues = addDefaultSelectOptionToSelectValues(mappedValues, defaultValue, defaultText);
  }
  return mappedValues;
};
