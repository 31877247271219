import React from 'react';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import commonPropTypes from '../../../common/common-prop-types';
import CommonTable from '../../common-components/CommonTable';

export default class Table extends React.Component {
  static propTypes = {
    ...commonPropTypes.table,
  };

  constructor(props) {
    super(props);
    const { authToken } = this.props;

    this.loadProductGroupList = this.loadProductGroupList.bind(this);
    this.deleteProductGroup = this.deleteProductGroup.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
  }

  render() {
    const { authToken, textsKey } = this.props;

    return (
      <CommonTable
        authToken={authToken}
        textsKey={textsKey}
        dataFunctions={{
          loadData: this.loadProductGroupList,
          deleteItem: this.deleteProductGroup,
        }}
        hasMaxColumnSize={true}
        testIdPrefix="products-table"
      />
    );
  }

  loadProductGroupList() {
    return this.configurationDataApi.getProductGroups();
  }

  deleteProductGroup(productGroup) {
    return this.configurationDataApi.deleteProductGroup(productGroup);
  }
}
