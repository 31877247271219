import React from 'react';
import { Button, ButtonContainer, Column, Layout } from 'cj-common-components';
import PropTypes from 'prop-types';
import { getConfigSection } from '../../../../common/utils';
import uiTexts from '../../../../resources/uiTexts.json';
import InfoIconText from '../../../common-components/InfoIconText';

export const DisplayRuleExpressionsButton = ({ isCreationFlow, handler, textsKey }) => {
  const buttonText = getConfigSection(uiTexts, `${textsKey}.title`);
  const disabled = isCreationFlow;

  return (
    <Layout center className="u-mb-xsmall">
      <Column span="1/1">
        {disabled && (
          <InfoIconText
            text={getConfigSection(uiTexts, `${textsKey}.disabledButtonText`)}
            testId="display-rule-expressions-info-text"
          />
        )}
        <ButtonContainer>
          <Button
            key="base-edit-form-button-back"
            secondary
            type="button"
            onClick={handler}
            disabled={disabled}
            testId="display-rule-expressions-button"
          >
            {buttonText}
          </Button>
        </ButtonContainer>
      </Column>
    </Layout>
  );
};

DisplayRuleExpressionsButton.propTypes = {
  props: PropTypes.shape({
    isUpdateOperation: PropTypes.bool.isRequired,
    handler: PropTypes.func.isRequired,
  }),
};
