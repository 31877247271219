import React from 'react';
import * as Yup from 'yup';
import * as moment from 'moment';
import commonPropTypes from '../../../common/common-prop-types';
import BaseFormFields from '../../common-components/BaseFormFields';
import { getConfigSection } from '../../../common/utils';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import { mapValuesArrayToSelectComponentValues } from '../../common-components/common-functions';
import { httpStatusCodes } from '../../../common/external-definitions/http-status-codes';
import InfoIconText from '../../common-components/InfoIconText';
import { RequiredFieldsTip } from '../../common-components/RequiredFieldsTip';

const uiTexts = require('../../../resources/uiTexts.json');

const errorsTexts = require('../../../resources/errorTexts.json');

const error = getConfigSection(errorsTexts, 'validation');

export default class FormFields extends React.PureComponent {
  static validationSchema() {
    return Yup.object().shape({
      description: Yup.string().required(error.mandatoryField),
      creationDateStart: Yup.date().required(error.mandatoryField),
      creationDateEnd: Yup.date()
        .when(
          'creationDateStart',
          (creationDateStart, schema) =>
            creationDateStart &&
            schema.min(creationDateStart, '"Creation date start" cannot be before "Creation date end"'),
        )
        .test(
          'creationDateEnd',
          '"Creation date start" and "Creation date end" cannot be further than 7 days apart',
          (value, context) => {
            const dateStart = moment(context.parent.creationDateStart);
            const dateEnd = moment(value);
            const daysDiff = dateEnd.diff(dateStart, 'days');

            if (daysDiff && daysDiff > 7) return false;
            return true;
          },
        )
        .required(error.mandatoryField),
    });
  }

  static propTypes = {
    ...commonPropTypes.formFields,
  };

  constructor(props) {
    super(props);

    const { authToken } = this.props;
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
  }

  render() {
    const { renderGenericMultiSelect, renderGenericInput, renderGenericCheckbox } = BaseFormFields;
    const { authToken, textsKey, isFormEditable, formikProps } = this.props;

    const fieldsTextKey = `${textsKey}.form.fields`;
    const selectAnOptionText = getConfigSection(uiTexts, `${fieldsTextKey}.httpCodes`);

    const fieldsRenderConfig = [
      {
        id: 'description',
        useRequiredLabel: true,
        function: renderGenericInput('text', isFormEditable),
      },

      {
        id: 'creationDateStart',
        useRequiredLabel: true,
        function: renderGenericInput('date', isFormEditable),
      },
      {
        id: 'creationDateEnd',
        useRequiredLabel: true,
        function: renderGenericInput('date', isFormEditable),
      },
      {
        id: 'dateIconSpace',
        function: () => {},
        labelAddOn: (
          <InfoIconText
            text={getConfigSection(uiTexts, 'merchants.notificationRetries.form.fields.infoIconText')}
            testId="dateIconSpace-info-text"
          />
        ),
      },
      {
        id: 'httpCodes',
        function: renderGenericMultiSelect(
          isFormEditable,
          selectAnOptionText,
          mapValuesArrayToSelectComponentValues(httpStatusCodes, 'httpStatus', 'value'),
          formikProps,
        ),
      },
      {
        id: 'isDryRun',
        function: renderGenericCheckbox(isFormEditable, ''),
      },
      {
        id: 'isDryRunIconSpace',
        function: () => {},
        labelAddOn: (
          <InfoIconText
            text={getConfigSection(uiTexts, 'merchants.notificationRetries.form.fields.dryRunIconText')}
            testId="isDryRunIconSpace-info-text"
          />
        ),
      },
    ];

    return (
      <>
        <RequiredFieldsTip />
        <BaseFormFields
          authToken={authToken}
          fieldsTextKey={fieldsTextKey}
          fieldsRenderConfig={fieldsRenderConfig}
          testIdPrefix="notification-retry-form"
        />
      </>
    );
  }
}
