import { isEmpty, get } from '@turbopay/ts-helpers/object-utils';
import { getEnumText, parseDecimalToString } from '../../../../common/utils';
import { PAYMENT_OPTIONS, PAYMENT_OPTIONS_NAMES, INTEGRATION_TYPE } from '../../../../common/constants';

function convertJsonToCriteria(jsonArray) {
  return jsonArray
    .map(obj => `${obj.name}: ${obj.value}`)
    .reduce((accum, curr) => (isEmpty(accum) ? curr : `${accum}\n${curr}`), '');
}

function getLegacyPaymentOptions(paymentOptions) {
  const enabledPaymentOptions = Object.values(paymentOptions)
    // method.isGuest === undefined
    // For old records when we don't have isGuest
    .filter(method => method.isGuest || method.isGuest === undefined)
    .map(method => ({
      code: getEnumText(PAYMENT_OPTIONS, method.code),
    }));

  const storablePaymentOptions = Object.values(paymentOptions)
    .filter(method => method.isStorable)
    .map(method => ({
      code: getEnumText(PAYMENT_OPTIONS, method.code),
    }));

  let disabledPayUMethods = [];

  if (enabledPaymentOptions.find(po => po.code === PAYMENT_OPTIONS_NAMES.PAYU)) {
    disabledPayUMethods = [PAYMENT_OPTIONS_NAMES.PAYUBNK, PAYMENT_OPTIONS_NAMES.PAYUBLK];
  } else if (
    enabledPaymentOptions.find(
      po => po.code === PAYMENT_OPTIONS_NAMES.PAYUBNK || po.code === PAYMENT_OPTIONS_NAMES.PAYUBLK,
    )
  ) {
    disabledPayUMethods = [PAYMENT_OPTIONS_NAMES.PAYU];
  }

  return { enabledPaymentOptions, storablePaymentOptions, disabledPayUMethods };
}

function prepareRule(rule, amount) {
  const result = { ...rule };

  if (!isEmpty(rule.properties)) {
    result.properties = {
      ...rule.properties,
      amount: isEmpty(amount)
        ? {
            operator: undefined,
            value: undefined,
          }
        : {
            ...amount,
            value: parseDecimalToString(amount.value),
          },
    };
  }

  return result;
}

export const prepareChannelDataTransformation = (channel, isMerchantStoredPaymentsEnabled) => {
  const { configuration, rule, priority, destinationAccounts } = channel;
  const { paymentOptions, autoCapture, criteria, integrationType } = configuration;
  const amount = get(rule, 'properties.amount', {});
  const partialChannelData = {
    isMerchantStoredPaymentsEnabled,
    priority,
    destinationAccounts,
    configuration: {
      ...configuration,
      paymentOptions: {},
      autoCapture: {
        ...autoCapture,
        delayDays: autoCapture.isEnabled || !autoCapture.delayDays ? '' : autoCapture.delayDays.toString(),
      },
      criteria: isEmpty(criteria) ? '' : convertJsonToCriteria(criteria),
    },
  };

  if (integrationType === INTEGRATION_TYPE.LEGACY) {
    const { enabledPaymentOptions, storablePaymentOptions, disabledPayUMethods } = getLegacyPaymentOptions(
      paymentOptions,
    );

    partialChannelData.configuration.paymentOptions = {
      isEnabled: enabledPaymentOptions,
      isStorable: storablePaymentOptions,
      disabledPayUMethods,
    };
  } else {
    partialChannelData.configuration.paymentOptions = {
      upcfPaymentOptions: paymentOptions,
    };
  }

  const newObject = Object.assign({}, channel, partialChannelData);

  if (!isEmpty(rule)) {
    newObject.rule = prepareRule(rule, amount);
  }

  return newObject;
};
