import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'cj-common-components';
import { getConfigSection } from '../../common/utils';

const uiTexts = require('../../resources/uiTexts.json');

export default class ScreenHeader extends React.PureComponent {
  static propTypes = {
    textsKey: PropTypes.string.isRequired,
    testId: PropTypes.string,
  };

  render() {
    const { textsKey, testId } = this.props;
    const headerText = getConfigSection(uiTexts, `${textsKey}.header`);

    return (
      <div>
        {headerText && (
          <div>
            <Heading className="u-mt" headerType={2} testId={testId || 'page-header'}>
              {headerText}
            </Heading>
          </div>
        )}
      </div>
    );
  }
}
