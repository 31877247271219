import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import config from '../../resources/config.json';
import { getScreenTextKey, getConfigSection } from '../../common/utils';
import { ROUTES } from '../../common/constants';
import ConfigurationDataApi from '../../api/ConfigurationData';
import { withAuth } from '../../auth/withAuth';
import UsersTable from './Table';
import ScreenHeader from '../common-components/ScreenHeader';
import commonPropTypes from '../../common/common-prop-types';
import User from './User';
import UserMerchantsAccessEditForm from './userMerchantsAccess/UserMerchantsAccessEditForm';
import UserGroupsAccessEditForm from './userGroupsAccess/UserGroupsAccessEditForm';

const linksConfig = getConfigSection(config, 'ui.mainContainer.menu');

class Users extends React.PureComponent {
  static propTypes = {
    authToken: commonPropTypes.authToken,
    location: commonPropTypes.router.location,
  };

  constructor(props) {
    super(props);

    const { authToken, location } = this.props;
    this.location = location;
    this.authToken = authToken;
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(this.authToken.accessToken);
    this.handleGoBack = this.handleGoBack.bind(this, linksConfig.users.link);
  }

  render() {
    const { dispatch } = this.props;
    const textsKey = getScreenTextKey(linksConfig.users.link);
    const merchantsAccessTextsKey = 'users.userMerchantsAccess';
    const groupsAccessTextsKey = `${textsKey}.userGroupsAccess`;
    const isFormEditable = true;

    return (
      <Switch>
        <Route
          exact
          path={linksConfig.users.link}
          render={() => (
            <>
              <ScreenHeader textsKey={textsKey} />
              <UsersTable authToken={this.authToken} textsKey={textsKey} />
            </>
          )}
        />
        <Route
          exact
          path={ROUTES.editUser}
          render={routeProps => (
            <>
              <ScreenHeader textsKey={textsKey} />
              <User
                getUser={this.getUser}
                authToken={this.authToken}
                textsKey={textsKey}
                onBack={this.handleGoBack}
                setGroups={this.setGroups}
                dispatch={dispatch}
                isFormEditable={isFormEditable}
                {...routeProps}
              />
            </>
          )}
        />
        <Route
          exact
          path={ROUTES.editUserMerchantsAccess}
          render={routeProps => (
            <>
              <ScreenHeader textsKey={`${textsKey}.userMerchantsAccess.table`} />
              <UserMerchantsAccessEditForm
                authToken={this.authToken}
                isFormEditable={isFormEditable}
                onBack={this.handleGoBack}
                dispatch={dispatch}
                textsKey={merchantsAccessTextsKey}
                {...routeProps}
              />
            </>
          )}
        />
        <Route
          exact
          path={ROUTES.createUserMerchantsAccess}
          render={routeProps => (
            <>
              <ScreenHeader textsKey={`${textsKey}.userMerchantsAccess.table`} />
              <UserMerchantsAccessEditForm
                authToken={this.authToken}
                isFormEditable={isFormEditable}
                onBack={this.handleGoBack}
                dispatch={dispatch}
                textsKey={merchantsAccessTextsKey}
                {...routeProps}
              />
            </>
          )}
        />
        <Route
          exact
          path={ROUTES.editUserGroupsAccess}
          render={routeProps => (
            <>
              <ScreenHeader textsKey={`${textsKey}.userGroupsAccess.table`} />
              <UserGroupsAccessEditForm
                authToken={this.authToken}
                isFormEditable={isFormEditable}
                onBack={this.handleGoBack}
                dispatch={dispatch}
                textsKey={groupsAccessTextsKey}
                {...routeProps}
              />
            </>
          )}
        />
        <Route
          exact
          path={ROUTES.createUserGroupsAccess}
          render={routeProps => (
            <>
              <ScreenHeader textsKey={`${textsKey}.userGroupsAccess.table`} />
              <UserGroupsAccessEditForm
                authToken={this.authToken}
                isFormEditable={isFormEditable}
                dispatch={dispatch}
                textsKey={groupsAccessTextsKey}
                onBack={this.handleGoBack}
                {...routeProps}
              />
            </>
          )}
        />
      </Switch>
    );
  }

  handleGoBack = (path, match) => {
    if (match) {
      const { userId } = match.params;
      this.props.history.replace(`${path}/${userId}`);
    } else {
      this.props.history.replace(path);
    }
  };
}

export default connect()(withRouter(withAuth(Users, linksConfig.users.link)));
