import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export function Select(props) {
  const componentInputSelectWrapperClass = classNames(
    {
      'c-input  ': true,
      'c-input--select  ': true,
    },
    props.className
  ).trim()

  const componentInputSelectClass = classNames({
    'c-input__input  ': true,
    'is-error  ': props.isError,
  }).trim()

  function inputSelectName() {
    return props.name
  }

  function inputSelectId() {
    return props.id
  }

  return (
    <div className={componentInputSelectWrapperClass}>
      <select
        data-testid={props.testId}
        className={componentInputSelectClass}
        name={inputSelectName()}
        id={inputSelectId()}
        disabled={props.disabled ? 'disabled' : null}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        aria-labelledby={props.ariaLabelledby}
      >
        {props.values.map(item => (
          <option
            key={`${item.value}${item.label}`}
            value={item.value}
            disabled={item.disabled}
          >
            {item.label}
          </option>
        ))}
      </select>
    </div>
  )
}

Select.propTypes = {
  testId: PropTypes.string,
  className: PropTypes.string,
  isError: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  ariaLabelledby: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ),
}
