import React from 'react';

import ConfigurationDataApi from '../../../../api/ConfigurationData';
import CommonTable from '../../../common-components/CommonTable';
import commonPropTypes from '../../../../common/common-prop-types';
import { formatDate } from '../../../../common/date-time-utils';
import { ROUTES, USER_ROLE } from '../../../../common/constants';
import * as TokenHelper from '../../../../auth/token-helper';

export default class GroupsTable extends React.Component {
  static propTypes = {
    ...commonPropTypes.Table,
  };

  constructor(props) {
    super(props);
    const { authToken } = this.props;

    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
    this.loadMerchantGroupsWithMerchant = this.loadMerchantGroupsWithMerchant.bind(this);
    this.deleteMerchantFromGroup = this.deleteMerchantFromGroup.bind(this);
  }

  render() {
    const { authToken, textsKey, isFormEditable, merchantGroupAddPath } = this.props;
    const creationPath = 'addGroup';
    const redirectRowId = 'groupId';
    const thisTextKey = `${textsKey}.groupsWithMerchant`;
    const userRole = TokenHelper.getRole() || USER_ROLE.ROLE_NONE;

    return (
      <>
        <CommonTable
          authToken={authToken}
          textsKey={thisTextKey}
          dataFunctions={{
            loadData: this.loadMerchantGroupsWithMerchant,
            deleteItem: this.deleteMerchantFromGroup,
          }}
          isFormEditable={isFormEditable}
          canClickOnItem={userRole === USER_ROLE.ROLE_ADMIN}
          isRowEditable={userRole === USER_ROLE.ROLE_ADMIN}
          canAddNewItem={isFormEditable}
          redirectPath={ROUTES.merchantGroups}
          redirectRowId={redirectRowId}
          addItemPath={merchantGroupAddPath}
          creationPath={creationPath}
          testIdPrefix="groups-table"
        />
      </>
    );
  }

  async loadMerchantGroupsWithMerchant() {
    const { merchantData } = this.props;
    const groups = await this.configurationDataApi.getMerchantGroupsWithMerchant(merchantData.id);

    const data = groups.groups.map(group =>
      Object.assign({}, group, {
        creationDate: formatDate(group.creationDate),
      }),
    );

    return data;
  }

  async deleteMerchantFromGroup(group) {
    const { merchantData, showSuccessMessage } = this.props;
    const response = await this.configurationDataApi.deleteMerchantGroupMerchant(group.groupId, merchantData.id);

    showSuccessMessage();
    return response;
  }
}
