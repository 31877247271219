import React from 'react';
import { Button, ButtonContainer, Column, Layout } from 'cj-common-components';
import PropTypes from 'prop-types';
import { getConfigSection } from '../../../../common/utils';
import uiTexts from '../../../../resources/uiTexts.json';
import InfoIconText from '../../../common-components/InfoIconText';

export const SplitPaymentsConfigurationButton = ({ isCreationFlow, handler }) => {
  const buttonText = getConfigSection(uiTexts, 'merchants.channels.form.fields.splitB2BPaymentsConfiguration.title');
  const disabled = isCreationFlow;

  return (
    <Layout center className="u-mb-xsmall">
      <Column span="1/1">
        {disabled && (
          <InfoIconText
            text={getConfigSection(
              uiTexts,
              'merchants.channels.form.fields.splitB2BPaymentsConfiguration.info.configurationInfo',
            )}
            testId="split-b2b-info-text"
          />
        )}
        <ButtonContainer>
          <Button
            key="base-edit-form-button-back"
            secondary
            type="button"
            onClick={handler}
            disabled={disabled}
            testId="split-b2b-button"
          >
            {buttonText}
          </Button>
        </ButtonContainer>
      </Column>
    </Layout>
  );
};

SplitPaymentsConfigurationButton.propTypes = {
  props: PropTypes.shape({
    isUpdateOperation: PropTypes.bool.isRequired,
    handler: PropTypes.func.isRequired,
  }),
};
