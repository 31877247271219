import React from 'react';
import { Spinner } from 'cj-common-components';
import { USER_ROLE } from '../../common/constants';
import ConfigurationDataApi from '../../api/ConfigurationData';
import UserEditForm from './EditForm';
import UserMerchantsAccessTable from './userMerchantsAccess/UserMerchantsAccessTable';
import UserGroupsAccessTable from './userGroupsAccess/UserGroupsAccessTable';

export default class User extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      isLoading: true,
    };
    const { authToken } = this.props;
    this.getUsers = this.getUsers.bind(this);
    this.modifyUser = this.modifyUser.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
  }

  componentDidMount() {
    this.getUsers();
  }

  render() {
    const {
      textsKey,
      match: {
        params: { userId },
      },
      authToken,
      onBack,
      isFormEditable,
      location,
      dispatch,
    } = this.props;
    const merchantsAccessTextsKey = `${textsKey}.userMerchantsAccess`;
    const groupsAccessTextsKey = `${textsKey}.userGroupsAccess`;
    const user = this.getUser(userId) || {};
    const { isLoading } = this.state;
    return isLoading ? (
      <Spinner center small={false} fullPage />
    ) : (
      <>
        <UserEditForm
          data={user}
          authToken={authToken}
          textsKey={textsKey}
          onBack={() => onBack()}
          onSaveModifiedItem={this.modifyUser}
          isFormEditable={isFormEditable}
        />
        {user.role !== USER_ROLE.ROLE_ADMIN && (
          <>
            <UserGroupsAccessTable
              authToken={authToken}
              textsKey={groupsAccessTextsKey}
              data={user}
              location={location}
              dispatch={dispatch}
            />
            <UserMerchantsAccessTable
              authToken={authToken}
              textsKey={merchantsAccessTextsKey}
              data={user}
              location={location}
              dispatch={dispatch}
            />
          </>
        )}
      </>
    );
  }

  async modifyUser(user) {
    const { onBack } = this.props;
    await this.configurationDataApi.modifyUser(user);
    onBack();
    return user;
  }

  getUser(username) {
    const { users } = this.state;
    return users.find(x => x.username === username);
  }

  async getUsers() {
    const users = await this.configurationDataApi.getUsers();
    this.setState({
      users,
      isLoading: false,
    });
  }
}
