// Source: https://www.iso.org/iso-4217-currency-codes.html
export const CURRENCY_CODES = [
  {
    isoCode: 'AFN',
    name: 'Afghani',
  },
  {
    isoCode: 'DZD',
    name: 'Algerian Dinar',
  },
  {
    isoCode: 'ARS',
    name: 'Argentine Peso',
  },
  {
    isoCode: 'AMD',
    name: 'Armenian Dram',
  },
  {
    isoCode: 'AWG',
    name: 'Aruban Florin',
  },
  {
    isoCode: 'AUD',
    name: 'Australian Dollar',
  },
  {
    isoCode: 'AZN',
    name: 'Azerbaijan Manat',
  },
  {
    isoCode: 'BSD',
    name: 'Bahamian Dollar',
  },
  {
    isoCode: 'BHD',
    name: 'Bahraini Dinar',
  },
  {
    isoCode: 'THB',
    name: 'Baht',
  },
  {
    isoCode: 'PAB',
    name: 'Balboa',
  },
  {
    isoCode: 'BBD',
    name: 'Barbados Dollar',
  },
  {
    isoCode: 'BYN',
    name: 'Belarusian Ruble',
  },
  {
    isoCode: 'BZD',
    name: 'Belize Dollar',
  },
  {
    isoCode: 'BMD',
    name: 'Bermudian Dollar',
  },
  {
    isoCode: 'VES',
    name: 'Bolívar Soberano',
  },
  {
    isoCode: 'BOB',
    name: 'Boliviano',
  },
  {
    isoCode: 'BRL',
    name: 'Brazilian Real',
  },
  {
    isoCode: 'BND',
    name: 'Brunei Dollar',
  },
  {
    isoCode: 'BGN',
    name: 'Bulgarian Lev',
  },
  {
    isoCode: 'BIF',
    name: 'Burundi Franc',
  },
  {
    isoCode: 'CVE',
    name: 'Cabo Verde Escudo',
  },
  {
    isoCode: 'CAD',
    name: 'Canadian Dollar',
  },
  {
    isoCode: 'KYD',
    name: 'Cayman Islands Dollar',
  },
  {
    isoCode: 'XOF',
    name: 'CFA Franc BCEAO',
  },
  {
    isoCode: 'XAF',
    name: 'CFA Franc BEAC',
  },
  {
    isoCode: 'XPF',
    name: 'CFP Franc',
  },
  {
    isoCode: 'CLP',
    name: 'Chilean Peso',
  },
  {
    isoCode: 'COP',
    name: 'Colombian Peso',
  },
  {
    isoCode: 'KMF',
    name: 'Comorian Franc ',
  },
  {
    isoCode: 'CDF',
    name: 'Congolese Franc',
  },
  {
    isoCode: 'BAM',
    name: 'Convertible Mark',
  },
  {
    isoCode: 'NIO',
    name: 'Cordoba Oro',
  },
  {
    isoCode: 'CRC',
    name: 'Costa Rican Colon',
  },
  {
    isoCode: 'CUP',
    name: 'Cuban Peso',
  },
  {
    isoCode: 'CZK',
    name: 'Czech Koruna',
  },
  {
    isoCode: 'GMD',
    name: 'Dalasi',
  },
  {
    isoCode: 'DKK',
    name: 'Danish Krone',
  },
  {
    isoCode: 'MKD',
    name: 'Denar',
  },
  {
    isoCode: 'DJF',
    name: 'Djibouti Franc',
  },
  {
    isoCode: 'STN',
    name: 'Dobra',
  },
  {
    isoCode: 'DOP',
    name: 'Dominican Peso',
  },
  {
    isoCode: 'VND',
    name: 'Dong',
  },
  {
    isoCode: 'XCD',
    name: 'East Caribbean Dollar',
  },
  {
    isoCode: 'EGP',
    name: 'Egyptian Pound',
  },
  {
    isoCode: 'SVC',
    name: 'El Salvador Colon',
  },
  {
    isoCode: 'ETB',
    name: 'Ethiopian Birr',
  },
  {
    isoCode: 'EUR',
    name: 'Euro',
  },
  {
    isoCode: 'FKP',
    name: 'Falkland Islands Pound',
  },
  {
    isoCode: 'FJD',
    name: 'Fiji Dollar',
  },
  {
    isoCode: 'HUF',
    name: 'Forint',
  },
  {
    isoCode: 'GHS',
    name: 'Ghana Cedi',
  },
  {
    isoCode: 'GIP',
    name: 'Gibraltar Pound',
  },
  {
    isoCode: 'HTG',
    name: 'Gourde',
  },
  {
    isoCode: 'PYG',
    name: 'Guarani',
  },
  {
    isoCode: 'GNF',
    name: 'Guinean Franc',
  },
  {
    isoCode: 'GYD',
    name: 'Guyana Dollar',
  },
  {
    isoCode: 'HKD',
    name: 'Hong Kong Dollar',
  },
  {
    isoCode: 'UAH',
    name: 'Hryvnia',
  },
  {
    isoCode: 'ISK',
    name: 'Iceland Krona',
  },
  {
    isoCode: 'INR',
    name: 'Indian Rupee',
  },
  {
    isoCode: 'IRR',
    name: 'Iranian Rial',
  },
  {
    isoCode: 'IQD',
    name: 'Iraqi Dinar',
  },
  {
    isoCode: 'JMD',
    name: 'Jamaican Dollar',
  },
  {
    isoCode: 'JOD',
    name: 'Jordanian Dinar',
  },
  {
    isoCode: 'KES',
    name: 'Kenyan Shilling',
  },
  {
    isoCode: 'PGK',
    name: 'Kina',
  },
  {
    isoCode: 'HRK',
    name: 'Kuna',
  },
  {
    isoCode: 'KWD',
    name: 'Kuwaiti Dinar',
  },
  {
    isoCode: 'AOA',
    name: 'Kwanza',
  },
  {
    isoCode: 'MMK',
    name: 'Kyat',
  },
  {
    isoCode: 'LAK',
    name: 'Lao Kip',
  },
  {
    isoCode: 'GEL',
    name: 'Lari',
  },
  {
    isoCode: 'LBP',
    name: 'Lebanese Pound',
  },
  {
    isoCode: 'ALL',
    name: 'Lek',
  },
  {
    isoCode: 'HNL',
    name: 'Lempira',
  },
  {
    isoCode: 'SLL',
    name: 'Leone',
  },
  {
    isoCode: 'LRD',
    name: 'Liberian Dollar',
  },
  {
    isoCode: 'LYD',
    name: 'Libyan Dinar',
  },
  {
    isoCode: 'SZL',
    name: 'Lilangeni',
  },
  {
    isoCode: 'LSL',
    name: 'Loti',
  },
  {
    isoCode: 'MGA',
    name: 'Malagasy Ariary',
  },
  {
    isoCode: 'MWK',
    name: 'Malawi Kwacha',
  },
  {
    isoCode: 'MYR',
    name: 'Malaysian Ringgit',
  },
  {
    isoCode: 'MUR',
    name: 'Mauritius Rupee',
  },
  {
    isoCode: 'MXN',
    name: 'Mexican Peso',
  },
  {
    isoCode: 'MXV',
    name: 'Mexican Unidad de Inversion (UDI)',
  },
  {
    isoCode: 'MDL',
    name: 'Moldovan Leu',
  },
  {
    isoCode: 'MAD',
    name: 'Moroccan Dirham',
  },
  {
    isoCode: 'MZN',
    name: 'Mozambique Metical',
  },
  {
    isoCode: 'BOV',
    name: 'Mvdol',
  },
  {
    isoCode: 'NGN',
    name: 'Naira',
  },
  {
    isoCode: 'ERN',
    name: 'Nakfa',
  },
  {
    isoCode: 'NAD',
    name: 'Namibia Dollar',
  },
  {
    isoCode: 'NPR',
    name: 'Nepalese Rupee',
  },
  {
    isoCode: 'ANG',
    name: 'Netherlands Antillean Guilder',
  },
  {
    isoCode: 'ILS',
    name: 'New Israeli Sheqel',
  },
  {
    isoCode: 'TWD',
    name: 'New Taiwan Dollar',
  },
  {
    isoCode: 'NZD',
    name: 'New Zealand Dollar',
  },
  {
    isoCode: 'BTN',
    name: 'Ngultrum',
  },
  {
    isoCode: '',
    name: 'No universal currency',
  },
  {
    isoCode: 'KPW',
    name: 'North Korean Won',
  },
  {
    isoCode: 'NOK',
    name: 'Norwegian Krone',
  },
  {
    isoCode: 'MRU',
    name: 'Ouguiya',
  },
  {
    isoCode: 'TOP',
    name: 'Pa’anga',
  },
  {
    isoCode: 'PKR',
    name: 'Pakistan Rupee',
  },
  {
    isoCode: 'MOP',
    name: 'Pataca',
  },
  {
    isoCode: 'CUC',
    name: 'Peso Convertible',
  },
  {
    isoCode: 'UYU',
    name: 'Peso Uruguayo',
  },
  {
    isoCode: 'PHP',
    name: 'Philippine Peso',
  },
  {
    isoCode: 'GBP',
    name: 'Pound Sterling',
  },
  {
    isoCode: 'BWP',
    name: 'Pula',
  },
  {
    isoCode: 'QAR',
    name: 'Qatari Rial',
  },
  {
    isoCode: 'GTQ',
    name: 'Quetzal',
  },
  {
    isoCode: 'ZAR',
    name: 'Rand',
  },
  {
    isoCode: 'OMR',
    name: 'Rial Omani',
  },
  {
    isoCode: 'KHR',
    name: 'Riel',
  },
  {
    isoCode: 'RON',
    name: 'Romanian Leu',
  },
  {
    isoCode: 'MVR',
    name: 'Rufiyaa',
  },
  {
    isoCode: 'IDR',
    name: 'Rupiah',
  },
  {
    isoCode: 'RUB',
    name: 'Russian Ruble',
  },
  {
    isoCode: 'RWF',
    name: 'Rwanda Franc',
  },
  {
    isoCode: 'SHP',
    name: 'Saint Helena Pound',
  },
  {
    isoCode: 'SAR',
    name: 'Saudi Riyal',
  },
  {
    isoCode: 'RSD',
    name: 'Serbian Dinar',
  },
  {
    isoCode: 'SCR',
    name: 'Seychelles Rupee',
  },
  {
    isoCode: 'SGD',
    name: 'Singapore Dollar',
  },
  {
    isoCode: 'PEN',
    name: 'Sol',
  },
  {
    isoCode: 'SBD',
    name: 'Solomon Islands Dollar',
  },
  {
    isoCode: 'KGS',
    name: 'Som',
  },
  {
    isoCode: 'SOS',
    name: 'Somali Shilling',
  },
  {
    isoCode: 'TJS',
    name: 'Somoni',
  },
  {
    isoCode: 'SSP',
    name: 'South Sudanese Pound',
  },
  {
    isoCode: 'LKR',
    name: 'Sri Lanka Rupee',
  },
  {
    isoCode: 'SDG',
    name: 'Sudanese Pound',
  },
  {
    isoCode: 'SRD',
    name: 'Surinam Dollar',
  },
  {
    isoCode: 'SEK',
    name: 'Swedish Krona',
  },
  {
    isoCode: 'CHF',
    name: 'Swiss Franc',
  },
  {
    isoCode: 'SYP',
    name: 'Syrian Pound',
  },
  {
    isoCode: 'BDT',
    name: 'Taka',
  },
  {
    isoCode: 'WST',
    name: 'Tala',
  },
  {
    isoCode: 'TZS',
    name: 'Tanzanian Shilling',
  },
  {
    isoCode: 'KZT',
    name: 'Tenge',
  },
  {
    isoCode: 'TTD',
    name: 'Trinidad and Tobago Dollar',
  },
  {
    isoCode: 'MNT',
    name: 'Tugrik',
  },
  {
    isoCode: 'TND',
    name: 'Tunisian Dinar',
  },
  {
    isoCode: 'TRY',
    name: 'Turkish Lira',
  },
  {
    isoCode: 'TMT',
    name: 'Turkmenistan New Manat',
  },
  {
    isoCode: 'AED',
    name: 'UAE Dirham',
  },
  {
    isoCode: 'UGX',
    name: 'Uganda Shilling',
  },
  {
    isoCode: 'CLF',
    name: 'Unidad de Fomento',
  },
  {
    isoCode: 'COU',
    name: 'Unidad de Valor Real',
  },
  {
    isoCode: 'UYW',
    name: 'Unidad Previsional',
  },
  {
    isoCode: 'UYI',
    name: 'Uruguay Peso en Unidades Indexadas (UI)',
  },
  {
    isoCode: 'USD',
    name: 'US Dollar',
  },
  {
    isoCode: 'USN',
    name: 'US Dollar (Next day)',
  },
  {
    isoCode: 'UZS',
    name: 'Uzbekistan Sum',
  },
  {
    isoCode: 'VUV',
    name: 'Vatu',
  },
  {
    isoCode: 'CHE',
    name: 'WIR Euro',
  },
  {
    isoCode: 'CHW',
    name: 'WIR Franc',
  },
  {
    isoCode: 'KRW',
    name: 'Won',
  },
  {
    isoCode: 'YER',
    name: 'Yemeni Rial',
  },
  {
    isoCode: 'JPY',
    name: 'Yen',
  },
  {
    isoCode: 'CNY',
    name: 'Yuan Renminbi',
  },
  {
    isoCode: 'ZMW',
    name: 'Zambian Kwacha',
  },
  {
    isoCode: 'ZWL',
    name: 'Zimbabwe Dollar',
  },
  {
    isoCode: 'PLN',
    name: 'Zloty',
  },
];
