import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Label } from '../Label/Label'

export function Toggle(props) {
  const componentToggleClass = classNames(
    {
      'c-toggle  ': true,
      'c-toggle--on-off  ': props.off,
      'c-toggle--a-b  ': props.toggleAB,
    },
    props.className
  ).trim()

  if (!props.toggleAB) {
    return (
      <div className={componentToggleClass}>
        <Label for={props.id} noStyle>
          {props.off && <span className="c-toggle__text">{props.off}</span>}
          <input
            data-testid={props.testId}
            className="c-toggle__input"
            type="checkbox"
            name="toggle"
            id={props.id}
            value={props.value}
            checked={props.checked}
            defaultChecked={props.defaultChecked}
            onChange={props.onChange}
          />
          {props.on && <span className="c-toggle__text">{props.on}</span>}
        </Label>
      </div>
    )
  }
  const forA = `${props.id}A`
  const forB = `${props.id}B`
  const dataTestA = `${props.testId}A`
  const dataTestB = `${props.testId}B`
  return (
    <div className={componentToggleClass}>
      <input
        data-testid={dataTestA}
        className="c-toggle__input"
        type="radio"
        name="toggle-switch"
        id={forA}
        value={props.value}
        checked={props.checked === undefined ? props.checked : !props.checked}
        defaultChecked={
          props.defaultChecked === undefined
            ? props.defaultChecked
            : !props.defaultChecked
        }
        onChange={props.onChange}
      />
      <label htmlFor={forA} className="c-toggle__text">
        {props.off}
      </label>
      <input
        data-testid={dataTestB}
        className="c-toggle__input"
        type="radio"
        name="toggle-switch"
        id={forB}
        value={props.value}
        checked={props.checked}
        defaultChecked={props.defaultChecked}
        onChange={props.onChange}
      />
      <label htmlFor={forB} className="c-toggle__text">
        {props.on}
      </label>
    </div>
  )
}

Toggle.propTypes = {
  testId: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  off: PropTypes.string,
  on: PropTypes.string,
  toggleAB: PropTypes.bool,
  value: PropTypes.string,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func,
}

Toggle.defaultProps = {
  toggleAB: false,
  defaultChecked: undefined,
  value: '',
}
