import * as jwtdecode from 'jwt-decode';
import { retrieveTokens, deleteTokens } from './auth-storage';
import { getAppBaseUrl } from '../common/utils';
import config from '../resources/config.json';
import { USER_ROLE } from '../common/constants';

export function isTokenValid() {
  return retrieveTokens() != null && getSessionTimeLeftInSeconds() > 0;
}

export function getToken() {
  const tokens = retrieveTokens();
  if (!tokens) return null;
  return {
    ...tokens,
    idTokenObject: jwtdecode(tokens.idToken),
    accessTokenObject: jwtdecode(tokens.accessToken),
  };
}

export function clearAll() {
  deleteTokens();
}

export function redirectForToken() {
  const { REACT_APP_COGNITO_AUTH_ENDPOINT, REACT_APP_COGNITO_CLIENT_ID } = process.env;
  const redirectUri = `${getAppBaseUrl(process.env)}${config.auth.redirectPath}`;
  const authUrl = `${REACT_APP_COGNITO_AUTH_ENDPOINT}?client_id=${REACT_APP_COGNITO_CLIENT_ID}&response_type=token&scope=${config.auth.scope}&redirect_uri=${redirectUri}`;
  window.location = authUrl;
}

export function logout() {
  clearAll();
  const { REACT_APP_COGNITO_LOGOUT_ENDPOINT, REACT_APP_COGNITO_CLIENT_ID } = process.env;
  const logoutUri = `${getAppBaseUrl(process.env)}${config.auth.logoutPath}`;
  const logoutUrl = `${REACT_APP_COGNITO_LOGOUT_ENDPOINT}?client_id=${REACT_APP_COGNITO_CLIENT_ID}&logout_uri=${logoutUri}`;
  window.location = logoutUrl;
}

export function getSessionTimeLeftInSeconds() {
  try {
    const tokens = retrieveTokens();
    const expireTimestamp = jwtdecode(tokens.accessToken).exp;
    const now = Math.floor(Date.now() / 1000);
    return expireTimestamp - now;
  } catch (e) {
    return 0;
  }
}

export function getRole() {
  if (!isTokenValid()) return USER_ROLE.ROLE_NONE;
  const groups = getToken().accessTokenObject['cognito:groups'];
  if (!groups) return USER_ROLE.ROLE_NONE;
  return groups[0];
}
