import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spinner } from 'cj-common-components';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withAuth } from '../../auth/withAuth';
import commonPropTypes from '../../common/common-prop-types';
import { NotFoundResource } from '../common-components/NotFoundResource';
import ConfigurationDataApi from '../../api/ConfigurationData';
import { ROUTE_KEYS, ROUTES, RESOURCES_KEYS } from '../../common/constants';
import MerchantGroupEditForm from './MerchantGroupEditForm';
import { setIsLoading, setMerchantGroupName } from '../../redux/breadCrumbsSlice';
import UserEditForm from './users/UserEditForm';
import MerchantEditForm from './merchants/MerchantEditForm';

class MerchantGroupsPage extends React.PureComponent {
  static propTypes = {
    authToken: commonPropTypes.authToken,
    dispatch: PropTypes.func.isRequired,
    ...commonPropTypes.router,
  };

  constructor(props) {
    super(props);
    this.state = {
      merchantGroupData: {},
      isLoading: true,
    };
    const { authToken } = this.props;
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
    this.updateMerchantGroupData = this.updateMerchantGroupData.bind(this);
    this.getMerchantGroupData = this.getMerchantGroupData.bind(this);
  }

  componentDidMount() {
    const {
      dispatch,
      match: {
        params: { merchantGroupId },
      },
    } = this.props;

    if (merchantGroupId === ROUTE_KEYS.creation) {
      dispatch(setIsLoading({ isLoading: false }));
      this.setState({
        isLoading: false,
        merchantGroupData: {},
      });
    } else {
      dispatch(setIsLoading({ isLoading: true }));
      this.setState({ isLoading: true });
      this.getMerchantGroupData(merchantGroupId);
    }
  }

  render() {
    const { authToken, merchantGroupId } = this.props;
    const { isLoading, merchantGroupData } = this.state;
    this.configurationDataApi = this.props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);

    return isLoading ? (
      <Spinner center small={false} fullPage />
    ) : (
      <>
        <Switch>
          <Route
            exact
            path={ROUTES.merchantGroupPage}
            render={() => (
              <MerchantGroupEditForm authToken={authToken} merchantGroupData={merchantGroupData} isFormEditable />
            )}
          />
          <Route
            exact
            path={ROUTES.merchantGroupEditUserForm}
            render={routeProps => (
              <UserEditForm
                routeProps={routeProps}
                authToken={authToken}
                merchantGroupData={merchantGroupData}
                isFormEditable
                onBack={() => this.handleGoBack(`/${RESOURCES_KEYS.merchantGroups}/${merchantGroupId}`)}
              />
            )}
          />
          <Route
            exact
            path={ROUTES.merchantGroupAddUserForm}
            render={routeProps => (
              <UserEditForm
                routeProps={routeProps}
                authToken={authToken}
                merchantGroupData={merchantGroupData}
                isFormEditable
                onBack={() => this.handleGoBack(`/${RESOURCES_KEYS.merchantGroups}/${merchantGroupId}`)}
              />
            )}
          />
          <Route
            exact
            path={ROUTES.merchantGroupAddMerchantForm}
            render={() => (
              <MerchantEditForm
                authToken={authToken}
                merchantGroupData={merchantGroupData}
                isFormEditable
                onBack={() => this.handleGoBack(`/${RESOURCES_KEYS.merchantGroups}/${merchantGroupId}`)}
              />
            )}
          />
          <Route exact path="*" render={() => NotFoundResource()} />
        </Switch>
      </>
    );
  }

  updateMerchantGroupData(updatedData) {
    this.setPreparedData(updatedData);
  }

  getMerchantGroupData(merchantGroupId) {
    this.configurationDataApi.getMerchantGroupById(merchantGroupId).then(result => {
      this.setPreparedData(result);
    });
  }

  setPreparedData(merchantGroupData) {
    const { dispatch } = this.props;

    dispatch(setMerchantGroupName({ merchantGroupName: merchantGroupData.name }));
    dispatch(setIsLoading({ isLoading: false }));
    this.setState({
      merchantGroupData,
      isLoading: false,
    });
  }

  handleGoBack = location => {
    this.props.history.replace(location);
  };
}

export default connect()(withRouter(withAuth(MerchantGroupsPage, ROUTES.merchantGroups)));
