export const initializieEmptyChannelRule = channel => {
  const modifiedChannel = Object.assign({}, channel);
  if (!Object.prototype.hasOwnProperty.call(modifiedChannel, 'rule')) {
    modifiedChannel.rule = {
      properties: {
        amount: null,
        customerBillingAddressCountries: [],
        currencies: [],
        customerGroups: [],
        productGroups: [],
      },
    };
  }
  return modifiedChannel;
};
