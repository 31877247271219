import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export function Progress(props) {
  const componentProgressClass = classNames(
    {
      'c-progress  ': true,
    },
    props.className
  ).trim()

  return (
    <progress
      data-testid={props.testId}
      className={componentProgressClass}
      value={props.value}
      max={props.max}
    />
  )
}

Progress.propTypes = {
  testId: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.number,
  max: PropTypes.number,
}
