import React from 'react';

import ConfigurationDataApi from '../../../api/ConfigurationData';
import CurrencyEditForm from './EditForm';
import commonPropTypes from '../../../common/common-prop-types';
import CommonTable from '../../common-components/CommonTable';

export default class Table extends React.Component {
  static propTypes = {
    ...commonPropTypes.Table,
  };

  constructor(props) {
    super(props);
    const { authToken } = this.props;

    this.loadCurrenciesList = this.loadCurrenciesList.bind(this);
    this.deleteCurrency = this.deleteCurrency.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
  }

  render() {
    const { authToken, textsKey } = this.props;

    return (
      <CommonTable
        authToken={authToken}
        textsKey={textsKey}
        dataFunctions={{
          loadData: this.loadCurrenciesList,
          deleteItem: this.deleteCurrency,
        }}
        editComponent={{
          type: CurrencyEditForm,
        }}
        isRowEditable={false}
        hasMaxColumnSize={true}
        testIdPrefix="currencies-table"
      />
    );
  }

  loadCurrenciesList() {
    return this.configurationDataApi.getCurrencies();
  }

  deleteCurrency(currency) {
    return this.configurationDataApi.deleteCurrency(currency);
  }
}
