import { isEmpty } from '@turbopay/ts-helpers/object-utils';
import { getEnumText } from '../../../common/utils';
import { ACCESS_TYPE, OPERATOR_ACCESS_LEVEL } from '../../../common/constants';

export const prepareMerchantDataTransformation = ({ merchant }) => {
  const { accessLevel, fspayProgramCode, apiUsername, apiPassword, gatekeeperSessions } = merchant;

  const newObject = Object.assign({}, merchant, {
    credentials: {
      apiUsername,
      apiPassword,
    },
    accessLevel: accessLevel && getEnumText(OPERATOR_ACCESS_LEVEL, accessLevel),
    storedPayments: {
      isEnabled: !isEmpty(fspayProgramCode),
      fspayProgramCode,
    },
    gatekeeperSessions: gatekeeperSessions ? gatekeeperSessions.toString() : '',
  });
  delete newObject.fspayProgramCode;

  return newObject;
};

export const prepareMerchantListItemData = merchant => {
  return Object.assign({}, merchant, {
    accessLevel: getEnumText(OPERATOR_ACCESS_LEVEL, merchant.accessLevel),
    accessType: merchant.accessType
      ? `${getEnumText(ACCESS_TYPE, ACCESS_TYPE.GROUP)}: ${merchant.accessType.name}`
      : getEnumText(ACCESS_TYPE, ACCESS_TYPE.DIRECT),
    isLocked: merchant.isLocked,
    isMerchantLink: true,
    creationDate: merchant.creationDate.substring(0, 19),
    strongAuthentication: merchant.strongAuthentication ? 'enabled' : '',
    isCheckoutJourney: merchant.isCheckoutJourney ? 'enabled' : '',
    businessDomain: merchant.businessDomain || '',
  });
};
