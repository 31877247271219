let currentTokens;

export function storeTokens({ idToken, accessToken }) {
  currentTokens = { idToken, accessToken };
}

export function retrieveTokens() {
  return currentTokens;
}

export function deleteTokens() {
  currentTokens = null;
}

export function storeLocation(path) {
  localStorage.setItem('sm-auth-location', path);
}

export function retrieveLocation() {
  return localStorage.getItem('sm-auth-location');
}
