import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as AuthStorage from './auth-storage';
import * as TokenHelper from './token-helper';
import { getConfigSection, getScreenTextKey } from '../common/utils';
import { ModalError } from '../screens/common-components/ModalWindow';
import commonPropTypes from '../common/common-prop-types';
import { ROUTES } from '../common/constants';

const config = require('../resources/config.json');

export function withAuth(WrappedComponent, componentLocation) {
  class AuthHOC extends React.PureComponent {
    constructor(props) {
      super(props);
      AuthStorage.storeLocation(props.location.pathname);

      if (!TokenHelper.isTokenValid()) {
        TokenHelper.redirectForToken();
      }

      this.state = {
        hasAuth: TokenHelper.isTokenValid(),
        authToken: TokenHelper.getToken(),
        errorMessageAcknowledged: false,
      };
    }

    render() {
      const { hasAuth, authToken, errorMessageAcknowledged } = this.state;
      const { location, loggedUser } = this.props;

      if (loggedUser.role) {
        const userRole = loggedUser.role;
        const allowedScreens = getConfigSection(config, 'permissions')[userRole] || [];
        const requestedScreen = getScreenTextKey(componentLocation || location.pathname);

        let hasUserPermission;

        if (componentLocation === ROUTES.accessDenied) {
          hasUserPermission = true;
        } else {
          hasUserPermission = allowedScreens.some(screen => screen === requestedScreen);
        }

        return (
          <>
            {hasAuth && hasUserPermission && (
              <WrappedComponent authToken={authToken} userRole={userRole} {...this.props} />
            )}
            {hasAuth && !hasUserPermission && !errorMessageAcknowledged && <Redirect to="/accessDenied" />}
          </>
        );
      } else {
        return <></>;
      }
    }

    renderErrorDialog() {
      return (
        <ModalError
          errorKey="common.noPermission"
          onConfirm={() => {
            this.setState({
              errorMessageAcknowledged: true,
            });
          }}
        />
      );
    }

    static defaultPropTypes = {
      location: commonPropTypes.router.location,
    };
  }

  const mapStateToProps = state => {
    const { loggedUser } = state.appData;
    return { loggedUser };
  };

  return connect(mapStateToProps)(AuthHOC);
}
