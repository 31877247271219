import React from 'react';
import PropTypes from 'prop-types';

// This class is a partial implementation of Bronson's FormGroup component.
// It renders a box grouping several form elements but without the
// functionality of hiding/expanding the content
export default class SimpleFormGroup extends React.PureComponent {
  static propTypes = {
    // We do really need to pass any component here, so lint rule disabled
    // eslint-disable-next-line react/forbid-prop-types
    children: PropTypes.any.isRequired,
    keyName: PropTypes.string.isRequired,
    testId: PropTypes.string,
  };

  render() {
    const { children, keyName, testId } = this.props;

    return (
      <div className="c-form-section-group u-mb" key={keyName} data-testid={testId}>
        <div className="c-form-section  js-form-section  js-is-fx  c-form-section--success">
          <div className="c-form-section__panel  js-form-section__panel" aria-hidden="false">
            <div className="c-form-section__content">{children}</div>
          </div>
        </div>
      </div>
    );
  }
}
