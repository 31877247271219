import React from 'react';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import CultureEditForm from './EditForm';
import EnhancedForm from '../../common-components/EnhancedForm';

export default class Form extends React.Component {
  constructor(props) {
    super(props);
    const { authToken } = this.props;

    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
  }

  render() {
    const { authToken, textsKey, isFormEditable, navigationFunction, onBack, merchantData } = this.props;
    const merchantUnderEdition = merchantData;

    const textsKeyIntegration = `${textsKey}.culture`;

    return (
      <EnhancedForm
        data={merchantUnderEdition}
        authToken={authToken}
        textsKey={textsKeyIntegration}
        editComponent={{
          type: CultureEditForm,
        }}
        dataFunctions={{
          modifyItem: () => {},
        }}
        onBack={onBack}
        isVisible
        isFormEditable={isFormEditable}
        isSaveButtonVisible={false}
        navigationFunction={navigationFunction}
      />
    );
  }
}
