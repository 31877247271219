import React from 'react';
import { connect } from 'react-redux';

const MerchantGroupBreadCrumb = ({ merchantGroupName }) => <span>{merchantGroupName}</span>;
const mapStateToProps = state => {
  const { merchantGroupName } = state.breadCrumbs;
  return { merchantGroupName };
};

export default connect(mapStateToProps)(MerchantGroupBreadCrumb);
