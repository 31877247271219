import { createSlice } from '@reduxjs/toolkit';

const dataSlice = createSlice({
  name: 'dataSlice',
  initialState: {
    merchantsList: [],
    channelsList: [],
    loggedUser: {},
  },
  reducers: {
    setMerchantsList: (state, action) => {
      const { merchantsList } = action.payload;
      // eslint-disable-next-line no-param-reassign
      state.merchantsList = merchantsList;
    },
    setChannelsList: (state, action) => {
      const { channelsList } = action.payload;
      // eslint-disable-next-line no-param-reassign
      state.channelsList = channelsList;
    },
    setLoggedUser: (state, action) => {
      const { loggedUser } = action.payload;
      // eslint-disable-next-line no-param-reassign
      state.loggedUser = loggedUser;
    },
  },
});

export const { setMerchantsList, setChannelsList, setLoggedUser } = dataSlice.actions;

export default dataSlice.reducer;
