import { createSlice } from '@reduxjs/toolkit';

const breadCrumbsSlice = createSlice({
  name: 'breadCrumbs',
  initialState: {
    channelName: null,
    merchantName: null,
    merchantGroupName: null,
    merchantGroupEditUserName: null,
    isLoading: true,
    targetMerchantAccountReference: null,

  },
  reducers: {
    setMerchantName: (state, action) => {
      const { merchantName } = action.payload;
      // eslint-disable-next-line no-param-reassign
      state.merchantName = merchantName;
    },
    setMerchantGroupName: (state, action) => {
      const { merchantGroupName } = action.payload;
      // eslint-disable-next-line no-param-reassign
      state.merchantGroupName = merchantGroupName;
    },
    setMerchantGroupEditUserName: (state, action) => {
      const { merchantGroupEditUserName } = action.payload;
      // eslint-disable-next-line no-param-reassign
      state.merchantGroupEditUserName = merchantGroupEditUserName;
    },
    setChannelName: (state, action) => {
      const { channelName } = action.payload;
      // eslint-disable-next-line no-param-reassign
      state.channelName = channelName;
    },
    setIsLoading: (state, action) => {
      const { isLoading } = action.payload;
      // eslint-disable-next-line no-param-reassign
      state.isLoading = isLoading;
    },
    setTargetMerchantAccountReference: (state, action) => {
      const { targetMerchantAccountReference } = action.payload;
      state.targetMerchantAccountReference = targetMerchantAccountReference;
    },
  },
});

export const {
  setMerchantName,
  setMerchantGroupName,
  setMerchantGroupEditUserName,
  setChannelName,
  setIsLoading,
  setTargetMerchantAccountReference,
} = breadCrumbsSlice.actions;

export default breadCrumbsSlice.reducer;
