import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import { Layout, Column } from '../Layout/Layout'
import { Image } from '../../Atoms/Image/Image'

export function DataOverview({
  children,
  title,
  icon,
  backLabel,
  backHref,
  image,
  imageAlt,
}) {
  function renderDataContent() {
    if (image) {
      return (
        <Layout>
          <Column span="1/2" m="1/1">
            <div className="c-data-overview__body">
              <dl className="c-dl-horizontal">{children}</dl>
            </div>
          </Column>
          <Column span="1/2" m="1/1">
            <Image src={image} alt={imageAlt} />
          </Column>
        </Layout>
      )
    }

    return (
      <div className="c-data-overview__body">
        <dl className="c-dl-horizontal">{children}</dl>
      </div>
    )
  }

  return (
    <article className="c-data-overview">
      <header className="c-data-overview__header">
        {icon && (
          <div className="c-data-overview__icon">
            <i className={`c-icon  c-icon--[${icon}]`} aria-hidden="true" />
          </div>
        )}

        <h4 className="c-data-overview__title">{title}</h4>

        {backLabel && (
          <RouterLink
            to={backHref}
            className="c-btn  c-data-overview__button  c-btn--link  c-btn--small"
            aria-label={backLabel}
          >
            <i className="c-btn__icon" />
            <span className="c-btn__text">{backLabel}</span>
          </RouterLink>
        )}
      </header>

      {renderDataContent()}
    </article>
  )
}

DataOverview.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  backLabel: PropTypes.string,
  backHref: PropTypes.string,
  children: props => {
    if (
      React.Children.toArray(props.children).some(
        child => child.type !== DataOverviewItem
      )
    ) {
      return new Error(
        "'DataOverview' children should be of type 'DataOverview.Item'"
      )
    }
    return undefined
  },
}

DataOverview.Item = DataOverviewItem

function DataOverviewItem({ label, value }) {
  return (
    <React.Fragment>
      <dt className="c-dl-horizontal__title">{label}</dt>
      <dd className="c-dl-horizontal__item">{value}</dd>
    </React.Fragment>
  )
}

DataOverviewItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
}
