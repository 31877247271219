import React from 'react';
import ConfigurationDataApi from '../../api/ConfigurationData';
import CommonTable from '../common-components/CommonTable';
import commonPropTypes from '../../common/common-prop-types';
import { prepareMerchantListItemData } from './merchantDataUtils/prepareMerchantDataTransformation';
import { checkEditFormCanBeModified } from './merchantDataUtils/checkEditFormCanBeModified';
import { withAuth } from '../../auth/withAuth';
import { ROUTE_KEYS } from '../../common/constants';
import { connect } from 'react-redux';
import { setMerchantFilters, setMerchantSorting } from '../../redux/merchantFiltersSlice';

class MerchantsTable extends React.Component {
  static propTypes = {
    authToken: commonPropTypes.authToken,
  };

  constructor(props) {
    super(props);
    const { authToken } = this.props;
    this.loadMerchantsList = this.loadMerchantsList.bind(this);
    this.deleteMerchant = this.deleteMerchant.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
    this.state = {
      merchantsList: [],
    };
  }

  render() {
    const { navigationFunction, dispatch, merchantFilters } = this.props;
    const { merchantsList } = this.state;
    const isFormEditable = checkEditFormCanBeModified(merchantsList);

    return (
      <CommonTable
        textsKey={ROUTE_KEYS.merchants}
        dataFunctions={{
          loadData: this.loadMerchantsList,
          deleteItem: this.deleteMerchant,
        }}
        isFormEditable={isFormEditable}
        navigationFunction={navigationFunction}
        isAccessLevelApplicable
        hasMaxColumnSize={true}
        onFiltersChanged={data => dispatch(setMerchantFilters({ filters: data }))}
        initialFilteringValues={merchantFilters.filters}
        onSortingChanged={data => dispatch(setMerchantSorting({ sorting: data }))}
        initialSortingValues={merchantFilters.sorting}
        testIdPrefix="merchants-table"
      />
    );
  }

  async loadMerchantsList() {
    const { authToken } = this.props;
    const { username } = authToken.accessTokenObject;
    const response = await this.configurationDataApi.getMerchants(username);
    const preparedResponse = response.map(item => prepareMerchantListItemData(item));
    this.setState({
      merchantsList: preparedResponse,
    });
    return preparedResponse;
  }

  deleteMerchant(merchant) {
    return this.configurationDataApi.deleteMerchant(merchant.id);
  }
}

const connectFilters = connect(state => ({ merchantFilters: state.merchantFilters }));

export default connectFilters(withAuth(MerchantsTable));
