import * as Yup from 'yup';
import { getConfigSection } from '../../../../common/utils';
import errorsTexts from '../../../../resources/errorTexts.json';
import { PROGRAM_ACCOUNT_NUMBERS } from '../../../../common/constants';

const errorMessages = getConfigSection(errorsTexts, 'validation');

export const getDestinationAccountsValidation = ({ programAccountType }) => {
  return Yup.object().shape({
    merchantReference: Yup.string()
      .required(errorMessages.mandatoryField)
      .max(127, errorMessages.maxLength.replace('$MAX$', 127)),
    merchantProgramCode: Yup.string().when('programAccountType', {
      is: () => {
        return programAccountType === PROGRAM_ACCOUNT_NUMBERS['00'];
      },
      then: Yup.string().required(errorMessages.mandatoryField),
    }),
    merchantAccno: Yup.string()
      .required(errorMessages.mandatoryField)
      .max(127, errorMessages.maxLength.replace('$MAX$', 127)),
    merchantCurrency: Yup.string()
      .required(errorMessages.mandatoryField)
      .max(127, errorMessages.maxLength.replace('$MAX$', 127)),
  });
};
