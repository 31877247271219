import React from 'react';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import { getEnumText, getEnumValueFromText } from '../../../common/utils';
import commonPropTypes from '../../../common/common-prop-types';
import { OPERATOR_ACCESS_LEVEL, SORT_COLUMNS } from '../../../common/constants';
import CommonTable from '../../common-components/CommonTable';

export default class UsersTable extends React.Component {
  static propTypes = {
    ...commonPropTypes.table,
  };

  static prepareDataTransformation(user) {
    const { accessLevel } = user;

    return Object.assign({}, user, {
      accessLevel: getEnumText(OPERATOR_ACCESS_LEVEL, accessLevel),
    });
  }

  static revertDataTransformation(user) {
    const { accessLevel } = user;

    return Object.assign({}, user, {
      accessLevel: getEnumValueFromText(OPERATOR_ACCESS_LEVEL, accessLevel),
    });
  }

  constructor(props) {
    super(props);
    const { authToken } = this.props;
    this.loadUsersData = this.loadUsersData.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
  }

  render() {
    const { authToken, textsKey } = this.props;
    const textsKeyUsers = `${textsKey}.users`;
    const creationPath = 'addUser';
    const editPath = 'editUser';
    const rowId = 'userId';

    return (
      <CommonTable
        authToken={authToken}
        textsKey={textsKeyUsers}
        dataFunctions={{
          loadData: this.loadUsersData,
          modifyItem: item => item,
          deleteItem: this.deleteUser,
        }}
        defaultSortColumn={SORT_COLUMNS.CREATION_DATE}
        creationPath={creationPath}
        editPath={editPath}
        rowId={rowId}
        hasHeader
        testIdPrefix="users-table"
      />
    );
  }

  async loadUsersData() {
    const { merchantGroupData } = this.props;
    const data = await this.configurationDataApi.getMerchantGroupUsers(merchantGroupData.id);
    const preparedUsers = data.users?.map(user =>
      Object.assign({}, user, {
        accessLevel: getEnumText(OPERATOR_ACCESS_LEVEL, user.accessLevel),
      }),
    );

    return preparedUsers;
  }

  deleteUser(user) {
    const { merchantGroupData } = this.props;

    return this.configurationDataApi.deleteMerchantGroupUser(merchantGroupData.id, user.userId);
  }
}
