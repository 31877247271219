import React from 'react';
import { Spinner } from 'cj-common-components';
import PropTypes from 'prop-types';
import BaseEditForm from '../../../../common-components/BaseEditForm';
import { ModalError } from '../../../../common-components/ModalWindow';
import FormFields from './TargetMerchantAccountFormFields';
import commonPropTypes from '../../../../../common/common-prop-types';
import { ROUTE_KEYS } from '../../../../../common/constants';
import ConfigurationDataApi, { isCreationPath } from '../../../../../api/ConfigurationData';
import { getConfigSection, reconcile, textsArrayToString } from '../../../../../common/utils';
import { setIsLoading, setTargetMerchantAccountReference } from '../../../../../redux/breadCrumbsSlice';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { BlockNavigation } from '../../../../common-components/BlockNavigation';
import { diff } from 'deep-diff';

const uiTexts = require('../../../../../resources/uiTexts.json');
const confirmationTexts = getConfigSection(uiTexts, 'common.table.dialogs.error');

class TargetMerchantAccountEditForm extends React.Component {
  static propTypes = {
    routeProps: PropTypes.shape({
      match: commonPropTypes.router.match,
    }),
    // eslint-disable-next-line react/forbid-prop-types
    authToken: commonPropTypes.authToken,
    textsKey: PropTypes.string,
    isFormEditable: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    const { authToken, routeProps } = this.props;

    this.state = {
      isCreationUrl: isCreationPath(routeProps.match.url),
      data: {},
      isLoading: false,
      successMessage: false,
      modalState: {
        isVisible: false,
        data: {},
      },
      skipNavBlock: false,
    };
    this.valuesRef = React.createRef();
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
    this.showSuccessMessage = this.showSuccessMessage.bind(this);
    this.addTargetMerchantAccount = this.addTargetMerchantAccount.bind(this);
    this.modifyTargetMerchantAccount = this.modifyTargetMerchantAccount.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    this.initializeData();
  }

  compareValues = () => {
    if (this.state.skipNavBlock) {
      return;
    }

    if (!this.valuesRef.current || !this.valuesRef.current.values) {
      return;
    }

    const fVals = this.valuesRef.current.values;

    let diffs = (diff(this.state.data, fVals) || []).filter(change => {
      if (change.kind === 'N') {
        if (!reconcile(change.rhs)) return false;
      }
      return true;
    });

    if (diffs.length === 0) {
      diffs = undefined;
    }
    return diffs;
  };

  render() {
    const { authToken, textsKey, onCancel, isVisible, isFormEditable } = this.props;
    const { isLoading, modalState, data, isCreationUrl } = this.state;

    return isLoading ? (
      <Spinner center small={false} fullPage />
    ) : (
      <>
        {this.state.successMessage && this.renderSuccessMessage()}
        {modalState.isVisible && this.renderModal(modalState)}
        <BlockNavigation compareValues={this.compareValues} />
        <BaseEditForm
          valuesRef={this.valuesRef}
          data={data}
          isCreationFlow={isCreationUrl}
          authToken={authToken}
          textsKey={textsKey}
          onBack={this.goBack}
          onSaveModifiedItem={this.modifyTargetMerchantAccount}
          onSaveNewItem={this.addTargetMerchantAccount}
          onCancel={onCancel}
          isVisible={isVisible}
          fieldsComponent={{
            type: FormFields,
          }}
          validationSchema={FormFields.validationSchema}
          isFormEditable={isFormEditable}
          isSaveButtonEnable={isFormEditable}
        />
      </>
    );
  }

  setLoading(isLoading) {
    this.setState({ isLoading });
  }

  trimUrlBeforeUpdate(url) {
    const targetMerchantAccountIdPrefix = 'TARGET-MERCHANT-ACCOUNT@';
    const endIndex = url.indexOf(targetMerchantAccountIdPrefix);

    if (endIndex > -1) {
      return url.substring(0, endIndex + targetMerchantAccountIdPrefix.length);
    } else {
      return url;
    }
  }

  async initializeData() {
    const {
      routeProps: {
        match: {
          params: { channelId, merchantId, targetAccountId },
        },
      },
      dispatch,
    } = this.props;

    const { isCreationUrl } = this.state;

    if (isCreationUrl === false) {
      dispatch(setIsLoading({ isLoading: true }));

      this.setState({ isLoading: true });

      const targetMerchantAccount = await this.getTargetMerchantAccount(merchantId, channelId, targetAccountId);

      dispatch(
        setTargetMerchantAccountReference({
          targetMerchantAccountReference: targetMerchantAccount.targetMerchantAccountReference,
        }),
      );
      dispatch(setIsLoading({ isLoading: false }));

      this.setState({
        isLoading: false,
        data: {
          ...targetMerchantAccount,
        },
      });
    }
  }

  showSuccessMessage(state = true) {
    this.setState({
      ...this.state,
      successMessage: state,
    });
  }

  renderSuccessMessage() {
    const text = getConfigSection(uiTexts, 'common.successMessage');

    return (
      <p className="merchant-changes-success-message" data-testid="success-message">
        {text}
      </p>
    );
  }
  async addTargetMerchantAccount(data) {
    // add the data object to state
    // to keep the field values after page refresh.
    // The page refreshes upone request
    this.setState({ isLoading: true, data, skipNavBlock: true });

    const {
      routeProps: {
        match: {
          params: { channelId, merchantId },
        },
      },
    } = this.props;

    try {
      await this.configurationDataApi.createTargetMerchantAccount(merchantId, channelId, data);
      this.setState({ isLoading: false, data });
      this.goBack();
    } catch (err) {
      const message = textsArrayToString(err.response?.data?.message);
      const { buttonOk } = confirmationTexts;

      this.changeModalState({
        isVisible: true,
        buttonConfirmText: buttonOk,
        errorKey: message,
      });
      this.setState({ isLoading: false, data });
    }
  }

  goBack() {
    const {
      routeProps: {
        match: {
          params: { channelId, merchantId },
        },
        history,
      },
    } = this.props;

    history.push(
      `/${ROUTE_KEYS.merchants}/${merchantId}/${ROUTE_KEYS.channels}/${channelId}/${ROUTE_KEYS.targetMerchantAccounts}`,
    );
  }

  changeModalState({ isVisible, buttonConfirmText, errorKey, title }) {
    this.setState({
      modalState: { isVisible, buttonConfirmText, errorKey, title },
    });
  }

  renderModal({ buttonConfirmText, errorKey, title }) {
    return (
      <ModalError
        title={title}
        buttonConfirmText={buttonConfirmText}
        onConfirm={() => this.changeModalState(false)}
        errorKey={errorKey}
      />
    );
  }

  async modifyTargetMerchantAccount(targetMerchantAccount) {
    const {
      routeProps: {
        match: {
          params: { channelId, merchantId, targetAccountId },
        },
      },
      location,
      history,
      dispatch,
    } = this.props;

    const {
      data: { targetMerchantAccountReference },
    } = this.state;

    this.setState({ isLoading: true, skipNavBlock: true });

    try {
      const { id, ...rest } = targetMerchantAccount;
      const result = await this.configurationDataApi.updateTargetMerchantAccount(
        merchantId,
        channelId,
        targetAccountId,
        rest,
      );

      if (targetMerchantAccountReference !== targetMerchantAccount.targetMerchantAccountReference) {
        const nextUrl = `${this.trimUrlBeforeUpdate(location.pathname)}${
          targetMerchantAccount.targetMerchantAccountReference
        }`;

        dispatch(
          setTargetMerchantAccountReference({
            targetMerchantAccountReference: targetMerchantAccount.targetMerchantAccountReference,
          }),
        );

        history.push(nextUrl);
      }

      this.setState({ isLoading: false, successMessage: true, data: { ...result } });
    } catch (err) {
      const message = textsArrayToString(err.response?.data?.message);
      const { buttonOk } = confirmationTexts;

      this.changeModalState({
        isVisible: true,
        buttonConfirmText: buttonOk,
        errorKey: message,
      });

      this.setState({ isLoading: false });
    }
  }

  async getTargetMerchantAccount(merchantId, channelId, targetAccountId) {
    return await this.configurationDataApi.getTargetMerchantAccount(merchantId, channelId, targetAccountId);
  }
}

export default connect()(withRouter(TargetMerchantAccountEditForm));
