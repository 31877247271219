import React from 'react';
import { connect } from 'react-redux';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import CommonTable from '../../common-components/CommonTable';
import { getConfigSection } from '../../../common/utils';
import commonPropTypes from '../../../common/common-prop-types';
import { SORT_COLUMNS } from '../../../common/constants';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import NotificationsPageFilter from './NotificationsPageFilter';
import { removeEmptyValuesFromObject } from '../../../common/utils';

const uiTexts = require('../../../resources/uiTexts.json');

class NotificationsPage extends React.Component {
  static propTypes = {
    ...commonPropTypes.table,
  };

  constructor(props) {
    super(props);
    this.state = {
      pointer: null,
      canMakeRequest: true,
    };
    const { authToken } = this.props;
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
    this.loadData = this.loadData.bind(this);
  }

  render() {
    const { authToken, textsKey, isFormEditable, merchantData } = this.props;
    const { pointer } = this.state;
    const headerLevelsSeparator = getConfigSection(uiTexts, 'common.headerLevelsSeparator');
    const parentHeader = getConfigSection(uiTexts, `${textsKey}.table.header`);
    const historyParentHeader = `${parentHeader} ${headerLevelsSeparator} ${merchantData.name}`;
    const scrollAtributes = {
      pointer,
    };

    return (
      <>
        <NotificationsPageFilter
          filterNotifications={filters => this.filterNotifications(filters)}
        ></NotificationsPageFilter>
        <CommonTable
          authToken={authToken}
          textsKey={textsKey}
          parentLevelHeader={historyParentHeader}
          dataFunctions={{
            loadData: this.loadData,
            deleteItem: () => {},
          }}
          defaultSortColumn={SORT_COLUMNS.CREATION_DATE}
          canAddNewItem={false}
          canClickOnItem={false}
          isRowDeletable={false}
          isFormEditable={isFormEditable}
          isRowEditable={false}
          isInfiniteLoading={true}
          canDownloadItem={true}
          showItemMessage={true}
          scrollAtributes={scrollAtributes}
          onRef={ref => (this.tableRef = ref)}
          testIdPrefix="notifications-table"
        />
      </>
    );
  }

  async loadData(searchParams, resetPointer) {
    const { merchantId } = this.props.match.params;
    const { pointer, canMakeRequest } = this.state;

    if (!canMakeRequest && !resetPointer) {
      return;
    }

    const merchantNotifications = await this.configurationDataApi.getMerchantNotifications(
      merchantId,
      resetPointer ? undefined : pointer,
      searchParams,
    );

    this.setState({
      pointer: merchantNotifications.nextPointer,
      canMakeRequest: !!merchantNotifications.nextPointer,
    });

    return merchantNotifications.results.map(x => (x = { ...x, sourceSystem: x.sourceSystem || '' }));
  }

  async filterNotifications(values) {
    this.tableRef.filterData(removeEmptyValuesFromObject(values));
  }
}

export default connect()(withRouter(NotificationsPage));
