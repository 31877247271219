import React from 'react';
import { Spinner } from 'cj-common-components';
import PropTypes from 'prop-types';
import BaseEditForm from '../../../common-components/BaseEditForm';
import FormFields from './FormFields';
import commonPropTypes from '../../../../common/common-prop-types';
import { OPERATOR_ACCESS_LEVEL, ROUTE_KEYS } from '../../../../common/constants';
import ConfigurationDataApi from '../../../../api/ConfigurationData';
import { getEnumText, getEnumValueFromText } from '../../../../common/utils';
import uiTexts from '../../../../resources/uiTexts.json';
import { getConfigSection, reconcile } from '../../../../common/utils';
import { BlockNavigation } from '../../../common-components/BlockNavigation';

export default class UserEditForm extends React.Component {
  static propTypes = {
    routeProps: PropTypes.shape({
      match: commonPropTypes.router.match,
    }),
    // eslint-disable-next-line react/forbid-prop-types
    merchantData: PropTypes.object,
    authToken: commonPropTypes.authToken,
    textsKey: PropTypes.string,
    isFormEditable: PropTypes.bool,
    onBack: PropTypes.func,
  };

  constructor(props) {
    super(props);
    const { authToken } = this.props;
    this.state = {
      isLoading: true,
      userData: {},
      merchantUsers: [],
      successMessage: false,
      skipNavBlock: false,
    };
    this.addUser = this.addUser.bind(this);
    this.modifyUser = this.modifyUser.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
    this.showSuccessMessage = this.showSuccessMessage.bind(this);

    this.valuesRef = React.createRef();
  }

  componentDidMount() {
    const {
      routeProps: {
        match: {
          params: { userId },
        },
      },
    } = this.props;

    this.loadUsersData().then(merchantUsers => {
      if (userId === ROUTE_KEYS.creation) {
        this.setState({ merchantUsers, userData: {}, isLoading: false });
      } else {
        const userData = merchantUsers.find(user => user.id === userId);
        this.setState({ merchantUsers, userData, isLoading: false });
      }
    });
  }

  setLoading(isLoading) {
    this.setState({ isLoading });
  }

  async addUser(newUser) {
    this.setState({ ...this.state, skipNavBlock: true });
    this.setLoading(true);
    const { merchantData, onBack } = this.props;
    await this.configurationDataApi
      .addMerchantUser(merchantData.id, this.mapToUserToMerchantRequest(newUser))
      .then(() => {
        this.setLoading(false);
        onBack();
      });
  }

  async modifyUser(updatedUser) {
    this.setState({ ...this.state, skipNavBlock: true });
    this.setLoading(true);
    const { merchantData } = this.props;
    this.showSuccessMessage(false);
    await this.configurationDataApi
      .modifyMerchantUser(merchantData.id, this.mapToUserToMerchantRequest(updatedUser))
      .then(() => {
        this.setLoading(false);
        this.showSuccessMessage();
      });
  }

  async loadUsersData() {
    const { merchantData, authToken } = this.props;
    const { username } = authToken.accessTokenObject;
    // eslint-disable-next-line max-len
    const usersData = await this.configurationDataApi.getMerchantUsersWithDirectAccess(merchantData.id);

    const preparedUsers = usersData.users
      .filter(user => user.id !== username)
      .map(user =>
        Object.assign({}, user, {
          accessLevel: getEnumText(OPERATOR_ACCESS_LEVEL, user.accessLevel),
        }),
      );

    return preparedUsers;
  }

  mapToUserToMerchantRequest(user) {
    return {
      id: user.id,
      accessLevel: getEnumValueFromText(OPERATOR_ACCESS_LEVEL, user.accessLevel),
    };
  }

  compareValues = () => {
    if (this.state.skipNavBlock) {
      return;
    }

    const fVals = this.valuesRef.current.values;
    const origVals = this.state.userData || {};

    const hasDiffs =
      reconcile(origVals.accessLevel) !== reconcile(fVals.accessLevel) ||
      reconcile(origVals.id) !== reconcile(fVals.id);

    if (hasDiffs) {
      return [];
    }
  };

  render() {
    const { authToken, textsKey, onBack, onCancel, isVisible, isFormEditable } = this.props;
    const { userData, isLoading, merchantUsers } = this.state;

    return isLoading ? (
      <Spinner center small={false} fullPage />
    ) : (
      <>
        {this.state.successMessage && this.renderSuccessMessage()}
        <BlockNavigation compareValues={this.compareValues} />
        <BaseEditForm
          valuesRef={this.valuesRef}
          data={userData}
          authToken={authToken}
          textsKey={textsKey}
          onBack={onBack}
          onSaveModifiedItem={(...args) => {
            return this.modifyUser(...args);
          }}
          onSaveNewItem={(...args) => {
            return this.addUser(...args);
          }}
          onCancel={onCancel}
          isVisible={isVisible}
          fieldsComponent={{
            type: FormFields,
          }}
          validationSchema={FormFields.validationSchema}
          isFormEditable={isFormEditable}
          customProps={{ merchantUsers }}
          isSaveButtonEnable={isFormEditable}
        />
      </>
    );
  }

  showSuccessMessage(state = true) {
    this.setState({
      ...this.state,
      successMessage: state,
    });
  }

  renderSuccessMessage() {
    const text = getConfigSection(uiTexts, 'common.successMessage');
    return (
      <p className="merchant-changes-success-message" data-testid="success-message">
        {text}
      </p>
    );
  }
}
