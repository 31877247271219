import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export function IconList(props) {
  const componentListClass = classNames(
    {
      'c-icon-list  ': true,
    },
    props.className
  ).trim()

  function renderList() {
    if (props.ordered) {
      return (
        <ol data-testid={props.testId} className={componentListClass}>
          {props.children}
        </ol>
      )
    }
    return (
      <ul data-testid={props.testId} className={componentListClass}>
        {props.children}
      </ul>
    )
  }

  return renderList(props.items)
}

IconList.Item = ({ children, icon, iconClass, iconMeaning, className }) => {
  const itemIconClass = classNames(
    {
      'c-icon-list__icon  ': true,
      'c-icon  ': true,
      [`c-icon--[${icon}]`]: !!icon,
    },
    iconClass
  ).trim()

  return (
    <li className={className}>
      <i className={itemIconClass} aria-hidden="true" />
      {iconMeaning && <span className="u-visually-hidden">{iconMeaning} </span>}
      {children}
    </li>
  )
}

IconList.Item.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.string,
  iconClass: PropTypes.string,
  iconMeaning: PropTypes.string,
}

IconList.Item.displayName = 'IconList.Item'

IconList.propTypes = {
  testId: PropTypes.string,
  className: PropTypes.string,
  children: props => {
    if (
      React.Children.toArray(props.children).some(
        child => child.type !== IconList.Item
      )
    ) {
      return new Error("'IconList' children should be of type 'IconList.Item'")
    }
    return undefined
  },
}
