export const NOTIFICATIONS_OBJECT_TYPES = [
  {
    label: '',
    value: '',
  },
  {
    label: 'payment',
    value: 'payment',
  },
  {
    label: 'order',
    value: 'order',
  },
  {
    label: 'network-token',
    value: 'network-token',
  },
  {
    label: 'refund',
    value: 'refund',
  },
  {
    label: 'prepayment',
    value: 'prepayment',
  },
  {
    label: 'stored-payment-option',
    value: 'stored-payment-option',
  },
];
