import React from 'react';

import ConfigurationDataApi from '../../../api/ConfigurationData';
import CommonTable from '../../common-components/CommonTable';
import commonPropTypes from '../../../common/common-prop-types';
import EditForm from './EditForm';
import { getConfigSection } from '../../../common/utils';
import { withRouter } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import { connect } from 'react-redux';

const uiTexts = require('../../../resources/uiTexts.json');
const activePaymentOptionsButton = getConfigSection(uiTexts, 'referenceData.upcfPaymentOptions.active.table.buttons');
const deletedPaymentOptionsButton = getConfigSection(uiTexts, 'referenceData.upcfPaymentOptions.deleted.table.buttons');

class Table extends React.Component {
  static propTypes = {
    ...commonPropTypes.Table,
  };

  constructor(props) {
    super(props);
    const { authToken } = this.props;
    this.loadPaymentOptionsList = this.loadPaymentOptionsList.bind(this);
    this.deletePaymentOption = this.deletePaymentOption.bind(this);
    this.restorePaymentOption = this.restorePaymentOption.bind(this);
    this.handleTableButtonFunc = this.handleTableButtonFunc.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
  }

  render() {
    const { authToken, textsKey, isArchive } = this.props;
    const testIdPrefix = isArchive ? 'deleted-upcf-payment-options-table' : 'upcf-payment-options-table';

    return (
      <CommonTable
        canAddNewItem={!isArchive}
        isRowDeletable={!isArchive}
        authToken={authToken}
        textsKey={textsKey}
        dataFunctions={{
          loadData: this.loadPaymentOptionsList,
          deleteItem: this.deletePaymentOption,
          restoreItem: this.restorePaymentOption,
        }}
        editComponent={{
          type: EditForm,
        }}
        canRestoreItem={isArchive}
        restoreItemDialogText={'This will affect all channels where this option was enabled. Do you wish to continue?'}
        tableButtonData={{
          tableButtonText: isArchive ? deletedPaymentOptionsButton.text : activePaymentOptionsButton.text,
          tableButtonKey: isArchive ? deletedPaymentOptionsButton.key : activePaymentOptionsButton.key,
          tableReloadData: true,
          tableButtonFunc: async () => this.handleTableButtonFunc(),
        }}
        deleteItemDialogText={
          'After deleting the payment option, it will no longer be available in transactions, but will remain in channel settings. Do you want to proceed?'
        }
        hasMaxColumnSize={true}
        testIdPrefix={testIdPrefix}
      />
    );
  }

  handleTableButtonFunc() {
    const { isArchive } = this.props;

    if (isArchive) {
      this.props.history.replace(`${ROUTES.referenceData}/upcfPaymentOptions`);
    } else {
      this.props.history.replace(`${ROUTES.referenceData}/upcfPaymentOptions/archive`);
    }
  }

  async loadPaymentOptionsList() {
    const { isArchive } = this.props;

    let paymentOptions = await this.configurationDataApi.getPaymentOptions();
    paymentOptions = paymentOptions.map(po => (po = { ...po, deletionDate: po.deletionDate ? po.deletionDate : '' }));

    return isArchive ? paymentOptions.filter(po => po.deletionDate) : paymentOptions.filter(po => !po.deletionDate);
  }

  deletePaymentOption(paymentOption) {
    return this.configurationDataApi.deletePaymentOption(paymentOption);
  }

  restorePaymentOption(paymentOption) {
    return this.configurationDataApi.restorePaymentOption(paymentOption);
  }
}

export default connect()(withRouter(Table));
