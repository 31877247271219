import React from 'react';
import * as TokenHelper from '../auth/token-helper';
import { getConfigSection } from '../common/utils';

const uiTexts = require('../resources/uiTexts.json');

export default class Logout extends React.PureComponent {
  constructor(props) {
    super(props);
    if (TokenHelper.getToken()) {
      TokenHelper.logout();
    }
  }

  render() {
    const logoutSuccessfulText = getConfigSection(uiTexts, 'logout.successful');
    return (
      <div>
        <span>{logoutSuccessfulText}</span>
      </div>
    );
  }
}
