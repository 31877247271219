export const isPaymentOptionEnabled = po =>
  po.isGuest.value ||
  po.isCitStorable.value ||
  po.isMitStorable.value ||
  po.isStoredCitPaymentsAllowed.value ||
  po.isStoredMitPaymentsAllowed.value;

export const getEnabledPaymentOptions = paymentOptions => {
  return paymentOptions.filter(isPaymentOptionEnabled);
};
