import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Accordion } from '../../Molecules/Accordion/Accordion'
import { List } from '../../Atoms/List/List'

export function FooterFull(props) {
  const componentFooterFullClass = classNames(
    {
      'c-footer  ': true,
    },
    props.className
  ).trim()

  function FooterNavElements() {
    return React.Children.map(props.children, child => {
      return (
        <Accordion footerAccordion defaultOpen={props.defaultOpen ? 0 : null}>
          <Accordion.Item title={child.props.title}>
            <List
              className="c-footer-nav__list js-footer-accordion__panel js-is-ready"
              listElemClassName="c-footer-nav__item"
            >
              {React.Children.map(child.props.children, item => {
                return (
                  <List.Item className="c-footer-nav__link">{item}</List.Item>
                )
              })}
            </List>
          </Accordion.Item>
        </Accordion>
      )
    })
  }

  function FooterNav() {
    if (React.Children.toArray(props.children).length === 0) {
      return null
    }
    return (
      <div className="c-footer__module  c-footer__module--nav">
        <div className="o-page-wrap">
          <nav className="c-footer-nav">
            <div className="c-footer-nav__columns">
              <FooterNavElements defaultOpen={props.defaultOpen}>
                {props.children}
              </FooterNavElements>
            </div>
          </nav>
        </div>
      </div>
    )
  }

  function Breadcrumbs() {
    if (!props.breadcrumbs) {
      return null
    }
    return (
      <div className="c-footer__module  c-footer__module--breadcrumb">
        <div className="o-page-wrap">
          <div className="c-footer-breadcrumb">
            <nav>{props.breadcrumbs}</nav>
          </div>
        </div>
      </div>
    )
  }

  function FooterMetaNav() {
    if (!props.metaNav) {
      return null
    }
    return (
      <div className="c-footer__module  c-footer__module--meta-nav">
        <div className="o-page-wrap">
          <nav className="c-footer-meta-bar">
            {props.metaNav}
            <span className="c-footer-copyright">{props.copyright}</span>
          </nav>
        </div>
      </div>
    )
  }

  function FooterFootnotes() {
    if (!props.footnotes) {
      return null
    }
    return (
      <div className="c-footer__module  c-footer__module--footnotes">
        <div className="o-page-wrap">
          <div className="c-footer-footnotes">{props.footnotes}</div>
        </div>
      </div>
    )
  }

  return (
    <footer className={componentFooterFullClass}>
      <Breadcrumbs breadcrumbs={props.breadcrumbs} />
      <FooterNav defaultOpen={props.defautlOpen} />
      {props.asteriskExplanation && (
        <div className="c-footer__module">
          <div className="o-page-wrap">
            <p>{props.asteriskExplanation}</p>
          </div>
        </div>
      )}
      <FooterMetaNav metaNav={props.metaNav} copyright={props.copyright} />
      <FooterFootnotes footnotes={props.footnotes} />
    </footer>
  )
}

FooterFull.Item = ({ children }) => {
  return children
}

FooterFull.propTypes = {
  defautlOpen: PropTypes.bool,
  className: PropTypes.string,
  copyright: PropTypes.string,
  breadcrumbs: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  asteriskExplanation: PropTypes.string,
  metaNav: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  footnotes: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.arrayOf(children => {
    if (
      React.Children.toArray(children).some(
        child => child.type !== FooterFull.Item
      )
    ) {
      return new Error(
        "'FooterFull' children should be of type 'FooterFull.Item'"
      )
    }
    return undefined
  }),
}
FooterFull.defaultProps = {
  className: '',
}

FooterFull.Item.displayName = 'FooterFull.Item'
FooterFull.Item.propTypes = {
  href: PropTypes.string,
  target: PropTypes.string,
}
