import React from 'react';
import { Spinner } from 'cj-common-components';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import commonPropTypes from '../../../common/common-prop-types';
import BaseEditForm from '../../common-components/BaseEditForm';
import UserAccessFormFields from './UserMerchantsAccessFormFields';
import { BlockNavigation } from '../../common-components/BlockNavigation';
import { reconcile } from '../../../common/utils';

export default class UserAccessEditForm extends React.PureComponent {
  static propTypes = {
    ...commonPropTypes.editForm,
  };

  constructor(props) {
    super(props);

    this.state = {
      merchants: [],
      isLoading: true,
      skipNavBlock: false,
    };

    const { authToken } = this.props;
    this.handleLoadUserAccessData = this.handleLoadUserAccessData.bind(this);
    this.handleAddUserAccess = this.handleAddUserAccess.bind(this);
    this.handleModifyUserAccess = this.handleModifyUserAccess.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);

    this.valuesRef = React.createRef();
  }

  componentDidMount() {
    this.handleLoadUserAccessData();
  }

  compareValues = merchant => {
    if (this.state.skipNavBlock) {
      return;
    }

    const fVals = this.valuesRef.current.values;
    if (
      reconcile(fVals.merchantId) !== reconcile(merchant.merchantId) ||
      reconcile(fVals.accessLevel) !== reconcile(merchant.accessLevel)
    ) {
      return [];
    }
  };

  render() {
    const overridenInitialValues = {
      merchantId: '',
    };

    const {
      authToken,
      onBack,
      match: {
        params: { merchantId },
      },
      isFormEditable,
      textsKey,
      match,
    } = this.props;

    const { isLoading, merchants } = this.state;
    const merchant = this.getMerchant(merchantId) || {};

    return isLoading ? (
      <Spinner center small={false} fullPage />
    ) : (
      <>
        <BlockNavigation compareValues={() => this.compareValues(merchant)} />
        <BaseEditForm
          valuesRef={this.valuesRef}
          data={merchant}
          authToken={authToken}
          textsKey={textsKey}
          onBack={() => onBack(match)}
          onSaveModifiedItem={this.handleModifyUserAccess}
          onSaveNewItem={this.handleAddUserAccess}
          overridenInitialValues={overridenInitialValues}
          fieldsComponent={{
            type: UserAccessFormFields,
          }}
          validationSchema={UserAccessFormFields.validationSchema}
          isFormEditable={isFormEditable}
          isUpdateOperation={!!merchantId}
          customProps={{ merchants, currentMerchant: merchant }}
        />
      </>
    );
  }

  handleAddUserAccess = async merchant => {
    this.setState({ ...this.state, skipNavBlock: true });

    const {
      match: {
        params: { userId },
      },
      onBack,
      match,
    } = this.props;

    const userAccess = await this.configurationDataApi.addUserMerchantsAccess(userId, merchant);
    onBack(match);
    return userAccess;
  };

  handleModifyUserAccess = async merchant => {
    this.setState({ ...this.state, skipNavBlock: true });

    const {
      match: {
        params: { userId },
      },
      onBack,
      match,
    } = this.props;

    const { merchantId, accessLevel } = merchant;
    const updateData = { accessLevel };
    await this.configurationDataApi.modifyUserMerchantsAccess(userId, merchantId, updateData);
    onBack(match);
    return merchant;
  };

  handleLoadUserAccessData = async () => {
    const {
      match: {
        params: { userId },
      },
    } = this.props;
    let merchants = { merchants: [] };

    merchants = await this.configurationDataApi.getUserMerchantsAccess(userId);

    this.setState({ merchants: merchants.merchants });

    this.setState({ isLoading: false });
    return merchants.merchants;
  };

  getMerchant(merchantId) {
    const { merchants } = this.state;
    return merchants.find(x => x.merchantId === merchantId);
  }
}
