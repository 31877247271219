import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export const Icon = React.forwardRef((props, ref) => {
  const iconClass = `c-icon--[${props.icon}]  `

  const iconSizeClass = `c-icon--${props.size}  `

  const componentIconClass = classNames(
    {
      'c-icon  ': true,
      [iconClass]: true,
      [iconSizeClass]: props.size,
    },
    props.className
  ).trim()

  return (
    <i data-testid={props.testId} ref={ref} className={componentIconClass} />
  )
})

Icon.propTypes = {
  testId: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.oneOf([
    'xxsmall',
    'xsmall',
    'small',
    'medium',
    'large',
    'xlarge',
    'xxlarge',
  ]),
}

Icon.defaultProps = {
  icon: 'home',
}
