import React from 'react';
import commonPropTypes from '../../../../common/common-prop-types';
import DisplayRuleExpressionsTable from './DisplayRuleExpressionsTable';
import { ROUTE_KEYS } from '../../../../common/constants';

class DisplayRuleExpressions extends React.PureComponent {
  static propTypes = {
    authToken: commonPropTypes.authToken,
  };

  constructor(props) {
    super(props);

    this.goBack = this.goBack.bind(this);
  }

  render() {
    const {
      authToken,
      isFormEditable,
      textsKey,
      paymentOptionsWithTags,
      getDisplayRuleExpressions,
      routeProps,
    } = this.props;
    const { channelId, merchantId } = routeProps.match.params;

    return (
      <>
        <DisplayRuleExpressionsTable
          authToken={authToken}
          textsKey={textsKey}
          merchantId={merchantId}
          isFormEditable={isFormEditable}
          paymentOptionsWithTags={paymentOptionsWithTags}
          getDisplayRuleExpressions={getDisplayRuleExpressions}
          channelId={channelId}
          onBack={this.goBack}
        />
      </>
    );
  }

  goBack() {
    const { routeProps, onBack } = this.props;
    const { channelId, merchantId } = routeProps.match.params;

    onBack(`/${ROUTE_KEYS.merchants}/${merchantId}/${ROUTE_KEYS.channels}/${channelId}`);
  }
}

export default DisplayRuleExpressions;
