import { Button, ButtonContainer } from 'cj-common-components';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { isEmpty } from '@turbopay/ts-helpers/object-utils';
import { getConfigSection } from '../../common/utils';
import uiTexts from '../../resources/uiTexts.json';
import { ROUTE_KEYS, AUTHENTICATION_TYPES } from '../../common/constants';

const buttonsTexts = getConfigSection(uiTexts, `merchants.form.buttons`);

const MerchantLinks = ({ merchantData, showMerchantLinks }) => {
  const history = useHistory();
  const location = useLocation();

  const goNextLocation = nextLocation => {
    history.push(`${location.pathname}/${nextLocation}`);
  };

  const { strongAuthentication, ...restMerchantData } = merchantData;
  const isDisabled = isEmpty(restMerchantData);

  return showMerchantLinks ? (
    <div>
      <ButtonContainer className="u-mb-xsmall">
        <Button
          secondary
          type="button"
          disabled={isDisabled}
          onClick={() => goNextLocation(ROUTE_KEYS.channels)}
          testId="channels-button"
        >
          {buttonsTexts.channels}
        </Button>
      </ButtonContainer>
      <ButtonContainer className="u-mb-xsmall">
        <Button
          secondary
          type="button"
          disabled={isDisabled}
          onClick={() => goNextLocation(ROUTE_KEYS.usersAndGroups)}
          testId="users-and-groups-button"
        >
          {buttonsTexts.usersAndGroups}
        </Button>
      </ButtonContainer>
      <ButtonContainer className="u-mb-xsmall">
        <Button
          secondary
          type="button"
          disabled={isDisabled}
          onClick={() => goNextLocation(ROUTE_KEYS.integration)}
          testId="integration-settings-button"
        >
          {buttonsTexts.integration}
        </Button>
      </ButtonContainer>
      <ButtonContainer className="u-mb-xsmall">
        <Button
          secondary
          type="button"
          disabled={isDisabled}
          onClick={() => {
            goNextLocation(ROUTE_KEYS.notificationSettings);
          }}
          testId="notifications-settings-button"
        >
          {buttonsTexts.notificationSettings}
        </Button>
      </ButtonContainer>
      <ButtonContainer className="u-mb-xsmall">
        <Button
          secondary
          type="button"
          disabled={isDisabled}
          onClick={() => goNextLocation(ROUTE_KEYS.culture)}
          testId="culture-settings-button"
        >
          {buttonsTexts.culture}
        </Button>
      </ButtonContainer>
      {process.env.REACT_APP_MERCHANT_AUTHENTICATION_METHOD === AUTHENTICATION_TYPES.OAUTH ? null : (
        <ButtonContainer className="u-mb-xsmall">
          <Button
            secondary
            type="button"
            disabled={isDisabled || !strongAuthentication}
            onClick={() => goNextLocation(ROUTE_KEYS.authenticationSettings)}
            testId="authentication-settings-button"
          >
            {buttonsTexts.authentication}
          </Button>
        </ButtonContainer>
      )}
      <ButtonContainer className="u-mb-xsmall">
        <Button
          secondary
          type="button"
          disabled={isDisabled}
          onClick={() => {
            goNextLocation(ROUTE_KEYS.corsDomains);
          }}
          testId="cors-domains-button"
        >
          {buttonsTexts.corsDomains}
        </Button>
      </ButtonContainer>
      <ButtonContainer className="u-mb-xsmall">
        <Button
          secondary
          type="button"
          disabled={isDisabled}
          onClick={() => {
            goNextLocation(ROUTE_KEYS.hostedPaymentPage);
          }}
          testId="hpp-button"
        >
          {buttonsTexts.hostedPaymentPage}
        </Button>
      </ButtonContainer>
      <ButtonContainer className="u-mb-xsmall">
        <Button
          secondary
          type="button"
          disabled={isDisabled}
          onClick={() => {
            goNextLocation(ROUTE_KEYS.networkTokenAndThreeDsManagement);
          }}
          testId="ntc-3ds-button"
        >
          {buttonsTexts.networkTokenAndThreeDsManagement}
        </Button>
      </ButtonContainer>
      <ButtonContainer className="u-mb-xsmall">
        <Button
          secondary
          type="button"
          disabled={isDisabled}
          onClick={() => {
            goNextLocation(ROUTE_KEYS.merchantHistory);
          }}
          testId="history-button"
        >
          {buttonsTexts.history}
        </Button>
      </ButtonContainer>
      <ButtonContainer className="u-mb-xsmall">
        <Button
          secondary
          type="button"
          disabled={isDisabled}
          onClick={() => {
            goNextLocation(ROUTE_KEYS.achSettings);
          }}
          testId="ach-settings-button"
        >
          {buttonsTexts.achSettings}
        </Button>
      </ButtonContainer>
      <ButtonContainer className="u-mb-xsmall">
        <Button
          secondary
          type="button"
          disabled={isDisabled}
          onClick={() => {
            goNextLocation(ROUTE_KEYS.widgetConfiguration);
          }}
          testId="widget-configuration-button"
        >
          {buttonsTexts.widgetConfiguration}
        </Button>
      </ButtonContainer>
    </div>
  ) : (
    <div></div>
  );
};

export default connect()(MerchantLinks);
