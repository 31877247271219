import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Paragraph } from '../../Atoms/Paragraph/Paragraph'

export const HeroTeaser = ({
  imageUrl,
  imageSrcset,
  imageSizes,
  title,
  subTitle,
  className,
  imageAlt,
  focusPart,
  auto,
  inverted,
  buttonText,
  buttonOnClick,
}) => {
  const componentClass = classNames(
    {
      'c-hero-teaser  ': true,
      [`c-hero-teaser--${focusPart}  `]: focusPart,
      'c-hero-teaser--auto  ': auto,
      'c-hero-teaser--inverted  ': inverted,
    },
    className
  ).trim()

  return (
    <div className={componentClass}>
      <img
        src={imageUrl}
        srcSet={imageSrcset}
        className="c-hero-teaser__image"
        sizes={imageSizes}
        alt={imageAlt}
      />
      <div className="c-hero-teaser__content">
        <div className="c-hero-teaser__content-inner">
          <header className="c-hero-teaser__heading">
            <div className="c-hero-teaser__title">
              <h1>
                <span className="c-hero-teaser__title-row">{title}</span>
              </h1>
            </div>
            {subTitle && (
              <div className="c-hero-teaser__subtitle">
                <Paragraph>{subTitle}</Paragraph>
              </div>
            )}
          </header>
          {buttonText && (
            <div className="c-hero-teaser__button">
              <button type="button" className="c-btn" onClick={buttonOnClick}>
                <span className="c-btn__text">{buttonText}</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

HeroTeaser.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  imageSrcset: PropTypes.string,
  imageSizes: PropTypes.string,
  title: PropTypes.node,
  subTitle: PropTypes.node,
  auto: PropTypes.bool,
  inverted: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonOnClick: PropTypes.func,
  focusPart: PropTypes.string,
}
