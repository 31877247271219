import { combineReducers, createStore } from '@reduxjs/toolkit';
import breadCrumbsSlice from './breadCrumbsSlice';
import dataSlice from './dataSlice';
import merchantFiltersSlice from './merchantFiltersSlice';

const createRootReducer = () =>
  combineReducers({
    breadCrumbs: breadCrumbsSlice,
    appData: dataSlice,
    merchantFilters: merchantFiltersSlice,
  });

export const configureStore = preloadedState => {
  const store = createStore(createRootReducer(), preloadedState);
  return store;
};
