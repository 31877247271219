import React from 'react';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import { WidgetConfigurationEditForm } from './EditForm';
import EnhancedForm from '../../common-components/EnhancedForm';
import { convertFileToBase64 } from '../../../common/utils';
import uiTexts from '../../../resources/uiTexts.json';
import { getConfigSection } from '../../../common/utils';
import { WIDGET_HEADER_IMAGE_POSITION } from '../../../common/constants';

class WidgetConfigurationForm extends React.PureComponent {
  constructor(props) {
    super(props);
    const { authToken } = this.props;
    this.modifyData = this.modifyData.bind(this);
    this.loadFooterImage = this.loadWidgetConfiguration.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);

    this.state = {
      footerImageURL: undefined,
      headerImageURL: undefined,
      headerImageData: {
        headerImagePosition: WIDGET_HEADER_IMAGE_POSITION[0].value,
      },
      successMessage: false,
    };
  }

  componentDidMount() {
    this.loadWidgetConfiguration();
  }

  loadWidgetConfiguration() {
    const { merchantId } = this.props;
    this.getMerchantWidgetConfiguration(merchantId);
  }

  render() {
    const { authToken, textsKey, isFormEditable, navigationFunction, onBack } = this.props;

    const { footerImageURL, headerImageURL, headerImageData } = this.state;
    return (
      <>
        {this.state.successMessage && this.renderSuccessMessage()}
        <EnhancedForm
          data={{ headerImageData }}
          authToken={authToken}
          textsKey={textsKey}
          editComponent={{
            type: WidgetConfigurationEditForm,
          }}
          dataFunctions={{
            modifyItem: this.modifyData,
          }}
          isCreationFlow={false}
          onBack={onBack}
          isVisible
          isFormEditable={isFormEditable}
          navigationFunction={navigationFunction}
          customProps={{
            footerImageURL,
            headerImageURL,
          }}
        />
      </>
    );
  }

  async getMerchantWidgetConfiguration(merchantId) {
    const {
      footerImageURL,
      headerImageURL,
      headerImagePosition,
    } = await this.configurationDataApi.getMerchantWidgetConfiguration(merchantId);

    this.setState({
      footerImageURL,
      headerImageURL,
      headerImageData: {
        headerImagePosition: headerImagePosition || WIDGET_HEADER_IMAGE_POSITION[0].value,
      },
    });
  }

  async modifyData(data) {
    const { merchantId } = this.props;
    const { headerImageURL } = this.state;
    const { footerImage, headerImageData } = data;
    const { headerImagePosition, headerImage } = headerImageData;

    const footerImageFile = footerImage ? await convertFileToBase64(footerImage) : undefined;
    const headerImageFile = headerImage ? await convertFileToBase64(headerImage) : undefined;

    const response = await this.configurationDataApi.updateMerchantWidgetConfiguration(merchantId, {
      footerImage: footerImageFile,
      headerImage: headerImageFile,
      headerImagePosition: (headerImage || headerImageURL) && headerImagePosition,
    });

    this.setState({
      ...this.state,
      successMessage: true,
      headerImageURL: response.data.headerImageURL,
      footerImageURL: response.data.footerImageURL,
      headerImageData: {
        headerImagePosition: response.data.headerImagePosition || WIDGET_HEADER_IMAGE_POSITION[0].value,
      },
    });

    // returns an empty object to satisfy
    // EnhancedForm.getItemsWithSameKey,
    // which looks for internal state
    // (obtained as the result of modifyData)
    return {};
  }

  renderSuccessMessage() {
    const text = getConfigSection(uiTexts, 'common.successMessage');
    return (
      <p className="merchant-changes-success-message" data-testid="success-message">
        {text}
      </p>
    );
  }
}

export default WidgetConfigurationForm;
