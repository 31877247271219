import React from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends React.PureComponent {
  componentDidMount() {
    const unlisten = this.props.history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }

  render() {
    return <></>;
  }
}

export default withRouter(ScrollToTop);
