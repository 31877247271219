import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function MultiSelect(props) {
  const {
    values,
    initialSelectedValues,
    openMenu,
    isError,
    id,
    name,
    className,
    placeholder,
    onChange,
    selectAllLabel,
    clearLabel,
    showClearBtn,
    showSelectAllBtn,
    isDisabled,
    testIdPrefix,
  } = props;
  const [isOpen, setIsOpen] = React.useState(openMenu);
  const [focusedValue, setFocusedValue] = React.useState(0);
  const [selectedValues, setSelectedValues] = React.useState(initialSelectedValues);
  const [filteredValues, setFilteredValues] = React.useState(values);
  const [inputValue, setInputValue] = React.useState('');
  const [isInitialized, setInitialized] = React.useState(false);
  let isIgnoreBlur = false;
  let textInput;

  React.useEffect(() => {
    onChange(selectedValues);
    // TODO: check the reason of the infinite loop here if we
    // keep the autocorrection proposed by lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues]);

  // Fill option list and initial values once when values are loaded
  React.useEffect(() => {
    if (!isInitialized && Object.keys(values).length) {
      setFilteredValues(values.filter(item => !initialSelectedValues.find(el => el === item.value)));
      setSelectedValues(values.filter(el => initialSelectedValues.includes(el.value)));
      setInitialized(true);
    }
    // TODO: check the reason of the infinite loop here if we
    // keep the autocorrection proposed by lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const componentMultiSelectWrapperClass = classNames(
    {
      'c-custom-select  ': true,
      'is-open': isOpen,
    },
    className,
  );

  const componentInputSelectClass = classNames({
    'c-input__input  ': true,
    'is-error  ': isError,
  });

  // ignoreBlur is used to ignore blur event on click on option dropdown list
  const setIgnoreBlur = () => {
    isIgnoreBlur = true;
  };

  const clearIgnoreBlur = () => {
    isIgnoreBlur = false;
  };

  /* EventHandler */
  const onBlur = e => {
    if (!isIgnoreBlur) {
      e.preventDefault();
      setIsOpen(false);
    }
  };

  const onKeyDown = e => {
    switch (e.key) {
      case 'Escape':
      case 'Tab':
        setIsOpen(false);
        break;
      case 'Enter':
        e.preventDefault();
        if (
          Object.keys(filteredValues).length &&
          focusedValue >= 0 &&
          focusedValue < Object.keys(filteredValues).length
        ) {
          const currentSelection = filteredValues[focusedValue];
          if (currentSelection) {
            setFilteredValues(
              values.filter(
                item => !selectedValues.find(el => el.value === item.value) && item.value !== currentSelection.value,
              ),
            );
            setSelectedValues(selectedValues.concat(currentSelection));
            setInputValue('');
            setFocusedValue(focusedValue - 1);
          }
        }
        break;
      case 'ArrowDown':
        e.preventDefault();
        if (focusedValue < filteredValues.length - 1) {
          setFocusedValue(focusedValue + 1);
        }
        break;
      case 'ArrowUp':
        if (focusedValue > 0) {
          setFocusedValue(focusedValue - 1);
        }
        break;
      default:
    }
  };

  const onInputChange = e => {
    setInputValue(e.target.value);
    const filteredList =
      e.target.value.length <= 0
        ? values.filter(item => !selectedValues.find(el => el.value === item.value))
        : filteredValues.filter(
            item => item.label && item.label.toLowerCase().startsWith(e.target.value.toLowerCase()),
          );
    setFilteredValues(filteredList);
    setFocusedValue(Object.keys(filteredList).length ? 0 : -1);
  };

  const onClickOption = e => {
    const value = e.currentTarget.getAttribute('value');
    const selection = values.filter(item => item.value === value);
    if (Array.isArray(selection) && selection.length === 1) {
      setSelectedValues(selectedValues.concat(selection));
      setFilteredValues(
        values.filter(item => !selectedValues.find(el => el.value === item.value) && item !== selection[0]),
      );
      setInputValue('');
    }
    textInput.focus();
  };

  const onDeleteOption = e => {
    const value = e.currentTarget.getAttribute('value');
    const selection = values.filter(item => item.value === value);
    setSelectedValues(selectedValues.filter(item => item.value !== value));
    setFilteredValues(filteredValues.concat(selection));
    setFocusedValue(-1);
  };

  const onHoverOption = e => {
    const value = e.currentTarget.getAttribute('value');
    const selection = values.filter(item => item.value === value);
    setFocusedValue(filteredValues.findIndex(el => el.value === selection[0].value));
  };

  const onSelectClick = () => {
    setIsOpen(true);
    textInput.focus();
  };

  const arrowClick = () => {
    setIsOpen(!isOpen);
  };

  const selectAll = () => {
    setSelectedValues(values);
    setFilteredValues([]);
  };

  const clear = () => {
    setSelectedValues([]);
    setFilteredValues(values);
  };

  /* Render Functions */
  const renderOptions = () => {
    if (!isOpen) {
      return null;
    }
    return (
      <ul
        className="c-custom-select__dropdown"
        onMouseDown={setIgnoreBlur}
        onMouseUp={clearIgnoreBlur}
        role="presentation"
      >
        {filteredValues.map(renderOption)}
      </ul>
    );
  };

  const renderOption = (item, index) => {
    const cssClasses = classNames({
      'c-custom-select__item  ': true,
      'is-active  ': index === focusedValue,
    });
    const testId = testIdPrefix ? `${testIdPrefix}-item` : undefined;

    return (
      <li
        className={cssClasses}
        key={`${index}${item.value}`}
        value={item.value || ''}
        onClick={onClickOption}
        onMouseOver={onHoverOption}
        onFocus={onHoverOption}
        role="presentation"
        data-testid={testId}
      >
        {item.label}
      </li>
    );
  };

  const renderTags = () => {
    return <div className="u-text-left  ">{selectedValues.map(renderTag)}</div>;
  };

  const renderTag = (item, index) => {
    const testId = testIdPrefix ? `${testIdPrefix}-selected-item` : undefined;

    return (
      <span className="c-tag  u-mr-xxsmall  " key={`${index}${item.value}`} data-testid={testId}>
        <span className="c-tag__label">{item.label}</span>
        {!isDisabled && (
          <button
            className="c-tag__close"
            title="Close"
            type="button"
            value={item.value || ''}
            onClick={onDeleteOption}
            data-testid="delete-button"
          />
        )}
      </span>
    );
  };

  const renderClearBtn = () => {
    return (
      showClearBtn && (
        <button className="c-input__addon" type="button" onClick={clear} data-testid="clear-button">
          <span className="c-btn__text">{clearLabel}</span>
        </button>
      )
    );
  };

  const renderSelectAllBtn = () => {
    return (
      showSelectAllBtn && (
        <button className="c-input__addon" type="button" onClick={selectAll} data-testid="select-all-button">
          <span className="c-btn__text">{selectAllLabel}</span>
        </button>
      )
    );
  };

  const renderArrowDropdown = () => {
    return (
      <button
        className="c-input__addon  c-input__addon--no-background"
        type="button"
        onMouseDown={() => {
          isIgnoreBlur = true;
        }}
        onClick={arrowClick}
        data-testid="open-select-button"
      >
        <i className="c-icon  c-icon--[semantic-expand]"></i>
      </button>
    );
  };

  const inputTestId = testIdPrefix ? `${testIdPrefix}-input` : undefined;

  return (
    <div>
      {renderTags()}
      <div>
        {/* Lint rule disabled since there is no role matching
          the purpose of this <div> */}
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div className={componentMultiSelectWrapperClass} onBlur={onBlur} onKeyDown={onKeyDown}>
          <div className="c-input">
            <input
              className={componentInputSelectClass}
              onClick={onSelectClick}
              type="text"
              id={id}
              name={name}
              placeholder={placeholder}
              onChange={onInputChange}
              value={inputValue}
              ref={input => {
                textInput = input;
              }}
              disabled={isDisabled}
              data-testid={inputTestId}
            />
            {renderArrowDropdown()}
            {renderSelectAllBtn()}
            {renderClearBtn()}
          </div>
          {renderOptions()}
        </div>
      </div>
    </div>
  );
}

MultiSelect.propTypes = {
  openMenu: PropTypes.bool,
  isError: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  initialSelectedValues: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
      PropTypes.string,
    ]),
  ),
  onChange: PropTypes.func,
  selectAllLabel: PropTypes.string,
  clearLabel: PropTypes.string,
  showClearBtn: PropTypes.bool,
  showSelectAllBtn: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

MultiSelect.defaultProps = {
  placeholder: 'Select',
  initialSelectedValues: [],
  selectAllLabel: 'Select All',
  clearLabel: 'Clear',
  showClearBtn: true,
  showSelectAllBtn: true,
  isDisabled: false,
};
