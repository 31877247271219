import React from 'react';
import PropTypes from 'prop-types';
import BaseFormFields from './BaseFormFields';

const EditTableCell = ({
  tableData,
  isEdited,
  rowValue,
  onChange,
  onBlur,
  filterItems,
  setPriority,
  isCheckboxEnabled,
  effector,
  columnTextKey,
  selectCells,
  readOnlyCells,
  checkBoxCells,
  canAddNewItem,
  isFormEditable,
  testIdPrefix,
}) => {
  return (
    <>
      {canAddNewItem && isEdited
        ? selectTypeOfInput(
            tableData,
            rowValue,
            onChange,
            onBlur,
            columnTextKey,
            selectCells,
            isFormEditable,
            readOnlyCells,
            checkBoxCells,
            filterItems,
            setPriority,
            isCheckboxEnabled,
            effector,
            testIdPrefix,
          )
        : rowValue}
    </>
  );
};

const selectTypeOfInput = (
  tableData,
  value,
  onChange,
  onBlur,
  columnTextKey,
  selectCells,
  isFormEditable,
  readOnlyCells,
  checkBoxCells,
  filterItems,
  setPriority,
  isCheckboxEnabled,
  effector,
  testIdPrefix,
) => {
  const { renderGenericSelect, renderGenericInput, renderGenericCheckbox } = BaseFormFields;
  const cellKeySelect = selectCells && Object.keys(selectCells).find(cell => cell === columnTextKey);
  const cellKeyCheckbox = checkBoxCells && checkBoxCells.find(cell => cell === columnTextKey);
  const readOnlyCell = readOnlyCells && readOnlyCells.find(cell => cell.name === columnTextKey);
  const isCellEditable = readOnlyCell ? false : true;

  if (cellKeySelect) {
    const filteredItems = filterItems ? filterItems(cellKeySelect, selectCells, effector) : selectCells[cellKeySelect];

    return renderGenericSelect(
      isFormEditable,
      filteredItems || selectCells[cellKeySelect],
    )({
      name: columnTextKey,
      value: value,
      onChange: onChange,
      onBlur: onBlur,
      testIdPrefix: testIdPrefix,
    });
  }

  if (cellKeyCheckbox) {
    return renderGenericCheckbox(
      isCheckboxEnabled,
      undefined,
    )({
      name: columnTextKey,
      value: isCheckboxEnabled ? value : false,
      onChange: onChange,
      onBlur: onBlur,
      testIdPrefix: testIdPrefix,
    });
  }

  let inputValue;

  if (isCellEditable) {
    inputValue = value;
  } else if (setPriority) {
    inputValue = setPriority(tableData);
  } else {
    inputValue = readOnlyCell.value;
  }

  return renderGenericInput(
    'text',
    isCellEditable,
  )({
    name: columnTextKey,
    value: inputValue,
    onChange: onChange,
    onBlur: onBlur,
    testIdPrefix: testIdPrefix,
  });
};

EditTableCell.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.any),
  isEdited: PropTypes.bool,
  rowValue: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  canAddNewItem: PropTypes.bool.isRequired,
  isFormEditable: PropTypes.bool.isRequired,
  filterItems: PropTypes.func,
  setPriority: PropTypes.func,
  isCheckboxEnabled: PropTypes.bool,
  effector: PropTypes.string,
  columnTextKey: PropTypes.string,
  selectCells: PropTypes.arrayOf(PropTypes.any),
  checkBoxCells: PropTypes.arrayOf(PropTypes.string),
  readOnlyCells: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  testIdPrefix: PropTypes.string,
};

export default EditTableCell;
