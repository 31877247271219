import React from 'react';
import { connect } from 'react-redux';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import CommonTable from '../../common-components/CommonTable';
import { getConfigSection } from '../../../common/utils';
import commonPropTypes from '../../../common/common-prop-types';
import { formatDate } from '../../../common/date-time-utils';
import AuthenticationKeyForm from './AuthenticationKeyForm';
import { SORT_COLUMNS } from '../../../common/constants';
import PropTypes from 'prop-types';

const uiTexts = require('../../../resources/uiTexts.json');

class AuthenticationKeysView extends React.Component {
  static propTypes = {
    ...commonPropTypes.table,
    authenticationType: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    const { authToken } = this.props;
    this.loadAuthenticationKeys = this.loadAuthenticationKeysData.bind(this);
    this.addNewKey = this.addNewKey.bind(this);
    this.deleteAuthenticationKey = this.deleteAuthenticationKey.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);

    this.state = {
      successMessage: false,
      rerenderAuthKeysTableKey: '',
      authKeys: [],
    };
  }

  render() {
    const { authToken, textsKey, isFormEditable, merchantData, merchantId, authenticationType } = this.props;

    const { authKeys, rerenderAuthKeysTableKey } = this.state;

    const headerLevelsSeparator = getConfigSection(uiTexts, 'common.headerLevelsSeparator');

    const parentHeader = getConfigSection(uiTexts, `${textsKey}.table.header`);
    const authenticationKeysParentHeader = `${parentHeader} ${headerLevelsSeparator} ${merchantData.name}`;
    const textKeyAuthenticationKeys = `${textsKey}.authenticationSettings.authenticationKeys`;

    return (
      <>
        {this.state.successMessage && this.renderSuccessMessage()}
        <CommonTable
          key={rerenderAuthKeysTableKey}
          authToken={authToken}
          textsKey={textKeyAuthenticationKeys}
          parentLevelHeader={authenticationKeysParentHeader}
          dataFunctions={{
            loadData: this.loadAuthenticationKeys,
            deleteItem: this.deleteAuthenticationKey,
          }}
          defaultSortColumn={SORT_COLUMNS.CREATION_DATE}
          canAddNewItem={false}
          canClickOnItem={false}
          canDeleteItem={isFormEditable}
          isFormEditable={isFormEditable}
          isRowEditable={false}
          canFilterItems={false}
          testIdPrefix="authentication-keys-table"
        />
        <AuthenticationKeyForm
          valuesRef={this.props.valuesRef}
          authKeys={authKeys}
          authToken={authToken}
          isFormEditable={isFormEditable}
          merchantId={merchantId}
          onAddNewKey={this.addNewKey}
          authenticationType={authenticationType}
        />
      </>
    );
  }

  addNewKey() {
    this.setState({ ...this.state, rerenderAuthKeysTableKey: Date.now(), successMessage: true });
  }

  async loadAuthenticationKeysData() {
    const { merchantId } = this.props;
    const authKeys = await this.configurationDataApi.getAuthenticationKeys(merchantId);

    const preparedAuthKeys = authKeys.map(authKey =>
      Object.assign({}, authKey, {
        creationDate: formatDate(authKey.creationDate),
      }),
    );

    this.setState({ ...this.state, authKeys: preparedAuthKeys });

    return preparedAuthKeys;
  }

  async deleteAuthenticationKey(key) {
    const { merchantId } = this.props;
    this.setState({
      ...this.state,
      successMessage: false,
    });

    const response = await this.configurationDataApi.deleteAuthenticationKey(merchantId, key.id);
    this.setState({
      ...this.state,
      successMessage: true,
    });

    return response;
  }

  renderSuccessMessage() {
    const text = getConfigSection(uiTexts, 'common.successMessage');
    return (
      <p className="merchant-changes-success-message" data-testid="success-message">
        {text}
      </p>
    );
  }
}

export default connect()(AuthenticationKeysView);
