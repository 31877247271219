import React from 'react';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import { getConfigSection, textsArrayToString } from '../../../common/utils';
import { ModalError, ModalSuccess } from '../../common-components/ModalWindow';

const uiTexts = require('../../../resources/uiTexts.json');

export default class Form extends React.Component {
  constructor(props) {
    super(props);
    const { authToken } = this.props;

    this.state = {
      isUploadEnd: true,
      isUploadSuccessful: true,
      showInfo: false,
    };

    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
  }

  render() {
    const texts = this.props.uiTexts || uiTexts;
    const { textsKey } = this.props;
    this.textsKeyIntegration = textsKey;
    const header = getConfigSection(texts, `${this.textsKeyIntegration}.form.header`, '');
    const { isUploadEnd, isUploadSuccessful, showInfo } = this.state;
    const testIdPrefix = 'culture-settings-form';

    return (
      <div>
        {!isUploadSuccessful && <>{this.renderErrorDialog()}</>}
        {showInfo && isUploadEnd && isUploadSuccessful && <>{this.renderSuccessDialog()}</>}
        <h4 className="u-mb" data-testid={`${testIdPrefix}-header`}>
          {header}
        </h4>
        <div className="c-form-section-group u-mb" data-testid={`${testIdPrefix}-container`}>
          <div className="c-form-section  js-form-section  js-is-fx  c-form-section--success">
            <div className="c-form-section__panel  js-form-section__panel">
              <div className="c-form-section__content">
                <div>
                  <div className="">
                    <div className="c-form-field">
                      {/* eslint-disable-next-line max-len */}
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label className="c-form-field__label" data-testid={`${testIdPrefix}-options-label`}>
                        {this.getText('group')}
                      </label>
                      <div className="c-form-field__box"> </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="c-form-field">
                      <label className="c-form-field__label" data-testid={`${testIdPrefix}-download-label`}>
                        {' '}
                        {this.getText('download')}
                        <ul className="c-icon-list">
                          <li>
                            <i
                              className="c-icon-list__icon   c-icon   c-icon--[control-info-circle]"
                              aria-hidden="true"
                            >
                              {' '}
                            </i>
                            <p data-testid={`${testIdPrefix}-download-info-text`}>
                              <small>{this.getText('downloadInfo')}</small>
                            </p>
                          </li>
                        </ul>
                      </label>
                      <div className="c-form-field__box">
                        <button
                          className="c-btn   c-btn--secondary"
                          type="button"
                          aria-expanded="false"
                          onClick={this.downloadHandler.bind(this)}
                          data-testid="download-button"
                        >
                          <span className="c-btn__text">{this.getText('downloadButton')}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="c-form-field">
                      <label className="c-form-field__label" data-testid={`${testIdPrefix}-upload-label`}>
                        {' '}
                        {this.getText('upload')}
                        <ul className="c-icon-list">
                          <li>
                            <i
                              className="c-icon-list__icon   c-icon   c-icon--[control-info-circle]"
                              aria-hidden="true"
                            >
                              {' '}
                            </i>
                            <p data-testid={`${testIdPrefix}-upload-text-info`}>
                              <small>{this.getText('uploadInfo')}</small>
                            </p>
                          </li>
                        </ul>
                      </label>
                      <div className="c-form-field__box">
                        <input
                          name="options.upload"
                          onChange={this.uploadHandler.bind(this)}
                          type="file"
                          value=""
                          data-testid={`${testIdPrefix}-upload-button`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderErrorDialog() {
    const { errorMessage } = this.state;
    return (
      <ModalError
        title={errorMessage}
        onConfirm={() => {
          this.setState({
            isUploadSuccessful: true,
            isUploadEnd: true,
          });
        }}
      />
    );
  }

  renderSuccessDialog() {
    const { resultMessage } = this.state;
    return (
      <ModalSuccess
        title={resultMessage}
        onConfirm={() => {
          this.setState({
            isUploadSuccessful: true,
            isUploadEnd: true,
            showInfo: false,
          });
        }}
      />
    );
  }

  downloadHandler() {
    this.configurationDataApi.downloadDefaultCulture();
  }

  uploadHandler(event) {
    const fileList = event.target.files;
    const formData = new FormData();
    formData.append('file', fileList[0]);
    this.submitFile(formData);
  }

  submitFile(data) {
    this.isUploadEnd = false;
    this.isUploadSuccessful = false;
    this.configurationDataApi
      .uploadDefaultCulture(data)
      .then(res => {
        this.setState({
          isUploadSuccessful: true,
          showInfo: true,
          resultMessage: res.data.message,
        });
      })
      .catch(err => {
        this.setState({
          isUploadSuccessful: false,
          errorMessage: textsArrayToString(err.response?.data?.message),
        });
      })
      .finally(() => {
        this.setState({
          isUploadEnd: true,
        });
      });
  }

  getText(key) {
    return getConfigSection(uiTexts, `${this.textsKeyIntegration}.fields.${key}`);
  }
}
