import React from 'react';
import * as Yup from 'yup';
import SimpleFormGroup from '../../../common-components/SimpleFormGroup';
import PropTypes from 'prop-types';
import { Label, Input, Button, Column, Layout, Select } from 'cj-common-components';
import { getConfigSection } from '../../../../common/utils';
import { Formik, Form } from 'formik';
import { TARGET_MERCHANT_ACCOUNTS_SEARCH_FILTERS } from './constants/searchFilters';

const uiTexts = require('../../../../resources/uiTexts.json');

class TargetMerchantAccountsFilter extends React.Component {
  static propTypes = {
    filterTargetMerchantAccounts: PropTypes.func,
    textsKey: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.handleSubmitForm = this.handleSubmitFormBind.bind(this);
  }

  render() {
    const initialValues = {
      filterName: 'targetMerchantAccountReference',
      targetMerchantAccountReference: '',
    };

    const filterValidation = Yup.object().shape({
      targetMerchantAccountReference: Yup.string(),
    });

    const testIdPrefix = 'target-merchant-accounts-search';

    return (
      <div className="u-mt-large" data-testid={`${testIdPrefix}-container`}>
        <SimpleFormGroup keyName="target-merchant-accounts-filter">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={this.handleSubmitForm}
            validationSchema={filterValidation}
          >
            {/* {({ values, handleChange, handleSubmit, touched, errors }) => (
              <Form onSubmit={handleSubmit}>
                <Layout className="u-mb">
                  <Column span="10/12">
                    <Label>{getConfigSection(uiTexts, `${this.props.textsKey}.filter.searchBy`)}</Label>
                    <Input
                      id="targetMerchantAccountReference"
                      name="targetMerchantAccountReference"
                      type="text"
                      value={values.targetMerchantAccountReference}
                      onChange={handleChange}
                    />
                    {touched.targetMerchantAccountReference && errors.targetMerchantAccountReference ? (
                      <p className="c-error-message">{errors.targetMerchantAccountReference}</p>
                    ) : null}
                  </Column>
                  <Column span="2/12">
                    <Button className="mt-35" secondary type="submit">
                      {getConfigSection(uiTexts, `${this.props.textsKey}.filter.searchBtnText`)}
                    </Button>
                  </Column>
                </Layout>
              </Form>
            )} */}
            {({ values, handleChange, handleSubmit, touched, errors }) => (
              <Form onSubmit={handleSubmit}>
                <Layout className="u-mb">
                  <Column span="5/12">
                    <Label testId={`${testIdPrefix}-filterName-label`}>
                      {getConfigSection(uiTexts, `${this.props.textsKey}.filter.searchBy`)}
                    </Label>
                    <Select
                      name="filterName"
                      value={values.filterName}
                      values={TARGET_MERCHANT_ACCOUNTS_SEARCH_FILTERS}
                      readOnly={true}
                      onChange={handleChange}
                      testId={`${testIdPrefix}-filterName-select`}
                    ></Select>
                  </Column>
                  <Column span="5/12">
                    <Label testId={`${testIdPrefix}-targetMerchantAccountReference-label`}>
                      {getConfigSection(uiTexts, `${this.props.textsKey}.filter.targetMerchantAccountReference`)}
                    </Label>
                    <Input
                      id="targetMerchantAccountReference"
                      name="targetMerchantAccountReference"
                      type="text"
                      value={values.targetMerchantAccountReference}
                      onChange={handleChange}
                      testId={`${testIdPrefix}-targetMerchantAccountReference-input`}
                    />
                    {touched.targetMerchantAccountReference && errors.targetMerchantAccountReference ? (
                      <p
                        className="c-error-message"
                        data-testid={`${testIdPrefix}-targetMerchantAccountReference-error-message`}
                      >
                        {errors.targetMerchantAccountReference}
                      </p>
                    ) : null}
                  </Column>
                  <Column span="2/12">
                    <Button className="mt-35" secondary type="submit" testId="search-button">
                      {getConfigSection(uiTexts, 'merchants.notifications.filter.searchBtnText')}
                    </Button>
                  </Column>
                </Layout>
              </Form>
            )}
          </Formik>
        </SimpleFormGroup>
      </div>
    );
  }

  handleSubmitFormBind(values) {
    const { filterName } = values;

    if (filterName) {
      this.props.filterTargetMerchantAccounts({ filterName, filterValue: values[filterName] });
    } else {
      this.props.filterTargetMerchantAccounts();
    }
  }
}

export default TargetMerchantAccountsFilter;
