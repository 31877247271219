import React, { useState } from 'react';
import { Modal } from 'cj-common-components';
import BaseFormFields from '../common-components/BaseFormFields';
import { getConfigSection } from '../../common/utils';

const uiTexts = require('../../resources/uiTexts.json');
const errorTexts = require('../../resources/errorTexts.json');

export function ModalCopyMerchant(props) {
  const { onConfirm, onCancel, status, hasTargetMerchantAccounts } = props;

  const warningTexts = getConfigSection(uiTexts, 'common.table.dialogs.warning');
  const { header, buttonOk, buttonCancel } = warningTexts;
  const message = getConfigSection(uiTexts, `merchants.form.dialogs.warningText`);
  const hasTargetMerchantAccountsMessage = getConfigSection(uiTexts, `merchants.form.dialogs.warningTextHasTMAs`);

  const [name, setName] = useState('');
  const [nameErr, setNameErr] = useState('');

  const handleNameChange = e => {
    const val = e.target.value;
    setName(val);
    if (val.length) {
      setNameErr('');
    }
  };

  const handleConfirmCopy = () => {
    if (!name.length) {
      setNameErr(errorTexts.validation.mandatoryField);
      return;
    }

    if (name.length > 200) {
      setNameErr(errorTexts.validation.maxLength.replace('$MAX$', 200));
      return;
    }

    onConfirm(name);
  };

  return (
    <Modal
      title={header}
      shown
      buttonConfirmText={buttonOk}
      onConfirm={handleConfirmCopy}
      buttonCancelText={buttonCancel}
      onCancel={onCancel}
      status={status}
      hideCloseButton
      center
      closeAny={false}
    >
      <div className="copy-message">{message}</div>

      {BaseFormFields.renderGenericInput(
        'text',
        true,
      )({
        value: name,
        onChange: handleNameChange,
      })}
      {nameErr && <p className="copy-merchant c-error-message">{nameErr}</p>}

      {hasTargetMerchantAccounts && (
        <div className="copy-target-merchant-accounts-message">{hasTargetMerchantAccountsMessage}</div>
      )}
    </Modal>
  );
}
