import React from 'react';
import ConfigurationDataApi from '../../../../api/ConfigurationData';
import commonPropTypes from '../../../../common/common-prop-types';
import uiTexts from '../../../../resources/uiTexts.json';
import { getConfigSection } from '../../../../common/utils';
import CommonTable from '../../../common-components/CommonTable';
import { DISPLAY_RULE_EXPRESSION_ACTIONS, DISPLAY_RULE_EXPRESSION_OPERATORS } from '../../../../common/constants';
import { ModalError } from '../../../common-components/ModalWindow';
import TooltipIconButton from '../../../common-components/TooltipIconButton';
import { BlockNavigation } from '../../../common-components/BlockNavigation';

const config = require('../../../../resources/config.json');

const icons = getConfigSection(config, 'ui.common.table.icons');

export default class Table extends React.Component {
  static propTypes = {
    ...commonPropTypes.Table,
  };

  constructor(props) {
    super(props);
    const { authToken } = this.props;

    this.loadDisplayRuleExpressions = this.loadDisplayRuleExpressions.bind(this);
    this.addNewitem = this.addNewitem.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.filterItems = this.filterItems.bind(this);
    this.isCheckboxEnabled = this.isCheckboxEnabled.bind(this);
    this.isAddNewItemButtonDisabled = this.isAddNewItemButtonDisabled.bind(this);
    this.setPriority = this.setPriority.bind(this);
    this.modifyPriority = this.modifyPriority.bind(this);
    this.isCustomTagDeleted = this.isCustomTagDeleted.bind(this);
    this.renderDeletedCustomTagWarnIcon = this.renderDeletedCustomTagWarnIcon.bind(this);

    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);

    this.state = {
      channel: {},
      paymentOptions: [],
      successMessage: false,
      error: false,
      errorMessage: '',
    };

    this.valuesRef = React.createRef();
  }

  sendDisplayRuleExpressions = () => {
    this.props.getDisplayRuleExpressions(this.state.channel.configuration.displayRuleExpressions);
  };

  compareValues = () => {
    const editedRow = this.valuesRef.current.values.find(val => val.isEdited);
    if (editedRow) {
      return [];
    }
  };

  render() {
    const { navigationFunction, textsKey, onBack, paymentOptionsWithTags, isFormEditable } = this.props;
    const thisTextKey = `${textsKey}`;
    const checkBoxCells = ['label'];
    const paymentOptions = paymentOptionsWithTags.map(po => {
      return {
        value: po.paymentOptionCode,
        label: po.displayName,
      };
    });
    const customTags = paymentOptionsWithTags.flatMap(po =>
      po.customTags.map(tag => {
        return {
          value: tag,
          label: tag,
          paymentOptionCode: po.paymentOptionCode,
        };
      }),
    );
    const selectCells = {
      customTag: customTags,
      operator: Object.entries(DISPLAY_RULE_EXPRESSION_OPERATORS).map(([key, value]) => ({
        value: key,
        label: value,
      })),
      action: Object.entries(DISPLAY_RULE_EXPRESSION_ACTIONS).map(([key, value]) => ({
        value: key,
        label: value,
      })),
      paymentOption: paymentOptions,
    };

    return (
      <>
        {this.state.successMessage && this.renderSuccessMessage()}
        {this.state.error && this.renderErrorDialog()}
        <BlockNavigation compareValues={this.compareValues} />
        <CommonTable
          valuesRef={this.valuesRef}
          textsKey={thisTextKey}
          dataFunctions={{
            loadData: this.loadDisplayRuleExpressions,
            addItem: this.addNewitem,
            deleteItem: this.deleteItem,
            filterItems: this.filterItems,
            isCheckboxEnabled: this.isCheckboxEnabled,
            setPriority: this.setPriority,
            renderEmptyColumnContent: this.renderDeletedCustomTagWarnIcon,
            increaseItem: this.modifyPriority(-1),
            decreaseItem: this.modifyPriority(1),
          }}
          canClickOnItem={false}
          canAddInlineItem={true}
          canAddNewItem={true}
          isAddNewItemButtonDisabled={this.isAddNewItemButtonDisabled()}
          onBack={onBack}
          onSave={this.handleOnSave}
          isFormEditable={isFormEditable}
          navigationFunction={navigationFunction}
          hasBackButton={true}
          hasSaveButton={true}
          canFilterItems={false}
          canSortItems={false}
          selectCells={selectCells}
          readOnlyCells={this.state.readOnlyCells}
          checkBoxCells={checkBoxCells}
          effectorColumn="paymentOption"
          affectedColumn="customTag"
          defaultSortColumn="priority"
          defaultSortOrderAsc={true}
          testIdPrefix="display-rule-expressions-table"
        />
      </>
    );
  }

  async loadDisplayRuleExpressions() {
    const { merchantId, channelId } = this.props;
    const channel = await this.configurationDataApi.getChannelById(merchantId, channelId);
    const upcfPaymentOptions = await this.configurationDataApi.getUpcfPaymentOptions();

    this.setState({
      ...this.state,
      channel,
      paymentOptions: upcfPaymentOptions,
      readOnlyCells: [
        {
          name: 'priority',
          value: '',
        },
      ],
    });

    return this.mapDisplayRuleExpressions(channel.configuration.displayRuleExpressions, upcfPaymentOptions);
  }

  async deleteItem(item) {
    const { merchantId } = this.props;
    const channel = this.state.channel;
    const displayRuleExpressions = channel.configuration.displayRuleExpressions;

    const expressions = item.isEdited
      ? displayRuleExpressions
      : displayRuleExpressions.filter(
          expression =>
            !(
              expression.upcfPaymentOptionCode === item.upcfPaymentOptionCode &&
              expression.customTag === item.customTag &&
              DISPLAY_RULE_EXPRESSION_OPERATORS[expression.operator] === item.operator &&
              expression.value === item.value
            ),
        );

    channel.configuration.displayRuleExpressions = expressions;

    const updatedChannel = await this.configurationDataApi.modifyChannel(merchantId, channel);

    this.setState({
      channel: updatedChannel,
      successMessage: true,
      error: false,
    });

    this.sendDisplayRuleExpressions();

    return this.mapDisplayRuleExpressions(
      updatedChannel.configuration.displayRuleExpressions,
      this.state.paymentOptions,
    );
  }

  async addNewitem(newItem) {
    const { merchantId } = this.props;
    const channel = this.state.channel;
    const displayRuleExpressions = channel.configuration.displayRuleExpressions;
    const newItemMapped = this.mapNewItem(newItem);

    const existingItem = displayRuleExpressions?.find(
      expression =>
        expression.upcfPaymentOptionCode === newItemMapped.upcfPaymentOptionCode &&
        expression.customTag === newItemMapped.customTag &&
        expression.operator === newItemMapped.operator &&
        expression.value === newItemMapped.value,
    );

    this.setState({
      ...this.state,
      successMessage: false,
    });

    if (existingItem) {
      this.setState({
        ...this.state,
        error: true,
        errorMessage: getConfigSection(uiTexts, 'merchants.channels.form.fields.displayRuleExpressions.errorMessage'),
      });

      return this.mapDisplayRuleExpressions(displayRuleExpressions, this.state.paymentOptions);
    }

    const updatedChannel = {
      ...channel,
      configuration: {
        ...channel.configuration,
        displayRuleExpressions: displayRuleExpressions ? [...displayRuleExpressions, newItemMapped] : [newItemMapped],
      },
    };
    const savedChannel = await this.configurationDataApi.modifyChannel(merchantId, updatedChannel);

    this.setState({ ...this.state, channel: savedChannel, successMessage: true, error: false });
    this.sendDisplayRuleExpressions();

    return this.mapDisplayRuleExpressions(savedChannel.configuration.displayRuleExpressions, this.state.paymentOptions);
  }

  setPriority(displayRuleExpressions) {
    return displayRuleExpressions.length;
  }

  modifyPriority = stepValue => async item => {
    const { merchantId } = this.props;
    const channel = this.state.channel;
    const displayRuleExpressions = [...this.state.channel.configuration.displayRuleExpressions];

    const currentIndex = displayRuleExpressions.findIndex(
      expression =>
        expression.upcfPaymentOptionCode === item.upcfPaymentOptionCode &&
        expression.customTag === item.customTag &&
        DISPLAY_RULE_EXPRESSION_OPERATORS[expression.operator] === item.operator &&
        expression.value === item.value,
    );

    const expression = displayRuleExpressions[currentIndex];

    this.setState({
      ...this.state,
      successMessage: false,
    });

    displayRuleExpressions[currentIndex] = displayRuleExpressions[currentIndex + stepValue];
    displayRuleExpressions[currentIndex + stepValue] = expression;

    const updatedChannel = {
      ...channel,
      configuration: {
        ...channel.configuration,
        displayRuleExpressions: displayRuleExpressions,
      },
    };

    await this.configurationDataApi.modifyChannel(merchantId, updatedChannel);

    this.setState({
      ...this.state,
      successMessage: true,
    });

    return displayRuleExpressions;
  };

  mapNewItem(newItem) {
    return {
      customTag: newItem.customTag,
      operator: newItem.operator,
      value: newItem.value,
      action: newItem.action,
      useGreyOutLabel: newItem.action === 'HIDE' ? false : newItem.label,
      upcfPaymentOptionCode: newItem.paymentOption,
    };
  }

  mapDisplayRuleExpressions(expressions, upcfPaymentOptions) {
    return expressions
      ? expressions.map((expression, index) => ({
          ...expression,
          operator: DISPLAY_RULE_EXPRESSION_OPERATORS[expression.operator],
          action: DISPLAY_RULE_EXPRESSION_ACTIONS[expression.action],
          priority: ++index,
          label: expression.useGreyOutLabel,
          paymentOption: upcfPaymentOptions.find(po => po.smartPayCode === expression.upcfPaymentOptionCode)
            .displayName,
        }))
      : [];
  }

  filterItems(cellKey, selectCells, paymentOptionCode) {
    return cellKey === 'customTag'
      ? selectCells.customTag.filter(tag => tag.paymentOptionCode === paymentOptionCode)
      : selectCells[cellKey];
  }

  isCheckboxEnabled(displayRuleExpression, cellKey, value) {
    if (cellKey === 'action') return value !== 'HIDE';

    return displayRuleExpression.action !== 'HIDE';
  }

  isAddNewItemButtonDisabled() {
    const { paymentOptionsWithTags } = this.props;

    return paymentOptionsWithTags.length === 0;
  }

  renderSuccessMessage() {
    const text = getConfigSection(uiTexts, 'common.successMessage');
    return (
      <p className="merchant-changes-success-message" data-testid="success-message">
        {text}
      </p>
    );
  }

  renderErrorDialog() {
    const { errorMessage } = this.state;
    return (
      <ModalError
        errorKey={errorMessage}
        onConfirm={() => {
          this.setState({
            errorMessage: '',
            error: false,
          });
        }}
      />
    );
  }

  isCustomTagDeleted(displayRuleExpression) {
    const paymentOption = this.state.paymentOptions.find(
      po => po.smartPayCode === displayRuleExpression.upcfPaymentOptionCode,
    );

    if (!paymentOption) return true;

    const customTag = paymentOption.customTags?.find(tag => tag === displayRuleExpression.customTag);

    return !customTag;
  }

  renderDeletedCustomTagWarnIcon(displayRuleExpression) {
    return (
      this.isCustomTagDeleted(displayRuleExpression) && (
        <TooltipIconButton
          tooltipText={getConfigSection(
            uiTexts,
            'merchants.channels.form.fields.displayRuleExpressions.table.deletedCustomTagWarnIcon',
          )}
          buttonProps={{
            className: 'c-table__btn--border red-icon',
            secondary: true,
            small: true,
            icon: icons.warn,
          }}
          testId="warn-tooltip"
        />
      )
    );
  }
}
