// Business model pool - Country codes list
// Source: https://bitbucket.org/acbears/bus-models/src/master/models.pool.json
export const COUNTRY_CODES_POOL_MODEL = {
  Afghanistan: {
    value: 'AF',
  },
  AlandIslands: {
    value: 'AX',
  },
  Albania: {
    value: 'AL',
  },
  Algeria: {
    value: 'DZ',
  },
  AmericanSamoa: {
    value: 'AS',
  },
  Andorra: {
    value: 'AD',
  },
  Angola: {
    value: 'AO',
  },
  Anguilla: {
    value: 'AI',
  },
  Antarctica: {
    value: 'AQ',
  },
  AntiguaAndBarbuda: {
    value: 'AG',
  },
  Argentina: {
    value: 'AR',
  },
  Armenia: {
    value: 'AM',
  },
  Aruba: {
    value: 'AW',
  },
  Australia: {
    value: 'AU',
  },
  Austria: {
    value: 'AT',
  },
  Azerbaijan: {
    value: 'AZ',
  },
  Bahamas: {
    value: 'BS',
  },
  Bahrain: {
    value: 'BH',
  },
  Bangladesh: {
    value: 'BD',
  },
  Barbados: {
    value: 'BB',
  },
  Belarus: {
    value: 'BY',
  },
  Belgium: {
    value: 'BE',
  },
  Belize: {
    value: 'BZ',
  },
  Benin: {
    value: 'BJ',
  },
  Bermuda: {
    value: 'BM',
  },
  Bhutan: {
    value: 'BT',
  },
  Bolivia: {
    value: 'BO',
  },
  BosniaAndHerzegovina: {
    value: 'BA',
  },
  Botswana: {
    value: 'BW',
  },
  BouvetIsland: {
    value: 'BV',
  },
  Brazil: {
    value: 'BR',
  },
  BritishIndianOceanTerritory: {
    value: 'IO',
  },
  BruneiDarussalam: {
    value: 'BN',
  },
  Bulgaria: {
    value: 'BG',
  },
  BurkinaFaso: {
    value: 'BF',
  },
  Burundi: {
    value: 'BI',
  },
  Cambodia: {
    value: 'KH',
  },
  Cameroon: {
    value: 'CM',
  },
  Canada: {
    value: 'CA',
  },
  CapeVerde: {
    value: 'CV',
  },
  CaymanIslands: {
    value: 'KY',
  },
  CentralAfricanRepublic: {
    value: 'CF',
  },
  Chad: {
    value: 'TD',
  },
  Chile: {
    value: 'CL',
  },
  China: {
    value: 'CN',
  },
  ChristmasIsland: {
    value: 'CX',
  },
  CocosKeelingIslands: {
    value: 'CC',
  },
  Colombia: {
    value: 'CO',
  },
  Comoros: {
    value: 'KM',
  },
  Congo: {
    value: 'CG',
  },
  CongoDemocraticRepublic: {
    value: 'CD',
  },
  CookIslands: {
    value: 'CK',
  },
  CostaRica: {
    value: 'CR',
  },
  Croatia: {
    value: 'HR',
  },
  Cuba: {
    value: 'CU',
  },
  Cyprus: {
    value: 'CY',
  },
  CzechRepublic: {
    value: 'CZ',
  },
  Denmark: {
    value: 'DK',
  },
  Djibouti: {
    value: 'DJ',
  },
  Dominica: {
    value: 'DM',
  },
  DominicanRepublic: {
    value: 'DO',
  },
  Ecuador: {
    value: 'EC',
  },
  Egypt: {
    value: 'EG',
  },
  ElSalvador: {
    value: 'SV',
  },
  EquatorialGuinea: {
    value: 'GQ',
  },
  Eritrea: {
    value: 'ER',
  },
  Estonia: {
    value: 'EE',
  },
  Ethiopia: {
    value: 'ET',
  },
  FalklandIslands: {
    value: 'FK',
  },
  FaroeIslands: {
    value: 'FO',
  },
  Fiji: {
    value: 'FJ',
  },
  Finland: {
    value: 'FI',
  },
  France: {
    value: 'FR',
  },
  FrenchGuiana: {
    value: 'GF',
  },
  FrenchPolynesia: {
    value: 'PF',
  },
  FrenchSouthernTerritories: {
    value: 'TF',
  },
  Gabon: {
    value: 'GA',
  },
  Gambia: {
    value: 'GM',
  },
  Georgia: {
    value: 'GE',
  },
  Germany: {
    value: 'DE',
  },
  Ghana: {
    value: 'GH',
  },
  Gibraltar: {
    value: 'GI',
  },
  Greece: {
    value: 'GR',
  },
  Greenland: {
    value: 'GL',
  },
  Grenada: {
    value: 'GD',
  },
  Guadeloupe: {
    value: 'GP',
  },
  Guam: {
    value: 'GU',
  },
  Guatemala: {
    value: 'GT',
  },
  Guernsey: {
    value: 'GG',
  },
  Guinea: {
    value: 'GN',
  },
  GuineaBissau: {
    value: 'GW',
  },
  Guyana: {
    value: 'GY',
  },
  Haiti: {
    value: 'HT',
  },
  HeardIslandMcdonaldIslands: {
    value: 'HM',
  },
  HolySeeVaticanCityState: {
    value: 'VA',
  },
  Honduras: {
    value: 'HN',
  },
  HongKong: {
    value: 'HK',
  },
  Hungary: {
    value: 'HU',
  },
  Iceland: {
    value: 'IS',
  },
  India: {
    value: 'IN',
  },
  Indonesia: {
    value: 'ID',
  },
  Iran: {
    value: 'IR',
  },
  Iraq: {
    value: 'IQ',
  },
  Ireland: {
    value: 'IE',
  },
  IsleOfMan: {
    value: 'IM',
  },
  Israel: {
    value: 'IL',
  },
  Italy: {
    value: 'IT',
  },
  IvoryCoast: {
    value: 'CI',
  },
  Jamaica: {
    value: 'JM',
  },
  Japan: {
    value: 'JP',
  },
  Jersey: {
    value: 'JE',
  },
  Jordan: {
    value: 'JO',
  },
  Kazakhstan: {
    value: 'KZ',
  },
  Kenya: {
    value: 'KE',
  },
  Kiribati: {
    value: 'KI',
  },
  Korea: {
    value: 'KR',
  },
  Kuwait: {
    value: 'KW',
  },
  Kyrgyzstan: {
    value: 'KG',
  },
  LaoPeoplesDemocraticRepublic: {
    value: 'LA',
  },
  Latvia: {
    value: 'LV',
  },
  Lebanon: {
    value: 'LB',
  },
  Lesotho: {
    value: 'LS',
  },
  Liberia: {
    value: 'LR',
  },
  LibyanArabJamahiriya: {
    value: 'LY',
  },
  Liechtenstein: {
    value: 'LI',
  },
  Lithuania: {
    value: 'LT',
  },
  Luxembourg: {
    value: 'LU',
  },
  Macao: {
    value: 'MO',
  },
  Macedonia: {
    value: 'MK',
  },
  Madagascar: {
    value: 'MG',
  },
  Malawi: {
    value: 'MW',
  },
  Malaysia: {
    value: 'MY',
  },
  Maldives: {
    value: 'MV',
  },
  Mali: {
    value: 'ML',
  },
  Malta: {
    value: 'MT',
  },
  MarshallIslands: {
    value: 'MH',
  },
  Martinique: {
    value: 'MQ',
  },
  Mauritania: {
    value: 'MR',
  },
  Mauritius: {
    value: 'MU',
  },
  Mayotte: {
    value: 'YT',
  },
  Mexico: {
    value: 'MX',
  },
  Micronesia: {
    value: 'FM',
  },
  Moldova: {
    value: 'MD',
  },
  Monaco: {
    value: 'MC',
  },
  Mongolia: {
    value: 'MN',
  },
  Montenegro: {
    value: 'ME',
  },
  Montserrat: {
    value: 'MS',
  },
  Morocco: {
    value: 'MA',
  },
  Mozambique: {
    value: 'MZ',
  },
  Myanmar: {
    value: 'MM',
  },
  Namibia: {
    value: 'NA',
  },
  Nauru: {
    value: 'NR',
  },
  Nepal: {
    value: 'NP',
  },
  Netherlands: {
    value: 'NL',
  },
  NetherlandsAntilles: {
    value: 'AN',
  },
  NewCaledonia: {
    value: 'NC',
  },
  NewZealand: {
    value: 'NZ',
  },
  Nicaragua: {
    value: 'NI',
  },
  Niger: {
    value: 'NE',
  },
  Nigeria: {
    value: 'NG',
  },
  Niue: {
    value: 'NU',
  },
  NorfolkIsland: {
    value: 'NF',
  },
  NorthernMarianaIslands: {
    value: 'MP',
  },
  Norway: {
    value: 'NO',
  },
  Oman: {
    value: 'OM',
  },
  Pakistan: {
    value: 'PK',
  },
  Palau: {
    value: 'PW',
  },
  PalestinianTerritory: {
    value: 'PS',
  },
  Panama: {
    value: 'PA',
  },
  PapuaNewGuinea: {
    value: 'PG',
  },
  Paraguay: {
    value: 'PY',
  },
  Peru: {
    value: 'PE',
  },
  Philippines: {
    value: 'PH',
  },
  Pitcairn: {
    value: 'PN',
  },
  Poland: {
    value: 'PL',
  },
  Portugal: {
    value: 'PT',
  },
  PuertoRico: {
    value: 'PR',
  },
  Qatar: {
    value: 'QA',
  },
  Reunion: {
    value: 'RE',
  },
  Romania: {
    value: 'RO',
  },
  RussianFederation: {
    value: 'RU',
  },
  Rwanda: {
    value: 'RW',
  },
  SaintBarthelemy: {
    value: 'BL',
  },
  SaintHelena: {
    value: 'SH',
  },
  SaintKittsAndNevis: {
    value: 'KN',
  },
  SaintLucia: {
    value: 'LC',
  },
  SaintMartin: {
    value: 'MF',
  },
  SaintPierreAndMiquelon: {
    value: 'PM',
  },
  SaintVincentAndGrenadines: {
    value: 'VC',
  },
  Samoa: {
    value: 'WS',
  },
  SanMarino: {
    value: 'SM',
  },
  SaoTomeAndPrincipe: {
    value: 'ST',
  },
  SaudiArabia: {
    value: 'SA',
  },
  Senegal: {
    value: 'SN',
  },
  Serbia: {
    value: 'RS',
  },
  Seychelles: {
    value: 'SC',
  },
  SierraLeone: {
    value: 'SL',
  },
  Singapore: {
    value: 'SG',
  },
  Slovakia: {
    value: 'SK',
  },
  Slovenia: {
    value: 'SI',
  },
  SolomonIslands: {
    value: 'SB',
  },
  Somalia: {
    value: 'SO',
  },
  SouthAfrica: {
    value: 'ZA',
  },
  SouthGeorgiaAndSandwichIsl: {
    value: 'GS',
  },
  Spain: {
    value: 'ES',
  },
  SriLanka: {
    value: 'LK',
  },
  Sudan: {
    value: 'SD',
  },
  Suriname: {
    value: 'SR',
  },
  SvalbardAndJanMayen: {
    value: 'SJ',
  },
  Swaziland: {
    value: 'SZ',
  },
  Sweden: {
    value: 'SE',
  },
  Switzerland: {
    value: 'CH',
  },
  SyrianArabRepublic: {
    value: 'SY',
  },
  Taiwan: {
    value: 'TW',
  },
  Tajikistan: {
    value: 'TJ',
  },
  Tanzania: {
    value: 'TZ',
  },
  Thailand: {
    value: 'TH',
  },
  TimorLeste: {
    value: 'TL',
  },
  Togo: {
    value: 'TG',
  },
  Tokelau: {
    value: 'TK',
  },
  Tonga: {
    value: 'TO',
  },
  TrinidadAndTobago: {
    value: 'TT',
  },
  Tunisia: {
    value: 'TN',
  },
  Turkey: {
    value: 'TR',
  },
  Turkmenistan: {
    value: 'TM',
  },
  TurksAndCaicosIslands: {
    value: 'TC',
  },
  Tuvalu: {
    value: 'TV',
  },
  Uganda: {
    value: 'UG',
  },
  Ukraine: {
    value: 'UA',
  },
  UnitedArabEmirates: {
    value: 'AE',
  },
  UnitedKingdom: {
    value: 'GB',
  },
  UnitedStates: {
    value: 'US',
  },
  UnitedStatesOutlyingIslands: {
    value: 'UM',
  },
  Uruguay: {
    value: 'UY',
  },
  Uzbekistan: {
    value: 'UZ',
  },
  Vanuatu: {
    value: 'VU',
  },
  Venezuela: {
    value: 'VE',
  },
  Vietnam: {
    value: 'VN',
  },
  VirginIslandsBritish: {
    value: 'VG',
  },
  VirginIslandsUS: {
    value: 'VI',
  },
  WallisAndFutuna: {
    value: 'WF',
  },
  WesternSahara: {
    value: 'EH',
  },
  Yemen: {
    value: 'YE',
  },
  Zambia: {
    value: 'ZM',
  },
  Zimbabwe: {
    value: 'ZW',
  },
};
