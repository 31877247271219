import React from 'react'
import PropTypes from 'prop-types'

export function Heading({ headerType, children, testId, ...otherProps }) {
  switch (headerType) {
    case 1:
      return (
        <h1 data-testid={testId} {...otherProps}>
          {children}
        </h1>
      )
    case 2:
      return (
        <h2 data-testid={testId} {...otherProps}>
          {children}
        </h2>
      )
    case 3:
      return (
        <h3 data-testid={testId} {...otherProps}>
          {children}
        </h3>
      )
    case 4:
      return (
        <h4 data-testid={testId} {...otherProps}>
          {children}
        </h4>
      )
    case 5:
      return (
        <h5 data-testid={testId} {...otherProps}>
          {children}
        </h5>
      )
    case 6:
      return (
        <h6 data-testid={testId} {...otherProps}>
          {children}
        </h6>
      )
    default:
      return null
  }
}

Heading.propTypes = {
  testId: PropTypes.string,
  headerType: PropTypes.number,
  children: PropTypes.node,
}

Heading.defaultProps = {
  headerType: 1,
}
