import React from 'react';
import { Heading } from 'cj-common-components';
import { getConfigSection } from '../../common/utils';
import { withAuth } from '../../auth/withAuth';
import { ROUTES } from '../../common/constants';

const uiTexts = require('../../resources/uiTexts.json');

class AccessDenied extends React.PureComponent {
  render() {
    const code = getConfigSection(uiTexts, 'accessDenied.code');
    const header = getConfigSection(uiTexts, 'accessDenied.header');
    const subheader = getConfigSection(uiTexts, 'accessDenied.subheader');

    return (
      <div className="o-layout--center">
        <Heading headerType={3}>{code}</Heading>
        <Heading headerType={4}>{header}</Heading>
        <Heading headerType={6}>{subheader}</Heading>
      </div>
    );
  }
}

export default withAuth(AccessDenied, ROUTES.accessDenied);
