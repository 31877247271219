import React from 'react';
import { connect } from 'react-redux';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import CommonTable from '../../common-components/CommonTable';
import { getConfigSection } from '../../../common/utils';
import commonPropTypes from '../../../common/common-prop-types';
import { SORT_COLUMNS } from '../../../common/constants';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { adaptHistoryDetailsResponse } from './adaptHistoryDetailsResponse';

const uiTexts = require('../../../resources/uiTexts.json');
class MerchantHistoryPage extends React.Component {
  static propTypes = {
    ...commonPropTypes.table,
  };

  constructor(props) {
    super(props);
    const { authToken } = this.props;
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
    this.loadData = this.loadData.bind(this);
  }

  render() {
    const { authToken, textsKey, isFormEditable, merchantData } = this.props;
    const headerLevelsSeparator = getConfigSection(uiTexts, 'common.headerLevelsSeparator');
    const parentHeader = getConfigSection(uiTexts, `${textsKey}.table.header`);
    const historyParentHeader = `${parentHeader} ${headerLevelsSeparator} ${merchantData.name}`;

    return (
      <>
        <CommonTable
          authToken={authToken}
          textsKey={textsKey}
          parentLevelHeader={historyParentHeader}
          dataFunctions={{
            loadData: this.loadData,
          }}
          defaultSortColumn={SORT_COLUMNS.CREATION_DATE}
          canAddNewItem={false}
          canClickOnItem={false}
          canDownloadItem={true}
          isRowDeletable={false}
          isFormEditable={isFormEditable}
          isRowEditable={false}
          canFilterItems={false}
          hasMaxColumnSize={true}
          testIdPrefix="history-entries-table"
        />
      </>
    );
  }

  async loadData() {
    const { historyEntryId, merchantId } = this.props.match.params;
    const historyEntry = await this.configurationDataApi.getMerchantHistoryEntry(merchantId, historyEntryId);

    const adaptedResponse = adaptHistoryDetailsResponse(historyEntry);
    return adaptedResponse;
  }
}

export default connect()(withRouter(MerchantHistoryPage));
