import React from 'react';
import * as Yup from 'yup';
import BaseFormFields from '../../../common-components/BaseFormFields';
import ConfigurationDataApi from '../../../../api/ConfigurationData';
import { getConfigSection } from '../../../../common/utils';
import { mapValuesArrayToSelectComponentValues } from '../../../common-components/common-functions';
import commonPropTypes from '../../../../common/common-prop-types';
import { RequiredFieldsTip } from '../../../common-components/RequiredFieldsTip';

const uiTexts = require('../../../../resources/uiTexts.json');
const errorsTexts = require('../../../../resources/errorTexts.json');

const error = getConfigSection(errorsTexts, 'validation');

export default class FormFields extends React.PureComponent {
  static propTypes = {
    ...commonPropTypes.formFields,
  };

  static validationSchema() {
    return Yup.object().shape({
      id: Yup.string().required(error.mandatoryField),
    });
  }

  constructor(props) {
    super(props);
    const { authToken } = this.props;

    this.updateRefDataState = this.updateRefDataState.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);

    this.state = {
      merchantGroupsList: [],
    };
  }

  render() {
    const { textsKey, authToken, isUpdateOperation, isFormEditable, customProps, dataStatusLoadCallback } = this.props;

    const { merchantGroupsList } = this.state;
    const { groupsWithMerchant } = customProps;
    const fieldsTextKey = `${textsKey}.form.fields`;
    const { renderGenericSelect } = BaseFormFields;
    const selectAnOptionText = getConfigSection(uiTexts, 'common.editForm.comboSelectAnOption');

    const fieldsRenderConfig = [
      {
        // TODO: in case we are editing an existing user,
        // we need to avoid fetching again the list of users
        // from the backend
        id: 'id',
        useRequiredLabel: true,
        function: renderGenericSelect(
          isFormEditable && !isUpdateOperation,
          mapValuesArrayToSelectComponentValues(
            merchantGroupsList.filter(group => !groupsWithMerchant.includes(group.id)),
            'name',
            'id',
            {
              defaultValue: '',
              defaultText: selectAnOptionText,
            },
          ),
        ),
      },
    ];

    return (
      <>
        <RequiredFieldsTip />
        <BaseFormFields
          authToken={authToken}
          fieldsTextKey={fieldsTextKey}
          fieldsRenderConfig={fieldsRenderConfig}
          refDataLoadFunctions={[this.configurationDataApi.getMerchantGroups]}
          refDataLoadCallback={this.updateRefDataState}
          dataStatusLoadCallback={dataStatusLoadCallback}
          testIdPrefix="group-form"
        />
      </>
    );
  }

  updateRefDataState(refDataArray) {
    this.setState({ merchantGroupsList: refDataArray[0].groups });
  }
}
