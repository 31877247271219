import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export function Button(props) {
  const componentButtonClass = classNames(
    {
      'c-btn  ': true,
      'c-btn--link  ': props.asLink,
      'c-btn--secondary  ': props.secondary,
      'c-btn--full  ': props.fullWidth,
      'c-btn--icon  ': props.icon && !props.simple,
      'c-btn--small  ': props.small,
      'c-btn--round  ': props.round,
      'c-btn--truncate  ': props.truncated,
      'c-btn--simple  ': props.simple,
      'c-btn--icon-only  ': props.icon && !props.children,
    },
    props.className
  ).trim()

  const iconClass =
    props.asLink && !props.simple
      ? `c-icon--[semantic-forward]  `
      : `c-icon--[${props.icon}]  `

  const componentButtonIconClass = classNames({
    'c-btn__icon  ': true,
    'c-icon  ': true,
    [iconClass]: true,
  }).trim()

  function renderReversedIcon() {
    if ((props.icon && props.iconReversed) || props.asLink) {
      return <i className={componentButtonIconClass} />
    }
    return null
  }

  function renderIcon() {
    if (props.icon && !props.iconReversed && !props.simple) {
      return <i className={componentButtonIconClass} />
    }
    return null
  }

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      data-testid={props.testId}
      className={componentButtonClass}
      type={props.type}
      title={props.title}
      disabled={props.disabled ? 'disabled' : null}
      aria-disabled={props.disabled ? true : null}
      aria-expanded={props.ariaExpanded}
      aria-label={props.ariaLabel}
      style={props.style}
      onClick={props.onClick}
    >
      {renderReversedIcon()}
      {props.children && <span className="c-btn__text">{props.children}</span>}
      {renderIcon()}
    </button>
  )
}

Button.propTypes = {
  testId: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  secondary: PropTypes.bool,
  title: PropTypes.string,
  icon: PropTypes.string,
  small: PropTypes.bool,
  simple: PropTypes.bool,
  ariaExpanded: PropTypes.bool,
  ariaLabel: PropTypes.string,
  asLink: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  iconReversed: PropTypes.bool, // eslint-disable-line
  round: PropTypes.bool,
  truncated: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
  style: PropTypes.object, // eslint-disable-line
}

Button.defaultProps = {
  className: '',
  type: 'button',
  secondary: false,
  small: false,
  simple: false,
  ariaExpanded: false,
  asLink: false,
  disabled: false,
  fullWidth: false,
  iconReversed: false,
  round: false,
  truncated: false,
}
