import { isEmpty, get, uniq } from '@turbopay/ts-helpers/object-utils';
import { getEnumValueFromText, parseStringToDecimal } from '../../../../common/utils';
import { PAYMENT_OPTIONS, PAYMENT_OPTIONS_SDK_CONFIG, INTEGRATION_TYPE } from '../../../../common/constants';
import { initializieEmptyChannelRule } from './initializieEmptyChannelRule';
import { getEnabledPaymentOptions } from './enabledPaymentOptions';

function convertCriteriaToJson(criteriaStr) {
  const nameValuePair = criteriaStr
    .split('\n')
    .map(item => String(item).trim())
    .filter(item => !isEmpty(item));
  return nameValuePair.map(item => {
    const index = item.indexOf(':');
    const splitArray = [];
    splitArray.push(item.substring(0, index), item.substring(index + 1));
    return {
      name: String(splitArray[0]).trim(),
      value: String(splitArray[1]).trim(),
    };
  });
}

const getDelayDays = autoCapture => {
  return autoCapture.isEnabled || isEmpty(autoCapture.delayDays) ? 0 : parseInt(autoCapture.delayDays, 10);
};

function mapLegacyPaymentOptions(paymentOptions) {
  let allPaymentOptionsCode = [
    ...paymentOptions.isEnabled.map(item => item.code),
    ...paymentOptions.isStorable.map(item => item.code),
  ];

  allPaymentOptionsCode = uniq(allPaymentOptionsCode);

  return allPaymentOptionsCode
    .map(methodCode => {
      const isStorable = !isEmpty(paymentOptions.isStorable.find(item => methodCode === item.code));
      const isGuest = !isEmpty(paymentOptions.isEnabled.find(item => methodCode === item.code));

      let mappedPaymentOption = {
        isStorable,
        isGuest,
      };

      if (!methodCode) {
        return undefined;
      }

      const code = getEnumValueFromText(PAYMENT_OPTIONS, methodCode);

      mappedPaymentOption = {
        ...mappedPaymentOption,
        code,
        ...PAYMENT_OPTIONS_SDK_CONFIG[code],
      };

      return mappedPaymentOption;
    })
    .filter(x => x);
}

function mapUpcfPaymentOptions(paymentOptions) {

  const allPaymentOptions = [];

  if(paymentOptions.activeUpcfPaymentOptions) {
    allPaymentOptions.push(...paymentOptions.activeUpcfPaymentOptions);
  }

  if(paymentOptions.inactiveUpcfPaymentOptions) {
    allPaymentOptions.push(...paymentOptions.inactiveUpcfPaymentOptions);
  }
  
  const enabledPaymentOptions = getEnabledPaymentOptions(allPaymentOptions);

  return enabledPaymentOptions.map(po => ({
    code: po.code,
    isCitStorable: po.isCitStorable.value,
    isMitStorable: po.isMitStorable.value,
    isStoredCitPaymentsAllowed: po.isStoredCitPaymentsAllowed.value,
    isStoredMitPaymentsAllowed: po.isStoredMitPaymentsAllowed.value,
    isGuest: po.isGuest.value,
    isUpcfChannel: true,
  }));
}

export const revertChannelDataTransformation = inputChannel => {
  const channel = initializieEmptyChannelRule(inputChannel);
  const { configuration, rule, priority, destinationAccounts } = channel;
  const {
    amount,
    customerBillingAddressCountries,
    currencies,
    customerGroups,
    productGroups,
    shopCountries,
  } = rule.properties;
  const { paymentOptions, autoCapture, criteria, integrationType } = configuration;

  let newPaymentOptions = [];

  if (integrationType === INTEGRATION_TYPE.LEGACY) {
    newPaymentOptions = mapLegacyPaymentOptions(paymentOptions);
  } else {
    newPaymentOptions = mapUpcfPaymentOptions(paymentOptions);
  }

  const nullableAmount = isEmpty(get(amount, 'value'))
    ? undefined
    : {
        ...amount,
        value: parseStringToDecimal(amount.value),
      };

  const newObject = Object.assign({}, channel, {
    priority,
    destinationAccounts,
    configuration: {
      ...configuration,
      connectorType: integrationType === INTEGRATION_TYPE.UPCF ? undefined : configuration.connectorType,
      criteria: isEmpty(criteria) ? undefined : convertCriteriaToJson(criteria),
      paymentOptions: newPaymentOptions,
      autoCapture: {
        ...autoCapture,
        delayDays: getDelayDays(autoCapture),
      },
    },
    rule: {
      ...rule,
      properties: {
        ...rule.properties,
        amount: nullableAmount,
        customerBillingAddressCountries: isEmpty(customerBillingAddressCountries)
          ? undefined
          : customerBillingAddressCountries.map(item => (isEmpty(item.value) ? item : item.value)),
        currencies: isEmpty(currencies) ? undefined : currencies.map(item => (isEmpty(item.value) ? item : item.value)),
        customerGroups: isEmpty(customerGroups)
          ? undefined
          : customerGroups.map(item => (isEmpty(item.value) ? item : item.value)),
        productGroups: isEmpty(productGroups)
          ? undefined
          : productGroups.map(item => (isEmpty(item.value) ? item : item.value)),
        shopCountries: isEmpty(shopCountries)
          ? undefined
          : shopCountries.map(item => (isEmpty(item.value) ? item : item.value)),
      },
      expression: {
        value: [
          [get(amount, 'value'), 'amount'],
          [customerBillingAddressCountries, 'customerBillingAddressCountries'],
          [currencies, 'currencies'],
          [customerGroups, 'customerGroups'],
          [productGroups, 'productGroups'],
        ].reduce((accum, curr) => {
          const currVal = curr[0];
          const currStr = curr[1];
          if (isEmpty(currVal)) {
            return accum;
          }
          const newItem = isEmpty(accum) ? currStr : ` AND ${currStr}`;
          return accum + newItem;
        }, ''),
      },
    },
  });

  if (isEmpty(newObject.rule.expression.value)) {
    newObject.rule = undefined;
  }

  delete newObject.isMerchantStoredPaymentsEnabled;

  return newObject;
};
