import React from 'react';
import { Column, Layout } from 'cj-common-components';
import { connect } from 'react-redux';
import { cloneDeep, isEqual, set } from '@turbopay/ts-helpers/object-utils';
import { withRouter } from 'react-router-dom';
import { getConfigSection } from '../../../../common/utils';
import uiTexts from '../../../../resources/uiTexts.json';
import SplitPaymentsPage from './SplitPaymentsPage';
import { RenderButtons } from '../../../common-components/FormButtons';
import ConfigurationDataApi from '../../../../api/ConfigurationData';
import { CANCEL_ACTION, SUBMIT_ACTION } from '../../../common-components/constants/constants';
import { ModalConfirmOperation } from '../../../common-components/ModalWindow';
import { ROUTE_KEYS } from '../../../../common/constants';
import { BlockNavigation } from '../../../common-components/BlockNavigation';
import { diff } from 'deep-diff';

class SplitPaymentsEditForm extends React.PureComponent {
  constructor(props) {
    super(props);
    const { authToken } = this.props;
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
    this.setSubmittingDetails = this.setSubmittingDetails.bind(this);
    this.checkIsSettingsChanged = this.checkIsSettingsChanged.bind(this);
    const { data } = this.props;
    this.state = {
      channelId: null,
      merchantId: null,
      initialValues: data,
      data: cloneDeep(data),
      confirmationModal: {
        isVisible: false,
        actionFlow: null,
        message: '',
      },
    };
    this.isFormDirty = false;
    this.isNeedActionPrevent = false;

    this.valuesRef = React.createRef();
  }

  componentDidMount() {
    const {
      routeProps: {
        match: {
          params: { channelId, merchantId },
        },
      },
    } = this.props;

    this.setState({
      ...this.state,

      channelId,
      merchantId,
    });
  }

  handleGoBack() {
    this.props.hideSuccessMessage();
    const { channelId, merchantId } = this.state;
    this.props.history.replace(`/${ROUTE_KEYS.merchants}/${merchantId}/${ROUTE_KEYS.channels}/${channelId}`);
  }

  dispatchFormData = (path, data) => {
    this.setState(prevState => {
      const newState = { ...prevState };
      set(newState, `data.${path}`, data);
      return newState;
    });
  };

  setSubmittingDetails = props => {
    const { isFormDirty, isNeedActionPrevent } = props;
    this.isFormDirty = isFormDirty;
    this.isNeedActionPrevent = isNeedActionPrevent;
  };

  compareValues = () => {
    const origDestAcc = this.state.initialValues.paymentSplitConfiguration?.destinationAccounts || [];
    const currDestAcc = this.state.data.paymentSplitConfiguration?.destinationAccounts || [];
    const dataDiffs = diff(origDestAcc, currDestAcc);

    const initialValues = this.valuesRef.current.initialValues;
    const currentValues = this.valuesRef.current.values;
    const formDiffs = diff(initialValues, currentValues);

    return dataDiffs || formDiffs;
  };

  render() {
    const { authToken, textsKey, isFormEditable, dataStatusLoadCallback } = this.props;
    const { data } = this.state;

    return (
      <>
        {this.renderConfirmationModal()}
        <BlockNavigation compareValues={this.compareValues} />
        <SplitPaymentsPage
          valuesRef={this.valuesRef}
          authToken={authToken}
          textsKey={textsKey}
          isFormEditable={isFormEditable}
          dataStatusLoadCallback={dataStatusLoadCallback}
          formData={data}
          dispatchFormData={this.dispatchFormData}
          setSubmittingDetails={this.setSubmittingDetails}
        />
        <Layout center className="u-mt-xxsmall">
          <Column span="6/12">
            <RenderButtons
              isSaveButtonVisible
              isSaveButtonEnable={isFormEditable}
              isFormEditable
              onBack={this.handleGoBack}
              onSubmit={() => this.handleSubmitForm(data)}
            />
          </Column>
        </Layout>
      </>
    );
  }

  renderConfirmationModal() {
    const { confirmationModal } = this.state;
    const deleteItemTexts = getConfigSection(uiTexts, 'common.table.dialogs.deleteItem');
    const { header, buttonNo, buttonYes } = deleteItemTexts;

    const cancelHandler = () =>
      this.setState({
        ...this.state,
        confirmationModal: {
          isVisible: false,
          actionFlow: null,
          message: '',
        },
      });

    const confirmHandler = () => {
      const { data } = this.state;
      const { actionFlow } = confirmationModal;

      if (actionFlow === SUBMIT_ACTION) {
        this.isNeedActionPrevent = false;
        this.setState(
          {
            ...this.state,
            confirmationModal: {
              isVisible: false,
            },
          },
          () => this.handleSubmitForm(data),
        );
      }

      if (actionFlow === CANCEL_ACTION) {
        this.isNeedActionPrevent = false;
        this.setState(
          {
            ...this.state,
            confirmationModal: {
              isVisible: false,
            },
          },
          () => this.handleGoBack(),
        );
      }
    };

    return (
      <>
        {confirmationModal.isVisible && (
          <ModalConfirmOperation
            title={header}
            buttonConfirmText={buttonYes}
            onConfirm={confirmHandler}
            buttonCancelText={buttonNo}
            onCancel={cancelHandler}
            message={confirmationModal.message}
            testId="confirm-modal"
          />
        )}
      </>
    );
  }

  checkIsSettingsChanged() {
    const { initialValues, data } = this.state;
    const { customFormComponent } = this.props;
    const initialCopy = cloneDeep(initialValues);

    if (customFormComponent) {
      if (isEqual(initialCopy, data)) {
        return false;
      }

      return true;
    }

    return false;
  }

  async handleSubmitForm(values) {
    const { saveChannelData } = this.props;
    if (this.isNeedActionPrevent) {
      const beforeSubmitMessage = getConfigSection(uiTexts, 'common.table.dialogs.deleteItem.beforeSubmitMessage');
      this.setState({
        ...this.state,
        confirmationModal: {
          isVisible: true,
          actionFlow: SUBMIT_ACTION,
          message: beforeSubmitMessage,
        },
      });
    } else {
      await saveChannelData(values);
    }
  }
}

export default connect()(withRouter(SplitPaymentsEditForm));
