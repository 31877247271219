import React from 'react';
import ConfigurationDataApi from '../../../../api/ConfigurationData';
import commonPropTypes from '../../../../common/common-prop-types';
import uiTexts from '../../../../resources/uiTexts.json';
import errorTexts from '../../../../resources/errorTexts.json';
import { getConfigSection } from '../../../../common/utils';
import CommonTable from '../../../common-components/CommonTable';
import { ModalError } from '../../../common-components/ModalWindow';
import { BlockNavigation } from '../../../common-components/BlockNavigation';
import { diff } from 'deep-diff';

export default class Table extends React.Component {
  static propTypes = {
    ...commonPropTypes.Table,
  };

  constructor(props) {
    super(props);
    const { authToken } = this.props;

    this.loadCustomTags = this.loadCustomTags.bind(this);
    this.addCustomTag = this.addCustomTag.bind(this);
    this.deleteCustomTag = this.deleteCustomTag.bind(this);

    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);

    this.state = {
      paymentOption: {},
      successMessage: false,
      error: false,
      errorMessage: '',
      skipNavBlock: false,
    };

    this.valuesRef = React.createRef();
  }

  compareValues = () => {
    if (this.state.skipNavBlock) {
      return;
    }

    if (!this.valuesRef.current) {
      return;
    }

    const fVals = this.valuesRef.current.values;
    const origVals = this.state.customTags;

    if (!origVals && !fVals[0].customTag) {
      return;
    }

    const diffs = (diff(origVals, fVals) || []).filter(change => {
      if (change.kind === 'N' && change.path?.includes('internalId')) {
        return false;
      }
      return true;
    });

    return diffs.length !== 0;
  };

  render() {
    const { navigationFunction, textsKey, onBack, isFormEditable } = this.props;
    const thisTextKey = `${textsKey}.customTags`;
    const deleteItemDialogMessage = getConfigSection(
      uiTexts,
      'referenceData.upcfPaymentOptions.customTags.dialogs.deleteItem',
    );

    return (
      <>
        {this.state.successMessage && this.renderSuccessMessage()}
        {this.state.error && this.renderErrorDialog()}
        <BlockNavigation compareValues={this.compareValues} />
        <CommonTable
          valuesRef={this.valuesRef}
          textsKey={thisTextKey}
          dataFunctions={{
            loadData: this.loadCustomTags,
            addItem: this.addCustomTag,
            deleteItem: this.deleteCustomTag,
          }}
          canClickOnItem={false}
          canAddInlineItem={true}
          onBack={onBack}
          isFormEditable={isFormEditable}
          navigationFunction={navigationFunction}
          hasBackButton={true}
          hasSaveButton={true}
          canFilterItems={false}
          deleteItemDialogMessage={deleteItemDialogMessage}
          testIdPrefix="custom-tags-table"
        />
      </>
    );
  }

  async loadCustomTags() {
    const {
      match: {
        params: { upcfPaymentOptionId },
      },
    } = this.props;

    const paymentOption = await this.configurationDataApi.getPaymentOption(upcfPaymentOptionId);

    if (paymentOption) {
      this.setState({ paymentOption });
    }

    const cTags = paymentOption.customTags?.map(customTag => ({ customTag }));

    if (cTags) {
      this.setState({ customTags: cTags });
    }

    return cTags;
  }

  async deleteCustomTag(item) {
    const paymentOption = this.state.paymentOption;

    paymentOption.customTags = item.isEdited
      ? paymentOption.customTags.filter(customTag => !customTag.isEdited)
      : paymentOption.customTags.filter(customTag => customTag !== item.customTag);

    const updatedPaymentOptions = await this.configurationDataApi.updatePaymentOption(paymentOption.id, paymentOption);

    this.setState({
      paymentOption: updatedPaymentOptions,
      successMessage: true,
      error: false,
    });

    return updatedPaymentOptions.customTags?.map(customTag => ({ customTag }));
  }

  async addCustomTag(item) {
    this.setState({ ...this.state, skipNavBlock: true });
    const paymentOption = this.state.paymentOption;

    if (!item.customTag) {
      this.setState({
        successMessage: false,
        error: true,
        errorMessage: getConfigSection(errorTexts, 'validation.mandatoryField'),
      });

      return paymentOption.customTags?.map(customTag => ({ customTag }));
    }

    if (paymentOption.customTags && paymentOption.customTags.find(customTag => customTag === item.customTag)) {
      this.setState({
        paymentOption: paymentOption,
        successMessage: false,
        error: true,
        errorMessage: getConfigSection(uiTexts, 'referenceData.upcfPaymentOptions.customTags.errorMessage'),
      });

      return paymentOption.customTags?.map(customTag => ({ customTag }));
    }

    if (item?.customTag) {
      paymentOption.customTags = [...(paymentOption.customTags || []), item.customTag];
    }

    if (paymentOption.customTags?.length === 0) {
      return paymentOption.customTags;
    }

    const updatedPaymentOptions = await this.configurationDataApi.updatePaymentOption(paymentOption.id, paymentOption);

    this.setState({
      paymentOption: updatedPaymentOptions,
      successMessage: true,
      error: false,
    });

    return updatedPaymentOptions.customTags?.map(customTag => ({ customTag }));
  }

  renderSuccessMessage() {
    const text = getConfigSection(uiTexts, 'common.successMessage');
    return (
      <p className="merchant-changes-success-message" data-testid="success-message">
        {text}
      </p>
    );
  }

  renderErrorDialog() {
    const { errorMessage } = this.state;
    return (
      <ModalError
        errorKey={errorMessage}
        onConfirm={() => {
          this.setState({
            errorMessage: '',
            error: false,
          });
        }}
      />
    );
  }
}
