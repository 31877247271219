import React from 'react';
import { Heading } from 'cj-common-components';
import { getConfigSection } from '../../common/utils';
import uiTexts from '../../resources/uiTexts.json';

const resourceNotFoundMessage = getConfigSection(uiTexts, 'common.resourceNotFound');
export const NotFoundResource = () => {
  return (
    <Heading className="u-mb" headerType={4}>
      {resourceNotFoundMessage}
    </Heading>
  );
};
