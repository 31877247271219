import { getConfigSection } from '../common/utils';
import uiTexts from '../resources/uiTexts.json';
import { ROUTES } from '../common/constants';
import MerchantName from './MerchantBreadcrumb';
import MerchantGroupName from './MerchantGroupBreadcrumb';
import MerchantGroupEditUserName from './MerchantGroupEditUserBreadcrumb';
import ChannelName from './ChannelBreadcrumb';
import TargetMerchantAccountReference from './TargetMerchantAccountReferenceBreadcrumb';

const merchantsResources = getConfigSection(uiTexts, 'merchants');
const merchantGroupsResources = getConfigSection(uiTexts, 'merchantGroups');

export const routes = [
  {
    path: ROUTES.merchants,
    breadcrumb: getConfigSection(merchantsResources, 'table.header'),
  },
  {
    path: ROUTES.createMerchant,
    breadcrumb: getConfigSection(uiTexts, 'common.editForm.headerNewItem'),
  },
  { path: ROUTES.merchantPage, breadcrumb: MerchantName },
  {
    path: ROUTES.merchantGroups,
    breadcrumb: getConfigSection(merchantGroupsResources, 'table.header'),
  },
  {
    path: ROUTES.createMerchantGroup,
    breadcrumb: getConfigSection(uiTexts, 'common.editForm.headerNewItem'),
  },
  { path: ROUTES.merchantGroupPage, breadcrumb: MerchantGroupName },
  {
    path: ROUTES.merchantGroupAddUserForm,
    breadcrumb: getConfigSection(uiTexts, 'common.editForm.headerNewUser'),
  },
  {
    path: ROUTES.merchantGroupEditUserForm,
    breadcrumb: MerchantGroupEditUserName,
  },
  {
    path: ROUTES.merchantGroupAddMerchantForm,
    breadcrumb: getConfigSection(uiTexts, 'common.editForm.headerNewMerchant'),
  },
  {
    path: ROUTES.merchantChannels,
    breadcrumb: getConfigSection(merchantsResources, 'channels.table.header'),
  },
  {
    path: ROUTES.channelCreation,
    breadcrumb: getConfigSection(uiTexts, 'common.editForm.headerNewItem'),
  },
  {
    path: ROUTES.targetMerchantAccountCreation,
    breadcrumb: getConfigSection(uiTexts, 'common.editForm.headerNewItem'),
  },
  {
    path: ROUTES.merchantCulture,
    breadcrumb: getConfigSection(merchantsResources, 'culture.form.header'),
  },
  {
    path: ROUTES.merchantIntegration,
    breadcrumb: getConfigSection(merchantsResources, 'integration.form.header'),
  },
  {
    path: ROUTES.notificationSettings,
    breadcrumb: getConfigSection(merchantsResources, 'notificationSettings.form.header'),
  },
  {
    path: ROUTES.merchantUsersAndGroups,
    breadcrumb: getConfigSection(merchantGroupsResources, 'merchants.form.header'),
  },
  {
    path: ROUTES.channelPage,
    breadcrumb: ChannelName,
  },
  {
    path: ROUTES.hostedPaymentPage,
    breadcrumb: getConfigSection(merchantsResources, 'hostedPaymentPage.header'),
  },
  {
    path: ROUTES.paymentsSplitConfiguration,
    breadcrumb: getConfigSection(merchantsResources, 'channels.form.fields.splitB2BPaymentsConfiguration.title'),
  },
  {
    path: ROUTES.targetMerchantAccounts,
    breadcrumb: getConfigSection(merchantsResources, 'channels.form.fields.targetMerchantAccounts.title'),
  },
  {
    path: ROUTES.networkTokenAndThreeDsManagement,
    breadcrumb: getConfigSection(merchantsResources, 'networkTokenAndThreeDsManagement.header'),
  },
  {
    path: ROUTES.achSettings,
    breadcrumb: getConfigSection(merchantsResources, 'achSettings.form.header'),
  },
  {
    path: ROUTES.notificationRetries,
    breadcrumb: getConfigSection(merchantsResources, 'notificationRetries.table.header'),
  },
  {
    path: ROUTES.merchantNotifications,
    breadcrumb: getConfigSection(merchantsResources, 'notifications.table.header'),
  },
  {
    path: ROUTES.targetMerchantAccount,
    breadcrumb: TargetMerchantAccountReference,
  },
  {
    path: ROUTES.authenticationSettings,
    breadcrumb: getConfigSection(merchantsResources, 'authenticationSettings.form.header'),
  },
  {
    path: ROUTES.merchantCorsDomains,
    breadcrumb: getConfigSection(merchantsResources, 'corsDomains.header'),
  },
  {
    path: ROUTES.displayRuleExpressions,
    breadcrumb: getConfigSection(merchantsResources, 'channels.form.fields.displayRuleExpressions.title'),
    },
];
