import React from 'react';
import { get } from '@turbopay/ts-helpers/object-utils';
import { connect } from 'react-redux';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import CommonTable from '../../common-components/CommonTable';
import { getConfigSection } from '../../../common/utils';
import ChannelEditForm from './ChannelEditForm';
import commonPropTypes from '../../../common/common-prop-types';
import { revertChannelDataTransformation } from './channelDataUtils/revertChannelDataTransformation';
import { prepareChannelDataTransformation } from './channelDataUtils/prepareChannelDataTransformation';
import { setChannelsList } from '../../../redux/dataSlice';

const uiTexts = require('../../../resources/uiTexts.json');

class ChannelsTable extends React.Component {
  static propTypes = {
    ...commonPropTypes.table,
  };

  constructor(props) {
    super(props);
    const { authToken } = this.props;
    this.loadChannelsData = this.loadChannelsData.bind(this);
    this.modifyChannel = this.modifyChannel.bind(this);
    this.deleteChannel = this.deleteChannel.bind(this);
    this.setPriority = this.setPriority.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);

    this.state = {
      successMessage: false,
    };
  }

  render() {
    const { authToken, textsKey, isFormEditable, merchantData } = this.props;
    const headerLevelsSeparator = getConfigSection(uiTexts, 'common.headerLevelsSeparator');
    const parentHeader = getConfigSection(uiTexts, `${textsKey}.table.header`);
    const channelsParentHeader = `${parentHeader} ${headerLevelsSeparator} ${merchantData.name}`;
    const textsKeyChannels = `${textsKey}.channels`;

    return (
      <>
        {this.state.successMessage && this.renderSuccessMessage()}
        <CommonTable
          authToken={authToken}
          textsKey={textsKeyChannels}
          parentLevelHeader={channelsParentHeader}
          dataFunctions={{
            loadData: this.loadChannelsData,
            modifyItem: this.modifyChannel,
            deleteItem: this.deleteChannel,
            increaseItem: this.setPriority(-1),
            decreaseItem: this.setPriority(1),
          }}
          editComponent={{
            type: ChannelEditForm,
          }}
          canFilterItems={false}
          canSortItems={false}
          defaultSortColumn="priority"
          defaultSortOrderAsc={true}
          canAddNewItem={isFormEditable}
          canDeleteItem={isFormEditable}
          isFormEditable={isFormEditable}
          testIdPrefix="channels-table"
        />
      </>
    );
  }

  async loadChannelsData() {
    const { merchantId, merchantData, dispatch } = this.props;
    const response = await this.configurationDataApi.getChannels(merchantId);

    dispatch(setChannelsList({ channelsList: response }));

    const channels = response.map(item =>
      prepareChannelDataTransformation(item, get(merchantData, 'storedPayments.isEnabled', false)),
    );

    return channels;
  }

  async modifyChannel(channel) {
    const { merchantId } = this.props;

    const updatedChannel = await this.configurationDataApi.modifyChannel(
      merchantId,
      revertChannelDataTransformation(channel),
    );
    return prepareChannelDataTransformation(updatedChannel);
  }

  async deleteChannel(channel) {
    const { merchantId } = this.props;

    this.setState({
      ...this.state,
      successMessage: false,
    });

    const response = await this.configurationDataApi.deleteChannel(merchantId, channel.sortKey);

    this.setState({
      ...this.state,
      successMessage: true,
    });

    return response;
  }

  setPriority = stepValue => async currentChannel => {
    const { merchantId } = this.props;
    const { sortKey } = currentChannel;
    const newPriority = currentChannel.priority + stepValue;

    this.setState({
      ...this.state,
      successMessage: false,
    });

    const response = await this.configurationDataApi.updateChannelPriority(merchantId, sortKey, {
      priority: newPriority,
    });

    this.setState({
      ...this.state,
      successMessage: true,
    });

    return response;
  };

  renderSuccessMessage() {
    const text = getConfigSection(uiTexts, 'common.successMessage');

    return (
      <p className="merchant-changes-success-message" data-testid="success-message">
        {text}
      </p>
    );
  }
}

export default connect()(ChannelsTable);
