import React from 'react';
import { connect } from 'react-redux';

const ChannelBreadcrumb = ({ channelName }) => <span>{channelName}</span>;

const mapStateToProps = state => {
  const { channelName } = state.breadCrumbs;
  return { channelName };
};

export default connect(mapStateToProps)(ChannelBreadcrumb);
