import React from 'react';
import * as Yup from 'yup';
import { isEmpty, get, uniqueId } from '@turbopay/ts-helpers/object-utils';
import { Spinner } from 'cj-common-components';
import {
  YupSpaceFreeNumber,
  YupStringOnlyDigits,
  YupStringWithoutWhitespaces,
} from '../../common-components/common-validations';
import BaseFormFields from '../../common-components/BaseFormFields';
import InfoIconText from '../../common-components/InfoIconText';
import {
  PAYMENT_OPTIONS,
  PAYMENT_OPTIONS_NAMES,
  PAYMENT_OPTIONS_STORED,
  CHANNEL_RULE_OPERATORS,
  PCI_TYPES,
  PCI_TYPE,
  EXEMPTION_FLAGS,
  INTEGRATION_TYPES,
  INTEGRATION_TYPE,
} from '../../../common/constants';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import { getConfigSection, getEnumText, getEnumKeys } from '../../../common/utils';
import {
  mapValuesArrayToSelectComponentValues,
  addDefaultSelectOptionToSelectValues,
} from '../../common-components/common-functions';
import commonPropTypes from '../../../common/common-prop-types';
import UpcfPaymentOptionsTable from './UpcfPaymentOptionsTable';
import {
  preparaUpcfPaymentOptionsForCreateNewChannel,
  preparePaymentOptionsForUpdateChannel,
} from './channelDataUtils/prepareChannelUpcfPaymentOptions';
import { isPaymentOptionEnabled } from './channelDataUtils/enabledPaymentOptions';

const uiTexts = require('../../../resources/uiTexts.json');
const errorsTexts = require('../../../resources/errorTexts.json');

const errorMessages = getConfigSection(errorsTexts, 'validation');

const CARD_BRANDS_BIN_BLACK_LIST = 'configuration.cardBrandsBinBlacklist';
const IS_STORABLE = 'isStorable';
const IS_ENABLED = 'isEnabled';
const ACTIVE_UPCF_PAYMENT_OPTIONS = 'activeUpcfPaymentOptions';
const INACTIVE_UPCF_PAYMENT_OPTIONS = 'inactiveUpcfPaymentOptions';

export default class FormFields extends React.PureComponent {
  static validationSchema(channelsNames, isCreationFlow) {
    function validateCrossAmount() {
      const { value, operator } = this.parent;

      if (isEmpty(value) === isEmpty(operator)) {
        return true;
      }

      const currentValidatedItem = this.path.split('.').pop();

      return !isEmpty(this.parent[currentValidatedItem]);
    }

    const atLeastOnePayment = {
      name: 'at-least-one-must-be-selected',
      message: errorMessages.mandatoryPaymentOptionsField,
      test: value => {
        if (value.isEnabled && value.isStorable) {
          return value.isEnabled.length > 0 || value.isStorable.length > 0;
        } else if (value.activeUpcfPaymentOptions && value.inactiveUpcfPaymentOptions) {
          const hasActivePaymentOptionsSelcted = value.activeUpcfPaymentOptions.some(isPaymentOptionEnabled);
          const hasInactivePaymentOptionsSelcted = value.inactiveUpcfPaymentOptions.some(isPaymentOptionEnabled);

          return hasActivePaymentOptionsSelcted || hasInactivePaymentOptionsSelcted;
        }

        return true;
      },
    };

    return Yup.object().shape({
      name: Yup.string()
        .test({
          name: 'cross-test-duplicate-name',
          message: errorMessages.duplicateChannelName,
          test: newName => (isCreationFlow ? !channelsNames.includes(newName) : true),
        })
        .required(errorMessages.mandatoryField),
      priority: Yup.number()
        .integer(errorMessages.positiveInteger)
        .positive(errorMessages.positiveInteger)
        .typeError(errorMessages.positiveInteger)
        .required(errorMessages.mandatoryField),
      account: Yup.object().shape({
        programAccountNumber: YupStringOnlyDigits.required(errorMessages.mandatoryField),
        programAccountType: YupStringOnlyDigits.required(errorMessages.mandatoryField),
        merchantAccountNumber: Yup.string()
          .test(YupStringWithoutWhitespaces())
          .required(errorMessages.mandatoryField),
        channelExternalReference: Yup.string().nullable(),
        billingReferenceC1: Yup.string()
          .notRequired()
          .max(127, errorMessages.maxLength.replace('$MAX$', 127)),
      }),
      rule: Yup.object().shape({
        properties: Yup.object({
          amount: Yup.object({
            operator: Yup.string().test({
              name: 'cross-test-amount-operator',
              message: errorMessages.mandatoryField,
              test: validateCrossAmount,
            }),
            value: Yup.string()
              .matches(/^\d+(?:,\d{1,4})?$/, {
                message: errorMessages.positiveWithNDecimals.replace('$DECIMAL_PLACES$', '4'),
                excludeEmptyString: false,
              })
              .test({
                name: 'cross-test-amount-value',
                message: errorMessages.mandatoryField,
                test: validateCrossAmount,
              }),
          }),
        }),
      }),
      configuration: Yup.object().shape({
        paymentOptions: Yup.object()
          .shape({
            isEnabled: Yup.array(),
            isStorable: Yup.array(),
            disabledPayUMethods: Yup.array(),
            activeUpcfPaymentOptions: Yup.array(),
            inactiveUpcfPaymentOptions: Yup.array(),
          })
          .test(atLeastOnePayment),
        autoCapture: Yup.object({
          isEnabled: Yup.boolean(),
          delayDays: YupSpaceFreeNumber.when(IS_ENABLED, {
            is: false,
            then: Yup.number()
              .integer(errorMessages.positiveInteger)
              .positive(errorMessages.positiveInteger)
              .min(1, errorMessages.integerGreaterThan.replace('$MIN$', '0'))
              .max(99, 'The maximum accepted number is 99')
              .typeError(errorMessages.positiveInteger),
            otherwise: Yup.number().nullable(),
          }),
        }),
        autoCancel: Yup.boolean(),
        autoCancelExpirationJobAuth: Yup.boolean(),
        criteria: Yup.string().test({
          name: 'valid-json-array-test',
          message: errorMessages.invalidChannelCriteriaFormat,
          test: function validateCriteria(testedStr) {
            if (isEmpty(testedStr)) {
              return true;
            }
            const items = testedStr.split('\n').map(item => String(item).trim());
            return items.every(
              item => item.match(/^([a-zA-Z0-9_][a-zA-Z0-9_ ]*:[a-zA-Z_./\-@0-9\s]+(\n|$))+$/g) !== null,
            );
          },
        }),
        cardBrandsBinBlacklist: Yup.array()
          .of(
            Yup.object({
              regularExpression: Yup.string(),
              comment: Yup.string(),
            }),
          )
          .default([])
          .test('regularExpresion !== string.empty', 'All rows must be filled with regular expression', value => {
            return value.length === 0 || !value.find(item => !item.regularExpression);
          }),
      }),
    });
  }

  static propTypes = {
    ...commonPropTypes.formFields,
  };

  constructor(props) {
    super(props);

    const { authToken } = this.props;

    this.renderAutoCaptureCheckbox = this.renderAutoCaptureCheckbox.bind(this);
    // The --fix option in eslint always reformats the next two lines, removing
    // the manual fix each time. Therefore rule disabled for this section.
    /* eslint-disable max-len */
    this.renderAutoCaptureDealyDaysField = this.renderAutoCaptureDealyDaysField.bind(this);
    this.renderIsVWPaymentsCheckbox = this.renderIsVWPaymentsCheckbox.bind(this);
    this.renderAutoCancelCheckbox = this.renderAutoCancelCheckbox.bind(this);
    this.renderAutoCancelExpirationJobAuthCheckbox = this.renderAutoCancelExpirationJobAuthCheckbox.bind(this);
    this.renderIs3RICheckbox = this.renderIs3RICheckbox.bind(this);
    this.handleAutoCaptureCheckboxChange = this.handleAutoCaptureCheckboxChange.bind(this);
    this.handleAutoCancelCheckboxChange = this.handleAutoCancelCheckboxChange.bind(this);
    this.handleIs3RICheckboxChange = this.handleIs3RICheckboxChange.bind(this);
    this.handlePaymentOptionsChange = this.handlePaymentOptionsChange.bind(this);
    this.handleIsVWPaymentsCheckboxChange = this.handleIsVWPaymentsCheckboxChange.bind(this);
    this.handleIntegrationTypeChange = this.handleIntegrationTypeChange.bind(this);
    this.updateRefDataState = this.updateRefDataState.bind(this);
    this.renderUpcfPaymentOptionsTable = this.renderUpcfPaymentOptionsTable.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
    /* eslint-enable max-len */

    this.state = {
      channel: {},
      countryList: [],
      currencyList: [],
      customerGroupList: [],
      productGroupList: [],
      upcfRefPaymentOptions: [],
      isLoading: false,
    };

    this.initCardBrandsBinBlacklist();

    this.addNewLine = () => {
      const { formikProps } = this.props;
      const { setFieldValue } = formikProps;
      const { values } = formikProps;
      const cardBrandsBinBlacklist = get(values, CARD_BRANDS_BIN_BLACK_LIST, []);
      const itemsCopy = [...cardBrandsBinBlacklist];

      itemsCopy.push({
        regularExpression: '',
        comment: '',
        id: uniqueId(),
      });

      setFieldValue(CARD_BRANDS_BIN_BLACK_LIST, itemsCopy);
    };

    this.deleteRow = (e, id) => {
      e.preventDefault();
      e.stopPropagation();

      const { formikProps } = this.props;
      const { setFieldValue } = formikProps;
      const { values } = formikProps;
      const cardBrandsBinBlacklist = get(values, CARD_BRANDS_BIN_BLACK_LIST, []);
      const itemsCopy = [...cardBrandsBinBlacklist];
      const indexOfItem = itemsCopy.findIndex(i => i.id === id);

      itemsCopy.splice(indexOfItem, 1);

      setFieldValue(CARD_BRANDS_BIN_BLACK_LIST, itemsCopy);
    };
  }

  render() {
    const { textsKey, authToken, dataStatusLoadCallback, formikProps, customProps } = this.props;
    const channelData = customProps.channelData;
    const { integrationType } = formikProps.values.configuration;
    const { isLoading } = this.state;
    const fieldsTextKey = `${textsKey}.form.fields`;
    const fieldsRenderConfig = this.prepareFieldsRenderConfig();

    const refDataLoadFunctions = [
      this.configurationDataApi.getCountries,
      this.configurationDataApi.getCurrencies,
      this.configurationDataApi.getCustomerGroups,
      this.configurationDataApi.getProductGroups,
    ];

    if (integrationType === INTEGRATION_TYPE.UPCF) {
      refDataLoadFunctions.push(() =>
        Promise.all([
          this.configurationDataApi.getUpcfPaymentOptions(),
          this.configurationDataApi.getChannelById(channelData.id, channelData.sortKey),
        ]).then(([paymentOptions, channel]) => {
          this.props.customProps.onChannelLoaded(paymentOptions);

          this.setState({
            channel: channel,
            upcfRefPaymentOptions: paymentOptions,
            isLoading: false,
          });

          this.initUpcfPaymentOptions();

          return paymentOptions;
        }),
      );
    }

    return (
      <>
        {isLoading && <Spinner center small={false} fullPage />}
        <BaseFormFields
          authToken={authToken}
          fieldsTextKey={fieldsTextKey}
          fieldsRenderConfig={fieldsRenderConfig}
          refDataLoadFunctions={refDataLoadFunctions}
          refDataLoadCallback={this.updateRefDataState}
          dataStatusLoadCallback={dataStatusLoadCallback}
          testIdPrefix="channel-form"
        />
      </>
    );
  }

  initUpcfPaymentOptions() {
    const { formikProps, isUpdateOperation, customProps } = this.props;
    const { setFieldValue } = formikProps;
    const { upcfRefPaymentOptions } = this.state;

    if (upcfRefPaymentOptions.length === 0) {
      return;
    }

    let allUpcfPaymentOptionsForForm = [];

    if (isUpdateOperation) {
      const { channelData } = customProps;
      const { upcfPaymentOptions } = channelData.configuration.paymentOptions;

      allUpcfPaymentOptionsForForm = preparePaymentOptionsForUpdateChannel(upcfPaymentOptions, upcfRefPaymentOptions);
    } else {
      allUpcfPaymentOptionsForForm = preparaUpcfPaymentOptionsForCreateNewChannel(upcfRefPaymentOptions);
    }

    if (allUpcfPaymentOptionsForForm.length > 0) {
      const activePaymentOptions = allUpcfPaymentOptionsForForm.filter(po => !po.deletionDate);
      const inactivePaymentOptions = allUpcfPaymentOptionsForForm.filter(po => po.deletionDate);

      setFieldValue('configuration.paymentOptions.activeUpcfPaymentOptions', activePaymentOptions);
      setFieldValue('configuration.paymentOptions.inactiveUpcfPaymentOptions', inactivePaymentOptions);

      if (this.props.customProps?.onPaymentOptionsPrepared && isUpdateOperation) {
        this.props.customProps.onPaymentOptionsPrepared({
          ...formikProps.values.configuration.paymentOptions,
          activeUpcfPaymentOptions: activePaymentOptions,
          inactiveUpcfPaymentOptions: inactivePaymentOptions,
        });
      }
    }
  }

  initCardBrandsBinBlacklist() {
    const { formikProps } = this.props;
    const { setFieldValue } = formikProps;
    const { values } = formikProps;
    const cardBrandsBinBlacklist = get(values, CARD_BRANDS_BIN_BLACK_LIST, []);
    setFieldValue(
      CARD_BRANDS_BIN_BLACK_LIST,
      cardBrandsBinBlacklist.map(item => ({ ...item, id: uniqueId() })),
    );
  }

  prepareFieldsRenderConfig() {
    const { textsKey, isFormEditable, formikProps, customProps } = this.props;
    const { countryList, currencyList, customerGroupList, productGroupList } = this.state;
    const { integrationType } = formikProps.values.configuration;
    const { disabledPayUMethods, inactiveUpcfPaymentOptions } = formikProps.values.configuration.paymentOptions;
    const isPayUMethodSelected = !!disabledPayUMethods?.length;
    const fieldsTextKey = `${textsKey}.form.fields`;

    const {
      renderGenericInput,
      renderGenericCheckboxGroup,
      renderGenericMultiSelect,
      renderGenericSelect,
      renderGenericTextarea,
    } = BaseFormFields;
    const selectAnOptionText = getConfigSection(uiTexts, 'common.editForm.comboSelectAnOption');
    const criteriaPlaceholderText = getConfigSection(uiTexts, `${fieldsTextKey}.configuration.criteriaPlaceholder`);
    const { isMerchantStoredPaymentsEnabled } = customProps;

    const fieldsRenderConfig = [
      {
        id: 'name',
        function: renderGenericInput('text', isFormEditable),
        useRequiredLabel: true,
      },
      {
        id: 'priority',
        // Type is 'text' due to validation reasons. See comment for
        // similar case for autoCapture.delayDays below for further
        // details
        function: renderGenericInput('text', false),
      },
      {
        id: 'configuration.is3RI',
        hasNoLabel: true,
        function: this.renderIs3RICheckbox,
      },
      {
        id: 'account',
        subItems: [
          {
            id: 'group',
            function: () => {},
          },
          {
            id: 'programCode',
            function: renderGenericInput('text', isFormEditable),
            labelAddOn: (
              <InfoIconText
                text={getConfigSection(uiTexts, 'merchants.channels.form.fields.account.programCodeInfoText')}
                classNameOnItem="mb-0"
                classNameOnText="mb-0"
                testId="programCode-info-text"
              />
            ),
          },
          {
            id: 'programAccountNumber',
            function: renderGenericInput('text', isFormEditable),
            useRequiredLabel: true,
          },
          {
            id: 'programAccountType',
            function: renderGenericInput('text', isFormEditable),
            useRequiredLabel: true,
          },
          {
            id: 'merchantAccountNumber',
            function: renderGenericInput('text', isFormEditable),
            useRequiredLabel: true,
          },
          {
            id: 'channelExternalReference',
            function: renderGenericInput('text', isFormEditable),
          },
          {
            id: 'billingReferenceC1',
            function: renderGenericInput('text', isFormEditable),
            labelAddOn: (
              <InfoIconText
                text={getConfigSection(uiTexts, 'merchants.channels.form.fields.account.billingReferenceC1Info')}
                testId="billingReferenceC1-info-text"
              />
            ),
          },
          {
            id: 'isVWPayments',
            function: this.renderIsVWPaymentsCheckbox,
          },
        ],
      },
      {
        id: 'rule.properties',
        subItems: [
          {
            id: 'group',
            function: () => {},
            labelAddOn: (
              <InfoIconText
                text={getConfigSection(uiTexts, 'merchants.channels.form.fields.rule.properties.groupInfo')}
                testId="group-info-text"
              />
            ),
          },
          {
            id: 'amount',
            subItems: [
              { id: 'group', function: () => {} },
              {
                id: 'operator',
                function: renderGenericSelect(
                  isFormEditable,
                  addDefaultSelectOptionToSelectValues(
                    CHANNEL_RULE_OPERATORS.map(item => ({
                      value: item,
                      label: item,
                    })),
                    '',
                    selectAnOptionText,
                  ),
                ),
              },
              {
                id: 'value',
                function: renderGenericInput('text', isFormEditable),
              },
            ],
          },
          {
            id: 'currencies',
            function: renderGenericMultiSelect(
              isFormEditable,
              selectAnOptionText,
              mapValuesArrayToSelectComponentValues(currencyList, 'name', 'isoCode'),
              formikProps,
            ),
          },
          {
            id: 'shopCountries',
            function: renderGenericMultiSelect(
              isFormEditable,
              selectAnOptionText,
              mapValuesArrayToSelectComponentValues(countryList, 'name', 'isoCode'),
              formikProps,
            ),
          },
          {
            id: 'customerBillingAddressCountries',
            function: renderGenericMultiSelect(
              isFormEditable,
              selectAnOptionText,
              mapValuesArrayToSelectComponentValues(countryList, 'name', 'isoCode'),
              formikProps,
            ),
          },
          {
            id: 'customerGroups',
            function: renderGenericMultiSelect(
              isFormEditable,
              selectAnOptionText,
              mapValuesArrayToSelectComponentValues(customerGroupList, 'name', 'name'),
              formikProps,
            ),
          },
          {
            id: 'productGroups',
            function: renderGenericMultiSelect(
              isFormEditable,
              selectAnOptionText,
              mapValuesArrayToSelectComponentValues(productGroupList, 'name', 'name'),
              formikProps,
            ),
          },
        ],
      },
      {
        id: 'configuration.integrationType',
        function: this.renderIntegrationTypeDropdown.bind(this),
        labelAddOn: (
          <InfoIconText
            text={getConfigSection(uiTexts, 'merchants.channels.form.fields.configuration.integrationTypeInfo')}
            classNameOnItem="mb-0"
            classNameOnText="mb-0"
            testId="integrationType-info-text"
          />
        ),
      },
    ];

    if (integrationType === INTEGRATION_TYPE.LEGACY) {
      fieldsRenderConfig.push(
        {
          id: 'configuration.paymentOptions',
          isValidationRender: true,
          subItems: [
            {
              id: 'activeGroup',
              function: () => {},
              useRequiredLabel: true,
            },
            {
              id: IS_ENABLED,
              function: renderGenericCheckboxGroup(
                'payment-options-enabled-checkbox',
                getEnumKeys(PAYMENT_OPTIONS).map(method => getEnumText(PAYMENT_OPTIONS, method)),
                'code',
                isFormEditable,
                this.handlePaymentOptionsChange(IS_ENABLED),
                disabledPayUMethods,
              ),
              labelAddOn: isPayUMethodSelected && (
                <InfoIconText
                  text={getConfigSection(
                    uiTexts,
                    'merchants.channels.form.fields.configuration.paymentOptions.isPayUActiveInfo',
                  )}
                  testId={`paymentOptions.${IS_ENABLED}-info-text`}
                />
              ),
            },
            {
              id: IS_STORABLE,
              function: renderGenericCheckboxGroup(
                'payment-options-storable-checkbox',
                getEnumKeys(PAYMENT_OPTIONS_STORED).map(method => getEnumText(PAYMENT_OPTIONS, method)),
                'code',
                isFormEditable && isMerchantStoredPaymentsEnabled,
                this.handlePaymentOptionsChange(IS_STORABLE),
              ),
              labelAddOn: isFormEditable && !isMerchantStoredPaymentsEnabled && (
                <InfoIconText
                  text={getConfigSection(
                    uiTexts,
                    'merchants.channels.form.fields.configuration.paymentOptions.isStorableDisabledInfo',
                  )}
                  testId={`paymentOptions.${IS_STORABLE}-info-text`}
                />
              ),
            },
          ],
        },
        {
          id: 'configuration.connectorType',
          isNeedWrap: true,
          function: this.renderConnectorType.bind(this),
          labelAddOn: (
            <InfoIconText
              text={getConfigSection(uiTexts, 'merchants.channels.form.fields.configuration.connectorTypeGroupInfo')}
              testId="connectorType-info-text"
            />
          ),
        },
      );
    } else {
      fieldsRenderConfig.push({
        id: 'configuration.paymentOptions',
        isValidationRender: true,
        subItems: [
          {
            id: 'activeGroup',
            function: () => {},
          },
          {
            id: ACTIVE_UPCF_PAYMENT_OPTIONS,
            function: this.renderUpcfPaymentOptionsTable(ACTIVE_UPCF_PAYMENT_OPTIONS),
          },
        ],
      });
    }

    fieldsRenderConfig.push(
      {
        id: 'configuration.exemptionFlag',
        function: this.renderExemptionFlagDropdown.bind(this),
      },
      {
        id: 'configuration.cardBrandsBinBlacklist',
        isNeedWrap: true,
        isNotRenderValidation: true,
        function: this.renderBlacklistedBINsTable.bind(this),
        labelAddOn: (
          <InfoIconText
            text={getConfigSection(uiTexts, 'merchants.channels.form.fields.configuration.blacklistedBINsInfo')}
            testId="cardBrandsBinBlacklist-info-text"
          />
        ),
      },
      {
        id: 'configuration.autoCapture',
        subItems: [
          { id: 'group', function: () => {} },
          { id: 'isEnabled', function: this.renderAutoCaptureCheckbox },
          {
            id: 'delayDays',
            function: this.renderAutoCaptureDealyDaysField,
          },
          {
            id: 'autoCancel',
            function: this.renderAutoCancelCheckbox,
            customName: 'configuration.autoCancel',
          },
          {
            id: 'autoCancelExpirationJobAuth',
            function: this.renderAutoCancelExpirationJobAuthCheckbox,
            customName: 'configuration.autoCancelExpirationJobAuth',
          },
        ],
      },
      {
        id: 'configuration.criteria',
        function: renderGenericTextarea(isFormEditable, 10, 20, criteriaPlaceholderText),
      },
    );

    if (integrationType === INTEGRATION_TYPE.UPCF && inactiveUpcfPaymentOptions?.length > 0) {
      fieldsRenderConfig.push({
        id: 'configuration.paymentOptions',
        isValidationRender: true,
        subItems: [
          {
            id: 'inactiveGroup',
            function: () => {},
          },
          {
            id: INACTIVE_UPCF_PAYMENT_OPTIONS,
            function: this.renderUpcfPaymentOptionsTable(INACTIVE_UPCF_PAYMENT_OPTIONS),
          },
        ],
      });
    }

    return fieldsRenderConfig;
  }

  renderAutoCaptureCheckbox(props) {
    const { isFormEditable } = this.props;
    const { renderGenericCheckbox } = BaseFormFields;
    const checkboxEnabledText = getConfigSection(
      uiTexts,
      'merchants.channels.form.fields.configuration.autoCapture.isEnabled',
    );

    return renderGenericCheckbox(isFormEditable, checkboxEnabledText, {
      onChange: this.handleAutoCaptureCheckboxChange(props.value),
    })(props);
  }

  renderAutoCancelCheckbox(props) {
    const { formikProps, isFormEditable } = this.props;
    const { values } = formikProps;
    const { renderGenericCheckbox } = BaseFormFields;
    const checkboxEnabledText = getConfigSection(
      uiTexts,
      'merchants.channels.form.fields.configuration.autoCapture.autoCancelLabel',
    );
    const isEnabled = isFormEditable && get(values, 'configuration.autoCapture.isEnabled', false);

    let checked = get(values, 'configuration.autoCancel', false);

    if (!isEnabled) {
      values.configuration.autoCancel = false;
      checked = false;
    }

    return renderGenericCheckbox(isEnabled, checkboxEnabledText, {
      onChange: this.handleAutoCancelCheckboxChange(props.value),
    })({ ...props, checked });
  }

  renderAutoCancelExpirationJobAuthCheckbox(props) {
    const { formikProps, isFormEditable } = this.props;
    const { values } = formikProps;
    const { renderGenericCheckbox } = BaseFormFields;
    const checkboxEnabledText = getConfigSection(
      uiTexts,
      'merchants.channels.form.fields.configuration.autoCapture.autoCancelExpirationJobAuthLabel',
    );
    const isEnabled = isFormEditable || true;

    let checked = get(values, 'configuration.autoCancelExpirationJobAuth', false);

    if (!isEnabled) {
      values.configuration.autoCancelExpirationJobAuth = false;
      checked = false;
    }

    return renderGenericCheckbox(isEnabled, checkboxEnabledText, {
      onChange: this.handleAutoCancelExpirationJobAuthCheckboxChange(props.value),
    })({ ...props, checked });
  }

  renderIs3RICheckbox(props) {
    const { isFormEditable, textsKey } = this.props;
    const { renderGenericCheckbox } = BaseFormFields;
    const checkboxEnabledText = getConfigSection(uiTexts, `${textsKey}.form.fields.configuration.is3RI`);

    return (
      <>
        {renderGenericCheckbox(isFormEditable, checkboxEnabledText, {
          onChange: this.handleIs3RICheckboxChange(props.value),
        })(props)}

        <InfoIconText
          text={getConfigSection(uiTexts, 'merchants.channels.form.fields.configuration.3RIInfo')}
          testId="is3RI-info-text"
        />
      </>
    );
  }

  renderIsVWPaymentsCheckbox(props) {
    const { isFormEditable } = this.props;
    const { renderGenericCheckbox } = BaseFormFields;
    const checkboxEnabledText = getConfigSection(uiTexts, 'common.editForm.checkboxEnable');
    return renderGenericCheckbox(isFormEditable, checkboxEnabledText, {
      onChange: this.handleIsVWPaymentsCheckboxChange(props.value),
    })(props);
  }

  renderAutoCaptureDealyDaysField(fieldProps) {
    const { formikProps, isFormEditable } = this.props;
    const { values } = formikProps;
    const enabled = isFormEditable && !get(values, 'configuration.autoCapture.isEnabled', true);
    const { renderGenericInput } = BaseFormFields;
    // We are forced to use type="text" even for numerical values due
    // to limitations in the value returned by the input component when
    // making validations: if we use type=number then yup receives always
    // an undefined in case the field is empty or a non-numerical value
    // in provided by the user. This is not acceptable since we should
    // show different error messages in each situation
    return renderGenericInput('text', enabled)(fieldProps);
  }

  handleAutoCaptureCheckboxChange(isEnabled) {
    return () => {
      const { formikProps } = this.props;
      const { setFieldValue } = formikProps;

      setFieldValue('configuration.autoCapture.isEnabled', !isEnabled);
      if (!isEnabled) {
        setFieldValue('configuration.autoCancel', isEnabled);
        setFieldValue('configuration.autoCapture.delayDays', '');
      }
    };
  }

  handleIs3RICheckboxChange(isEnabled) {
    return () => {
      this.props.formikProps.setFieldValue('configuration.is3RI', !isEnabled);
    };
  }

  handleAutoCancelCheckboxChange(isEnabled) {
    return () => {
      const { formikProps } = this.props;
      const { setFieldValue } = formikProps;
      setFieldValue('configuration.autoCancel', !isEnabled);
    };
  }

  handleAutoCancelExpirationJobAuthCheckboxChange(isEnabled) {
    return () => {
      const { formikProps } = this.props;
      const { setFieldValue } = formikProps;
      setFieldValue('configuration.autoCancelExpirationJobAuth', !isEnabled);
    };
  }

  handleIsVWPaymentsCheckboxChange(isEnabled) {
    return () => {
      const { formikProps } = this.props;
      const { setFieldValue } = formikProps;
      setFieldValue('account.isVWPayments', !isEnabled);
    };
  }

  handlePaymentOptionsChange(paymentOptionsFieldName) {
    return method => () => {
      const { formikProps } = this.props;
      const { setFieldValue, values } = formikProps;
      let paymentOptions = [...values.configuration.paymentOptions[paymentOptionsFieldName]];

      if (paymentOptions.find(po => po.code === method)) {
        paymentOptions = paymentOptions.filter(po => po.code !== method);
      } else {
        paymentOptions.push({
          code: method,
        });
      }

      if (paymentOptions.find(po => po.code === PAYMENT_OPTIONS_NAMES.PAYU)) {
        setFieldValue('configuration.paymentOptions.disabledPayUMethods', [
          PAYMENT_OPTIONS_NAMES.PAYUBNK,
          PAYMENT_OPTIONS_NAMES.PAYUBLK,
        ]);
      } else if (
        paymentOptions.find(
          po => po.code === PAYMENT_OPTIONS_NAMES.PAYUBNK || po.code === PAYMENT_OPTIONS_NAMES.PAYUBLK,
        )
      ) {
        setFieldValue('configuration.paymentOptions.disabledPayUMethods', [PAYMENT_OPTIONS_NAMES.PAYU]);
      } else {
        setFieldValue('configuration.paymentOptions.disabledPayUMethods', []);
      }

      setFieldValue(`configuration.paymentOptions.${paymentOptionsFieldName}`, paymentOptions);
    };
  }

  handleIntegrationTypeChange() {
    return async event => {
      const { formikProps } = this.props;
      const { values, setValues } = formikProps;
      const { value } = event.target;

      const formValues = {
        ...values,
        configuration: {
          ...values.configuration,
          integrationType: value,
          connectorType: PCI_TYPE.PAYON,
        },
      };

      if (value === INTEGRATION_TYPE.LEGACY) {
        formValues.configuration.paymentOptions = {
          isEnabled: [],
          isStorable: [],
        };

        setValues(formValues);
      } else {
        this.setState({
          isLoading: true,
        });

        const paymentOptions = await this.configurationDataApi.getUpcfPaymentOptions();

        this.setState({
          upcfRefPaymentOptions: paymentOptions,
          isLoading: false,
        });

        formValues.configuration.paymentOptions = {
          activeUpcfPaymentOptions: [],
          inactiveUpcfPaymentOptions: [],
        };

        setValues(formValues);

        this.initUpcfPaymentOptions();
      }
    };
  }

  renderIntegrationTypeDropdown(props) {
    const { isFormEditable, textsKey, isUpdateOperation } = this.props;
    const { renderGenericSelect } = BaseFormFields;
    const enabled = isFormEditable && !isUpdateOperation;

    return renderGenericSelect(
      enabled,
      INTEGRATION_TYPES.map(item => {
        return {
          value: item,
          label: getConfigSection(uiTexts, `${textsKey}.types.integrationTypes.${item.toLowerCase()}`),
        };
      }),
      {
        onChange: this.handleIntegrationTypeChange(props),
      },
    )(props);
  }

  handlePaymentOptionEnabledChange(paymentOptionsType) {
    return (paymentOption, index, isChecked) => {
      const { formikProps } = this.props;
      const { setFieldValue } = formikProps;
      const {
        isGuest,
        isCitStorable,
        isMitStorable,
        isStoredCitPaymentsAllowed,
        isStoredMitPaymentsAllowed,
      } = paymentOption;

      const keyPrefix = `configuration.paymentOptions.${paymentOptionsType}.${index}`;

      setFieldValue(`${keyPrefix}.isGuest.value`, isGuest.enabled && !isChecked);
      setFieldValue(`${keyPrefix}.isCitStorable.value`, isCitStorable.enabled && !isChecked);
      setFieldValue(`${keyPrefix}.isMitStorable.value`, isMitStorable.enabled && !isChecked);
      setFieldValue(`${keyPrefix}.isStoredCitPaymentsAllowed.value`, isStoredCitPaymentsAllowed.enabled && !isChecked);
      setFieldValue(`${keyPrefix}.isStoredMitPaymentsAllowed.value`, isStoredMitPaymentsAllowed.enabled && !isChecked);
    };
  }

  handlePaymentOptionDisplayOptionChange(paymentOptionsType) {
    return (index, key, isChecked) => {
      const { formikProps } = this.props;
      const { setFieldValue } = formikProps;

      setFieldValue(`configuration.paymentOptions.${paymentOptionsType}.${index}.${key}.value`, !isChecked);
    };
  }

  renderUpcfPaymentOptionsTable(paymentOptionsType) {
    const { formikProps, isFormEditable, isUpdateOperation } = this.props;
    const { values } = formikProps;
    const enabled = isFormEditable && !isUpdateOperation;
    const paymentOptions = get(values, `configuration.paymentOptions.${paymentOptionsType}`);

    return props => (
      <UpcfPaymentOptionsTable
        enabled={enabled}
        paymentOptions={paymentOptions}
        handlePaymentOptionEnabledChange={this.handlePaymentOptionEnabledChange(paymentOptionsType).bind(this)}
        handlePaymentOptionDisplayOptionChange={this.handlePaymentOptionDisplayOptionChange(paymentOptionsType).bind(
          this,
        )}
        displayRuleExpressions={this.state.channel.configuration?.displayRuleExpressions}
        testIdPrefix="channel-form"
        {...props}
      ></UpcfPaymentOptionsTable>
    );
  }

  renderConnectorType(fieldProps) {
    const { isFormEditable, isUpdateOperation, textsKey, formikProps } = this.props;
    const { values } = formikProps;
    const { renderRadioButtonGroup } = BaseFormFields;
    const enabled = isFormEditable && !isUpdateOperation;

    const connectorType = get(values, 'configuration.connectorType', PCI_TYPE.PAYON);

    const items = PCI_TYPES.map(type => ({
      value: type,
      display: getConfigSection(uiTexts, `${textsKey}.types.${type.toLowerCase()}`),
      checked: type === connectorType,
    }));

    return renderRadioButtonGroup(items, enabled, formikProps, false)(fieldProps);
  }

  // eslint-disable-next-line class-methods-use-this
  isWithProxy(values) {
    return get(values, 'configuration.connectorType') === PCI_TYPE.PAYON_WITH_PCI_PROXY;
  }

  renderExemptionFlagDropdown(props) {
    const { formikProps, isFormEditable, textsKey } = this.props;
    const { values } = formikProps;
    const { renderGenericSelect } = BaseFormFields;
    const enabled = isFormEditable && this.isWithProxy(values);

    return renderGenericSelect(
      enabled,
      EXEMPTION_FLAGS.map(item => {
        return {
          value: item,
          label: getConfigSection(uiTexts, `${textsKey}.types.exemptions.${item.toLowerCase()}`),
        };
      }),
    )(props);
  }

  renderBlacklistedBINsTable(props) {
    const { formikProps, isFormEditable, textsKey } = this.props;
    const { values } = formikProps;
    const headers = [
      '',
      getConfigSection(uiTexts, `${textsKey}.form.headers.regularExpression`),
      getConfigSection(uiTexts, `${textsKey}.form.headers.comment`),
    ];
    const enabled = isFormEditable && this.isWithProxy(values);
    const { renderBinListTable } = BaseFormFields;
    const cardBrandsBinBlacklist = get(values, CARD_BRANDS_BIN_BLACK_LIST, []);

    let isDisabledAddButton = false;

    if (cardBrandsBinBlacklist.find(i => i.regularExpression === '')) {
      isDisabledAddButton = true;
    }

    return renderBinListTable(
      cardBrandsBinBlacklist,
      this.onSortEnd,
      this.addNewLine,
      this.deleteRow,
      enabled,
      headers,
      CARD_BRANDS_BIN_BLACK_LIST,
      isDisabledAddButton,
    )(props);
  }

  updateRefDataState(refDataArray) {
    this.setState({
      countryList: refDataArray[0],
      currencyList: refDataArray[1],
      customerGroupList: refDataArray[2],
      productGroupList: refDataArray[3],
      upcfRefPaymentOptions: refDataArray[4] || [],
    });
  }
}
