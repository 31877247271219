import { get } from '@turbopay/ts-helpers/object-utils';
import moment from 'moment';
import { FILE_TYPES, IMAGE_DIMENSION_COMPARATORS } from '../common/constants';

const uiTexts = require('../resources/uiTexts.json');
const config = require('../resources/config.json');

export const getConfigSection = (obj, path, def) => get(obj, path, typeof def === 'undefiend' ? {} : def);

function fromConstantSyntaxToCamelCase(constantSyntaxStr) {
  const regExp = new RegExp(/(_.?)/, 'g');
  return constantSyntaxStr.toLowerCase().replace(regExp, match => match.replace('_', '').toUpperCase());
}

function fromCamelCaseToConstantSyntax(camelCaseStr) {
  const regExp = new RegExp(/([A-Z].?)/, 'g');
  return camelCaseStr.replace(regExp, match => `_${match}`.toUpperCase()).toUpperCase();
}

export function getEnumKeys(enumObj) {
  return Object.keys(enumObj).filter(key => typeof enumObj[key] === 'string');
}

export function getEnumValues(enumObj) {
  return Object.values(enumObj).filter(value => typeof value === 'string');
}

export function getEnumText(enumObj, enumValue, texts = uiTexts) {
  const enumKey = fromConstantSyntaxToCamelCase(enumObj.getName());
  const valueKey = fromConstantSyntaxToCamelCase(enumValue);

  return getConfigSection(texts, `common.enums.${enumKey}.${valueKey}`);
}

export function getEnumValueFromText(enumObj, enumText, texts = uiTexts) {
  const enumKey = fromConstantSyntaxToCamelCase(enumObj.getName());
  const textKeyObj = getConfigSection(texts, `common.enums.${enumKey}`);
  const valueKey = Object.keys(textKeyObj).find(key => textKeyObj[key] === enumText);
  return enumObj[fromCamelCaseToConstantSyntax(valueKey)];
}

export function getScreenTextKey(routerPath, configuration = config) {
  const paths = getConfigSection(configuration, 'ui.mainContainer.menu');
  const idx = Object.keys(paths).findIndex(item => paths[item].link === routerPath);

  return idx >= 0 ? Object.keys(paths)[idx] : undefined;
}

export function getAppBaseUrl(processEnv, configuration = config) {
  const { REACT_APP_STAGE } = processEnv;

  if (REACT_APP_STAGE === 'prod') {
    return configuration.app.url.replace('$STAGE$', '');
  }
  if (REACT_APP_STAGE === 'cons' || REACT_APP_STAGE === 'int') {
    return configuration.app.url.replace('$STAGE$', `.${REACT_APP_STAGE}`);
  }
  if (REACT_APP_STAGE === 'dev') {
    return 'https://adminportal.dev.smartpay.jpmmps.com';
  }

  return 'https://adminportal.local.smartpay.jpmmps.com:3000';
}

export const parseStringToDecimal = str => (str ? parseFloat(str.replace(',', '.')) : undefined);

export const parseDecimalToString = decimal =>
  decimal || decimal === 0 ? decimal.toString().replace('.', ',') : undefined;

const transformBytesToMegabytes = bytes => bytes / 1024 / 1024;

export const checkFileSize = (file, fileSizeLimit) => {
  let isValid = true;
  if (file) {
    const size = transformBytesToMegabytes(file.size);
    isValid = size <= fileSizeLimit;
  }
  return isValid;
};

export const checkFileType = (file, typesPermitted) => {
  let isValid = true;

  if (file) {
    isValid = typesPermitted.includes(file.type);
  }

  return isValid;
};

export const checkImageDimensions = async (file, width, height, comparator = 'lte') => {
  // we checkFileType here again, because even if we have a yup test before this, that checks it and return false, we still proceed to check dimensions, and we get error if the file is invalid
  let isValid = checkFileType(file, [FILE_TYPES.JPG, FILE_TYPES.JPEG, FILE_TYPES.PNG]);

  if (file && isValid) {
    const URL = window.URL || window.webkitURL;
    const img = new Image();
    const objectUrl = URL.createObjectURL(file);

    img.onload = function() {
      if (comparator === IMAGE_DIMENSION_COMPARATORS.GREATER_THAN) {
        isValid = this.width >= width && this.height >= height;
      } else if (comparator === IMAGE_DIMENSION_COMPARATORS.LESS_THAN) {
        isValid = this.width <= width && this.height <= height;
      } else {
        isValid = this.width === width && this.height === height;
      }

      URL.revokeObjectURL(objectUrl);
    };

    img.src = objectUrl;
    await img.decode();
  }

  return isValid;
};

export const checkImageAspectRatio = async (file, aspectRatio) => {
  let isValid = true;

  if (file) {
    const aspectRatioWidth = +aspectRatio[0];
    const aspectRatioHeight = +aspectRatio[aspectRatio.length - 1];

    const URL = window.URL || window.webkitURL;
    const img = new Image();
    const objectUrl = URL.createObjectURL(file);

    img.onload = function() {
      if (this.width / this.height !== aspectRatioWidth / aspectRatioHeight) {
        isValid = false;
      }

      URL.revokeObjectURL(objectUrl);
    };

    img.src = objectUrl;
    await img.decode();
  }

  return isValid;
};

export const convertFileToBase64 = file => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = error => {
      reject(error);
    };
  });
};

export const getSecondsFromTime = time => {
  const timeObject = moment(time, 'HH:mm:ss');
  const seconds = timeObject.seconds();
  const minutes = timeObject.minutes();
  const hours = timeObject.hours();
  return seconds + minutes * 60 + hours * 3600;
};

export const lowerCaseStrings = o => {
  if (typeof o === 'string') {
    return o.toLowerCase();
  }
  return o;
};

export const removeEmptyValuesFromObject = obj => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      var value = obj[key];
      if (value === null || value === undefined || value === '') {
        delete obj[key];
      }
    }
  }

  return obj || {};
};

export const pipeOr = (...fns) => {
  return formValues => {
    return fns.reduce((acc, curr) => {
      return acc || curr(formValues);
    }, false);
  };
};

export const pipeAnd = (...fns) => {
  return formValues => {
    return fns.reduce((acc, curr) => {
      return acc && curr(formValues);
    }, true);
  };
};

export const textsArrayToString = texts => {
  return Array.isArray(texts) ? texts.join(', ') : texts;
};

export function reconcile(val) {
  if (val === '') {
    return undefined;
  }
  if (val === undefined) {
    return undefined;
  }
  return val;
}

// Testing utils; helps with debugging
// by simulating api errors
export const mockApiError = (orig, switchOn = true) => {
  return (...args) => {
    if (!switchOn) {
      return orig(...args);
    }
    return Promise.reject({
      response: {
        data: {
          message: 'Mocked Error',
        },
      },
    });
  };
};
