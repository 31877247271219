import React from 'react';
import * as Yup from 'yup';
import {
  getConfigSection,
  checkFileType,
  checkFileSize,
  checkImageAspectRatio,
  checkImageDimensions,
} from '../../../common/utils';
import { FILE_TYPES, IMAGE_ASPECT_RATIOS, IMAGE_DIMENSION_COMPARATORS } from '../../../common/constants';
import BaseFormFields from '../../common-components/BaseFormFields';
import InfoIconText from '../../common-components/InfoIconText';
import commonPropTypes from '../../../common/common-prop-types';
import { ModalConfirmOperation } from '../../common-components/ModalWindow';
import { RequiredFieldsTip } from '../../common-components/RequiredFieldsTip';
import { WIDGET_HEADER_IMAGE_POSITION } from '../../../common/constants';

const uiTexts = require('../../../resources/uiTexts.json');

export class WidgetConfigurationFormFields extends React.PureComponent {
  static validationSchema() {
    return Yup.object().shape({
      footerImage: Yup.mixed()
        .notRequired()
        .test('Image type', 'Image should be of type jpeg or png', file =>
          checkFileType(file, [FILE_TYPES.JPG, FILE_TYPES.JPEG, FILE_TYPES.PNG]),
        )
        .test('Image size', 'Image is too large', file => checkFileSize(file, 2))
        .test('Image aspect ratio', `The aspect ratio must be ${IMAGE_ASPECT_RATIOS['4:1']}`, file =>
          checkImageAspectRatio(file, IMAGE_ASPECT_RATIOS['4:1']).then(result => result),
        ),
      headerImageData: Yup.object({
        headerImage: Yup.mixed()
          .notRequired()
          .test('Image type', 'Image should be of type jpeg or png', file =>
            checkFileType(file, [FILE_TYPES.JPG, FILE_TYPES.JPEG, FILE_TYPES.PNG]),
          )
          .test('Image size', 'Image is too large', file => checkFileSize(file, 2))
          .test('Image dimensions', `Image should have 75px height and 250px`, file =>
            checkImageDimensions(file, 250, 75, IMAGE_DIMENSION_COMPARATORS.EQUAL).then(result => result),
          ),
        headerImagePosition: Yup.string()
          .required()
          .oneOf(WIDGET_HEADER_IMAGE_POSITION.map(x => x.value)),
      }),
    });
  }

  static propTypes = {
    ...commonPropTypes.formFields,
  };

  constructor(props) {
    super(props);

    this.state = {
      showWarning: false,
      imageReference: null,
    };
    this.chooseHeaderImageRef = React.createRef();
    this.chooseFooterImageRef = React.createRef();
  }

  render() {
    const { customProps, authToken, textsKey, isFormEditable, formikProps } = this.props;
    const { footerImageURL, headerImageURL } = customProps;
    const fieldsTextKey = `${textsKey}.form.fields`;
    const { renderWarningUpload, renderGenericSelect, renderUpload } = BaseFormFields;

    const { showWarning } = this.state;
    const texts = this.props.uiTexts || uiTexts;

    const isHeaderPositionEditable =
      isFormEditable &&
      (headerImageURL || (this.chooseHeaderImageRef.current && this.chooseHeaderImageRef.current.files.length));

    const fieldsRenderConfig = [
      {
        id: 'headerImageData',
        subItems: [
          {
            id: 'headerImage',
            labelAddOn: (
              <InfoIconText
                text={getConfigSection(texts, `${textsKey}.form.options.headerImage`)}
                testId="headerImage-info-text"
              />
            ),
            function: headerImageURL
              ? renderWarningUpload(
                  formikProps,
                  isFormEditable,
                  showWarning,
                  this.setShowWarning.bind(this, true, this.chooseHeaderImageRef),
                  this.chooseHeaderImageRef,
                  headerImageURL,
                  texts.merchants.widgetConfiguration.form.options.uploadedImage,
                )
              : renderUpload(
                  formikProps,
                  isFormEditable,
                  this.chooseHeaderImageRef,
                  headerImageURL,
                  texts.merchants.widgetConfiguration.form.options.uploadedImage,
                ),
          },
          {
            id: 'headerImagePosition',
            useRequiredLabel: isHeaderPositionEditable,
            function: renderGenericSelect(isHeaderPositionEditable, WIDGET_HEADER_IMAGE_POSITION),
          },
        ],
      },
      {
        id: 'footerImage',
        labelAddOn: (
          <InfoIconText
            text={getConfigSection(texts, `${textsKey}.form.options.footerImage`)}
            testId="footerImage-info-text"
          />
        ),
        function: footerImageURL
          ? renderWarningUpload(
              formikProps,
              isFormEditable,
              showWarning,
              this.setShowWarning.bind(this, true, this.chooseFooterImageRef),
              this.chooseFooterImageRef,
              footerImageURL,
              texts.merchants.widgetConfiguration.form.options.uploadedImage,
            )
          : renderUpload(
              formikProps,
              isFormEditable,
              this.chooseFooterImageRef,
              footerImageURL,
              texts.merchants.widgetConfiguration.form.options.uploadedImage,
            ),
        isNeedWrap: true,
      },
    ];

    return (
      <>
        {showWarning && <>{this.renderWarningDialog()}</>}
        <RequiredFieldsTip />
        <BaseFormFields
          authToken={authToken}
          fieldsTextKey={fieldsTextKey}
          fieldsRenderConfig={fieldsRenderConfig}
          testIdPrefix="widget-config-form"
        />
      </>
    );
  }

  renderWarningDialog() {
    const texts = this.props.uiTexts || uiTexts;
    const { imageReference } = this.state;

    const warningTexts = getConfigSection(texts, 'common.table.dialogs.warning');
    const { header, buttonOk, buttonCancel } = warningTexts;
    const { textsKey } = this.props;
    const message = getConfigSection(texts, `${textsKey}.form.options.changeWidgetFooterImageWarning`);
    return (
      <>
        <ModalConfirmOperation
          title={header}
          buttonConfirmText={buttonOk}
          onConfirm={() => {
            imageReference.current.click();
            this.setShowWarning(false);
          }}
          buttonCancelText={buttonCancel}
          onCancel={() => this.setShowWarning(false)}
          onClose={() => this.setShowWarning(false)}
          message={message}
        />
      </>
    );
  }

  setShowWarning(value, ref) {
    this.setState({ showWarning: value, imageReference: ref });
  }
}
