import React from 'react';
import { get, set, uniqueId } from '@turbopay/ts-helpers/object-utils';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import IntegrationEditForm from './EditForm';
import EnhancedForm from '../../common-components/EnhancedForm';
import uiTexts from '../../../resources/uiTexts.json';
import { getConfigSection } from '../../../common/utils';

export default class Form extends React.Component {
  // eslint-disable-next-line react/sort-comp
  revertDataTransformation(merchant) {
    const { credentials, gatekeeperSessions, authorizationRetryPolicy, storedPayments } = merchant;

    const authorizationIntervalsConfiguration = get(merchant, 'authorizationRetryPolicy.intervalsConfiguration');

    if (authorizationIntervalsConfiguration) {
      // eslint-disable-next-line max-len
      authorizationRetryPolicy.intervalsConfiguration = authorizationIntervalsConfiguration;
    }

    const authorizationRetryPolicyTimeConfiguration = get(merchant, 'authorizationRetryPolicy.timeConfiguration');

    if (authorizationRetryPolicyTimeConfiguration) {
      // eslint-disable-next-line max-len
      authorizationRetryPolicy.timeConfiguration = {
        numberOfRetries: authorizationRetryPolicyTimeConfiguration.numberOfRetries,
        retryTime: authorizationRetryPolicyTimeConfiguration.retryTime,
      };
    }

    return Object.assign({}, merchant, {
      ...credentials,
      authorizationRetryPolicy,
      fspayProgramCode: storedPayments.isEnabled ? storedPayments.fspayProgramCode : undefined,
      gatekeeperSessions: parseInt(gatekeeperSessions, 10),
    });
  }

  // eslint-disable-next-line class-methods-use-this
  static setTimeFromSeconds(secondsFromServer) {
    if (typeof secondsFromServer === 'string' || secondsFromServer instanceof String) {
      return secondsFromServer;
    }

    let hour = Math.floor(secondsFromServer / 3600);
    let min = Math.floor((secondsFromServer - hour * 3600) / 60);
    let sec = secondsFromServer - hour * 3600 - min * 60;

    hour = (hour < 10 ? '0' : '') + hour;
    min = (min < 10 ? '0' : '') + min;
    sec = (sec < 10 ? '0' : '') + sec;

    return `${hour}:${min}:${sec}`;
  }

  constructor(props) {
    super(props);
    const { authToken } = this.props;

    this.modifyIntegration = this.modifyIntegration.bind(this);

    this.state = {
      successMessage: false,
    };

    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
  }

  render() {
    const { authToken, textsKey, isFormEditable, navigationFunction, onBack, merchantData } = this.props;

    const currentMerchantData = merchantData;
    const textsKeyIntegration = `${textsKey}.integration`;

    const authorizationIntervalsConfiguration = get(
      currentMerchantData,
      'authorizationRetryPolicy.intervalsConfiguration',
    );

    if (authorizationIntervalsConfiguration) {
      // eslint-disable-next-line max-len
      currentMerchantData.authorizationRetryPolicy.intervalsConfiguration = authorizationIntervalsConfiguration.map(
        item => ({
          retryNumber: item.retryNumber,
          intervalBetweenRetriesSec: item.intervalBetweenRetriesSec,
          contentEditable: React.createRef(),
          id: uniqueId(),
        }),
      );
    }

    const authorizationRetryPolicyTimeConfiguration = get(
      currentMerchantData,
      'authorizationRetryPolicy.timeConfiguration',
    );

    if (authorizationRetryPolicyTimeConfiguration) {
      currentMerchantData.authorizationRetryPolicy.timeConfiguration = {
        retryTime: authorizationRetryPolicyTimeConfiguration.retryTime,
        numberOfRetries: authorizationRetryPolicyTimeConfiguration.numberOfRetries,
      };
    }

    return (
      <>
        {this.state.successMessage && this.renderSuccessMessage()}
        <EnhancedForm
          data={currentMerchantData}
          authToken={authToken}
          textsKey={textsKeyIntegration}
          editComponent={{
            type: IntegrationEditForm,
          }}
          dataFunctions={{
            modifyItem: this.modifyIntegration,
          }}
          onBack={onBack}
          isVisible
          isFormEditable={isFormEditable}
          navigationFunction={navigationFunction}
        />
      </>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  setIfExist(object, key, value) {
    if (get(object, key)) {
      set(object, key, value);
    }
  }

  async modifyIntegration(merchant) {
    const { updateData } = this.props;
    const updatedMerchant = await this.configurationDataApi.updateMerchant(this.revertDataTransformation(merchant));

    updateData(updatedMerchant);

    this.setState({
      ...this.state,
      successMessage: true,
    });

    return updatedMerchant;
  }

  renderSuccessMessage() {
    const text = getConfigSection(uiTexts, 'common.successMessage');
    return <p className="merchant-changes-success-message" data-testid="success-message">{text}</p>;
  }
}
