import React from 'react';
import { withRouter } from 'react-router-dom';
import commonPropTypes from '../../../common/common-prop-types';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import CommonTable from '../../common-components/CommonTable';

class UserGroupsAccessTable extends React.Component {
  static propTypes = {
    ...commonPropTypes.table,
  };

  constructor(props) {
    super(props);
    const { authToken } = this.props;

    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
  }

  render() {
    const { authToken, textsKey, data } = this.props;
    return (
      Object.keys(data).length !== 0 && (
        <CommonTable
          authToken={authToken}
          textsKey={textsKey}
          dataFunctions={{
            loadData: this.handleLoadUserAccessData,
            deleteItem: this.handleDeleteUserAccess,
          }}
          creationPath="createGroupAccess"
          handleEditRedirect={this.handleEditRedirect}
        />
      )
    );
  }

  handleLoadUserAccessData = async () => {
    const { data } = this.props;
    const groups = await this.configurationDataApi.getUserGroupsAccess(data.username);

    return groups.groups;
  };

  handleDeleteUserAccess = group => {
    const { data } = this.props;
    return this.configurationDataApi.deleteUserGroupsAccess(data.username, group.groupId);
  };

  handleEditRedirect = group => {
    const { location } = this.props;
    this.props.history.push(`${location.pathname}/editGroupAccess/${group.groupId}`);
  };
}

export default withRouter(UserGroupsAccessTable);
