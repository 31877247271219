import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'cj-common-components';
import { getConfigSection } from '../../../common/utils';
import InfoIconText from '../../common-components/InfoIconText';

const uiTexts = require('../../../resources/uiTexts.json');

export default class EnabledCulturesHeader extends React.PureComponent {
  static propTypes = {
    textsKey: PropTypes.string.isRequired,
    merchant: PropTypes.shape({
      culturesEnabled: PropTypes.object,
      merchantKey: PropTypes.string,
      merchantName: PropTypes.string,
      requestTime: PropTypes.string,
    }),
    loaded: PropTypes.bool,
  };

  render() {
    const { textsKey, merchant, loaded } = this.props;
    this.textsKeyIntegration = textsKey;

    return (
      <>
        <div
          className="c-form-section  js-form-section js-is-fx c-form-section--success"
          data-testid="enabled-cultures-container"
        >
          <div className="c-form-section__content">
            <div className="c-form-field">
              <h4 className="u-mb" data-testid="enabled-cultures-header">
                {this.getText('group')}
              </h4>
            </div>
            {!loaded && <Spinner section></Spinner>}
            {loaded && (
              <>
                <div className="c-form-field">
                  <label className="c-form-field__label" data-testid="last-updated-label">
                    {this.getText('lastUpdated')}:&nbsp;
                    <b>{merchant.lastUpdateDateTime}</b>
                  </label>
                </div>
                <div className="c-form-field">
                  <InfoIconText
                    text={getConfigSection(
                      uiTexts,
                      `${this.textsKeyIntegration}.form.fields.enabledCultures.informationTooltip`,
                    )}
                    testId="enabledCultures-info-text"
                  />
                </div>
                <div className="c-form-field">
                  <label className="c-form-field__label" data-testid="fallback-culture-label">
                    {this.getText('fallbackCulture')}:&nbsp;
                    <b>{this.getText('defaultCultureValue')}</b>
                  </label>
                </div>
                <div className="c-form-field">
                  <label className="c-form-field__label" data-testid="enabled-cultures-label">
                    {this.getText('enabledCultures')}:&nbsp;
                  </label>
                  {Object.keys(merchant.culturesEnabled).map((culture, key, merchantArray) => (
                    <b key={`culture-${culture}`} data-testid="enabled-culture-item">
                      {culture}
                      {/* eslint-disable-next-line no-negated-condition */}
                      {merchantArray.length - 1 !== key ? ', ' : ''}
                    </b>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }

  getText(key) {
    return getConfigSection(uiTexts, `${this.textsKeyIntegration}.form.fields.enabledCultures.${key}`);
  }
}
