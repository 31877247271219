import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Icon } from '../../Atoms/Icon/Icon'
import { Heading } from '../../Atoms/Heading/Heading'
import { Paragraph } from '../../Atoms/Paragraph/Paragraph'
import { Link } from '../../Atoms/Link/Link'
import { InfoIcon } from '../../Atoms/InfoIcon/InfoIcon'

export function ItemTeaser({
  testId,
  className,
  children,
  icon,
  smallIcon,
  title,
  titleHint,
  link,
  linkText,
  inline,
  inlineSmallScreen,
}) {
  const componentItemTeaserClass = classNames(
    {
      'c-item-teaser  ': true,
      'c-item-teaser--small  ': smallIcon,
      'c-item-teaser--inline  ': inline,
      'c-item-teaser--inline@small-screen  ': inlineSmallScreen,
    },
    className
  ).trim()

  return (
    <article data-testid={testId} className={componentItemTeaserClass}>
      <Icon icon={icon} className="c-item-teaser__icon" />
      <div className="c-item-teaser__body">
        <Heading headerType={5} className="c-item-teaser__title">
          {title} {titleHint && <InfoIcon>{titleHint}</InfoIcon>}
        </Heading>
        <Paragraph className="c-item-teaser__text">{children}</Paragraph>
        {link && (
          <div className="c-item-teaser__link">
            <Link icon="semantic-forward" normalLink size="small" href={link}>
              {linkText}
            </Link>
          </div>
        )}
      </div>
    </article>
  )
}

ItemTeaser.propTypes = {
  testId: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.string,
  smallIcon: PropTypes.bool,
  title: PropTypes.string,
  titleHint: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  inline: PropTypes.bool,
  inlineSmallScreen: PropTypes.bool,
}
