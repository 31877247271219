import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export function SectionHeading({
  className,
  headerType,
  children,
  subtitle,
  icon,
  testId,
  primary,
  smallSpacing,
  center,
  ...otherProps
}) {
  const componentSectionHeadingClass = classNames(
    {
      'c-section-heading': true,
      'c-section-heading--primary': primary,
      'c-section-heading--small-spacing': smallSpacing,
      'c-section-heading--center': center,
    },
    className
  ).trim()

  function renderIcon() {
    if (!icon) {
      return null
    }
    const iconClassName = `c-icon  c-icon--[${icon}]  c-section-heading__icon`
    return <i className={iconClassName} />
  }

  function renderSubtitle() {
    if (!subtitle) {
      return null
    }
    return <p className="c-section-heading__subtitle">{subtitle}</p>
  }

  function renderContent() {
    switch (headerType) {
      case 1:
        return (
          <h1 className="c-section-heading__title">
            {renderIcon()}
            {children}
          </h1>
        )
      case 2:
        return (
          <h2 className="c-section-heading__title">
            {renderIcon()}
            {children}
          </h2>
        )
      case 3:
        return (
          <h3 className="c-section-heading__title">
            {renderIcon()}
            {children}
          </h3>
        )
      case 4:
        return (
          <h4 className="c-section-heading__title">
            {renderIcon()}
            {children}
          </h4>
        )
      case 5:
        return (
          <h5 className="c-section-heading__title">
            {renderIcon()}
            {children}
          </h5>
        )
      case 6:
        return (
          <h6 className="c-section-heading__title">
            {renderIcon()}
            {children}
          </h6>
        )
      default:
        return null
    }
  }

  return (
    <header
      className={componentSectionHeadingClass}
      data-testid={testId}
      {...otherProps}
    >
      {renderContent()}
      {renderSubtitle()}
    </header>
  )
}

SectionHeading.propTypes = {
  className: PropTypes.string,
  testId: PropTypes.string,
  headerType: PropTypes.number,
  children: PropTypes.node,
  icon: PropTypes.string,
  primary: PropTypes.bool,
  smallSpacing: PropTypes.bool,
  center: PropTypes.bool,
}

SectionHeading.defaultProps = {
  headerType: 1,
}
