import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'cj-common-components';
import './TooltipIconButton.css';

export default class TooltipIconButton extends React.PureComponent {
  static propTypes = {
    tooltipText: PropTypes.string.isRequired,
    buttonProps: PropTypes.shape({
      className: PropTypes.string,
      secondary: PropTypes.bool,
      small: PropTypes.bool,
      round: PropTypes.bool,
      icon: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      type: PropTypes.string,
    }).isRequired,
    disabled: PropTypes.bool,
    testId: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
  };

  render() {
    const { tooltipText, buttonProps, disabled, testId } = this.props;
    return (
      <>
        <Tooltip content={tooltipText} placement="top-start" arrow distance={5}>
          <span>
            <Button disabled={disabled} {...buttonProps} testId={testId} />
          </span>
        </Tooltip>
      </>
    );
  }
}
