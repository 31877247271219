import React from 'react';
import * as Yup from 'yup';
import { get } from '@turbopay/ts-helpers/object-utils';
import BaseFormFields from '../../common-components/BaseFormFields';
import { CURRENCY_CODES } from '../../../common/constants';
import { mapValuesArrayToSelectComponentValues } from '../../common-components/common-functions';
import { getConfigSection } from '../../../common/utils';
import commonPropTypes from '../../../common/common-prop-types';

const uiTexts = require('../../../resources/uiTexts.json');

const errorsTexts = require('../../../resources/errorTexts.json');

const error = getConfigSection(errorsTexts, 'validation');

export default class FormFields extends React.PureComponent {
  static propTypes = {
    ...commonPropTypes.formFields,
  };

  static validationSchema() {
    return Yup.object().shape({
      name: Yup.string().required(error.mandatoryField),
    });
  }

  constructor(props) {
    super(props);
    this.handleNameChange = this.handleNameChange.bind(this);
  }

  render() {
    const { textsKey, authToken } = this.props;
    const fieldsTextKey = `${textsKey}.form.fields`;
    const { renderGenericInput, renderGenericSelect } = BaseFormFields;
    const selectAnOptionText = getConfigSection(uiTexts, 'common.editForm.comboSelectAnOption');

    const fieldsRenderConfig = [
      {
        id: 'isoCode',
        function: renderGenericInput('text', false),
      },
      {
        id: 'name',
        useRequiredLabel: true,
        function: renderGenericSelect(
          true,
          mapValuesArrayToSelectComponentValues(CURRENCY_CODES, ['name', 'isoCode'], 'name', {
            defaultValue: '',
            defaultText: selectAnOptionText,
          }),
          {
            onChange: this.handleNameChange,
          },
        ),
      },
    ];

    return (
      <>
        <BaseFormFields
          authToken={authToken}
          fieldsTextKey={fieldsTextKey}
          fieldsRenderConfig={fieldsRenderConfig}
          testIdPrefix="currency-form"
        />
      </>
    );
  }

  handleNameChange(event) {
    const { formikProps } = this.props;
    const { setFieldValue } = formikProps;
    const selectedItem = CURRENCY_CODES.find(item => item.name === event.target.value);
    // Please don´t change the order in this array. There is some
    // race condition among Formik and Yup making the validations
    // to be sometimes triggered with a "delay" of one event.
    // Keeping this order it seems that we give time enough to the
    // Yup promises to resolve and work without that delay.
    // More info here:
    // https://github.com/jaredpalmer/formik/issues/1524
    ['isoCode', 'name'].forEach(key => {
      setFieldValue(key, get(selectedItem, key, ''));
    });
  }
}
