import React from 'react';
import * as Yup from 'yup';
import BaseFormFields from '../../common-components/BaseFormFields';
import { OPERATOR_ACCESS_LEVEL, USER_ROLE } from '../../../common/constants';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import { getConfigSection, getEnumText, getEnumKeys } from '../../../common/utils';
import { mapValuesArrayToSelectComponentValues } from '../../common-components/common-functions';
import commonPropTypes from '../../../common/common-prop-types';
import { RequiredFieldsTip } from '../../common-components/RequiredFieldsTip';

const uiTexts = require('../../../resources/uiTexts.json');

const errorsTexts = require('../../../resources/errorTexts.json');

export default class FormFields extends React.PureComponent {
  static propTypes = {
    ...commonPropTypes.formFields,
  };

  static validationSchema() {
    return Yup.object().shape({
      userId: Yup.string().required(errorsTexts.mandatoryField),
      accessLevel: Yup.string().required(errorsTexts.mandatoryField),
    });
  }

  constructor(props) {
    super(props);
    const { authToken, customProps } = this.props;
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
    this.state = {
      selectedUser: customProps.selectedUser,
      userList: customProps.userList,
    };
  }

  render() {
    const { textsKey, authToken } = this.props;
    const { selectedUser, userList } = this.state;
    const fieldsTextKey = `${textsKey}.form.fields`;
    const { renderGenericSelect } = BaseFormFields;
    const selectAnOptionText = getConfigSection(uiTexts, 'common.editForm.comboSelectAnOption');

    const validUserList = selectedUser ? [] : userList.filter(user => user.role === USER_ROLE.ROLE_OPERATOR);

    const fieldsRenderConfig = [
      {
        id: 'userId',
        useRequiredLabel: true,
        function: renderGenericSelect(
          !selectedUser?.userId,
          mapValuesArrayToSelectComponentValues(
            validUserList?.filter(item => item.username),
            'username',
            'username',
            {
              defaultValue: selectedUser?.userId || '',
              defaultText: selectedUser?.userId || selectAnOptionText,
            },
          ),
        ),
      },
      {
        id: 'accessLevel',
        useRequiredLabel: true,
        function: renderGenericSelect(
          true,
          mapValuesArrayToSelectComponentValues(
            getEnumKeys(OPERATOR_ACCESS_LEVEL).map(item => ({
              key: getEnumText(OPERATOR_ACCESS_LEVEL, item),
              value: getEnumText(OPERATOR_ACCESS_LEVEL, item).toUpperCase(),
            })),
            'key',
            'value',
            {
              defaultValue: '',
              defaultText: selectAnOptionText,
            },
          ),
        ),
      },
    ];

    return (
      <>
        <RequiredFieldsTip />
        <BaseFormFields
          authToken={authToken}
          fieldsTextKey={fieldsTextKey}
          fieldsRenderConfig={fieldsRenderConfig}
          testIdPrefix="user-form"
        />
      </>
    );
  }
}
