import React from 'react';
import { connect } from 'react-redux';

const MerchantGroupBreadCrumb = ({ merchantGroupEditUserName }) => <span>{merchantGroupEditUserName}</span>;
const mapStateToProps = state => {
  const { merchantGroupEditUserName } = state.breadCrumbs;
  return { merchantGroupEditUserName };
};

export default connect(mapStateToProps)(MerchantGroupBreadCrumb);
