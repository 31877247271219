import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export function Table(props) {
  const componentListClass = classNames(
    {
      'c-table-wrapper  ': true,
      'c-table-wrapper--auto  ': props.auto,
      'c-table-wrapper--no-scroll  ': props.noScroll,
    },
    props.className
  ).trim()

  return (
    <div className={componentListClass}>
      <table>{props.children}</table>
    </div>
  )
}

Table.propTypes = {
  className: PropTypes.string,
  auto: PropTypes.bool,
  noScroll: PropTypes.bool,
  children: props => {
    if (
      React.Children.toArray(props.children).some(
        child =>
          !(
            child.type === Caption ||
            child.type === Thead ||
            child.type === Tbody ||
            child.type === Tfoot
          )
      )
    ) {
      return new Error(
        "'Table' children should be of type 'Caption', 'Thead', 'Tbody' or 'Tfoot'"
      )
    }
    return undefined
  },
}

Table.Caption = Caption
Table.Thead = Thead
Table.Tbody = Tbody
Table.Tfoot = Tfoot
Table.Tr = Tr
Table.Th = Th
Table.Td = Td

function Caption(props) {
  return <caption>{props.children}</caption>
}

Caption.propTypes = {
  children: PropTypes.string,
}

function Thead(props) {
  return <thead>{props.children}</thead>
}

function Tbody(props) {
  return <tbody>{props.children}</tbody>
}

function Tfoot(props) {
  return <tfoot>{props.children}</tfoot>
}

const childrenProps = {
  children: (props, propName, componentName) => {
    if (
      React.Children.toArray(props.children).some(child => child.type !== Tr)
    ) {
      return new Error(`'${componentName}' children should be of type 'Tr'`)
    }
    return undefined
  },
}

Thead.propTypes = childrenProps
Tbody.propTypes = childrenProps
Tfoot.propTypes = childrenProps

function Tr({ children, ...otherProps }) {
  return <tr {...otherProps}>{children}</tr>
}

Tr.propTypes = {
  children: props => {
    if (
      React.Children.toArray(props.children).some(
        child => !(child.type === Th || child.type === Td)
      )
    ) {
      return new Error("'Tr' children should be of type 'Th' or 'Td'")
    }
    return undefined
  },
}

function Th({ children, ...otherProps }) {
  return <th {...otherProps}>{children}</th>
}

Th.propTypes = {
  children: PropTypes.node,
}

function Td({ className, highlight, children, ...otherProps }) {
  const componentListClass = classNames(
    {
      'c-table__cell  ': highlight,
      'c-table__cell--highlight  ': highlight,
    },
    className
  ).trim()

  return (
    <td className={componentListClass} {...otherProps}>
      {children}
    </td>
  )
}

Td.propTypes = {
  className: PropTypes.string,
  highlight: PropTypes.bool,
  children: PropTypes.node,
}
