import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import * as querystring from 'query-string';
import { storeTokens, retrieveLocation } from './auth-storage';
import commonPropTypes from '../common/common-prop-types';
import ConfigurationDataApi from '../api/ConfigurationData';
import { connect } from 'react-redux';
import { setLoggedUser } from '../redux/dataSlice';
import { USER_ROLE } from '../common/constants';

class AuthCallback extends React.Component {
  constructor(props) {
    super(props);
    const query = props.location.hash.substring(1);
    const tokenParsed = querystring.parse(query);

    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(tokenParsed.access_token);

    storeTokens({
      accessToken: tokenParsed.access_token,
      idToken: tokenParsed.id_token,
    });

    const newLocation = retrieveLocation() || '/';

    this.state = {
      newLocation,
    };
  }

  async componentDidMount() {
    const { dispatch, history } = this.props;
    const response = await this.configurationDataApi.getUserProfile();

    dispatch(setLoggedUser({ loggedUser: response }));

    if (response.role === USER_ROLE.ROLE_NONE) {
      history.push('/accessDenied');
    }
  }

  render() {
    const { newLocation } = this.state;

    return <Redirect to={newLocation} />;
  }

  static defaultPropTypes = {
    location: commonPropTypes.router.location,
  };
}

export default connect()(withRouter(AuthCallback));
