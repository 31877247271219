import React from 'react';

import ConfigurationDataApi from '../../../api/ConfigurationData';
import CustomerGroupEditForm from './EditForm';
import commonPropTypes from '../../../common/common-prop-types';
import CommonTable from '../../common-components/CommonTable';

export default class Table extends React.Component {
  static propTypes = {
    ...commonPropTypes.table,
  };

  constructor(props) {
    super(props);
    const { authToken } = this.props;

    this.loadCustomerGroupList = this.loadCustomerGroupList.bind(this);
    this.deleteCustomerGroup = this.deleteCustomerGroup.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
  }

  render() {
    const { authToken, textsKey } = this.props;

    return (
      <CommonTable
        authToken={authToken}
        textsKey={textsKey}
        dataFunctions={{
          loadData: this.loadCustomerGroupList,
          deleteItem: this.deleteCustomerGroup,
        }}
        editComponent={{
          type: CustomerGroupEditForm,
        }}
        hasMaxColumnSize={true}
        testIdPrefix="customer-groups-table"
      />
    );
  }

  loadCustomerGroupList() {
    return this.configurationDataApi.getCustomerGroups();
  }

  deleteCustomerGroup(customerGroup) {
    return this.configurationDataApi.deleteCustomerGroup(customerGroup);
  }
}
