import React from 'react';
import BaseEditForm from '../../common-components/BaseEditForm';
import FormFields from './FormFields';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import commonPropTypes from '../../../common/common-prop-types';
import { getConfigSection, reconcile } from '../../../common/utils';
import { ROUTES } from '../../../common/constants';
import { BlockNavigation } from '../../common-components/BlockNavigation';
import { diff } from 'deep-diff';

const uiTexts = require('../../../resources/uiTexts.json');

class EditForm extends React.PureComponent {
  static propTypes = {
    ...commonPropTypes.editForm,
  };

  constructor(props) {
    super(props);
    this.goNotificationsPage = this.goNotificationsPage.bind(this);
    this.goNotificationRetries = this.goNotificationRetries.bind(this);
    this.valuesRef = React.createRef();
  }

  goNotificationsPage(merchantId) {
    this.props.history.replace(ROUTES.merchantNotifications.replace(':merchantId', merchantId));
  }

  goNotificationRetries(merchantId) {
    this.props.history.replace(ROUTES.notificationRetries.replace(':merchantId', merchantId));
  }

  compareValues = () => {
    const initialValues = this.valuesRef.current.initialValues;
    const currentValues = this.valuesRef.current.values;

    const origValues = {
      ...initialValues,
    };

    // internally modified
    origValues.notificationPolicy = this.props.data.notificationPolicy;

    let diffs = diff(origValues, currentValues) || [];

    diffs = diffs.filter(change => {
      if (change.kind === 'E') {
        if (change.lhs === undefined && change.rhs === '') {
          return false;
        }

        if (change.lhs === 'true' && change.rhs === true) {
          return false;
        }
        if (change.lhs === 'false' && change.rhs === false) {
          return false;
        }
      }
      if (change.kind === 'D') {
        if (change.path?.includes('oauthUrl') && !change.lhs) {
          return false;
        }
        if (!reconcile(change.lhs)) {
          return false;
        }
      }
      return true;
    });

    return diffs.length === 0 ? undefined : diffs;
  };

  render() {
    const {
      data,
      authToken,
      textsKey,
      parentHeader,
      onSaveModifiedItem,
      onSaveNewItem,
      onBack,
      onCancel,
      isVisible,
      isFormEditable,
      customProps,
    } = this.props;

    const headerText = getConfigSection(uiTexts, `${textsKey}.form.header`);

    const additionalInitialValues = {
      notificationPolicy: {
        isActiveNotifications: undefined,
        authType: undefined,
        username: '',
        password: '',
        url: undefined,
        oauthUrl: undefined,
        contentType: undefined,
        requestType: undefined,
        retryPolicy: {
          isRetryActive: undefined,
          retrySchema: undefined,
          intervalsConfiguration: [],
          timeConfiguration: {
            numberOfRetries: 0,
            retryTime: '00:00:00',
          },
        },
      },
      billingEventConfiguration: {
        numberOfDaysBefore: 0,
      },
    };

    return (
      <>
        <BlockNavigation compareValues={this.compareValues} />

        <BaseEditForm
          valuesRef={this.valuesRef}
          data={data}
          authToken={authToken}
          textsKey={textsKey}
          parentLevelHeader={parentHeader}
          currentLevelHeader={headerText}
          onBack={onBack}
          onSaveModifiedItem={onSaveModifiedItem}
          onSaveNewItem={onSaveNewItem}
          onCancel={onCancel}
          isVisible={isVisible}
          fieldsComponent={{
            type: FormFields,
          }}
          additionalInitialValues={additionalInitialValues}
          validationSchema={FormFields.validationSchema}
          isFormEditable={isFormEditable}
          customProps={customProps}
          isSaveButtonEnable
          additionalButtonComponents={this.renderAdditionalButtonComponents(this.props)}
        />
      </>
    );
  }

  renderAdditionalButtonComponents(props) {
    const { customProps } = props;

    return [
      {
        text: 'View Notifications',
        key: 'base-nottification-form-button',
        onClick: () => this.goNotificationsPage(customProps.merchantId),
        testId: 'view-notifications-button',
      },
      {
        text: 'View Notification Retries',
        key: 'base-nottification-retries-form-button',
        onClick: () => this.goNotificationRetries(customProps.merchantId),
        testId: 'view-notification-retries-button',
      },
    ];
  }
}

export default connect()(withRouter(EditForm));
