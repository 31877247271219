function mapSingleUpcfPaymentOptionDefault(paymentOption) {
  const {
    id,
    displayName,
    smartPayCode,
    deletionDate,
    isGuest,
    isCitStorable,
    isMitStorable,
    isStoredCitPaymentsAllowed,
    isStoredMitPaymentsAllowed,
  } = paymentOption;

  return {
    id,
    displayName,
    code: smartPayCode,
    deletionDate,
    isGuest: {
      enabled: isGuest,
      value: false,
    },
    isCitStorable: {
      enabled: isCitStorable,
      value: false,
    },
    isMitStorable: {
      enabled: isMitStorable,
      value: false,
    },
    isStoredCitPaymentsAllowed: {
      enabled: isStoredCitPaymentsAllowed,
      value: false,
    },
    isStoredMitPaymentsAllowed: {
      enabled: isStoredMitPaymentsAllowed,
      value: false,
    },
  };
}

// Maps old existing upcf channel with old payment options selection to new payment options selection
function mapExistingChannelPaymentOptionToNewWay(channelUpcfPaymentOption, upcfRefPaymentOption) {
  const { id, displayName, maskCitStoring } = upcfRefPaymentOption;
  const { code, isStorable, isGuest, deletionDate } = channelUpcfPaymentOption;

  const paymentOption = {
    id,
    displayName,
    code,
    deletionDate,
    isGuest: {
      enabled: upcfRefPaymentOption.isGuest,
      value: isGuest,
    },
    isStoredMitPaymentsAllowed: {
      enabled: upcfRefPaymentOption.isStoredMitPaymentsAllowed,
      value: upcfRefPaymentOption.isStoredMitPaymentsAllowed,
    },
  };

  if (isStorable) {
    paymentOption.isCitStorable = {
      enabled: upcfRefPaymentOption.isCitStorable,
      value: !maskCitStoring,
    };
    paymentOption.isMitStorable = {
      enabled: upcfRefPaymentOption.isMitStorable,
      value: true,
    };
    paymentOption.isStoredCitPaymentsAllowed = {
      enabled: upcfRefPaymentOption.isStoredCitPaymentsAllowed,
      value: !maskCitStoring,
    };
  } else {
    paymentOption.isCitStorable = {
      enabled: upcfRefPaymentOption.isCitStorable,
      value: false,
    };
    paymentOption.isMitStorable = {
      enabled: upcfRefPaymentOption.isMitStorable,
      value: false,
    };
    paymentOption.isStoredCitPaymentsAllowed = {
      enabled: upcfRefPaymentOption.isStoredCitPaymentsAllowed,
      value: false,
    };
  }

  return paymentOption;
}

// Maps upcf payment options of existing channel which uses new upcf payment options selection
function mapPaymentOptionsToUpdatedExitingChannel(paymentOptions, upcfRefPaymentOptions) {
  const result = [];

  for (const upcfRefPaymentOption of upcfRefPaymentOptions) {
    const upcfPaymentOption = paymentOptions.find(po => po.code === upcfRefPaymentOption.smartPayCode);

    if (upcfPaymentOption) {
      result.push({
        id: upcfRefPaymentOption.id,
        displayName: upcfRefPaymentOption.displayName,
        code: upcfPaymentOption.code,
        deletionDate: upcfRefPaymentOption.deletionDate,
        isGuest: {
          enabled: upcfRefPaymentOption.isGuest,
          value: upcfPaymentOption.isGuest,
        },
        isCitStorable: {
          enabled: upcfRefPaymentOption.isCitStorable,
          value: upcfPaymentOption.isCitStorable,
        },
        isMitStorable: {
          enabled: upcfRefPaymentOption.isMitStorable,
          value: upcfPaymentOption.isMitStorable,
        },
        isStoredCitPaymentsAllowed: {
          enabled: upcfRefPaymentOption.isStoredCitPaymentsAllowed,
          value: upcfPaymentOption.isStoredCitPaymentsAllowed,
        },
        isStoredMitPaymentsAllowed: {
          enabled: upcfRefPaymentOption.isStoredMitPaymentsAllowed,
          value: upcfPaymentOption.isStoredMitPaymentsAllowed,
        },
      });
    } else {
      result.push(mapSingleUpcfPaymentOptionDefault(upcfRefPaymentOption));
    }
  }

  return result;
}

export const preparePaymentOptionsForUpdateChannel = (channelUpcfPaymentOptions, upcfRefPaymentOptions) => {
  const isChannelUpdatedToNewPaymentOptions = channelUpcfPaymentOptions.some(po => po.hasOwnProperty('isCitStorable'));

  if (isChannelUpdatedToNewPaymentOptions) {
    return mapPaymentOptionsToUpdatedExitingChannel(channelUpcfPaymentOptions, upcfRefPaymentOptions);
  }

  const allUpcfPaymentOptionsForForm = [];

  for (const upcfRefPaymentOption of upcfRefPaymentOptions) {
    const channelUpcfPaymentOption = channelUpcfPaymentOptions.find(
      po => po.code === upcfRefPaymentOption.smartPayCode,
    );

    if (channelUpcfPaymentOption) {
      allUpcfPaymentOptionsForForm.push(
        mapExistingChannelPaymentOptionToNewWay(channelUpcfPaymentOption, upcfRefPaymentOption),
      );
    } else {
      allUpcfPaymentOptionsForForm.push(mapSingleUpcfPaymentOptionDefault(upcfRefPaymentOption));
    }
  }

  return allUpcfPaymentOptionsForForm;
};

export const preparaUpcfPaymentOptionsForCreateNewChannel = upcfRefPaymentOptions =>
  upcfRefPaymentOptions.map(mapSingleUpcfPaymentOptionDefault);
