import React from 'react';
import PropTypes from 'prop-types';
import PortalHeader from '../PortalHeader';

export class DefaultLayout extends React.PureComponent {
  static propTypes = {
    // Imposible to define a common type for this parameter
    // eslint-disable-next-line react/forbid-prop-types
    children: PropTypes.any.isRequired,
  };

  render() {
    const { children } = this.props;

    return (
      <div className="o-root">
        <PortalHeader {...this.props} />
        <div className="default-layout o-content-section" data-testid="page-container">
          <div className="o-page-wrap">{children}</div>
        </div>
      </div>
    );
  }
}

export default DefaultLayout;
