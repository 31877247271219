import React from 'react';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import commonPropTypes from '../../../common/common-prop-types';
import CommonTable from '../../common-components/CommonTable';
import { SORT_COLUMNS } from '../../../common/constants';

export default class MerchantsTable extends React.Component {
  static propTypes = {
    ...commonPropTypes.table,
  };

  constructor(props) {
    super(props);
    const { authToken } = this.props;
    this.loadMerchantsData = this.loadMerchantsData.bind(this);
    this.deleteMerchant = this.deleteMerchant.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
  }

  render() {
    const { authToken, textsKey } = this.props;
    const textsKeyMerchants = `${textsKey}.merchants`;
    const creationPath = 'addMerchant';

    return (
      <CommonTable
        authToken={authToken}
        textsKey={textsKeyMerchants}
        dataFunctions={{
          loadData: this.loadMerchantsData,
          addItem: this.add,
          modifyItem: item => item,
          deleteItem: this.deleteMerchant,
        }}
        defaultSortColumn={SORT_COLUMNS.CREATION_DATE}
        creationPath={creationPath}
        canClickOnItem={false}
        isRowEditable={false}
        hasHeader
        testIdPrefix="merchants-table"
      />
    );
  }

  async loadMerchantsData() {
    const { merchantGroupData } = this.props;

    const data = await this.configurationDataApi.getMerchantGroupMerchants(merchantGroupData.id);

    return data.merchants;
  }

  deleteMerchant(merchant) {
    const { merchantGroupData } = this.props;

    return this.configurationDataApi.deleteMerchantGroupMerchant(merchantGroupData.id, merchant.merchantId);
  }
}
