import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export function List(props) {
  const componentListClass = classNames(
    {
      'section-sign-list': props.sectionSign,
      'subcounter-list': props.subcounterSign,
      'alphabetical-list': props.alphabetical,
    },
    props.className
  ).trim()

  const componentListElemClass = classNames({}, props.listElemClassName).trim()

  function getListType() {
    if (props.roman) {
      return 'I'
    }
    if (props.alphabetical) {
      return 'a'
    }
    return 1
  }

  function renderList() {
    if (props.ordered) {
      return (
        <ol
          data-testid={props.testId}
          className={componentListClass}
          aria-hidden={props.ariaHidden}
          start={props.startFrom}
          type={getListType()}
        >
          {renderElements(props.startFrom)}
        </ol>
      )
    }
    return (
      <ul
        data-testid={props.testId}
        className={componentListClass}
        aria-hidden={props.ariaHidden}
      >
        {renderElements(props.startFrom)}
      </ul>
    )
  }

  function renderElements(start) {
    return React.Children.map(props.children, (child, index) => {
      return (
        <li
          // eslint-disable-next-line
          key={`${index}`}
          className={componentListElemClass}
          data-counter={(start || 1) + index}
        >
          {child}
        </li>
      )
    })
  }

  return renderList(props.items)
}

List.Item = ({ children }) => {
  return children
}

List.Item.propTypes = {
  children: PropTypes.node,
}

List.Item.displayName = 'List.Item'

List.propTypes = {
  testId: PropTypes.string,
  className: PropTypes.string,
  listElemClassName: PropTypes.string,
  ariaHidden: PropTypes.bool,
  ordered: PropTypes.bool,
  startFrom: PropTypes.number,
  roman: PropTypes.bool,
  alphabetical: PropTypes.bool,
  sectionSign: PropTypes.bool,
  subcounterSign: PropTypes.bool,
  children: props => {
    if (
      React.Children.toArray(props.children).some(
        child => child.type !== List.Item
      )
    ) {
      return new Error("'List' children should be of type 'List.Item'")
    }
    return undefined
  },
}
