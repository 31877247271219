import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import uuid from 'uuidv4'
import { ErrorMessage } from '../../Atoms/ErrorMessage/ErrorMessage'
import { Label } from '../../Atoms/Label/Label'
import { InfoIcon } from '../../Atoms/InfoIcon/InfoIcon'

export function FormField(props) {
  const [id] = useState(() => props.id || uuid())

  const memoizedLabel = React.useMemo(() => {
    return typeof props.label === 'string'
      ? passedProps => <Label {...passedProps}>{props.label}</Label>
      : props.label
  }, [props.label])

  const componentListClass = classNames(
    {
      'c-form-field  ': true,
    },
    props.className
  ).trim()

  function renderFormFieldBox() {
    return (
      <div className="c-form-field__box">
        {props.children &&
          props.children({
            id,
            ariaLabelledby: props.error ? `error-${id}` : undefined,
          })}
        {props.error && (
          <ErrorMessage id={`error-${id}`}>{props.error}</ErrorMessage>
        )}
      </div>
    )
  }

  return (
    <div className={componentListClass}>
      {props.label && memoizedLabel({ for: id, notion: props.isRequired })}
      {props.hint && <InfoIcon>{props.hint}</InfoIcon>}
      {renderFormFieldBox()}
    </div>
  )
}

FormField.propTypes = {
  className: PropTypes.string,
  children: PropTypes.func.isRequired, // eslint-disable-line
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
  error: PropTypes.string, // eslint-disable-line
  isRequired: PropTypes.bool, // eslint-disable-line
  hint: PropTypes.node,
}
