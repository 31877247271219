import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from 'cj-common-components/dist/bronson/bluelabel/img/logo.svg';
import logoSmall from 'cj-common-components/dist/bronson/bluelabel/img/logo-small-screen.svg';
import { getAllowedOptionsButtonConfig } from './common-components/common-functions';
import * as TokenHelper from '../auth/token-helper';
import * as AuthStorage from '../auth/auth-storage';
import commonPropTypes from '../common/common-prop-types';
import { getConfigSection } from '../common/utils';
import config from '../resources/config.json';

const USERNAME_UNKNOWN = getConfigSection(config, 'auth.usernameUnknown');

function formatTime(sec) {
  const min = Math.floor(sec / 60);
  const minPadding = min < 10 ? '0' : '';
  const fracsec = sec % 60;
  const secPadding = fracsec < 10 ? '0' : '';
  if (min <= 0 && sec <= 0) return 'Login';
  return `${minPadding}${min}:${secPadding}${fracsec} Refresh`;
}

class PortalHeader extends React.Component {
  static propTypes = {
    location: commonPropTypes.router.location,
  };

  constructor(props) {
    super(props);
    this.state = {
      showBurgerPanel: false,
      username: (
        TokenHelper.getToken() || {
          idTokenObject: { 'cognito:username': USERNAME_UNKNOWN },
        }
      ).idTokenObject['cognito:username'],
      sessionTime: TokenHelper.getSessionTimeLeftInSeconds(),
    };
    this.getAccessTokenTimeIntervalId = setInterval(() => {
      this.setState({
        sessionTime: TokenHelper.getSessionTimeLeftInSeconds(),
      });
    }, 1000);

    this.menuButtonsConfig = userRole =>
      getAllowedOptionsButtonConfig(userRole, 'mainContainer.menu', 'ui.mainContainer.menu');
  }

  componentWillUnmount() {
    clearInterval(this.getAccessTokenTimeIntervalId);
  }

  render() {
    const { username, sessionTime, showBurgerPanel } = this.state;
    const { loggedUser } = this.props;
    const userRole = loggedUser.role;

    return (
      <header className="c-header__wrapper">
        <div className="c-header  c-header--static">
          <div className="c-header__content-wrapper">
            <div className="c-header__logo">
              <a className="c-logo  c-logo--has-image" href="#" title="Home" data-testid="logo">
                <picture>
                  <source srcSet={logoSmall} media="(max-width: 720px)" />
                  <img src={logo} alt="VWFS" className="c-logo__image" />
                </picture>
              </a>
            </div>

            <div className="c-header__nav">
              {showBurgerPanel}
              <div className="c-site-nav  js-site-nav">
                <button
                  onClick={() => this.setState({ showBurgerPanel: !showBurgerPanel })}
                  className="c-site-nav__toggle  js-site-nav__toggle"
                  type="button"
                  title="Show navigation"
                  aria-expanded="false"
                  data-testid="show-navigation-button"
                >
                  <i className="c-site-nav__toggle-icon" aria-hidden={!showBurgerPanel}></i>
                </button>

                <div className="c-site-nav__panel  js-site-nav__navs" aria-hidden={!showBurgerPanel}>
                  <nav className="c-site-nav__navs" aria-label="primary">
                    <ul className="c-site-nav__nav">
                      {this.renderMenuItem(userRole)}
                      <li className="c-site-nav__item">
                        <span className="c-site-nav__link-label u-mr-xxsmall" style={{ whiteSpace: 'nowrap' }}>
                          <span data-testid="current-username">
                            {username === USERNAME_UNKNOWN ? '' : `${username}  `}
                          </span>
                          <a
                            tabIndex="-1"
                            role="button"
                            onClick={() => this.refreshToken()}
                            data-testid={username === USERNAME_UNKNOWN ? 'login-button' : 'refresh-token-button'}
                          >
                            {' '}
                            <span className="c-site-nav__link-label u-mr-xxsmall" style={{ whiteSpace: 'nowrap' }}>
                              [{formatTime(sessionTime)}]
                            </span>
                          </a>
                        </span>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }

  renderMenuItem(userRole) {
    const { showBurgerPanel, username } = this.state;
    return (
      username &&
      this.menuButtonsConfig(userRole).map((btn, i) => (
        <li className="c-site-nav__item" key={btn.link + i}>
          <Link
            onClick={() => this.setState({ showBurgerPanel: !showBurgerPanel })}
            className="js-site-nav__toggle c-site-nav__link  is-active"
            key={`maincontainer-menu-link-${btn.icon}`}
            to={btn.link}
            data-testid={`${btn.key}-menu-item`}
          >
            <span className="c-site-nav__link-label u-mr-xxsmall" style={{ whiteSpace: 'nowrap' }}>
              {btn.text}
            </span>
            <i className={`c-btn__icon   c-icon   c-icon--[${btn.icon}]`} />
          </Link>
        </li>
      ))
    );
  }

  refreshToken() {
    const { location } = this.props;
    const path = location.pathname === '/logout' ? '/' : location.pathname;
    AuthStorage.storeLocation(path);
    TokenHelper.redirectForToken();
  }
}

const mapStateToProps = state => {
  const { loggedUser } = state.appData;
  return { loggedUser };
};

export default connect(mapStateToProps)(PortalHeader);
