import React from 'react';
import * as Yup from 'yup';
import SimpleFormGroup from '../../common-components/SimpleFormGroup';
import PropTypes from 'prop-types';
import { Label, Input, Select, Button, Column, Layout } from 'cj-common-components';
import { getConfigSection } from '../../../common/utils';
import { Formik, Form } from 'formik';
import { NOTIFICATIONS_OBJECT_TYPES, NOTIFICATIONS_SEARCH_FILTERS } from '../../../common/notifications';

const uiTexts = require('../../../resources/uiTexts.json');
const errorsTexts = require('../../../resources/errorTexts.json');

class NotificationsPageFilter extends React.Component {
  static propTypes = {
    filterNotifications: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.handleSubmitForm = this.handleSubmitFormBind.bind(this);
    this.resetSearchByFieldsValues = this.resetSearchByFieldsValues.bind(this);
  }

  handleSubmitFormBind(values) {
    const { filterName } = values;

    if (filterName) {
      this.props.filterNotifications({ filterName, filterValue: values[filterName] });
    } else {
      this.props.filterNotifications();
    }
  }

  resetSearchByFieldsValues(setFieldValue) {
    setFieldValue('objectId', '');
    setFieldValue('objectType', '');
  }

  render() {
    const initialValues = {
      filterName: '',
      objectId: '',
      objectType: '',
    };

    const filterValidation = Yup.object().shape({
      objectId: Yup.string().max(64, errorsTexts.validation.maxLength.replace('$MAX$', 64)),
    });

    const testIdPrefix = 'notifications-search';

    return (
      <div className="u-mt-large" data-testid={`${testIdPrefix}-container`}>
        <SimpleFormGroup keyName="notifications-filter">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={this.handleSubmitForm}
            validationSchema={filterValidation}
          >
            {({ values, handleChange, handleSubmit, setFieldValue, touched, errors, resetForm }) => (
              <Form onSubmit={handleSubmit}>
                <Layout className="u-mb">
                  <Column span="5/12">
                    <Label testId={`${testIdPrefix}-filterName-label`}>
                      {getConfigSection(uiTexts, 'merchants.notifications.filter.searchBy')}
                    </Label>
                    <Select
                      name="filterName"
                      value={values.filterName}
                      values={NOTIFICATIONS_SEARCH_FILTERS}
                      onChange={ev => {
                        this.resetSearchByFieldsValues(setFieldValue, resetForm);
                        handleChange(ev);
                      }}
                      testId={`${testIdPrefix}-filterName-select`}
                    ></Select>
                  </Column>
                  <Column span="5/12">
                    {values.filterName === 'objectId' && (
                      <div>
                        <Label testId={`${testIdPrefix}-objectId-label`}>
                          {getConfigSection(uiTexts, 'merchants.notifications.filter.objectId')}
                        </Label>
                        <Input
                          id="objectId"
                          name="objectId"
                          type="text"
                          value={values.objectId}
                          onChange={handleChange}
                          testId={`${testIdPrefix}-objectId-input`}
                        />
                        {touched.objectId && errors.objectId ? (
                          <p className="c-error-message">{errors.objectId}</p>
                        ) : null}
                      </div>
                    )}
                    {values.filterName === 'objectType' && (
                      <div>
                        <Label testId={`${testIdPrefix}-objectType-label`}>
                          {getConfigSection(uiTexts, 'merchants.notifications.filter.objectType')}
                        </Label>
                        <Select
                          name="objectType"
                          value={values.objectType}
                          values={NOTIFICATIONS_OBJECT_TYPES}
                          onChange={handleChange}
                          testId={`${testIdPrefix}-objectType-label`}
                        ></Select>
                      </div>
                    )}
                  </Column>
                  <Column span="2/12">
                    <Button className="mt-35" secondary type="submit" testId="search-button">
                      {getConfigSection(uiTexts, 'merchants.notifications.filter.searchBtnText')}
                    </Button>
                  </Column>
                </Layout>
              </Form>
            )}
          </Formik>
        </SimpleFormGroup>
      </div>
    );
  }
}

export default NotificationsPageFilter;
