import React from 'react';
import { Provider } from 'react-redux';
import MainContainer from './screens/MainContainer';
import {  configureStore } from './redux/store';
import { BrowserRouter } from 'react-router-dom';

/*  loading of css synchronously,
 *  because of occasional error with not supported MIME type */
function App() {
  const [isLoaded, setLoaded] = React.useState(false);
  const store = configureStore();

  React.useEffect(() => {
    import('cj-common-components/dist/bronson/bluelabel/css/style.min.css').then(() => {
      import('./style/index.css').then(() => {
        setLoaded(true);
      });
    });
  }, []);

  return isLoaded ? (
    <Provider store={store}>
      <BrowserRouter>
        <MainContainer />
      </BrowserRouter>
    </Provider>
  ) : null;
}

export default App;
