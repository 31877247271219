import React from 'react';
import PropTypes from 'prop-types';
import { IconList } from 'cj-common-components';
import { getConfigSection } from '../../common/utils';

const config = require('../../resources/config.json');

const icons = getConfigSection(config, 'ui.common.table.icons');

export default class WarnIconText extends React.PureComponent {
  static propTypes = {
    text: PropTypes.string.isRequired,
    testId: PropTypes.string,
  };

  render() {
    const { text, testId } = this.props;

    return (
      <IconList>
        <IconList.Item icon={icons.warn}>
          <p data-testid={testId}>
            <small>{text}</small>
          </p>
        </IconList.Item>
      </IconList>
    );
  }
}
