import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export function Accordion(props) {
  const [shown, toggleShown] = useState(
    props.multipleOpen
      ? () => React.Children.map(props.children, () => false)
      : -1
  )

  useEffect(() => {
    if (props.defaultOpen !== null || props.defaultOpen !== undefined) {
      if (Array.isArray(props.defaultOpen)) {
        toggleShown([...shown].map((_, i) => props.defaultOpen.indexOf(i) >= 0))
        return
      }
      toggleShown(props.defaultOpen)
    }
  }, [])

  const panelRefs = React.Children.map(props.children, () => {
    return useRef(null)
  })

  const componentAccordionClass = classNames(
    {
      'c-accordion  ': !props.footerAccordion,
      'c-footer-nav__column  ': props.footerAccordion,
      'js-footer-accordion  ': props.footerAccordion,
      'js-is-ready  ': props.footerAccordion,
      'c-accordion--bg-panel  ': props.withBackground,
      'js-is-fx  ': props.animate,
    },
    props.className
  ).trim()

  function isActive(index) {
    return Array.isArray(shown) ? shown[index] : shown === index
  }

  function setShown(index) {
    if (Array.isArray(shown)) {
      toggleShown(
        shown.map((item, itemIndex) => {
          if (itemIndex === index) {
            return !item
          }
          return item
        })
      )
    } else {
      toggleShown(shown === index ? -1 : index)
    }
  }

  return (
    <div className={componentAccordionClass}>
      {React.Children.map(props.children, (child, index) => {
        const componentAccordionItemClass = classNames({
          'c-accordion__header  ': !props.footerAccordion,
          'c-footer-nav__title  ': props.footerAccordion,
          'is-active  ': isActive(index),
        }).trim()

        const panelStyle = {
          height: isActive(index) ? `auto` : '0px',
          visibility: isActive(index) ? 'visible' : 'hidden',
          overflowY: 'hidden',
        }
        return (
          // eslint-disable-next-line
          <React.Fragment key={index}>
            {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
            <div
              className={componentAccordionItemClass}
              onClick={() => setShown(index)}
              role="button"
            >
              {!props.footerAccordion && (
                <span className="c-accordion__title-icon" />
              )}
              {!props.footerAccordion && (
                <h3 className="c-accordion__title">
                  <button
                    className="c-accordion__title-label"
                    aria-expanded={isActive(index)}
                    type="button"
                  >
                    <span className="c-accordion__title-label-text">
                      {child.props.title}
                    </span>
                  </button>
                </h3>
              )}
              {props.footerAccordion && (
                <React.Fragment>
                  <h4 className="c-footer-nav__title-label">
                    {child.props.title}
                  </h4>
                  <button
                    className="c-footer-nav__title-label"
                    aria-expanded={isActive(index)}
                    type="button"
                  />
                </React.Fragment>
              )}
              {child.props.subtitle && (
                <p className="c-accordion__subtitle">{child.props.subtitle}</p>
              )}
            </div>
            {!props.footerAccordion && (
              <div
                style={panelStyle}
                className="c-accordion__panel"
                aria-hidden={!isActive(index)}
              >
                <div ref={panelRefs[index]} className="c-accordion__content">
                  {child}
                </div>
              </div>
            )}
            {props.footerAccordion && (
              <div style={panelStyle} aria-hidden={!isActive(index)}>
                <div ref={panelRefs[index]}>{child}</div>
              </div>
            )}
          </React.Fragment>
        )
      })}
    </div>
  )
}

Accordion.Item = ({ children }) => {
  return children
}

Accordion.Item.displayName = 'Accordion.Item'

Accordion.Item.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
}

Accordion.propTypes = {
  footerAccordion: PropTypes.bool,
  className: PropTypes.string,
  withBackground: PropTypes.bool,
  multipleOpen: PropTypes.bool,
  animate: PropTypes.bool,
  children: props => {
    if (
      React.Children.toArray(props.children).some(
        child => child.type !== Accordion.Item
      )
    ) {
      return new Error(
        "'Accordion' children should be of type 'Accordion.Item'"
      )
    }
    return undefined
  },
  defaultOpen: props => {
    if (!props.defaultOpen && props.defaultOpen !== 0) {
      return undefined
    }

    if (
      !Array.isArray(props.defaultOpen) &&
      !Number.isInteger(props.defaultOpen)
    ) {
      return new Error(
        'defaultOpen prop should be an integer or an array of integers'
      )
    }

    if (Array.isArray(props.defaultOpen) && !props.multipleOpen) {
      return new Error(
        'defaultOpen prop should be an integer when multipleOpen is false'
      )
    }

    if (Number.isInteger(props.defaultOpen) && props.multipleOpen) {
      return new Error(
        'defaultOpen prop should be an array of integers when multipleOpen is true'
      )
    }

    return undefined
  },
}

Accordion.defaultProps = {
  footerAccordion: false,
  className: '',
  withBackground: false,
  multipleOpen: false,
  animate: false,
}
