import React from 'react';
import { Button, ButtonContainer, Column, Layout } from 'cj-common-components';
import { getConfigSection } from '../../../common/utils';

const uiTexts = require('../../../resources/uiTexts.json');
const config = require('../../../resources/config.json');

const BackButton = ({ textsKey, onBack, backLocation }) => {
  const buttonsTexts = getConfigSection(uiTexts, `${textsKey}.form.buttons`);
  const buttonIcons = getConfigSection(config, 'ui.common.editForm.icons');

  const goBack = () => {
    onBack(backLocation);
  };

  return (
    <Layout center className="u-mt-xxsmall">
      <Column span="6/12">
        <ButtonContainer>
          <Button secondary type="button" iconReversed icon={buttonIcons.back} onClick={goBack} testId="back-button">
            {buttonsTexts.back}
          </Button>
        </ButtonContainer>
      </Column>
    </Layout>
  );
};

export default BackButton;
