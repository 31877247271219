export const httpStatusCodes = [
  { httpStatus: '200-OK', value: '200' },
  { httpStatus: '201-Created', value: '201' },
  { httpStatus: '202-Accepted', value: '202' },
  { httpStatus: '203-Non-Authoritative Information', value: '203' },
  { httpStatus: '204-No Content', value: '204' },
  { httpStatus: '205-Reset Content', value: '205' },
  { httpStatus: '206-Partial Content', value: '206' },
  { httpStatus: '207-Multi-Status', value: '207' },
  { httpStatus: '208-Already Reported', value: '208' },
  { httpStatus: '226-IM Used', value: '226' },
  { httpStatus: '300-Multiple Choices', value: '300' },
  { httpStatus: '301-Moved Permanently', value: '301' },
  { httpStatus: '302-Found', value: '302' },
  { httpStatus: '303-See Other', value: '303' },
  { httpStatus: '304-Not Modified', value: '304' },
  { httpStatus: '307-Temporary Redirect', value: '307' },
  { httpStatus: '308-Permanent Redirect', value: '308' },
  { httpStatus: '400-Bad Request', value: '400' },
  { httpStatus: '401 -Unauthorized', value: '401' },
  { httpStatus: '402-Payment Required', value: '402' },
  { httpStatus: '403-Forbidden', value: '403' },
  { httpStatus: '404-Not Found', value: '404' },
  { httpStatus: '405-Method Not Allowed', value: '405' },
  { httpStatus: '406-Not Acceptable', value: '406' },
  { httpStatus: '407-Proxy Authentication Required', value: '407' },
  { httpStatus: '408-Request Timeout', value: '408' },
  { httpStatus: '409-Conflict', value: '409' },
  { httpStatus: '410-Gone', value: '410' },
  { httpStatus: '411-Length Required', value: '411' },
  { httpStatus: '412-Precondition Failed', value: '412' },
  { httpStatus: '413-Content Too Large', value: '413' },
  { httpStatus: '414-URI Too Long', value: '414' },
  { httpStatus: '415-Unsupported Media Type', value: '415' },
  { httpStatus: '416-Range Not Satisfiable', value: '416' },
  { httpStatus: '417-Expectation Failed', value: '417' },
  { httpStatus: '421-Misdirected Request', value: '421' },
  { httpStatus: '422-Unprocessable Content', value: '422' },
  { httpStatus: '423-Locked', value: '423' },
  { httpStatus: '424-Failed Dependency', value: '424' },
  { httpStatus: '425-Too Early', value: '425' },
  { httpStatus: '426-Upgrade Required', value: '426' },
  { httpStatus: '428-Precondition Required', value: '428' },
  { httpStatus: '429-Too Many Requests', value: '429' },
  { httpStatus: '431-Request Header Fields Too Large', value: '431' },
  { httpStatus: '451-Unavailable for Legal Reasons', value: '451' },
  { httpStatus: '500-Internal Server Error', value: '500' },
  { httpStatus: '501-Not Implemented', value: '501' },
  { httpStatus: '502-Bad Gateway', value: '502' },
  { httpStatus: '503-Service Unavailable', value: '503' },
  { httpStatus: '504-Gateway Timeout', value: '504' },
  { httpStatus: '505-HTTP Version Not Supported', value: '505' },
  { httpStatus: '506-Variant Also Negotiates', value: '506' },
  { httpStatus: '507-Insufficient Storage', value: '507' },
  { httpStatus: '508-Loop Detected', value: '508' },
  { httpStatus: '511-Network Authentication Required', value: '511' },
];
