import React from 'react';
import PropTypes from 'prop-types';
import { IconList } from 'cj-common-components';
import { getConfigSection } from '../../common/utils';

const config = require('../../resources/config.json');

const icons = getConfigSection(config, 'ui.common.table.icons');

export default class InfoIconText extends React.PureComponent {
  static propTypes = {
    text: PropTypes.string.isRequired,
    classNameOnItem: PropTypes.string,
    classNameOnText: PropTypes.string,
    testId: PropTypes.string,
  };

  render() {
    const { text, classNameOnItem, classNameOnText, testId } = this.props;

    return (
      <IconList>
        <IconList.Item icon={icons.info} className={classNameOnItem}>
          <p className={classNameOnText} data-testid={testId}>
            <small>{text}</small>
          </p>
        </IconList.Item>
      </IconList>
    );
  }
}
