import React from 'react';
import * as Yup from 'yup';
import BaseFormFields from '../../../common-components/BaseFormFields';
import { OPERATOR_ACCESS_LEVEL, USER_ROLE } from '../../../../common/constants';
import ConfigurationDataApi from '../../../../api/ConfigurationData';
import { getConfigSection, getEnumText, getEnumKeys } from '../../../../common/utils';
import { mapValuesArrayToSelectComponentValues } from '../../../common-components/common-functions';
import commonPropTypes from '../../../../common/common-prop-types';
import { RequiredFieldsTip } from '../../../common-components/RequiredFieldsTip';

const uiTexts = require('../../../../resources/uiTexts.json');

const errorsTexts = require('../../../../resources/errorTexts.json');

const error = getConfigSection(errorsTexts, 'validation');

export default class FormFields extends React.PureComponent {
  static propTypes = {
    ...commonPropTypes.formFields,
  };

  static validationSchema() {
    return Yup.object().shape({
      id: Yup.string().required(error.mandatoryField),
      accessLevel: Yup.string().required(error.mandatoryField),
    });
  }

  constructor(props) {
    super(props);
    const { authToken } = this.props;
    this.updateRefDataState = this.updateRefDataState.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
    this.state = {
      userList: [],
    };
  }

  render() {
    const { textsKey, authToken, isUpdateOperation, isFormEditable, customProps, dataStatusLoadCallback } = this.props;

    const { userList } = this.state;
    const fieldsTextKey = `${textsKey}.form.fields`;
    const { renderGenericSelect, renderGenericInput } = BaseFormFields;
    const selectAnOptionText = getConfigSection(uiTexts, 'common.editForm.comboSelectAnOption');
    const { merchantUsers } = customProps;
    const currentUsername = authToken.accessTokenObject.username;

    const validUserList = userList.filter(
      user =>
        user.role === USER_ROLE.ROLE_OPERATOR &&
        user.username !== currentUsername &&
        (isUpdateOperation ? true : merchantUsers.find(merchUser => merchUser.id === user.username) === undefined),
    );

    const fieldsRenderConfig = [
      {
        // TODO: in case we are editing an existing user,
        // we need to avoid fetching again the list of users
        // from the backend
        id: 'id',
        useRequiredLabel: true,
        function: renderGenericSelect(
          isFormEditable && !isUpdateOperation,
          mapValuesArrayToSelectComponentValues(
            validUserList.filter(item => item.username),
            'username',
            'username',
            {
              defaultValue: '',
              defaultText: selectAnOptionText,
            },
          ),
        ),
      },
      {
        id: 'creationDate',
        function: renderGenericInput('text', false),
      },
      {
        id: 'accessLevel',
        useRequiredLabel: true,
        function: renderGenericSelect(
          isFormEditable,
          mapValuesArrayToSelectComponentValues(
            getEnumKeys(OPERATOR_ACCESS_LEVEL).map(item => ({
              key: getEnumText(OPERATOR_ACCESS_LEVEL, item),
              value: getEnumText(OPERATOR_ACCESS_LEVEL, item),
            })),
            'value',
            'key',
            {
              defaultValue: '',
              defaultText: selectAnOptionText,
            },
          ),
        ),
      },
    ];

    return (
      <>
        <RequiredFieldsTip />
        <BaseFormFields
          authToken={authToken}
          fieldsTextKey={fieldsTextKey}
          fieldsRenderConfig={fieldsRenderConfig}
          refDataLoadFunctions={[this.configurationDataApi.getUsers]}
          refDataLoadCallback={this.updateRefDataState}
          dataStatusLoadCallback={dataStatusLoadCallback}
          testIdPrefix="user-form"
        />
      </>
    );
  }

  updateRefDataState(refDataArray) {
    this.setState({ userList: refDataArray[0] });
  }
}
