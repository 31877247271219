import React from 'react';
import ConfigurationDataApi from '../../../../api/ConfigurationData';
import { getEnumText, getEnumValueFromText } from '../../../../common/utils';
import { OPERATOR_ACCESS_LEVEL } from '../../../../common/constants';
import commonPropTypes from '../../../../common/common-prop-types';
import CommonTable from '../../../common-components/CommonTable';
import { formatDate } from '../../../../common/date-time-utils';
import { USER_ROLE } from '../../../../common/constants';

export default class UsersTable extends React.Component {
  static propTypes = {
    ...commonPropTypes.table,
  };

  static prepareDataTransformation(user) {
    const { accessLevel } = user;
    return Object.assign({}, user, {
      accessLevel: getEnumText(OPERATOR_ACCESS_LEVEL, accessLevel),
    });
  }

  static revertDataTransformation(user) {
    const { accessLevel } = user;
    return Object.assign({}, user, {
      accessLevel: getEnumValueFromText(OPERATOR_ACCESS_LEVEL, accessLevel),
    });
  }

  constructor(props) {
    super(props);
    const { authToken } = this.props;
    this.loadUsersData = this.loadUsersData.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
  }

  render() {
    const { authToken, textsKey, isFormEditable } = this.props;

    const textsKeyUsers = `${textsKey}.users`;
    const creationPath = 'addUser';
    const userRole = this.props.loggedUser?.role;

    return (
      <CommonTable
        authToken={authToken}
        canAddNewItem={userRole === USER_ROLE.ROLE_ADMIN}
        textsKey={textsKeyUsers}
        dataFunctions={{
          loadData: this.loadUsersData,
          deleteItem: this.deleteUser,
        }}
        isFormEditable={isFormEditable}
        isAccessLevelApplicable={false}
        creationPath={creationPath}
        testIdPrefix="users-table"
      />
    );
  }

  async loadUsersData() {
    const { merchantData, authToken } = this.props;
    const { username } = authToken.accessTokenObject;
    // eslint-disable-next-line max-len
    const usersData = await this.configurationDataApi.getMerchantUsersWithDirectAccess(merchantData.id);

    const preparedUsers = usersData.users
      .filter(user => user.id !== username)
      .map(user =>
        Object.assign({}, user, {
          accessLevel: getEnumText(OPERATOR_ACCESS_LEVEL, user.accessLevel),
          creationDate: user.creationDate ? formatDate(user.creationDate) : '',
        }),
      );

    return preparedUsers;
  }

  async deleteUser(user) {
    const { merchantData, showSuccessMessage } = this.props;
    const response = await this.configurationDataApi.deleteMerchantUser(merchantData.id, user.id);

    showSuccessMessage();

    return response;
  }
}
