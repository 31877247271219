import React from 'react';
import * as Yup from 'yup';
import commonPropTypes from '../../../common/common-prop-types';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import { OPERATOR_ACCESS_LEVEL } from '../../../common/constants';
import { getConfigSection, getEnumText, getEnumKeys } from '../../../common/utils';
import BaseFormFields from '../../common-components/BaseFormFields';
import { mapValuesArrayToSelectComponentValues } from '../../common-components/common-functions';
import { RequiredFieldsTip } from '../../common-components/RequiredFieldsTip';

const uiTexts = require('../../../resources/uiTexts.json');
const errorsTexts = require('../../../resources/errorTexts.json');

export default class UserAccessFormFields extends React.PureComponent {
  static validationSchema() {
    return Yup.object().shape({
      groupId: Yup.string().required(errorsTexts.mandatoryField),
      accessLevel: Yup.string().required(errorsTexts.mandatoryField),
    });
  }

  static propTypes = {
    ...commonPropTypes.formFields,
  };

  constructor(props) {
    super(props);
    const { authToken } = this.props;

    this.updateRefDataState = this.updateRefDataState.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);

    this.state = {
      newGroupsList: [],
    };
  }

  render() {
    const { textsKey, authToken, isUpdateOperation, customProps, isFormEditable, dataStatusLoadCallback } = this.props;
    const fieldsTextKey = `${textsKey}.form.fields`;
    const { renderGenericSelect } = BaseFormFields;
    const selectAnOptionText = getConfigSection(uiTexts, 'common.editForm.comboSelectAnOption');
    const { groups, currentGroup } = customProps;
    const { newGroupsList } = this.state;

    const fieldsRenderConfig = [
      {
        id: 'groupId',
        useRequiredLabel: true,
        function: renderGenericSelect(
          isFormEditable && !isUpdateOperation,
          mapValuesArrayToSelectComponentValues(
            newGroupsList.filter(group => !groups.some(x => x.groupId === group.id)),
            'name',
            'id',
            {
              defaultValue: '',
              defaultText: currentGroup.groupName || selectAnOptionText,
            },
          ),
        ),
      },
      {
        id: 'accessLevel',
        useRequiredLabel: true,
        function: renderGenericSelect(
          true,
          mapValuesArrayToSelectComponentValues(
            getEnumKeys(OPERATOR_ACCESS_LEVEL).map(item => ({
              key: getEnumText(OPERATOR_ACCESS_LEVEL, item),
              value: item.toUpperCase(),
            })),
            'key',
            'value',
            {
              defaultValue: '',
              defaultText: selectAnOptionText,
            },
          ),
        ),
      },
    ];

    return (
      <>
        <RequiredFieldsTip />
        <BaseFormFields
          authToken={authToken}
          fieldsTextKey={fieldsTextKey}
          fieldsRenderConfig={fieldsRenderConfig}
          refDataLoadFunctions={isUpdateOperation ? [] : [this.configurationDataApi.getMerchantGroups]}
          refDataLoadCallback={isUpdateOperation ? null : this.updateRefDataState}
          dataStatusLoadCallback={dataStatusLoadCallback}
        />
      </>
    );
  }

  updateRefDataState(refDataArray) {
    this.setState({ newGroupsList: refDataArray[0].groups });
  }
}
