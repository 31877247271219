import React from 'react';
import ConfigurationDataApi from '../../../../api/ConfigurationData';
import CommonTable from '../../../common-components/CommonTable';
import { getConfigSection, removeEmptyValuesFromObject } from '../../../../common/utils';
import commonPropTypes from '../../../../common/common-prop-types';
import TargetMerchantAccountsFilter from './TargetMerchantAccountsFilter';
import InfoIconText from '../../../common-components/InfoIconText';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';

const uiTexts = require('../../../../resources/uiTexts.json');

class TargetMerchantAccountsPage extends React.Component {
  static propTypes = {
    ...commonPropTypes.table,
  };

  constructor(props) {
    super(props);
    this.state = {
      pointer: null,
      canMakeRequest: true,
      successMessage: false,
    };
    const { authToken } = this.props;
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
    this.loadData = this.loadData.bind(this);
    this.deleteTargetMerchantAccount = this.deleteTargetMerchantAccount.bind(this);
  }
  render() {
    const { authToken, textsKey, isFormEditable, merchantData } = this.props;
    const { pointer } = this.state;

    const headerLevelsSeparator = getConfigSection(uiTexts, 'common.headerLevelsSeparator');
    const parentHeader = getConfigSection(uiTexts, `${textsKey}.table.header`);
    const historyParentHeader = `${parentHeader} ${headerLevelsSeparator} ${merchantData.name}`;

    const scrollAtributes = {
      pointer,
    };

    const columnsInfo = [
      {
        title: 'billingReferenceC2',
        info: getConfigSection(
          uiTexts,
          'merchants.channels.form.fields.targetMerchantAccounts.info.billingReferenceC2Info',
        ),
      },
    ];

    return (
      <>
        <div className="u-mt">
          <InfoIconText
            text={getConfigSection(uiTexts, `${textsKey}.info.additionalInfo`)}
            testId="additionalInfo-info-text"
          />
        </div>
        <TargetMerchantAccountsFilter
          filterTargetMerchantAccounts={filters => this.filterTargetMerchantAccounts(filters)}
          textsKey={textsKey}
        ></TargetMerchantAccountsFilter>
        {this.state.successMessage && this.renderSuccessMessage()}
        <CommonTable
          authToken={authToken}
          textsKey={textsKey}
          parentLevelHeader={historyParentHeader}
          dataFunctions={{
            loadData: this.loadData,
            deleteItem: this.deleteTargetMerchantAccount,
          }}
          canAddNewItem={isFormEditable}
          canClickOnItem={true}
          isRowDeletable={true}
          isFormEditable={isFormEditable}
          isRowEditable={true}
          isInfiniteLoading={true}
          showItemMessage={true}
          scrollAtributes={scrollAtributes}
          onRef={ref => (this.tableRef = ref)}
          columnsInfo={columnsInfo}
          testIdPrefix="target-merchant-accounts-table"
        />
      </>
    );
  }

  async loadData(searchParams, resetPointer) {
    const { merchantId, channelId } = this.props.match.params;
    const { pointer, canMakeRequest } = this.state;

    if (!canMakeRequest && !resetPointer) {
      return;
    }

    if (!resetPointer) {
      searchParams.pointer = pointer;
    }

    const targetMerchantAccounts = await this.configurationDataApi.getTargetMerchantAccounts(
      merchantId,
      channelId,
      searchParams,
    );

    this.setState({
      pointer: targetMerchantAccounts.nextPointer,
      canMakeRequest: !!targetMerchantAccounts.nextPointer,
    });

    return targetMerchantAccounts.results;
  }

  async filterTargetMerchantAccounts(values) {
    this.tableRef.filterData(removeEmptyValuesFromObject(values));
  }

  async deleteTargetMerchantAccount(targetMerchantAccount) {
    const { merchantId, channelId } = this.props.match.params;
    const data = this.tableRef.state.data.filter(item => item.id !== targetMerchantAccount.id);

    this.tableRef.setState({
      ...this.tableRef,
      data,
    });

    this.setState({
      ...this.state,
      successMessage: false,
    });

    const response = await this.configurationDataApi.deleteTargetMerchantAccount(
      merchantId,
      channelId,
      targetMerchantAccount.id,
    );

    this.setState({
      ...this.state,
      successMessage: true,
    });

    return response;
  }

  renderSuccessMessage() {
    const text = getConfigSection(uiTexts, 'common.successMessage');
    return (
      <p className="merchant-changes-success-message" data-testid="success-message">
        {text}
      </p>
    );
  }
}

export default connect()(withRouter(TargetMerchantAccountsPage));
