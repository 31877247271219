import React from 'react';

import ConfigurationDataApi from '../../../api/ConfigurationData';
import CommonTable from '../../common-components/CommonTable';
import commonPropTypes from '../../../common/common-prop-types';

export default class Table extends React.Component {
  static propTypes = {
    ...commonPropTypes.Table,
  };

  constructor(props) {
    super(props);
    const { authToken } = this.props;

    this.loadCountriesList = this.loadCountriesList.bind(this);
    this.deleteCountry = this.deleteCountry.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
  }

  render() {
    const { authToken, textsKey } = this.props;

    return (
      <CommonTable
        authToken={authToken}
        textsKey={textsKey}
        dataFunctions={{
          loadData: this.loadCountriesList,
          deleteItem: this.deleteCountry,
        }}
        isRowEditable={false}
        hasMaxColumnSize={true}
        testIdPrefix="countries-table"
      />
    );
  }

  loadCountriesList() {
    return this.configurationDataApi.getCountries();
  }

  deleteCountry(country) {
    return this.configurationDataApi.deleteCountry(country);
  }
}
