import { getConfigSection } from '../utils';

const uiTexts = require('../../resources/uiTexts.json');

export const NOTIFICATIONS_SEARCH_FILTERS = [
  {
    label: '',
    value: '',
  },
  {
    label: getConfigSection(uiTexts, 'merchants.notifications.filter.objectId'),
    value: 'objectId',
  },
  {
    label: getConfigSection(uiTexts, 'merchants.notifications.filter.objectType'),
    value: 'objectType',
  },
];
