import React from 'react';
import BaseEditForm from '../common-components/BaseEditForm';
import FormFields from './FormFields';
import commonPropTypes from '../../common/common-prop-types';
import ConfigurationDataApi from '../../api/ConfigurationData';
import { BlockNavigation } from '../common-components/BlockNavigation';

export default class EditForm extends React.PureComponent {
  static propTypes = {
    ...commonPropTypes.editForm,
  };

  constructor(props) {
    super(props);
    const { authToken } = this.props;
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
    this.valuesRef = React.createRef();

    this.state = {
      skipNavBlock: false,
    };
  }

  compareValues = () => {
    if (this.state.skipNavBlock) {
      return;
    }
    const { data } = this.props;

    const fVals = this.valuesRef.current.values;

    if (data.role !== fVals.role) {
      return [];
    }
  };

  render() {
    const { data, textsKey, authToken, onBack, isFormEditable, onSaveModifiedItem } = this.props;

    const overridenInitialValues = {
      role: '',
    };

    return (
      <>
        <BlockNavigation compareValues={this.compareValues} />
        <BaseEditForm
          valuesRef={this.valuesRef}
          data={data}
          authToken={authToken}
          textsKey={textsKey}
          onBack={onBack}
          onSaveModifiedItem={(...args) => {
            this.setState({ skipNavBlock: true });
            return onSaveModifiedItem(...args);
          }}
          onSaveNewItem={() => {}}
          overridenInitialValues={overridenInitialValues}
          validationSchema={FormFields.validationSchema}
          isFormEditable={isFormEditable}
          fieldsComponent={{
            type: FormFields,
          }}
        />
      </>
    );
  }
}
