export const revertMerchantDataTransformation = merchant => {
  const { credentials, gatekeeperSessions } = merchant;

  if (credentials) {
    if(credentials.apiUsername) {
      credentials.apiUsername = credentials.apiUsername.trim();
    }
    if(credentials.apiPassword) {
      credentials.apiPassword = credentials.apiPassword.trim();
    }
  }

  const newMerchant = Object.assign({}, merchant, {
    ...credentials,
    gatekeeperSessions: parseInt(gatekeeperSessions, 10),
  });
  delete newMerchant.accessLevel;
  delete newMerchant.users;
  delete newMerchant.credentials;

  const { fspayProgramCode, isEnabled: isFspayProgramCodeEnabled } = merchant.storedPayments;
  if (isFspayProgramCodeEnabled) {
    newMerchant.fspayProgramCode = fspayProgramCode;
  }
  delete newMerchant.storedPayments;
  return newMerchant;
};
