import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from '../../Atoms/Link/Link'
import { List } from '../../Atoms/List/List'

export function PageFoot(props) {
  const componentPageFootClass = classNames(
    {
      'c-page-foot  ': true,
      'u-pb-xlarge  ': true,
    },
    props.className
  ).trim()

  function renderElements() {
    return React.Children.map(props.children, (child, index) => {
      return (
        // eslint-disable-next-line
        <List.Item key={index}>
          <Link href={child.props.href} target={child.props.target} noStyling>
            {child}
          </Link>
        </List.Item>
      )
    })
  }

  return (
    <footer className={componentPageFootClass}>
      <div className="c-page-foot__top-container">
        <Link backToTop href="#top">
          {props.backToTopLabel}
        </Link>
      </div>
      <div className="o-page-wrap  u-pv-large">
        <div className="o-split  o-split--collapse@s  o-split--top o-split--reversed">
          <List className="c-page-foot__meta-nav  u-mb@s">
            {renderElements()}
          </List>
          {props.asteriskExplanation && <p>{props.asteriskExplanation}</p>}
          {props.note && <p className="u-mb-none">{props.note}</p>}
        </div>
      </div>
      {props.disclaimerContent && (
        <div className="o-page-wrap">{props.disclaimerContent}</div>
      )}
      <div className="o-page-wrap">
        <ul className="c-footnotes" />
      </div>
    </footer>
  )
}

PageFoot.Item = ({ children }) => {
  return children
}

PageFoot.Item.displayName = 'PageFoot.Item'

PageFoot.propTypes = {
  backToTopLabel: PropTypes.string,
  className: PropTypes.string,
  note: PropTypes.string,
  asteriskExplanation: PropTypes.string,
  disclaimerContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.arrayOf(children => {
    if (
      React.Children.toArray(children).some(
        child => child.type !== PageFoot.Item
      )
    ) {
      return new Error("'PageFoot' children should be of type 'PageFoot.Item'")
    }
    return undefined
  }),
}

PageFoot.Item.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  target: PropTypes.string,
}
PageFoot.defaultProps = {
  className: '',
  backToTopLabel: 'Back to top',
}
