import React from 'react';
import { withRouter } from 'react-router-dom';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import commonPropTypes from '../../../common/common-prop-types';
import CommonTable from '../../common-components/CommonTable';

export class UserMerchantsAccessTable extends React.Component {
  static propTypes = {
    ...commonPropTypes.table,
  };

  constructor(props) {
    super(props);
    const { authToken } = this.props;
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
    this.handleLoadUserAccessData = this.handleLoadUserAccessData.bind(this);
  }

  render() {
    const { authToken, textsKey, data } = this.props;
    return (
      Object.keys(data).length !== 0 && (
        <CommonTable
          authToken={authToken}
          textsKey={textsKey}
          dataFunctions={{
            loadData: this.handleLoadUserAccessData,
            deleteItem: this.handleDeleteUserAccess,
          }}
          creationPath="createMerchantAccess"
          handleEditRedirect={this.handleEditRedirect}
        />
      )
    );
  }

  handleLoadUserAccessData = async () => {
    const { data } = this.props;
    const merchants = await this.configurationDataApi.getUserMerchantsAccess(data.username);

    return merchants.merchants;
  };

  handleDeleteUserAccess = merchant => {
    const { data } = this.props;
    return this.configurationDataApi.deleteUserMerchantsAccess(data.username, merchant.merchantId);
  };

  handleEditRedirect = merchant => {
    const { location } = this.props;
    this.props.history.push(`${location.pathname}/editMerchantAccess/${merchant.merchantId}`);
  };
}

export default withRouter(UserMerchantsAccessTable);
