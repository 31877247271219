import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export function MediaTeaser({
  pretitle,
  title,
  media,
  children,
  className,
  reversed,
}) {
  const componentClass = classNames(
    {
      'c-media-teaser  ': true,
      'c-media-teaser--reversed  ': reversed,
    },
    className
  ).trim()

  return (
    <section className={componentClass}>
      <div className="c-media-teaser__media">{media}</div>
      <div className="c-media-teaser__caption">
        {pretitle && <div className="c-media-teaser__pretitle">{pretitle}</div>}
        {title && <h3 className="c-media-teaser__title">{title}</h3>}
        {children}
      </div>
    </section>
  )
}

MediaTeaser.propTypes = {
  children: props => {
    if (
      React.Children.toArray(props.children).some(
        child =>
          child.type !== MediaTeaser.Body && child.type !== MediaTeaser.Footer
      )
    ) {
      return new Error(
        "'MediaTeaser' children should be of type 'MediaTeaser.Body' or 'MediaTeaser.Footer'"
      )
    }
    return undefined
  },
}

MediaTeaser.Body = MediaTeaserBody
MediaTeaser.Footer = MediaTeaserFooter

function MediaTeaserBody({ children }) {
  return <div className="c-media-teaser__body">{children}</div>
}

MediaTeaserBody.propTypes = {
  children: PropTypes.node,
}

function MediaTeaserFooter({ children }) {
  return <div className="c-media-teaser__footer">{children}</div>
}

MediaTeaserFooter.propTypes = {
  children: PropTypes.node,
}
