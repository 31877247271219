import React from 'react';
import { withRouter, Prompt } from 'react-router-dom';
import { ModalConfirmOperation } from './ModalWindow';
import uiTexts from '../../resources/uiTexts.json';
import { getConfigSection } from '../../common/utils';

const texts = getConfigSection(uiTexts, 'common.changesModal');

/**
 * Blocks history changes: push, go, replace, etc.
 * Block page reloads
 * Blocks back button navigation (browser)
 *
 * There must only be one instance of BlockNavigationLogic per page
 */
class BlockNavigationLogic extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isConfirmationModalVisible: false,
      confirmationCb: () => {},
    };
  }

  /**
   * Prevents browser back button clicks and
   * page reloads: page reload, host change.
   * ! Does not handle hash change (spa routing)
   */

  blockBrowserNavigation = () => {
    // blocks page reloads and address changes (hosts)
    window.onbeforeunload = event => {
      const hasChanges = this.props.compareValues();
      if (hasChanges) {
        event.preventDefault();
        event.returnValue = true;
      }
    };

    const unblockBrowserNavigation = () => {
      window.onbeforeunload = null;
    };

    return unblockBrowserNavigation;
  };

  /**
   * Blocks navigation using
   * the history object: push, go, etc.
   */
  blockHistoryNavigation = () => {
    const originalMethods = {
      push: this.props.history.push,
      go: this.props.history.go,
      replace: this.props.history.replace,
      goBack: this.props.history.goBack,
      goForward: this.props.history.goForward,
    };
    this.props.history.push = (...args) => {
      this.commonHandler(() => originalMethods.push(...args));
    };
    this.props.history.go = (...args) => {
      this.commonHandler(() => originalMethods.go(...args));
    };
    this.props.history.replace = (...args) => {
      this.commonHandler(() => originalMethods.replace(...args));
    };
    this.props.history.goBack = (...args) => {
      this.commonHandler(() => originalMethods.goBack(...args));
    };
    this.props.history.goForward = (...args) => {
      this.commonHandler(() => originalMethods.goForward(...args));
    };

    const unblockHistoryNavigation = () => {
      this.props.history.push = originalMethods.push;
      this.props.history.go = originalMethods.go;
      this.props.history.replace = originalMethods.replace;
      this.props.history.goBack = originalMethods.goBack;
      this.props.history.goForward = originalMethods.goForward;
    };

    return unblockHistoryNavigation;
  };

  componentDidMount() {
    // block history object navigation
    this.unblockHistoryNavigation = this.blockHistoryNavigation();

    // block browser controls navigation
    this.unlbockBrowserNavigation = this.blockBrowserNavigation();
  }

  componentWillUnmount() {
    this.unblockHistoryNavigation();
    this.unlbockBrowserNavigation();
  }

  commonHandler = cb => {
    const hasChanges = this.props.compareValues();
    if (hasChanges) {
      this.setState({
        ...this.state,
        isConfirmationModalVisible: true,
        confirmationCb: cb,
      });
    } else {
      cb();
    }
  };

  /**
   * Prevents navigation with the
   * back button of the browser: back button
   */
  renderBackButtonPrompt = () => {
    return (
      <Prompt
        when={!this.state.isConfirmationModalVisible}
        message={() => {
          const hasChanges = this.props.compareValues();
          return hasChanges ? texts.browserPromptMessage : true;
        }}
      />
    );
  };

  renderConfirmationModal = () => {
    return (
      this.state.isConfirmationModalVisible && (
        <ModalConfirmOperation
          title={texts.title}
          buttonConfirmText={texts.confirm}
          onConfirm={() => {
            this.state.confirmationCb();
          }}
          buttonCancelText={texts.cancel}
          onCancel={() => {
            this.setState({
              ...this.state,
              isConfirmationModalVisible: false,
            });
          }}
          message={texts.message}
          testId="changes-modal"
        />
      )
    );
  };

  render() {
    return (
      <>
        {this.renderBackButtonPrompt()}
        {this.renderConfirmationModal()}
      </>
    );
  }
}

export const BlockNavigation = withRouter(BlockNavigationLogic);
