import { orderBy } from '@turbopay/ts-helpers/object-utils';

export const getMaxChannelPriorityForMerchant = channels => {
  let maxPrio = 0;
  if (channels) {
    const sortedData = orderBy(channels, 'priority', 'desc');
    maxPrio = sortedData.length ? sortedData[0].priority : 0;
  }
  return maxPrio;
};
