import * as Yup from 'yup';
import { isEmpty } from '@turbopay/ts-helpers/object-utils';

const errorsTexts = require('../../resources/errorTexts.json');

// Workaround for Yup.number() validation problem
// (spaces allowed in numerical fields). See:
// https://github.com/jquense/yup/issues/694
export const YupSpaceFreeNumber = Yup.number().transform(function(value, originalValue) {
  if (isEmpty(originalValue) || originalValue.match(/\D+/)) {
    return null;
  }
  return parseInt(originalValue, 10);
});

// This type of input is to be used in cases where a
// number input type can not handle the data correctly,
// e.g.: '0123' (with number the initial 0 would be lost)
export const YupStringOnlyDigits = Yup.string().matches(/^\d*$/, {
  message: errorsTexts.validation.onlyNumbers,
  excludeEmptyString: false,
});

export const YupStringWithoutWhitespaces = () => {
  return {
    name: 'cross-test-whitespaces',
    message: errorsTexts.validation.withoutWhiteSpaces,
    test: value => {
      return value ? !value.includes(' ') : true;
    },
  };
};
