import React from 'react';
import { Spinner } from 'cj-common-components';
import commonPropTypes from '../../../common/common-prop-types';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import BaseEditForm from '../../common-components/BaseEditForm';
import UserAccessFormFields from './UserGroupsAccessFormFields';
import { BlockNavigation } from '../../common-components/BlockNavigation';
import { reconcile } from '../../../common/utils';

export default class UserAccessEditForm extends React.PureComponent {
  static propTypes = {
    ...commonPropTypes.editForm,
  };

  constructor(props) {
    super(props);

    this.state = {
      groups: [],
      isLoading: true,
    };

    const { authToken } = this.props;
    this.handleLoadUserAccessData = this.handleLoadUserAccessData.bind(this);
    this.handleAddUserAccess = this.handleAddUserAccess.bind(this);
    this.handleModifyUserAccess = this.handleModifyUserAccess.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);

    this.valuesRef = React.createRef();
  }

  componentDidMount() {
    this.handleLoadUserAccessData();
  }

  compareValues = group => {
    const fVals = this.valuesRef.current.values;

    if (
      reconcile(fVals.groupId) !== reconcile(group.groupId) ||
      reconcile(fVals.accessLevel) !== reconcile(group.accessLevel)
    ) {
      return [];
    }
  };

  render() {
    const overridenInitialValues = {
      merchantId: '',
    };

    const {
      authToken,
      onBack,
      isFormEditable,
      textsKey,
      match: {
        params: { groupId },
      },
      match,
    } = this.props;

    const { isLoading, groups } = this.state;
    const group = this.getGroup(groupId) || {};

    return isLoading ? (
      <Spinner center small={false} fullPage />
    ) : (
      <>
        <BlockNavigation compareValues={() => this.compareValues(group)} />
        <BaseEditForm
          valuesRef={this.valuesRef}
          data={group}
          authToken={authToken}
          textsKey={textsKey}
          onBack={() => onBack(match)}
          onSaveModifiedItem={this.handleModifyUserAccess}
          onSaveNewItem={this.handleAddUserAccess}
          overridenInitialValues={overridenInitialValues}
          fieldsComponent={{
            type: UserAccessFormFields,
          }}
          validationSchema={UserAccessFormFields.validationSchema}
          isFormEditable={isFormEditable}
          customProps={{ groups, currentGroup: group }}
        />
      </>
    );
  }

  handleAddUserAccess = async group => {
    const {
      match: {
        params: { userId },
      },
      onBack,
      match,
    } = this.props;

    const userAccess = await this.configurationDataApi.addUserGroupsAccess(userId, group);
    onBack(match);
    return userAccess;
  };

  handleModifyUserAccess = async group => {
    const {
      match: {
        params: { userId },
      },
      onBack,
      match,
    } = this.props;

    const { groupId, accessLevel } = group;
    const updateData = { accessLevel };
    await this.configurationDataApi.modifyUserGroupsAccess(userId, groupId, updateData);
    onBack(match);
    return group;
  };

  handleLoadUserAccessData = async () => {
    const {
      match: {
        params: { userId },
      },
    } = this.props;
    let groups = { groups: [] };

    groups = await this.configurationDataApi.getUserGroupsAccess(userId);
    this.setState({ groups: groups.groups });

    this.setState({ isLoading: false });
    return groups.groups;
  };

  getGroup(groupId) {
    const { groups } = this.state;
    return groups.find(x => x.groupId === groupId);
  }
}
