import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { getConfigSection } from '../../common/utils';
import config from '../../resources/config.json';

class AnchorField extends React.PureComponent {
  static propTypes = {
    text: PropTypes.string.isRequired,
    currentPath: PropTypes.string.isRequired,
    param: PropTypes.string.isRequired,
  };

  render() {
    const { text, currentPath, param} = this.props;
    const linksConfig = getConfigSection(config, 'ui.mainContainer.menu');

    let link;
    if(currentPath === linksConfig.corsDomains.link){
      link = linksConfig.merchants.link + '/' + param + currentPath;
    } else {
      link = currentPath + '/' + param;
    }

    return (
      <>
        <a
          href={link}
          onClick={e => {
            // prevent page refresh to avoid losing
            // redux state
            e.preventDefault();
            if (e.shiftKey || e.ctrlKey || e.metaKey) {
              window.open(link, '_blank');
            } else {
              this.props.history.push(link);
            }
          }}
        >
          {text}
        </a>
      </>
    );
  }
}

export default withRouter(AnchorField);
