import React from 'react';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import { NavLink } from 'react-router-dom';
import { getConfigSection } from '../common/utils';
import uiTexts from '../resources/uiTexts.json';
import { routes } from './config';

const headerLevelsSeparator = getConfigSection(uiTexts, 'common.headerLevelsSeparator');

const Breadcrumbs = ({ breadcrumbs }) => (
  <div id="app-breadcrumbs" data-testid="app-breadcrumbs">
    {breadcrumbs.map(({ match, breadcrumb }, i, arr) => {
      const isLastEl = i === arr.length - 1;
      return (
        <span key={match.url}>
          <NavLink to={match.url}>{breadcrumb}</NavLink>
          {!isLastEl && ` ${headerLevelsSeparator} `}
        </span>
      );
    })}
  </div>
);

export default withBreadcrumbs(routes)(Breadcrumbs);
