import PropTypes from 'prop-types';

const authTokenPropTypes = PropTypes.shape({
  accessToken: PropTypes.string.isRequired,
  accessTokenObject: PropTypes.shape({
    auth_time: PropTypes.number.isRequired,
    client_id: PropTypes.string.isRequired,
    'cognito:groups': PropTypes.arrayOf(PropTypes.string).isRequired,
    exp: PropTypes.number.isRequired,
    iat: PropTypes.number.isRequired,
    iss: PropTypes.string.isRequired,

    jti: PropTypes.string.isRequired,
    scope: PropTypes.string.isRequired,
    sub: PropTypes.string.isRequired,
    token_use: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    version: PropTypes.number.isRequired,
  }).isRequired,
  idToken: PropTypes.string.isRequired,
  idTokenObject: PropTypes.shape({
    at_hash: PropTypes.string.isRequired,
    aud: PropTypes.string.isRequired,
    auth_time: PropTypes.number.isRequired,
    'cognito:groups': PropTypes.arrayOf(PropTypes.string).isRequired,
    'cognito:username': PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    exp: PropTypes.number.isRequired,
    iat: PropTypes.number.isRequired,
    iss: PropTypes.string.isRequired,
    token_use: PropTypes.string.isRequired,
  }).isRequired,
}).isRequired;

const commonPropTypes = {
  authToken: authTokenPropTypes,
  formFields: {
    authToken: authTokenPropTypes,
    textsKey: PropTypes.string.isRequired,
    isFormEditable: PropTypes.bool.isRequired,
    // Injected by Formik, we don´t validate it
    // // eslint-disable-next-line no-any
    formikProps: PropTypes.any.isRequired,
    isUpdateOperation: PropTypes.bool,
    // This prop is a free-content object , therefore
    // imposible to define a unique structure
    // // eslint-disable-next-line no-any
    customProps: PropTypes.object,
  },
  editForm: {
    // data structure varies depending on the component, therefore
    // imposible to define a unique structure
    // // eslint-disable-next-line no-any
    data: PropTypes.object,
    tableData: PropTypes.array,
    authToken: authTokenPropTypes,
    textsKey: PropTypes.string.isRequired,
    onSaveModifiedItem: PropTypes.func,
    onSaveNewItem: PropTypes.func,
    onBack: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    isVisible: PropTypes.bool,
    isFormEditable: PropTypes.bool.isRequired,
    // This prop is a free-content object , therefore
    // imposible to define a unique structure
    // // eslint-disable-next-line no-any
    customProps: PropTypes.object,
    isSaveButtonVisible: PropTypes.bool,
  },
  table: {
    authToken: authTokenPropTypes,
    textsKey: PropTypes.string.isRequired,
    getParentState: PropTypes.func,
    setParentState: PropTypes.func,
    isFormEditable: PropTypes.bool,
    isSaveButtonVisible: PropTypes.bool,
    // This prop is a free-content object , therefore
    // imposible to define a unique structure
    // // eslint-disable-next-line no-any
    customProps: PropTypes.object,
  },
  router: {
    location: PropTypes.shape({
      hash: PropTypes.string,
      pathName: PropTypes.string,
      search: PropTypes.string,
      state: PropTypes.string,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      isExact: PropTypes.bool.isRequired,
      params: PropTypes.shape({ [PropTypes.string]: PropTypes.string }),
      path: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  },
};

export default commonPropTypes;
