import React from 'react';
import * as Yup from 'yup';
import BaseFormFields from '../../common-components/BaseFormFields';
import commonPropTypes from '../../../common/common-prop-types';
import { getConfigSection } from '../../../common/utils';
import { RequiredFieldsTip } from '../../common-components/RequiredFieldsTip';

const errorsTexts = require('../../../resources/errorTexts.json');
const error = getConfigSection(errorsTexts, 'validation');

export default class FormFields extends React.PureComponent {
  static validationSchema() {
    function validateJson() {
      const { achSettings } = this.parent;

      try {
        const achSettingsObj = JSON.parse(achSettings);
        const achSettingsString = JSON.stringify(achSettingsObj);
        const sizeInBytes = new Blob([achSettingsString], { type: 'application/json' }).size;

        if (sizeInBytes > 100000) return false;

        return true;
      } catch (e) {
        return false;
      }
    }

    return Yup.object().shape({
      achSettings: Yup.string()
        .required(error.mandatoryField)
        .test({
          name: 'test-valid-achSettings',
          message: error.json,
          test: validateJson,
        }),
    });
  }

  static propTypes = {
    ...commonPropTypes.formFields,
  };

  render() {
    const { textsKey, authToken, isFormEditable } = this.props;
    const fieldsTextKey = `${textsKey}.form.fields`;

    const { renderGenericTextarea } = BaseFormFields;

    const fieldsRenderConfig = [
      {
        id: 'achSettings',
        useRequiredLabel: true,
        function: renderGenericTextarea(isFormEditable, 20, 20),
      },
    ];

    return (
      <>
        <RequiredFieldsTip />
        <BaseFormFields
          authToken={authToken}
          fieldsTextKey={fieldsTextKey}
          fieldsRenderConfig={fieldsRenderConfig}
          testIdPrefix="ach-settings-form"
        />
      </>
    );
  }
}
