import React from 'react';
import ConfigurationDataApi from '../../api/ConfigurationData';
import commonPropTypes from '../../common/common-prop-types';
import CommonTable from '../common-components/CommonTable';
import { checkEditFormCanBeModified } from '../merchants/merchantDataUtils/checkEditFormCanBeModified';
import { ROUTE_KEYS, SORT_COLUMNS } from '../../common/constants';
import { withAuth } from '../../auth/withAuth';

class MerchantGroupListTable extends React.Component {
  static propTypes = {
    authToken: commonPropTypes.authToken,
  };

  constructor(props) {
    super(props);
    const { authToken } = this.props;

    this.loadMerchantGroupsList = this.loadMerchantGroupsList.bind(this);
    this.deleteMerchantGroup = this.deleteMerchantGroup.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
    this.state = {
      merchantGroupsList: [],
    };
  }

  render() {
    const { navigationFunction } = this.props;
    const { merchantGroupsList } = this.state;
    const isFormEditable = checkEditFormCanBeModified(merchantGroupsList);
    return (
      <CommonTable
        textsKey={ROUTE_KEYS.merchantGroups}
        dataFunctions={{
          loadData: this.loadMerchantGroupsList,
          addItem: this.addMerchantGroup,
          deleteItem: this.deleteMerchantGroup,
        }}
        isFormEditable={isFormEditable}
        navigationFunction={navigationFunction}
        defaultSortColumn={SORT_COLUMNS.CREATION_DATE}
        testIdPrefix="groups-table"
      />
    );
  }

  async loadMerchantGroupsList() {
    const data = await this.configurationDataApi.getMerchantGroups();

    return data.groups;
  }

  deleteMerchantGroup(merchantGroup) {
    return this.configurationDataApi.deleteMerchantGroup(merchantGroup.id);
  }
}

export default withAuth(MerchantGroupListTable);
