import React from 'react';
import BaseEditForm from '../../common-components/BaseEditForm';
import FormFields from './FormFields';
import commonPropTypes from '../../../common/common-prop-types';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import { BlockNavigation } from '../../common-components/BlockNavigation';
import { reconcile } from '../../../common/utils';

export default class EditForm extends React.PureComponent {
  static propTypes = {
    ...commonPropTypes.editForm,
  };

  constructor(props) {
    super(props);
    const { authToken } = this.props;

    this.addCurrency = this.addCurrency.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
    this.valuesRef = React.createRef();

    this.state = {
      skipNavBlock: false,
    };
  }

  compareValues = () => {
    if (this.state.skipNavBlock) {
      return;
    }

    const fVals = this.valuesRef.current.values;
    if (reconcile(fVals.isoCode) !== undefined) {
      return [];
    }
  };

  render() {
    const { textsKey } = this.props;
    const { authToken, onBack, onCancel, isVisible, isFormEditable } = this.props;
    const overridenInitialValues = {
      isoCode: '',
      name: '',
    };

    return (
      <>
        <BlockNavigation compareValues={this.compareValues} />
        <BaseEditForm
          valuesRef={this.valuesRef}
          data={{}}
          authToken={authToken}
          textsKey={textsKey}
          onBack={onBack}
          onSaveModifiedItem={() => {}}
          onSaveNewItem={this.addCurrency}
          onCancel={onCancel}
          isVisible={isVisible}
          fieldsComponent={{
            type: FormFields,
          }}
          validationSchema={FormFields.validationSchema}
          isFormEditable={isFormEditable}
          overridenInitialValues={overridenInitialValues}
        />
      </>
    );
  }

  async addCurrency(currency) {
    this.setState({ skipNavBlock: true });
    const { onBack } = this.props;
    await this.configurationDataApi.addCurrency(currency);
    onBack();
  }
}
