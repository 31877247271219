import { Button, ButtonContainer } from 'cj-common-components';
import React from 'react';
import { getConfigSection } from '../../common/utils';
import uiTexts from '../../resources/uiTexts.json';
import config from '../../resources/config.json';

export const RenderButtons = props => {
  const { isSaveButtonVisible, isSaveButtonEnable, isFormEditable, onSubmit, onBack } = props;
  const enabledSaveButton = isSaveButtonEnable && isFormEditable;
  const buttonTexts = getConfigSection(uiTexts, 'common.editForm.buttons');
  const buttonIcons = getConfigSection(config, 'ui.common.editForm.icons');
  const children = [];
  children.push(
    <Button
      key="base-edit-form-button-back"
      secondary
      type="button"
      icon={buttonIcons.back}
      iconReversed
      onClick={onBack}
      testId="back-button"
    >
      {buttonTexts.back}
    </Button>,
  );

  if (isSaveButtonVisible) {
    children.push(
      <Button
        key="base-edit-form-button-submit"
        secondary
        type="submit"
        icon={buttonIcons.save}
        iconReversed
        disabled={!enabledSaveButton}
        onClick={onSubmit}
        testId="save-button"
      >
        {buttonTexts.save}
      </Button>,
    );
  }

  return <ButtonContainer>{children}</ButtonContainer>;
};
