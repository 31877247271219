import React from 'react';
import { connect } from 'react-redux';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import CommonTable from '../../common-components/CommonTable';
import commonPropTypes from '../../../common/common-prop-types';
import { SORT_COLUMNS } from '../../../common/constants';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import EditForm from './EditForm';

class NotificationRetriesTable extends React.Component {
  static propTypes = {
    ...commonPropTypes.Table,
  };

  constructor(props) {
    super(props);
    this.state = {
      // will be set to null once the end is reached
      pointer: undefined,
    };
    const { authToken } = this.props;
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
    this.loadRetriesList = this.loadRetriesList.bind(this);
  }

  render() {
    const { authToken, textsKey, isFormEditable } = this.props;
    const { pointer } = this.state;
    const scrollAtributes = {
      pointer,
    };
    const creationPath = 'addRetry';

    return (
      <>
        <CommonTable
          authToken={authToken}
          textsKey={textsKey}
          dataFunctions={{
            loadData: this.loadRetriesList,
            addItem: item => item,
            deleteItem: () => {},
          }}
          editComponent={{
            type: EditForm,
          }}
          defaultSortColumn={SORT_COLUMNS.CREATION_DATE}
          canAddNewItem={isFormEditable}
          creationPath={creationPath}
          isRowDeletable={false}
          canClickOnItem={false}
          isFormEditable={isFormEditable}
          isRowEditable={false}
          isInfiniteLoading={true}
          scrollAtributes={scrollAtributes}
          testIdPrefix="notifications-retries-table"
        />
      </>
    );
  }

  async loadRetriesList() {
    const { merchantId } = this.props;
    const { pointer } = this.state;

    if (pointer === null) {
      return;
    }

    const retries = await this.configurationDataApi.getMerchantNotificationRetries(merchantId, pointer);

    const preparedResult = retries.results?.map(retry =>
      Object.assign({}, retry, {
        isDryRun: retry.isDryRun ? 'true' : 'false',
        totalNotificationsMatched: retry.totalNotificationsMatched.toString(),
      }),
    );

    this.setState({
      pointer: retries.nextPointer,
    });

    return preparedResult;
  }
}

export default connect()(withRouter(NotificationRetriesTable));
