import React from 'react'
import PropTypes from 'prop-types'
import './Cookie.css'

import { Button } from '../../Atoms/Button/Button'
import { Heading } from '../../Atoms/Heading/Heading'
import { Column, Layout } from '../Layout/Layout'

export function Cookie(props) {
  return (
    <div
      data-testid={props.testId}
      className={`c-cookie-container  ${props.className}`}
    >
      <Layout className="c-cookie-container__layout  ">
        <Column span="10/12" s="12/12">
          <Heading headerType={4} className="c-cookie-container__title  ">
            {props.title}
          </Heading>
          {props.children}
        </Column>
        <Column span="2/12" s="12/12" className="u-text-right">
          <Button
            className="c-cookie-container__button  "
            onClick={props.onClick}
          >
            {props.buttonText}
          </Button>
        </Column>
        <Column span="12/12">
          <Button
            className="c-cookie-container__button--small  "
            onClick={props.onClick}
            iconOnly
            small
            round
            icon="semantic-close"
          />
        </Column>
      </Layout>
    </div>
  )
}

Cookie.propTypes = {
  testId: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
}
