import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Tooltip } from '../../Molecules/Tooltip/Tooltip'

export function InfoIcon(props) {
  const componentInfoIconClass = classNames(
    {
      'c-icon  ': true,
      'c-info-icon  ': true,
      'c-icon--[semantic-info]': true,
    },
    props.className
  ).trim()

  return (
    <Tooltip
      allowHTML
      content={<React.Fragment>{props.children}</React.Fragment>}
    >
      {/* eslint-disable-next-line react/button-has-type */}
      <button
        data-testid={props.testId}
        className={componentInfoIconClass}
        data-tippy-content={props.children}
        type="button"
      />
    </Tooltip>
  )
}

InfoIcon.propTypes = {
  testId: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
}
