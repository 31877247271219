import React from 'react';
import ConfigurationDataApi from '../../api/ConfigurationData';
import CommonTable from '../common-components/CommonTable';
import commonPropTypes from '../../common/common-prop-types';
import { getConfigSection } from '../../common/utils';
import config from '../../resources/config.json';

const linksConfig = getConfigSection(config, 'ui.mainContainer.menu');

export default class Table extends React.Component {
  static propTypes = {
    ...commonPropTypes.Table,
  };

  constructor(props) {
    super(props);
    const { authToken } = this.props;

    this.loadUserList = this.loadUserList.bind(this);
    this.modifyUser = this.modifyUser.bind(this);

    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
  }

  render() {
    const { authToken, textsKey } = this.props;
    const redirectRowId = 'username';

    return (
      <CommonTable
        authToken={authToken}
        textsKey={textsKey}
        dataFunctions={{
          loadData: this.loadUserList,
          addItem: item => item,
          modifyItem: this.modifyUser,
        }}
        isRowDeletable={false}
        canAddNewItem={false}
        redirectPath={linksConfig.users.link}
        redirectRowId={redirectRowId}
        testIdPrefix="users-table"
      />
    );
  }

  async loadUserList() {
    const users = await this.configurationDataApi.getUsers();
    return users;
  }

  async modifyUser(user) {
    await this.configurationDataApi.modifyUser(user);
    return user;
  }
}
