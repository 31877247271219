import React from 'react';
import { connect } from 'react-redux';

const MerchantBreadCrumb = ({ merchantName }) => <span>{merchantName}</span>;
const mapStateToProps = state => {
  const { merchantName } = state.breadCrumbs;
  return { merchantName };
};

export default connect(mapStateToProps)(MerchantBreadCrumb);
