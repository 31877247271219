import React from 'react';
import { connect } from 'react-redux';

const TargetMerchantAccountReferenceBreadcrumb = ({ targetMerchantAccountReference }) => <span>{targetMerchantAccountReference}</span>;

const mapStateToProps = state => {
  const { targetMerchantAccountReference } = state.breadCrumbs;
  return { targetMerchantAccountReference };
};

export default connect(mapStateToProps)(TargetMerchantAccountReferenceBreadcrumb);
