import React from 'react';
import * as Yup from 'yup';
import { get, uniqueId } from '@turbopay/ts-helpers/object-utils';
import arrayMove from 'array-move';
import moment from 'moment';
import BaseFormFields from '../../common-components/BaseFormFields';
import commonPropTypes from '../../../common/common-prop-types';
import { getConfigSection, getSecondsFromTime, pipeAnd } from '../../../common/utils';
import { addDefaultSelectOptionToSelectValues } from '../../common-components/common-functions';
import { REGEX, RETRY_SCHEME_TYPES, TYPES, AUTHENTICATION_TYPES } from '../../../common/constants';
import { RequiredFieldsTip } from '../../common-components/RequiredFieldsTip';

const uiTexts = require('../../../resources/uiTexts.json');
const errorsTexts = require('../../../resources/errorTexts.json');
const error = getConfigSection(errorsTexts, 'validation');
const validationTexts = getConfigSection(errorsTexts, 'validation');

export default class FormFields extends React.PureComponent {
  static propTypes = {
    ...commonPropTypes.formFields,
  };

  static isAuthentication = authType =>
    authType === AUTHENTICATION_TYPES.BASIC || authType === AUTHENTICATION_TYPES.OAUTH;

  static validationSchema() {
    return Yup.object().shape({
      authorizationRetryPolicy: Yup.object({
        isRetryActive: Yup.bool(),
        retrySchema: Yup.string().when('isRetryActive', {
          is: true,
          then: Yup.string().required(error.mandatoryField),
        }),
        intervalsConfiguration: Yup.array().when(['isRetryActive', 'retrySchema'], {
          is: (isRetryActive, retrySchema) => isRetryActive && retrySchema === TYPES.AT_SPECIFIC_INTERVALS,
          then: Yup.array()
            .of(
              Yup.object({
                retryNumber: Yup.number(),
                intervalBetweenRetriesSec: Yup.number(),
              }),
            )
            .default([])
            .test('length > 0', 'At least 1 row must be added', value => value.length > 0)
            .test(
              'values >= 00:00:01',
              'All times must be >= 00:00:01',
              value => !value.find(v => v.intervalBetweenRetriesSec === 0),
            ),
        }),
        timeConfiguration: Yup.object().when(['isRetryActive', 'retrySchema'], {
          is: (isRetryActive, retrySchema) => isRetryActive && retrySchema === TYPES.AT_SPECIFIC_TIME,
          then: Yup.object({
            numberOfRetries: Yup.number()
              .max(99, validationTexts.integerSmallerThan.replace('$MAX$', 100))
              .min(1, validationTexts.integerGreaterThan.replace('$MIN$', 0))
              .required(error.mandatoryField),
            retryTime: Yup.string()
              .test('>=00:00:01', 'Minimum allowed value is 00:00:01', value => {
                const timeObject = moment(value, 'HH:mm:ss');
                const seconds = timeObject.seconds();
                const minutes = timeObject.minutes();
                const hours = timeObject.hours();

                const result = seconds + minutes * 60 + hours * 3600;

                return result !== 0;
              })
              .matches(REGEX.TIME_FORMAT, error.timeFormatError),
          }),
        }),
      }),
    });
  }

  constructor(props) {
    super(props);

    this.authorizationOnSortEnd = ({ oldIndex, newIndex }) => {
      const { formikProps } = this.props;
      const { setFieldValue } = formikProps;
      const { values } = formikProps;
      const authorizationSpecificIntervals = get(values, 'authorizationRetryPolicy.intervalsConfiguration', []);

      const authorizationSpecificIntervalsOrdered = arrayMove(authorizationSpecificIntervals, oldIndex, newIndex);

      setFieldValue('authorizationRetryPolicy.intervalsConfiguration', authorizationSpecificIntervalsOrdered);
    };

    this.authorizationAddNewLine = () => {
      const { formikProps } = this.props;
      const { values, setFieldValue } = formikProps;
      const authorizationSpecificIntervals = get(values, 'authorizationRetryPolicy.intervalsConfiguration', []);
      const itemsCopy = [...authorizationSpecificIntervals];
      const nextIndex = itemsCopy.length;
      itemsCopy.push({
        retryNumber: nextIndex + 1,
        intervalBetweenRetriesSec: 0,
        id: uniqueId(),
      });
      setFieldValue('authorizationRetryPolicy.intervalsConfiguration', itemsCopy);
    };

    const authorizationItemToChange = {};

    this.authorizationHandleChange = (value, id) => {
      authorizationItemToChange.value = value;
      authorizationItemToChange.id = id;
    };

    this.authorizationHandleBlur = () => {
      const { formikProps } = this.props;
      const { values } = formikProps;
      const { setFieldValue } = formikProps;
      const authorizationSpecificIntervals = get(values, 'authorizationRetryPolicy.intervalsConfiguration', []);
      const itemsCopy = [...authorizationSpecificIntervals];
      // eslint-disable-next-line max-len
      const item = itemsCopy.find(i => i.id === authorizationItemToChange.id);
      if (item) {
        item.intervalBetweenRetriesSec = authorizationItemToChange.value;
        setFieldValue('authorizationRetryPolicy.intervalsConfiguration', itemsCopy);
      }
    };

    this.authorizationDeleteRow = (e, id) => {
      e.preventDefault();
      e.stopPropagation();
      const { formikProps } = this.props;
      const { setFieldValue } = formikProps;
      const { values } = formikProps;
      const authorizationSpecificIntervals = get(values, 'authorizationRetryPolicy.intervalsConfiguration', []);
      const itemsCopy = [...authorizationSpecificIntervals];
      const indexOfItem = itemsCopy.findIndex(i => i.id === id);
      itemsCopy.splice(indexOfItem, 1);
      setFieldValue('authorizationRetryPolicy.intervalsConfiguration', itemsCopy);
    };
  }

  render() {
    const { textsKey, authToken } = this.props;
    const fieldsTextKey = `${textsKey}.form.fields`;
    /**
     * "Required" label predicates
     */
    const isPolicyEnabled = formValues => {
      const isEnabled = formValues.authorizationRetryPolicy.isRetryActive;
      return isEnabled;
    };

    const isRetryAtTime = formValues => {
      const retrySchema = formValues.authorizationRetryPolicy.retrySchema;
      return retrySchema === 'SPECIFIC_TIME';
    };

    const isRetryAtIntervals = formValues => {
      const retrySchema = formValues.authorizationRetryPolicy.retrySchema;
      return retrySchema === 'SPECIFIC_INTERVALS';
    };

    const fieldsRenderConfig = [
      {
        id: 'authorizationRetryPolicy',
        subItems: [
          {
            id: 'group',
            function: () => {},
          },
          {
            id: 'isRetryActive',
            function: this.renderAuthorizationRetryActiveCheckbox.bind(this),
          },
          {
            id: 'retrySchema',
            useRequiredLabel: isPolicyEnabled,
            function: this.renderAuthorizationRetrySchemaTypeDropdown.bind(this),
          },
          {
            id: 'timeConfiguration',
            subItems: [
              {
                id: 'group',
                function: () => {},
              },
              {
                id: 'numberOfRetries',
                useRequiredLabel: pipeAnd(isPolicyEnabled, isRetryAtTime),
                function: this.renderAuthorizationSpecificTimeNumber.bind(this),
              },
              {
                id: 'retryTime',
                useRequiredLabel: pipeAnd(isPolicyEnabled, isRetryAtTime),
                function: this.renderAuthorizationSpecificTime.bind(this),
              },
            ],
          },
          {
            id: 'intervalsConfiguration',
            subItems: [
              {
                id: 'specificIntervals',
                useRequiredLabel: pipeAnd(isPolicyEnabled, isRetryAtIntervals),
                function: this.renderAuthorizationSpecificIntervalsTable.bind(this),
              },
            ],
          },
        ],
      },
    ];

    return (
      <>
        <RequiredFieldsTip />
        <BaseFormFields
          authToken={authToken}
          fieldsTextKey={fieldsTextKey}
          fieldsRenderConfig={fieldsRenderConfig}
          testIdPrefix="integration-settings-form"
        />
      </>
    );
  }

  renderAuthorizationSpecificTime(props) {
    const { formikProps, isFormEditable } = this.props;
    const { renderGenericDurationPicker } = BaseFormFields;
    const { values } = formikProps;
    const retrySchemaType = get(values, 'authorizationRetryPolicy.retrySchema');
    const enabled =
      isFormEditable &&
      get(values, 'authorizationRetryPolicy.isRetryActive', false) &&
      retrySchemaType === TYPES.AT_SPECIFIC_TIME;

    return renderGenericDurationPicker(enabled, '1')({ ...props, formikProps, value: getSecondsFromTime(props.value) });
  }

  renderAuthorizationSpecificTimeNumber(props) {
    const { formikProps, isFormEditable } = this.props;
    const { renderGenericInput } = BaseFormFields;
    const { values } = formikProps;
    const retrySchemaType = get(values, 'authorizationRetryPolicy.retrySchema');
    const enabled =
      isFormEditable &&
      get(values, 'authorizationRetryPolicy.isRetryActive', false) &&
      retrySchemaType === TYPES.AT_SPECIFIC_TIME;

    return renderGenericInput('number', enabled)(props);
  }

  renderAuthorizationRetryActiveCheckbox(props) {
    const { isFormEditable } = this.props;
    const { renderGenericCheckbox } = BaseFormFields;
    const checkboxEnabledText = getConfigSection(uiTexts, 'common.editForm.checkboxEnable');

    return renderGenericCheckbox(isFormEditable, checkboxEnabledText, {
      onChange: this.handleAuthorizationRetryActiveCheckboxChange(props.value),
    })(props);
  }

  handleAuthorizationRetryActiveCheckboxChange(isEnabled) {
    return () => {
      const { formikProps } = this.props;
      const { setFieldValue } = formikProps;
      setFieldValue('authorizationRetryPolicy.isRetryActive', !isEnabled);
    };
  }

  renderAuthorizationRetrySchemaTypeDropdown(props) {
    const { formikProps, isFormEditable, textsKey } = this.props;
    const { values } = formikProps;
    const { renderGenericSelect } = BaseFormFields;
    const selectAnOptionText = getConfigSection(uiTexts, `${textsKey}.form.placeholders.retrySchemaType`);
    const enabled = isFormEditable && get(values, 'authorizationRetryPolicy.isRetryActive', false);

    return renderGenericSelect(
      enabled,
      addDefaultSelectOptionToSelectValues(
        RETRY_SCHEME_TYPES.map(item => ({
          value: item,
          label: getConfigSection(uiTexts, `${textsKey}.form.types.${item.toLowerCase()}`),
        })),
        '',
        selectAnOptionText,
      ),
    )(props);
  }

  renderAuthorizationSpecificIntervalsTable(props) {
    const { formikProps, isFormEditable, textsKey } = this.props;
    const { values } = formikProps;
    const headers = [
      '',
      getConfigSection(uiTexts, `${textsKey}.form.headers.retryNumber`),
      getConfigSection(uiTexts, `${textsKey}.form.headers.intervalBetween`),
    ];

    const retrySchemaType = get(values, 'authorizationRetryPolicy.retrySchema');
    const enabled =
      isFormEditable &&
      get(values, 'authorizationRetryPolicy.isRetryActive', false) &&
      retrySchemaType === TYPES.AT_SPECIFIC_INTERVALS;

    const { renderDurationTable } = BaseFormFields;
    const authorizationSpecificIntervals = get(values, 'authorizationRetryPolicy.intervalsConfiguration', []);

    let isDisabledAddButton = false;

    if (authorizationSpecificIntervals.find(i => i.intervalBetweenRetriesSec === 0)) {
      isDisabledAddButton = true;
    }

    return renderDurationTable(
      authorizationSpecificIntervals,
      this.authorizationOnSortEnd,
      this.authorizationAddNewLine,
      this.authorizationDeleteRow,
      this.authorizationHandleChange,
      this.authorizationHandleBlur,
      enabled,
      headers,
      'authorizationRetryPolicy.intervalsConfiguration',
      isDisabledAddButton,
    )({ ...props, testIdPrefix: 'integration-settings-form' });
  }
}
