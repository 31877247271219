import React from 'react';
import * as Yup from 'yup';
import { getConfigSection } from '../../../common/utils';
import BaseFormFields from '../../common-components/BaseFormFields';
import InfoIconText from '../../common-components/InfoIconText';
import commonPropTypes from '../../../common/common-prop-types';
import { mapValuesArrayToSelectComponentValues } from '../../common-components/common-functions';
import { COUNTRY_CODES } from '../../../common/constants';
import { COUNTRY_CODES_POOL_MODEL } from '../../../common/external-definitions/country-codes';
import { RequiredFieldsTip } from '../../common-components/RequiredFieldsTip';

const uiTexts = require('../../../resources/uiTexts.json');
const errorsTexts = require('../../../resources/errorTexts.json');

const error = getConfigSection(errorsTexts, 'validation');

const US_CA_COUNTRY_CODES = [COUNTRY_CODES_POOL_MODEL.UnitedStates.value, COUNTRY_CODES_POOL_MODEL.Canada.value];

export default class FormFields extends React.PureComponent {
  static validationSchema() {
    return Yup.object().shape({
      jpmMerchantId: Yup.string().required(error.mandatoryField),
      clearingName: Yup.string().required(error.mandatoryField),
      legalName: Yup.string().required(error.mandatoryField),
      email: Yup.string().required(error.mandatoryField),
      address: Yup.object({
        addressLine1: Yup.string()
          .required(error.mandatoryField)
          .max(60),
        city: Yup.string().required(error.mandatoryField),
        postalCode: Yup.string().required(error.mandatoryField),
        countryCode: Yup.string().required(error.mandatoryField),
        houseNumber: Yup.string().required(error.mandatoryField),
        state: Yup.string().when('countryCode', {
          is: val => US_CA_COUNTRY_CODES.includes(val),
          then: state => state.required(error.mandatoryField),
        }),
      }),
      optionalDetails: Yup.object({
        merchantCategoryCode: Yup.string().required(error.mandatoryField),
        bankIndustryCode: Yup.string().required(error.mandatoryField),
      }),
    });
  }

  static propTypes = {
    ...commonPropTypes.formFields,
  };

  constructor(props) {
    super(props);

    this.state = {
      showWarning: false,
    };

    this.handleCountryCodeChange = this.handleCountryCodeChange.bind(this);
    this.renderCheckbox = this.renderCheckbox.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  render() {
    const texts = this.props.uiTexts || uiTexts;
    const { authToken, textsKey, isFormEditable } = this.props;
    const fieldsTextKey = `${textsKey}.form.fields`;
    const selectAnOptionText = getConfigSection(texts, 'common.editForm.comboSelectAnOption');
    const { renderGenericSelect, renderGenericInput } = BaseFormFields;

    const fieldsRenderConfig = [
      {
        id: 'jpmMerchantId',
        useRequiredLabel: true,
        function: renderGenericInput('text', isFormEditable),
      },
      {
        id: 'clearingName',
        useRequiredLabel: true,
        function: renderGenericInput('text', isFormEditable),
      },
      {
        id: 'legalName',
        useRequiredLabel: true,
        function: renderGenericInput('text', isFormEditable),
      },
      {
        id: 'email',
        useRequiredLabel: true,
        function: renderGenericInput('text', isFormEditable),
      },
      {
        id: 'phone',
        function: renderGenericInput('text', isFormEditable),
      },
      {
        id: 'address',
        isBlock: true,
        isShadowBoxNeeded: true,
        numberOfBlocks: 2,
        blocks: [
          {
            id: 'addressBlockOne',
            subItems: [
              {
                id: 'addressLine1',
                useRequiredLabel: true,
                function: renderGenericInput('text', isFormEditable),
              },
              {
                id: 'addressLine2',
                function: renderGenericInput('text', isFormEditable),
              },
              {
                id: 'addressLine3',
                function: renderGenericInput('text', isFormEditable),
              },
              {
                id: 'state',
                function: renderGenericInput('text', isFormEditable),
              },
            ],
          },
          {
            id: 'addressBlockTwo',
            subItems: [
              {
                id: 'houseNumber',
                useRequiredLabel: true,
                function: renderGenericInput('text', isFormEditable),
              },
              {
                id: 'city',
                useRequiredLabel: true,
                function: renderGenericInput('text', isFormEditable),
              },
              {
                id: 'postalCode',
                useRequiredLabel: true,
                function: renderGenericInput('text', isFormEditable),
              },
              {
                id: 'countryCode',
                useRequiredLabel: true,
                function: renderGenericSelect(
                  true,
                  mapValuesArrayToSelectComponentValues(COUNTRY_CODES, 'isoCode', 'isoCode', {
                    defaultValue: '',
                    defaultText: selectAnOptionText,
                  }),
                  {
                    onChange: this.handleCountryCodeChange,
                  },
                ),
              },
            ],
          },
        ],
      },
      {
        id: 'optionalDetails',
        labelAddOn: (
          <InfoIconText
            text={getConfigSection(texts, `${fieldsTextKey}.optionalDetailsInfo`)}
            testId="optionalDetails-info-text"
          />
        ),
        isBlock: true,
        isShadowBoxNeeded: false,
        numberOfBlocks: 2,
        blocks: [
          {
            id: 'optionalDetailsBlockOne',
            subItems: [
              {
                id: 'is3dsEnabled',
                hasNoLabel: true,
                function: this.renderCheckbox,
              },
              {
                id: 'is3ds1FallbackEnabled',
                hasNoLabel: true,
                function: this.renderCheckbox,
              },
              {
                id: 'isNoScaFallbackEnabled',
                hasNoLabel: true,
                function: this.renderCheckbox,
              },
              {
                id: 'retrySoftDecline',
                hasNoLabel: true,
                function: this.renderCheckbox,
              },
              {
                id: 'allowFrictionlessRegistration',
                hasNoLabel: true,
                function: this.renderCheckbox,
              },
              {
                id: 'allowFrictionlessAuthorization',
                hasNoLabel: true,
                function: this.renderCheckbox,
              },
            ],
          },
          {
            id: 'optionalDetailsBlockTwo',
            subItems: [
              {
                id: 'merchantCategoryCode',
                useRequiredLabel: true,
                function: renderGenericInput('text', isFormEditable),
              },
              {
                id: 'bankIndustryCode',
                useRequiredLabel: true,
                function: renderGenericInput('text', isFormEditable),
              },
              {
                id: 'identifier',
                function: renderGenericInput('text', isFormEditable),
              },
            ],
          },
        ],
      },
    ];

    return (
      <>
        <RequiredFieldsTip />
        <BaseFormFields
          authToken={authToken}
          fieldsTextKey={fieldsTextKey}
          fieldsRenderConfig={fieldsRenderConfig}
          testIdPrefix="ntc-3ds-form"
        />
      </>
    );
  }

  handleCountryCodeChange(event) {
    const { formikProps } = this.props;
    const { setFieldValue } = formikProps;
    setFieldValue('address.countryCode', event.target.value);
  }

  renderCheckbox(props) {
    const { isFormEditable } = this.props;
    const { renderGenericCheckbox } = BaseFormFields;
    const texts = this.props.uiTexts || uiTexts;

    return renderGenericCheckbox(
      isFormEditable,
      getConfigSection(texts, `merchants.networkTokenAndThreeDsManagement.form.fields.${props.name}`),
      { onChange: this.handleCheckboxChange(props.value, props.name) },
    )(props);
  }

  handleCheckboxChange(isEnabled, fieldName) {
    return () => {
      const { formikProps } = this.props;
      const { setFieldValue } = formikProps;
      setFieldValue(fieldName, !isEnabled);
    };
  }
}
