import React from 'react';
import BaseEditForm from '../../common-components/BaseEditForm';
import FormFields from './FormFields';
import commonPropTypes from '../../../common/common-prop-types';
import { getConfigSection } from '../../../common/utils';
import { BlockNavigation } from '../../common-components/BlockNavigation';
import { diff } from 'deep-diff';

const uiTexts = require('../../../resources/uiTexts.json');

export default class EditForm extends React.PureComponent {
  static propTypes = {
    ...commonPropTypes.editForm,
  };

  constructor(props) {
    super(props);
    this.valuesRef = React.createRef();
  }

  compareValues = () => {
    const initialValues = this.valuesRef.current.initialValues;

    const origVals = {
      ...initialValues,
    };

    // internally modified
    if (this.props.data.authorizationRetryPolicy) {
      origVals.authorizationRetryPolicy = this.props.data.authorizationRetryPolicy;
    }

    const currentValues = this.valuesRef.current.values;

    let diffs = diff(origVals, currentValues) || [];

    diffs = diffs.filter(change => {
      if (change.kind === 'E' || change.kind === 'N') {
        if (change.lhs === undefined && change.rhs === '') {
          return false;
        }
        if (change.lhs === undefined && change.rhs === false && change.path.includes('isRetryActive')) {
          return false;
        }

        if (change.lhs === 'true' && change.rhs === true) {
          return false;
        }
        if (change.lhs === 'false' && change.rhs === false) {
          return false;
        }
      }
      return true;
    });

    return diffs.length === 0 ? undefined : diffs;
  };

  render() {
    const {
      data,
      authToken,
      textsKey,
      parentHeader,
      onSaveModifiedItem,
      onSaveNewItem,
      onBack,
      onCancel,
      isVisible,
      isFormEditable,
      customProps,
    } = this.props;

    const headerText = getConfigSection(uiTexts, `${textsKey}.form.header`);

    const additionalInitialValues = {
      authorizationRetryPolicy: {
        isRetryActive: undefined,
        retrySchema: undefined,
        intervalsConfiguration: [],
        timeConfiguration: {
          numberOfRetries: 0,
          retryTime: '00:00:00',
        },
      },
    };

    return (
      <>
        <BlockNavigation compareValues={this.compareValues} />
        <BaseEditForm
          valuesRef={this.valuesRef}
          data={data}
          authToken={authToken}
          textsKey={textsKey}
          parentLevelHeader={parentHeader}
          currentLevelHeader={headerText}
          onBack={onBack}
          onSaveModifiedItem={onSaveModifiedItem}
          onSaveNewItem={onSaveNewItem}
          onCancel={onCancel}
          isVisible={isVisible}
          fieldsComponent={{
            type: FormFields,
          }}
          additionalInitialValues={additionalInitialValues}
          validationSchema={FormFields.validationSchema}
          isFormEditable={isFormEditable}
          customProps={customProps}
        />
      </>
    );
  }
}
