import React from 'react';
import * as Yup from 'yup';
import BaseFormFields from '../../../../common-components/BaseFormFields';
import ConfigurationDataApi from '../../../../../api/ConfigurationData';
import { getConfigSection } from '../../../../../common/utils';
import { mapValuesArrayToSelectComponentValues } from '../../../../common-components/common-functions';
import commonPropTypes from '../../../../../common/common-prop-types';
import { RequiredFieldsTip } from '../../../../common-components/RequiredFieldsTip';

const uiTexts = require('../../../../../resources/uiTexts.json');

const errorsTexts = require('../../../../../resources/errorTexts.json');

const error = getConfigSection(errorsTexts, 'validation');

export default class FormFields extends React.PureComponent {
  static propTypes = {
    ...commonPropTypes.formFields,
  };

  static validationSchema() {
    const fieldValidations = getConfigSection(
      uiTexts,
      'merchants.channels.form.fields.targetMerchantAccounts.table.validation',
    );

    return Yup.object().shape({
      targetMerchantAccountReference: Yup.string()
        .test('whiteSpaces', fieldValidations.accountReference.whiteSpaces, function(item) {
          let firstChar = '';
          let lastChar = '';
          if (item && item.length) {
            firstChar = item[0];
            lastChar = item[item.length - 1];
          }

          return firstChar !== ' ' && lastChar !== ' ';
        })
        .required(error.mandatoryField)
        .max(127, error.maxLength.replace('$MAX$', 127)),
      merchantProgramCode: Yup.string()
        .notRequired()
        .max(127, error.maxLength.replace('$MAX$', 127)),
      merchantAccno: Yup.string()
        .required(error.mandatoryField)
        .max(127, error.maxLength.replace('$MAX$', 127)),
      billingReferenceC2: Yup.string()
        .notRequired()
        .max(127, error.maxLength.replace('$MAX$', 127)),
      merchantCurrency: Yup.string().required(error.mandatoryField),
    });
  }

  constructor(props) {
    super(props);
    const { authToken } = this.props;
    this.updateRefDataState = this.updateRefDataState.bind(this);

    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
    this.state = {
      currencyList: [],
    };
  }

  render() {
    const { textsKey, authToken, isFormEditable, dataStatusLoadCallback } = this.props;
    const { currencyList } = this.state;

    const fieldsTextKey = `${textsKey}.form.fields`;
    const { renderGenericSelect, renderGenericInput } = BaseFormFields;
    const selectAnOptionText = getConfigSection(uiTexts, 'common.editForm.comboSelectAnOption');

    const fieldsRenderConfig = [
      {
        id: 'targetMerchantAccountReference',
        function: renderGenericInput('text', isFormEditable),
        useRequiredLabel: true,
      },
      {
        id: 'merchantProgramCode',
        function: renderGenericInput('text', isFormEditable),
      },
      {
        id: 'merchantAccno',
        function: renderGenericInput('text', isFormEditable),
        useRequiredLabel: true,
      },
      {
        id: 'billingReferenceC2',
        function: renderGenericInput('text', isFormEditable),
      },
      {
        id: 'merchantCurrency',
        useRequiredLabel: true,
        function: renderGenericSelect(isFormEditable, [
          selectAnOptionText,
          ...mapValuesArrayToSelectComponentValues(currencyList, 'name', 'isoCode'),
        ]),
      },
    ];

    return (
      <>
        <RequiredFieldsTip />
        <BaseFormFields
          authToken={authToken}
          fieldsTextKey={fieldsTextKey}
          fieldsRenderConfig={fieldsRenderConfig}
          refDataLoadFunctions={[this.configurationDataApi.getCurrencies]}
          refDataLoadCallback={this.updateRefDataState}
          dataStatusLoadCallback={dataStatusLoadCallback}
          testIdPrefix="target-merchant-account-form"
        />
      </>
    );
  }

  updateRefDataState(refDataArray) {
    this.setState({ currencyList: refDataArray[0] });
  }
}
