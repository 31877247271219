import { get, isEmpty } from '@turbopay/ts-helpers/object-utils';
import { getEnumValueFromText } from '../../../common/utils';
import { OPERATOR_ACCESS_LEVEL } from '../../../common/constants';

export const checkEditFormCanBeModified = merchantUnderEdition => {
  // create new item
  if (isEmpty(get(merchantUnderEdition, 'id'))) {
    return true;
  }
  // edit existing item
  const accessLevel = get(merchantUnderEdition, 'accessLevel');
  if (isEmpty(accessLevel)) {
    return false;
  }

  const isMerchantLocked = get(merchantUnderEdition, 'isLocked');
  if (isMerchantLocked) {
    return false;
  }

  return getEnumValueFromText(OPERATOR_ACCESS_LEVEL, accessLevel) === OPERATOR_ACCESS_LEVEL.WRITE;
};
