import { createSlice } from '@reduxjs/toolkit';

const merchantFiltersSlice = createSlice({
  name: 'merchantFilters',
  initialState: {
    filters: null,
    sorting: null,
  },
  reducers: {
    setMerchantFilters: (state, action) => {
      const { filters } = action.payload;
      // eslint-disable-next-line no-param-reassign
      state.filters = filters;
    },
    setMerchantSorting: (state, action) => {
      const { sorting } = action.payload;
      // eslint-disable-next-line no-param-reassign
      state.sorting = sorting;
    },
  },
});

export const { setMerchantFilters, setMerchantSorting } = merchantFiltersSlice.actions;

export default merchantFiltersSlice.reducer;
