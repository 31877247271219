import React from 'react';
import * as Yup from 'yup';
import BaseFormFields from '../../common-components/BaseFormFields';
import { getConfigSection } from '../../../common/utils';
import commonPropTypes from '../../../common/common-prop-types';

const errorsTexts = require('../../../resources/errorTexts.json');

const error = getConfigSection(errorsTexts, 'validation');

export default class FormFields extends React.PureComponent {
  static propTypes = {
    ...commonPropTypes.formFields,
  };

  static validationSchema(productGroupsNames, isCreationFlow) {
    return Yup.object().shape({
      name: Yup.string()
        .required(error.mandatoryField)
        .test({
          name: 'cross-test-duplicate-name',
          message: error.duplicateProductGroupName,
          test: newName => (isCreationFlow ? !productGroupsNames.includes(newName) : true),
        })
        .max(100, errorsTexts.validation.nameLength100),
      description: Yup.string().required(error.mandatoryField),
    });
  }

  render() {
    const { textsKey, authToken, isUpdateOperation } = this.props;
    const isFieldEditable = !isUpdateOperation;
    const fieldsTextKey = `${textsKey}.form.fields`;
    const { renderGenericInput } = BaseFormFields;

    const fieldsRenderConfig = [
      {
        id: 'name',
        useRequiredLabel: true,
        function: renderGenericInput('text', isFieldEditable),
      },
      {
        id: 'description',
        useRequiredLabel: true,
        function: renderGenericInput('text', true),
      },
    ];

    return (
      <>
        <BaseFormFields
          authToken={authToken}
          fieldsTextKey={fieldsTextKey}
          fieldsRenderConfig={fieldsRenderConfig}
          testIdPrefix="product-form"
        />
      </>
    );
  }
}
