import React from 'react';
import ConfigurationDataApi from '../../api/ConfigurationData';
import commonPropTypes from '../../common/common-prop-types';
import CommonTable from '../common-components/CommonTable';

export default class Table extends React.Component {
  static propTypes = {
    authToken: commonPropTypes.authToken,
  };

  constructor(props) {
    super(props);
    const { authToken } = this.props;
    this.loadCorsDomains = this.loadCorsDomains.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
  }

  render() {
    const { textsKey, navigationFunction } = this.props;
    const thisTextKey = `${textsKey}.domainGroups`;

    return (
      <CommonTable
        textsKey={thisTextKey}
        canAddNewItem={false}
        canDeleteItem={false}
        canClickOnItem={false}
        dataFunctions={{
          loadData: this.loadCorsDomains,
        }}
        navigationFunction={navigationFunction}
        isFormEditable={false}
        isRowEditable={false}
        hasMaxColumnSize={true}
        testIdPrefix="cors-domains-table"
      />
    );
  }

 async loadCorsDomains() {
  //prepared data
  const result = await this.configurationDataApi.getAllMerchantsCorsDomains();

  const preparedResult = result.map(item =>
      Object.assign({}, item, {
        isMerchantLink: true
      }),
  );
  
  return preparedResult;
}
}
