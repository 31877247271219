import React from 'react';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import commonPropTypes from '../../../common/common-prop-types';
import CommonTable from '../../common-components/CommonTable';

export default class MerchantHistoryTable extends React.Component {
  static propTypes = {
    ...commonPropTypes.table,
  };

  constructor(props) {
    super(props);
    const { authToken } = this.props;
    this.loadMerchantHistoryData = this.loadMerchantHistoryData.bind(this);
    this.deleteHistory = this.deleteHistory.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
  }

  render() {
    const { authToken, textsKey, isFormEditable } = this.props;

    return (
      <CommonTable
        authToken={authToken}
        textsKey={textsKey}
        dataFunctions={{
          loadData: this.loadMerchantHistoryData,
          deleteItem: this.deleteHistory,
        }}
        isFormEditable={isFormEditable}
        isAccessLevelApplicable={false}
        isRowDeletable={false}
        canAddNewItem={false}
        testIdPrefix="history-table"
      />
    );
  }

  async loadMerchantHistoryData() {
    const { merchantId } = this.props;
    return await this.configurationDataApi.getMerchantHistory(merchantId);
  }

  deleteHistory() {
    return Promise.resolve();
  }
}
