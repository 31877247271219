import React, { useCallback } from 'react';

import { timeFromSeconds, secondsFromTime } from 'duration-formatter';
import { Button, Input } from 'cj-common-components';
import { getConfigSection } from '../../common/utils';
import { REGEX } from '../../common/constants';

const config = require('../../resources/config.json');

const icons = getConfigSection(config, 'ui.common.table.icons');

export default function DurationInput(props) {
  const {
    buttonIncrement,
    minValue,
    maxValue,
    onChange,
    name,
    className,
    enabled,
    noButtons,
    hoursPadding,
    formikProps,
    testIdPrefix,
  } = props;

  const [value, setValue] = React.useState(
    // eslint-disable-next-line react/destructuring-assignment
    props.value > 0 ? props.value : 0,
  );
  const [stringValue, setStringValue] = React.useState(invokeTimeFromSeconds(value));

  const BUTTON_INCREMENT = buttonIncrement || 1;

  function invokeTimeFromSeconds(seconds) {
    return timeFromSeconds(seconds, {
      seconds_decimal_places: 0,
      hours_padding: hoursPadding || 2,
    });
  }

  function setSeconds(e, secondsValue) {
    let newSecondsValue = secondsValue;
    if (minValue && newSecondsValue < minValue) {
      newSecondsValue = minValue;
    }

    if (maxValue && newSecondsValue > maxValue) {
      newSecondsValue = maxValue;
    }

    if (newSecondsValue < 0) {
      newSecondsValue = 0;
    }

    if (onChange) {
      e.target.value = invokeTimeFromSeconds(newSecondsValue);
      onChange(e, newSecondsValue);
    }

    setValue(newSecondsValue);
    setStringValue(invokeTimeFromSeconds(newSecondsValue));
  }

  function onChangeInput(e) {
    setStringValue(e.target.value);
    if (formikProps) {
      const { setFieldValue } = formikProps;
      setFieldValue(name, e.target.value);
    }

    if (checkField(e.target.value)) {
      const parsedSeconds = secondsFromTime(e.target.value, {
        template_string: '{H}:{M}:{S}',
      });

      if (parsedSeconds != null && onChange) {
        onChange(e, parsedSeconds);
      }
    }
  }

  function blurHandler(e) {
    if (checkField(e.target.value)) {
      if (e.target.value === '') {
        setStringValue(invokeTimeFromSeconds(value));
      } else {
        const parsedSeconds = secondsFromTime(e.target.value, {
          template_string: '{H}:{M}:{S}',
        });
        setSeconds(e, parsedSeconds);
      }
    }
    const { onBlur } = props;
    if (onBlur) {
      onBlur(e);
    }
  }

  function checkField(fieldValue) {
    const regEx = new RegExp(REGEX.TIME_FORMAT);
    return regEx.test(fieldValue);
  }

  const onChangeCallback = useCallback(onChangeInput, [formikProps, name, onChange]);
  const inputTestId = testIdPrefix ? `${testIdPrefix}-duration-input` : undefined;

  return (
    <div className={`duration-picker ${noButtons ? '' : 'o-inline-group'} ${className}`} onBlur={blurHandler}>
      <Input
        name={name}
        className={noButtons ? '' : 'o-inline-group__item'}
        type="text"
        onChange={onChangeCallback}
        value={stringValue}
        disabled={!enabled}
        testId={inputTestId}
      />
      {noButtons ? (
        ''
      ) : (
        <Button
          type="button"
          className="o-inline-group__item"
          onClick={e => setSeconds(e, value + BUTTON_INCREMENT)}
          icon={icons.increaseItem}
          testId="increase-button"
        />
      )}
      {noButtons ? (
        ''
      ) : (
        <Button
          type="button"
          className="o-inline-group__item"
          onClick={e => setSeconds(e, value - BUTTON_INCREMENT)}
          icon={icons.decreaseItem}
          testId="decrease-button"
        />
      )}
    </div>
  );
}
