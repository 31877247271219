import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Label } from '../Label/Label'

export function Tile(props) {
  const componentTileClass = classNames(
    {
      'c-tile-select  ': true,
      'is-error  ': props.isError,
    },
    props.className
  ).trim()

  const iconClass = `c-icon--[${props.icon}]  `

  const componentTileIconClass = classNames({
    'c-tile-select__icon  ': true,
    'c-icon  ': true,
    [iconClass]: true,
    'c-tile-select__icon--hidden-on-small-screens':
      props.iconHiddenSmallScreens,
  }).trim()

  function renderIcon() {
    if (props.icon) {
      return <i className={componentTileIconClass} />
    }
    return null
  }

  return (
    <div className={componentTileClass}>
      <Label className="c-tile-select__label" for={props.id} noStyle>
        <input
          data-testid={props.testId}
          className="c-tile-select__input"
          type={props.type}
          name={props.name}
          id={props.id}
          value={props.value}
          disabled={props.disabled}
          aria-disabled={props.disabled}
          checked={props.selected}
          onChange={props.onChange}
        />
        <span className="c-tile-select__body">
          <span className="c-tile-select__body-top">
            {renderIcon()}
            <span className="u-visually-hidden">{props.visuallyHidden}</span>
            <span className="c-tile-select__title">{props.title}</span>
            <span className="c-tile-select__text">{props.children}</span>
          </span>
          {props.withButton && (
            <span className="c-tile-select__body-bottom">
              <span className="c-btn  c-tile-select__button">
                <span className="c-btn__text">Optional Button</span>
              </span>
            </span>
          )}
        </span>
      </Label>
    </div>
  )
}

Tile.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  testId: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  visuallyHidden: PropTypes.string,
  icon: PropTypes.string,
  iconHiddenSmallScreens: PropTypes.bool,
  withButton: PropTypes.string,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  onChange: PropTypes.func,
  isError: PropTypes.bool,
  children: PropTypes.node,
}

Tile.defaultProps = {
  className: '',
  disabled: false,
  type: 'checkbox',
}
