import React from 'react';
import * as Yup from 'yup';
import BaseFormFields from '../../common-components/BaseFormFields';
import commonPropTypes from '../../../common/common-prop-types';
import { getConfigSection, textsArrayToString } from '../../../common/utils';
import InfoIconText from '../../common-components/InfoIconText';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import { ModalError, ModalSuccess, ModalConfirmOperation } from '../../common-components/ModalWindow';
import EnabledCulturesHeader from './EnabledCulturesHeader';

const uiTexts = require('../../../resources/uiTexts.json');

export default class FormFields extends React.PureComponent {
  static propTypes = {
    ...commonPropTypes.formFields,
  };

  static validationSchema() {
    return Yup.object().shape({});
  }

  constructor(props) {
    super(props);

    const { authToken } = this.props;

    this.state = {
      isUploadSuccessful: true,
      showInfo: false,
      showConfirm: false,
      merchant: {},
      merchantLoaded: false,
      successMessage: false,
    };

    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
  }

  componentDidMount() {
    this.loadMerchantData();
  }

  render() {
    const { textsKey, authToken, isFormEditable } = this.props;
    const fieldsTextKey = `${textsKey}.form.fields`;
    this.fieldsTextKey = fieldsTextKey;

    const { renderGenericButton, renderGenericUpload } = BaseFormFields;
    const texts = this.props.uiTexts || uiTexts;

    const fieldsRenderConfig = [
      {
        id: 'options',
        subItems: [
          {
            id: 'group',
            function: () => {},
          },
          {
            id: 'restoreDefault',
            labelAddOn: (
              <InfoIconText
                text={getConfigSection(texts, `${fieldsTextKey}.options.restoreDefaultInfo`)}
                testId="restoreDefault-info-text"
              />
            ),
            function: renderGenericButton(
              isFormEditable,
              getConfigSection(texts, `${fieldsTextKey}.options.resetDefault`),
              this.resetHandler.bind(this),
            ),
          },
          {
            id: 'download',
            labelAddOn: (
              <InfoIconText
                text={getConfigSection(texts, `${fieldsTextKey}.options.downloadInfo`)}
                testId="download-info-text"
              />
            ),
            function: renderGenericButton(
              true,
              getConfigSection(texts, `${fieldsTextKey}.options.downloadButton`),
              this.downloadHandler.bind(this),
            ),
          },
          {
            id: 'upload',
            labelAddOn: (
              <InfoIconText
                text={getConfigSection(texts, `${fieldsTextKey}.options.uploadInfo`)}
                testId="upload-info-text"
              />
            ),
            function: renderGenericUpload(isFormEditable, this.uploadHandler.bind(this)),
          },
        ],
      },
    ];

    const { isUploadSuccessful, showInfo, showConfirm, merchant, merchantLoaded } = this.state;

    return (
      <div>
        {!isUploadSuccessful && <>{this.renderErrorDialog()}</>}
        {showInfo && <>{this.renderSuccessDialog()}</>}
        {showConfirm && <>{this.renderConfirmDialog()}</>}
        {this.state.successMessage && this.renderSuccessMessage()}
        <EnabledCulturesHeader textsKey={textsKey} merchant={merchant} loaded={merchantLoaded} />
        <BaseFormFields
          authToken={authToken}
          fieldsTextKey={fieldsTextKey}
          fieldsRenderConfig={fieldsRenderConfig}
          testIdPrefix="culture-settings-form"
        />
      </div>
    );
  }

  renderErrorDialog() {
    const { errorMessage } = this.state;
    return (
      <ModalError
        title={errorMessage}
        onConfirm={() => {
          this.setState({
            ...this.state,
            isUploadSuccessful: true,
          });
        }}
      />
    );
  }

  renderConfirmDialog() {
    const texts = this.props.uiTexts || uiTexts;

    return (
      <ModalConfirmOperation
        title={getConfigSection(texts, 'common.table.dialogs.confirm.info')}
        buttonConfirmText={getConfigSection(texts, `${this.fieldsTextKey}.options.resetDefault`)}
        onConfirm={() => {
          const { formikProps } = this.props;
          const { values } = formikProps;
          const { merchantKey } = values;
          this.configurationDataApi
            .resetCulture(merchantKey)
            .then(res => {
              this.setState({
                ...this.state,
                showInfo: true,
                resultMessage: res.data.message,
                successMessage: true,
              });
            })
            .catch(() => {
              this.setState({
                ...this.state,
                successMessage: false,
              });
            })
            .finally(() => {
              this.setState({ ...this.state, showConfirm: false });
            });
        }}
        buttonCancelText={getConfigSection(texts, 'common.table.dialogs.confirm.buttonCancel')}
        onCancel={() => {
          this.setState({ ...this.state, showConfirm: false });
        }}
        message={getConfigSection(texts, 'common.table.dialogs.confirm.message')}
        testId="confirm-modal"
      />
    );
  }

  renderSuccessDialog() {
    const { resultMessage } = this.state;
    return (
      <ModalSuccess
        title={resultMessage}
        onConfirm={() => {
          this.setState({
            ...this.state,
            showInfo: false,
          });
        }}
      />
    );
  }

  resetHandler() {
    this.setState({
      ...this.state,
      showConfirm: true,
    });
  }

  downloadHandler() {
    const { formikProps } = this.props;
    const { values } = formikProps;
    const { merchantKey, name } = values;
    this.configurationDataApi.downloadCulture(merchantKey, name);
  }

  uploadHandler(event) {
    const fileList = event.target.files;
    const formData = new FormData();
    formData.append('file', fileList[0]);
    const { formikProps } = this.props;
    const { values } = formikProps;
    const { merchantKey } = values;
    this.submitFile(formData, merchantKey);
  }

  submitFile(data) {
    const { formikProps } = this.props;
    const { values } = formikProps;
    const { merchantKey } = values;
    this.configurationDataApi
      .uploadCulture(data, merchantKey)
      .then(res => {
        this.setState({
          ...this.state,
          isUploadSuccessful: true,
          showInfo: true,
          resultMessage: res.data.message,
          successMessage: true,
        });
      })
      .catch(err => {
        this.setState({
          ...this.state,
          isUploadSuccessful: false,
          errorMessage: textsArrayToString(err.response?.data?.message),
          successMessage: false,
        });
      });
  }

  async loadMerchantData() {
    const { formikProps } = this.props;
    const { values } = formikProps;
    const { merchantKey } = values;
    const data = await this.configurationDataApi.getCulture(merchantKey);
    this.setState({
      ...this.state,
      merchant: data,
      merchantLoaded: true,
    });
  }

  renderSuccessMessage() {
    const text = getConfigSection(uiTexts, 'common.successMessage');

    return (
      <p className="merchant-changes-success-message" data-testid="success-message">
        {text}
      </p>
    );
  }
}
