import { getConfigSection } from '../../../../../common/utils';

const uiTexts = require('../../../../../resources/uiTexts.json');

export const TARGET_MERCHANT_ACCOUNTS_SEARCH_FILTERS = [
  {
    label: getConfigSection(uiTexts, 'merchants.channels.form.fields.targetMerchantAccounts.filter.targetMerchantAccountReference'),
    value: 'targetMerchantAccountReference',
  },
];
