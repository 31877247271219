import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import Plyr from 'plyr'

export function Video(props) {
  const videoRef = useRef(null)
  const plyrInstance = useRef(null)

  useEffect(() => {
    plyrInit()
  }, [])

  function plyrInit() {
    plyrInstance.current = new Plyr(videoRef.current, {
      debug: false,
      autoplay: props.autoplay,
      invertTime: false,
      controls: [
        'play',
        'play-large',
        'progress',
        'current-time',
        'mute',
        'volume',
        'captions',
        'fullscreen',
      ],
      fullscreen: {
        fallback: true,
      },
      youtube: {
        noCookie: true,
      },
      urls: {
        youtube: {
          api: '', // Disable YouTube API (for title/aspect ratio calculation) calls through third-party noembed.com service.
        },
      },
    })
  }

  const plyrConfig = {}
  if (props.iconUrl) {
    plyrConfig.iconUrl = props.iconUrl
  }

  return (
    <video
      ref={videoRef}
      data-testid={props.testId}
      poster={props.poster}
      className="js-video"
      data-plyr-config={JSON.stringify(plyrConfig)}
      controls
    >
      {props.src &&
        Object.entries(props.src).map(entry => (
          <source
            key={`${entry[0]}${entry[1]}`}
            type={entry[0]}
            src={entry[1]}
          />
        ))}
      {props.alt}
    </video>
  )
}

Video.propTypes = {
  testId: PropTypes.string,
  src: PropTypes.objectOf(PropTypes.string),
  alt: PropTypes.string,
  poster: PropTypes.string,
  autoplay: PropTypes.bool,
  iconUrl: PropTypes.string,
}
