import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export function Wrapper({ children, pageWrapper = false }) {
  if (pageWrapper) {
    return <div className="o-main-container">{children}</div>
  }
  return <main>{children}</main>
}

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
  pageWrapper: PropTypes.bool,
}

export function Section({ children, pageWrap, altBackground }) {
  const componentListClass = classNames({
    'o-page-wrap  ': true,
    [`o-page-wrap--${pageWrap}`]: pageWrap,
  }).trim()

  return (
    <section
      className="o-content-section"
      data-theme={altBackground ? 'alternative' : undefined}
    >
      <div className={componentListClass}>{children}</div>
    </section>
  )
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  pageWrap: PropTypes.string,
  altBackground: PropTypes.bool,
}

function ComponentWrapper({ children }) {
  return <div className="o-component-wrapper">{children}</div>
}

ComponentWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

Section.ComponentWrapper = ComponentWrapper

export function Layout(props) {
  const componentListClass = classNames(
    {
      'o-layout  ': true,
      'o-layout--middle  ': props.middle,
      'o-layout--equal-height  ': props.equalHeight,
      'o-layout--center  ': props.center,
      'o-layout--right  ': props.right,
      'o-layout--flush  ': props.flush,
    },
    props.className
  ).trim()

  return <div className={componentListClass}>{props.children}</div>
}

Layout.propTypes = {
  className: PropTypes.string,
  middle: PropTypes.bool,
  equalHeight: PropTypes.bool,
  center: PropTypes.bool,
  right: PropTypes.bool,
  flush: PropTypes.bool,
  children: props => {
    if (
      React.Children.toArray(props.children).some(
        child => child.type !== Column
      )
    ) {
      return new Error("'Layout' children should be of type 'Column'")
    }
    return undefined
  },
}

export function Column(props) {
  const componentListClass = classNames(
    {
      'o-layout__item  ': true,
      [`u-${props.span}  `]: props.span,
      [`u-${props.xl}@xl  `]: props.xl,
      [`u-${props.l}@l  `]: props.l,
      [`u-${props.m}@m  `]: props.m,
      [`u-${props.s}@s  `]: props.s,
      [`u-${props.xs}@xs  `]: props.xs,
    },
    props.className
  ).trim()

  return <div className={componentListClass}>{props.children}</div>
}

const allowedFractions = [
  '1/1',
  '1/2',
  '2/2',
  '1/3',
  '2/3',
  '3/3',
  '1/4',
  '2/4',
  '3/4',
  '4/4',
  '1/5',
  '2/5',
  '3/5',
  '4/5',
  '5/5',
  '1/6',
  '2/6',
  '3/6',
  '4/6',
  '5/6',
  '6/6',
  '1/12',
  '2/12',
  '3/12',
  '4/12',
  '5/12',
  '6/12',
  '7/12',
  '8/12',
  '9/12',
  '10/12',
  '11/12',
  '12/12',
]

Column.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  span: PropTypes.oneOf(allowedFractions),
  xl: PropTypes.oneOf(allowedFractions),
  l: PropTypes.oneOf(allowedFractions),
  m: PropTypes.oneOf(allowedFractions),
  s: PropTypes.oneOf(allowedFractions),
  xs: PropTypes.oneOf(allowedFractions),
}
