import React from 'react';
import * as Yup from 'yup';
import BaseFormFields from '../common-components/BaseFormFields';
import commonPropTypes from '../../common/common-prop-types';
import UsersTable from './users/UsersTable';
import MerchantsTable from './merchants/MerchantsTable';

const errorsTexts = require('../../resources/errorTexts.json');

export default class FormFields extends React.PureComponent {
  static propTypes = {
    ...commonPropTypes.formFields,
  };

  static validationSchema() {
    return Yup.object().shape({
      name: Yup.string().required(errorsTexts.validation.mandatoryField),
    });
  }

  render() {
    const { textsKey, authToken, customProps } = this.props;
    const fieldsTextKey = `${textsKey}.form.fields`;
    const { renderGenericInput } = BaseFormFields;

    const fieldsRenderConfig = [
      {
        id: 'name',
        useRequiredLabel: true,
        function: renderGenericInput('text', true),
      },
    ];

    if (customProps.merchantGroupData?.name) {
      return (
        <>
          <BaseFormFields
            authToken={authToken}
            fieldsTextKey={fieldsTextKey}
            fieldsRenderConfig={fieldsRenderConfig}
            testIdPrefix="group-form"
          />
          <MerchantsTable authToken={authToken} textsKey={textsKey} merchantGroupData={customProps.merchantGroupData} />
          <UsersTable authToken={authToken} textsKey={textsKey} merchantGroupData={customProps.merchantGroupData} />
        </>
      );
    }
    return (
      <>
        <BaseFormFields authToken={authToken} fieldsTextKey={fieldsTextKey} fieldsRenderConfig={fieldsRenderConfig} />
      </>
    );
  }
}
