import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'cj-common-components';
import commonPropTypes from '../../common/common-prop-types';
import { ModalError } from './ModalWindow';
import { textsArrayToString } from '../../common/utils';

const STATUS_WORK_IN_PROGRESS = 1;
const STATUS_WORK_FINISHED = 2;
const STATUS_ERROR = 3;

export default class EnhancedForm extends React.Component {
  static propTypes = {
    data: PropTypes.shape().isRequired,
    isVisible: PropTypes.bool.isRequired,
    textsKey: PropTypes.string.isRequired,
    editComponent: PropTypes.shape({
      type: PropTypes.func.isRequired,
    }),
    authToken: commonPropTypes.authToken,
    isFormEditable: PropTypes.bool,
    parentLevelHeader: PropTypes.string,
    dataFunctions: PropTypes.shape({
      modifyItem: PropTypes.func.isRequired,
    }).isRequired,
    customProps: PropTypes.shape(),
    isSaveButtonVisible: PropTypes.bool,
    onBack: PropTypes.func,
  };

  static defaultProps = {
    isFormEditable: true,
  };

  constructor(props) {
    super(props);

    const { data } = this.props;

    this.state = {
      data,
      pageStatus: STATUS_WORK_FINISHED,
      errorKey: 'common.generalError',
    };
  }

  render() {
    const { pageStatus } = this.state;

    return (
      <div>
        {pageStatus === STATUS_WORK_IN_PROGRESS && this.renderSpinnerMainLayout()}
        {pageStatus === STATUS_WORK_FINISHED && this.renderEditItemForm()}
        {pageStatus === STATUS_ERROR && (
          <>
            {this.renderEditItemForm()}
            {this.renderErrorDialog()}
          </>
        )}
      </div>
    );
  }

  renderSpinnerMainLayout = () => {
    return <Spinner section small={false}></Spinner>;
  };

  renderErrorDialog() {
    const { errorKey } = this.state;
    return (
      <ModalError
        errorKey={errorKey}
        onConfirm={() => {
          this.setState({
            pageStatus: STATUS_WORK_FINISHED,
            errorKey: 'common.generalError',
          });
        }}
      />
    );
  }

  renderEditItemForm = () => {
    const {
      data,
      textsKey,
      editComponent,
      authToken,
      isFormEditable,
      customProps,
      isSaveButtonVisible,
      isVisible,
      onBack,
      onCancel,
      onSaveNewItem,
    } = this.props;
    const SpecificEditComponent = editComponent.type;

    const parentHeader = this.buildHeaderText();

    return (
      <>
        <SpecificEditComponent
          data={data}
          authToken={authToken}
          textsKey={textsKey}
          parentHeader={parentHeader}
          onBack={onBack}
          onSaveModifiedItem={this.saveModifiedDataItem}
          onSaveNewItem={onSaveNewItem || function() {}}
          isVisible={isVisible}
          onCancel={onCancel || function() {}}
          isFormEditable={isFormEditable}
          customProps={customProps}
          isSaveButtonVisible={isSaveButtonVisible}
        />
      </>
    );
  };

  buildHeaderText = () => {
    const { parentLevelHeader } = this.props;

    return parentLevelHeader;
  };

  saveModifiedDataItem = modifiedItem => {
    const { dataFunctions } = this.props;
    this.setState({
      pageStatus: STATUS_WORK_IN_PROGRESS,
    });
    return new Promise((resolve, reject) => {
      if (!this.handleDuplicateError(this.getItemsWithSameKey(modifiedItem))) {
        dataFunctions
          .modifyItem(modifiedItem)
          .then(updatedItem => {
            this.setState({
              data: updatedItem,
              pageStatus: STATUS_WORK_FINISHED,
            });
            resolve(updatedItem);
          })
          .catch(error => {
            this.setState({
              pageStatus: STATUS_ERROR,
              errorKey: textsArrayToString(error.response?.data?.message),
            });
            reject(error);
          });
      }
    });
  };

  getItemsWithSameKey = () => {
    const { data } = this.state;
    return data;
  };

  handleDuplicateError = (items, maxExistingItems = 0) => {
    if (items.length > maxExistingItems) {
      this.setState({
        pageStatus: STATUS_ERROR,
        errorKey: 'common.duplicatedRecord',
      });
      return true;
    }
    return false;
  };
}
