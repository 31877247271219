import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export function DefinitionList(props) {
  const componentDefinitionListClass = classNames(
    {
      'c-dl-split  ': props.split,
      'c-dl-split--responsive  ': props.split && props.responsive,
      'c-dl-horizontal  ':
        !props.split && !props.responsive && props.horizontal,
      'c-dl-horizontal--33-to-66  ': props.horizontal && props.horizontal33to66,
      'c-dl-horizontal--66-to-33  ': props.horizontal && props.horizontal66to33,
      'c-dl-horizontal--50-to-50  ': props.horizontal && props.horizontal50to50,
    },
    props.className
  ).trim()
  const componentDefinitionListTitleClass = classNames({
    'c-dl-horizontal__title  ':
      !props.split && !props.responsive && props.horizontal,
  }).trim()
  const componentDefinitionListItemClass = classNames({
    'c-dl-horizontal__item  ':
      !props.split && !props.responsive && props.horizontal,
  }).trim()

  function renderElements() {
    return React.Children.map(props.children, (child, index) => {
      if (!child) {
        return null
      }
      return (
        <React.Fragment
          // eslint-disable-next-line
          key={`${index}`}
        >
          <dt className={componentDefinitionListTitleClass}>
            {child.props.title}
          </dt>
          <dd className={componentDefinitionListItemClass}>{child}</dd>
        </React.Fragment>
      )
    })
  }

  return (
    <dl className={componentDefinitionListClass} data-testid={props.testId}>
      {renderElements()}
    </dl>
  )
}

DefinitionList.propTypes = {
  testId: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  split: PropTypes.bool,
  responsive: PropTypes.bool,
  horizontal33to66: PropTypes.bool,
  horizontal66to33: PropTypes.bool,
  horizontal50to50: PropTypes.bool,
}

DefinitionList.defaultProps = {
  className: '',
}

DefinitionList.Item = ({ children }) => {
  return children
}

DefinitionList.Item.propTypes = {
  children: PropTypes.node,
}

DefinitionList.Item.displayName = 'DefinitionList.Item'
