import React from 'react';
import BaseEditForm from '../../common-components/BaseEditForm';
import { WidgetConfigurationFormFields } from './FormFields';
import commonPropTypes from '../../../common/common-prop-types';
import { getConfigSection } from '../../../common/utils';
import { BlockNavigation } from '../../common-components/BlockNavigation';

const uiTexts = require('../../../resources/uiTexts.json');

export class WidgetConfigurationEditForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      skipNavBlock: false,
    };

    this.valuesRef = React.createRef();
  }

  static propTypes = {
    ...commonPropTypes.editForm,
  };

  compareValues = () => {
    if (this.state.skipNavBlock) {
      return;
    }
    const fVals = this.valuesRef.current.values;

    let hasChanges = fVals.headerImageData.headerImagePosition !== this.props.data.headerImageData.headerImagePosition;

    if (fVals.headerImageData.headerImage || fVals.footerImage) {
      hasChanges = true;
    }
    return hasChanges ? [] : undefined;
  };

  render() {
    const {
      data,
      authToken,
      textsKey,
      parentHeader,
      onSaveModifiedItem,
      onSaveNewItem,
      onBack,
      onCancel,
      isVisible,
      isFormEditable,
      customProps,
    } = this.props;

    const headerText = getConfigSection(uiTexts, `${textsKey}.header`);

    return (
      <>
        <BlockNavigation compareValues={this.compareValues} />
        <BaseEditForm
          valuesRef={this.valuesRef}
          data={data}
          authToken={authToken}
          textsKey={textsKey}
          parentLevelHeader={parentHeader}
          currentLevelHeader={headerText}
          onBack={onBack}
          onSaveModifiedItem={(...args) => {
            this.setState({ skipNavBlock: true });
            return onSaveModifiedItem(...args);
          }}
          onSaveNewItem={(...args) => {
            this.setState({ skipNavBlock: true });
            return onSaveNewItem(...args);
          }}
          onCancel={onCancel}
          isVisible={isVisible}
          isCreationFlow={false}
          fieldsComponent={{
            type: WidgetConfigurationFormFields,
          }}
          validationSchema={WidgetConfigurationFormFields.validationSchema}
          isFormEditable={isFormEditable}
          customProps={customProps}
        />
      </>
    );
  }
}
